import React, { useState, useEffect, useContext } from "react";
import { BlackAndWhite } from "../../themes/Colors";
import useWindowDimensions from "../../components/get-diemensions/UseWindowDiemensions";
import {
  BaseButtons,
  SectionTitle,
  TextAreaInput,
  TextInputField,
  TextInputFieldSearch,
} from "../../components/atoms";
import { AdminContext } from "../../context/admin-context";
import { useNavigate } from "react-router-dom";
import AdminCustomerTab from "./components/AdminCustomerTab";
import QuestionButton from "../customer-portal/components/medical-history/components/button/QuestionButton";
import Users from "./components/Users";
import { LoadingPopUp, MenuDropDown } from "../../components";
import DropDownButton from "../../components/drop-down-button/DropDownButton";
import Facilities from "./components/Facilities";
import Menus from "./components/Menus";
import { CSVLink } from "react-csv";
import DashBoard from "./components/DashBoard";
import MultiSelectorDate from "./components/multi-selector-date/MultiSelectorDate";
import MainScreenHolder from "./components/product-uploader/MainScreenHolder";
import ExcelExport from "./components/ExcelExport";
import { ProfileMenu } from "../canna-pos/components";
import WebsiteDisplayEdit from "./components/website-display-edit/WebsiteDisplayEdit";
import Inventory from "./components/inventory/Inventory";
import { HeadersPatients } from "../canna-pos/components/patient-display/components";
import PatientProfilePopUp from "./components/PatientProfilePopUp";
import Reporting from "./components/reporting/Reporting";

// import { useNavigate } from "react-router-dom";

const AdminEditor = () => {
  const { width, height } = useWindowDimensions();
  const [shrink, setShrink] = useState(false);

  const [onMenu, setOnMenu] = useState(false);
  const [patientStats, setPatientsStats] = useState(null);
  const [patientStatsAvalible, setPatientsStatsAvalible] = useState(false);
  const [prescriptionStats, setPrescriptionStats] = useState(null);

  const [patientCsvDownloadPopUp, setPatientCsvDownloadPopUp] = useState(false);
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [dayDate, setDayDate] = useState("");

  const [dayRegList, setDayRegList] = useState(null);

  const [tab, setTab] = useState("dashboard");
  const [subSelection, setSubSelection] = useState("none");
  const [userDisplay, setUserdisplay] = useState(false);
  const [csvExport, setCsvExport] = useState(null);

  const [websiteData, setWebsiteData] = useState(null);
  // const [urlPath, setUrlPath] = useState("");
  const admin = useContext(AdminContext);
  const navigate = useNavigate();

  const [storeStats, setStoreStats] = useState(null);
  const [productStats, setProductStats] = useState(null);
  const [saleStats, setSaleStats] = useState(null);
  const [staffStats, setStaffStats] = useState(null);

  //website welcome update fields
  const [welcomParaGraph1, setWelcomParagraph1] = useState("");
  const [welcomParaGraph2, setWelcomParagraph2] = useState("");
  const [welcomParaGraph3, setWelcomParagraph3] = useState("");

  const [merryFacesInfo, setMerryFacesInfo] = useState("");

  const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(null);

  const [refresh, setRefresh] = useState(false);

  const [search, setSearch] = useState("");
  const [customers, setCustomers] = useState(null);
  const [patientProfilePop, setPatientProfilePop] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const OnUserSearch = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/user/searchForUserPos",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            searchParameter: search,
            userId: admin.adminUserData.userId,
          }),
        }
      );

      const responseData = await response.json();

      setLoading(false);
      if (responseData.success) {
        setCustomers(responseData.data);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const downloadListfFetch = async () => {
    setLoading(true);

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/user/getPatintientList",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            date: day + " " + month + " " + dayDate + " " + "2024",
          }),
        }
      );

      const responseData = await response.json();
      if (!responseData.message) {
        setLoading(false);
        setDayRegList(responseData);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const GetStoreStats = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/admin/getStoreStatus",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: admin.adminUserData.userId,
          }),
        }
      );

      const responseData = await response.json();
      if (!responseData.error && !responseData.accessDenied) {
        setLoading(false);
        setStoreStats(responseData);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const GetProductStats = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/admin/getproductsStats",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: admin.adminUserData.userId,
          }),
        }
      );

      const responseData = await response.json();
      if (!responseData.error && !responseData.accessDenied) {
        setLoading(false);
        setProductStats(responseData);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  const GetStaffStats = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/admin/getStaffStats",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: admin.adminUserData.userId,
          }),
        }
      );

      const responseData = await response.json();
      if (!responseData.error && !responseData.accessDenied) {
        setLoading(false);
        setStaffStats(responseData);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const GoToSupport = () => {
    navigate("/supportAccess");
  };

  useEffect(() => {
    if (
      !admin.isloggedIn ||
      admin.adminUserData.type === "Customer" ||
      admin.adminUserData.type === "Doctor" ||
      admin.adminUserData.type === "Manager" ||
      admin.adminUserData.type === "Support" ||
      admin.adminUserData.type === "Investor"
    ) {
      navigate("/adminPortal");
    }
  }, []);

  useEffect(() => {
    setLoading(true);

    fetch("https://merry-jane-api.onrender.com/admin/getPatientStats")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setPatientsStats(resData);
        setPatientsStatsAvalible(true);
      });
    fetch("https://merry-jane-api.onrender.com/prescription/prescriptionStats")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setPrescriptionStats(resData);
        setLoading(false);
      });
    GetStaffStats();
    GetStoreStats();
    GetProductStats();
  }, [refresh]);
  useEffect(() => {
    fetch(
      "https://merry-jane-api.onrender.com/website/adminFetch/65784e47833b40e2a6f54c47"
    )
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setWebsiteData(resData);
      });
  }, [loading]);
  useEffect(() => {
    if (day !== "" && month !== "" && dayDate !== "") {
      //here will be the download trigger
      downloadListfFetch();
    }
  }, [day, month, dayDate]);

  // let paresedFile = JSON.parse(csvExport.users);

  return (
    <div
      style={{
        height: height,
        width: width,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        overflowX: "hidden",
        backgroundColor: BlackAndWhite.secondary,
        position: "relative",
        msOverflowX: "hidden",
        msOverflowY: "hidden",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          fontSize: 30,
          fontWeight: "700",
          color: BlackAndWhite.secondary,
          position: "fixed",
          width: width,
          backgroundColor: BlackAndWhite.primary,
          top: 0,
          height: 60,
          display: "flex",
          alignItems: "center",
          zIndex: 1000000001,
          WebkitBoxShadow: "0px 0px 10px 1px #00000033",
        }}
      >
        <div
          onClick={() => setShrink(shrink ? false : true)}
          style={{
            marginLeft: shrink ? 15 : 5,
            backgroundColor: shrink ? "#FFFFFFcc" : BlackAndWhite.secondary,
            height: shrink ? 95 : 135,
            width: shrink ? 95 : 202.5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: shrink ? 200 : 20,
            position: "absolute",
            top: shrink ? 10 : 5,
            WebkitBoxShadow: "0px 0px 10px 1px #00000033",
            transition: "all 0.3s",
            // borderStyle: "solid",
            // borderColor: BlackAndWhite.primary,
            // borderWidth: 5,
          }}
        >
          <div
            onClick={() => setShrink(shrink ? false : true)}
            style={{
              // marginLeft: shrink ? 5 : 5,
              backgroundColor: shrink
                ? BlackAndWhite.primary
                : BlackAndWhite.secondary,
              height: shrink ? 95 : 135,
              width: shrink ? 95 : 202.5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: shrink ? 200 : 8,
              // position: "absolute",
              // top: shrink ? 10 : 5,
              WebkitBoxShadow: "0px 0px 10px 1px #00000033",
              transition: "all 0.3s",
              zIndex: 10000001,
              // borderStyle: "solid",
              // borderColor: BlackAndWhite.primary,
              // borderWidth: 5,
            }}
          >
            <img
              alt="load"
              src={
                shrink
                  ? require("../../images/newLogos/2. Merry-Jane Logo White for Dark Background.png")
                  : require("../../images/newLogos/1. MJ Logo Black with White Surround.png")
              }
              style={{
                height: shrink ? 65 : 120,
                transition: "all 0.3s",
                zIndex: 10000001,
              }}
            />
          </div>
        </div>
        <div
          style={{
            flex: 1,
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginLeft: shrink ? 125 : 235,
              fontWeight: "bolder",
              transition: "all 0.3s",
              fontSize: 15,
            }}
          >
            {tab
              ? tab === "patients"
                ? "REGISTRY"
                : tab === "setupManage"
                ? "SETUP AND MANAGE"
                : tab.toLocaleUpperCase()
              : "loading"}
          </div>
        </div>

        <div
          style={{
            marginRight: 20,
            fontSize: 12,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "normal",
          }}
        >
          <div style={{ marginRight: 4 }}>
            {admin.adminUserData ? admin.adminUserData.firstName : "loading"}
          </div>
          <div style={{ marginRight: 12 }}>
            {admin.adminUserData ? admin.adminUserData.lastName : "loading"}
          </div>
          {/* //menu button */}
          <div
            onClick={() => setOnMenu(onMenu ? false : true)}
            style={{
              height: 25,
              width: 25,
              backgroundColor: onMenu
                ? BlackAndWhite.primary
                : BlackAndWhite.secondary,
              borderRadius: 100,
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              transition: "0.3s",
            }}
          >
            <div
              style={{
                height: 3,
                width: 3,
                borderRadius: 20,
                backgroundColor: onMenu
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary,
                transition: "0.3s",
              }}
            />
            <div
              style={{
                height: 3,
                width: 3,
                borderRadius: 20,
                backgroundColor: onMenu
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary,
                transition: "0.3s",
              }}
            />
            <div
              style={{
                height: 3,
                width: 3,
                borderRadius: 20,
                backgroundColor: onMenu
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary,
                transition: "0.3s",
              }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: shrink ? 80 : 212.5,
          backgroundColor: "#FFFFFF",
          height: "100%",
          marginTop: 10,
          alignItems: "center",
          transition: "all 0.3s",
          WebkitBoxShadow: "0px 0px 10px 1px #00000033",
          zIndex: 100000000,
          marginLeft: shrink ? 20 : 0,
        }}
      >
        <div style={{ height: shrink ? 110 : 145, transition: "all 0.3s" }} />
        {tab === "dashboard" ? null : shrink ? (
          <div
            onClick={() => {
              setTab("dashboard");
              setUserdisplay(false);
              setSubSelection("none");
            }}
            style={{
              height: 40,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              alt="icon"
              src={require("../../images/material-icons/backLeftBlack.png")}
              style={{
                height: 25,
                borderStyle: "solid",
                borderWidth: 1,
                borderRadius: 100,
                padding: 5,
                marginBottom: 10,
              }}
            />
          </div>
        ) : (
          <DropDownButton
            borderRadius={8}
            title={"BACK"}
            width={"95%"}
            height={40}
            onClick={() => {
              setTab("dashboard");
              setUserdisplay(false);
              setSubSelection("none");
            }}
          />
        )}

        {tab === "dashboard" ? (
          <>
            <QuestionButton
              borderOn={tab === "dashboard" ? true : false}
              title={shrink ? null : "DASHBOARD"}
              height={50}
              onClick={() => setTab("dashboard")}
              backgroundColor={
                tab === "dashboard"
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary
              }
              backgroundColorAlt={BlackAndWhite.primary}
              color={
                tab === "dashboard"
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary
              }
              colorAlt={BlackAndWhite.secondary}
              width={shrink ? 50 : "95%"}
              icon={
                tab === "dashboard"
                  ? require("../../images/material-icons/dashboardWhite.png")
                  : require("../../images/material-icons/dashboardBlack.png")
              }
              selected={tab === "dashboard" ? true : false}
              questionAmount={true}
              borderRadius={shrink ? "100%" : 8}
            />
            <div style={{ height: 5 }} />
          </>
        ) : null}
        {/* {tab === "pos" || tab === "dashboard" ? (
          <>
            <QuestionButton
              // notAvailable={true}
              borderRadius={shrink ? "100%" : 8}
              borderOn={tab === "pos" ? true : false}
              title={shrink ? null : "POS"}
              height={50}
              onClick={
                tab === "pos" ? () => setTab("dashboard") : () => setTab("pos")
              }
              backgroundColor={
                tab === "pos" ? BlackAndWhite.primary : BlackAndWhite.secondary
              }
              backgroundColorAlt={BlackAndWhite.primary}
              color={
                tab === "pos" ? BlackAndWhite.secondary : BlackAndWhite.primary
              }
              colorAlt={BlackAndWhite.secondary}
              width={shrink ? 50 : "95%"}
              icon={
                tab === "pos"
                  ? require("../../images/material-icons/posWhite.png")
                  : require("../../images/material-icons/posBlack.png")
              }
              icon2={require("../../images/material-icons/posWhite.png")}
              selected={tab === "pos" ? true : false}
              questionAmount={true}
            />
            <div style={{ height: 5 }} />
            {tab === "pos" ? (
              <BaseButtons
                // selected={subSelection === "productList" ? true : false}
                onClick={() => setSubSelection("processSale")}
                marginTopFalse={true}
                label={"PROCESS SALE"}
                mini={true}
                invert={subSelection === "processSale" ? false : true}
                height={40}
                width={"100%"}
                size={"95%"}
                fontSize={shrink ? 10 : null}
                borderRadius={8}
                menuButton={true}
              />
            ) : null}
            {tab === "pos" ? (
              <BaseButtons
                // selected={subSelection === "productList" ? true : false}
                onClick={() => setSubSelection("processOrder")}
                // marginTopFalse={true}
                label={"PROCESS ORDER"}
                mini={true}
                invert={subSelection === "processOrder" ? false : true}
                height={40}
                width={"100%"}
                size={"95%"}
                fontSize={shrink ? 10 : null}
                borderRadius={8}
                marginTop={5}
                menuButton={true}
              />
            ) : null}
            {tab === "pos" ? (
              <BaseButtons
                // selected={subSelection === "productList" ? true : false}
                onClick={() => setSubSelection("cashRegister")}
                // marginTopFalse={true}
                label={"CASH REGISTER"}
                mini={true}
                invert={subSelection === "cashRegister" ? false : true}
                height={40}
                width={"100%"}
                size={"95%"}
                fontSize={shrink ? 10 : null}
                borderRadius={8}
                marginTop={5}
                menuButton={true}
              />
            ) : null}
            {tab === "pos" ? (
              <BaseButtons
                // selected={subSelection === "productList" ? true : false}
                onClick={() => setSubSelection("salesHistory")}
                // marginTopFalse={true}
                label={"SALES HISTORY"}
                mini={true}
                invert={subSelection === "salesHistory" ? false : true}
                height={40}
                width={"100%"}
                size={"95%"}
                fontSize={shrink ? 10 : null}
                borderRadius={8}
                marginTop={5}
                menuButton={true}
              />
            ) : null}
            {tab === "pos" ? (
              <BaseButtons
                // selected={subSelection === "productList" ? true : false}
                onClick={() => setSubSelection("knowledgeBase")}
                // marginTopFalse={true}
                label={"KNOWLEDGE BASE"}
                mini={true}
                invert={subSelection === "knowledgeBase" ? false : true}
                height={40}
                width={"100%"}
                size={"95%"}
                fontSize={shrink ? 10 : null}
                borderRadius={8}
                marginTop={5}
                menuButton={true}
              />
            ) : null}
            {tab === "pos" ? (
              <BaseButtons
                // selected={subSelection === "productList" ? true : false}
                onClick={() => setSubSelection("customers")}
                // marginTopFalse={true}
                label={"CUSTOMERS"}
                mini={true}
                invert={subSelection === "customers" ? false : true}
                height={40}
                width={"100%"}
                size={"95%"}
                fontSize={shrink ? 10 : null}
                borderRadius={8}
                marginTop={5}
                menuButton={true}
              />
            ) : null}
          </>
        ) : null} */}
        {tab === "products" || tab === "dashboard" ? (
          <>
            <QuestionButton
              // inProgress={true}
              borderRadius={shrink ? "100%" : tab === "products" ? 8 : 8}
              borderOn={shrink ? true : tab === "products" ? true : false}
              title={shrink ? null : "PRODUCTS"}
              height={shrink ? 50 : tab === "products" ? 50 : 50}
              onClick={() => {
                setTab("products");
                setSubSelection("productList");
              }}
              backgroundColor={
                tab === "products"
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary
              }
              backgroundColorAlt={BlackAndWhite.primary}
              color={
                tab === "products"
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary
              }
              colorAlt={BlackAndWhite.secondary}
              width={shrink ? 50 : "95%"}
              icon={
                tab === "products"
                  ? require("../../images/material-icons/productWhite.png")
                  : require("../../images/material-icons/productBlack.png")
              }
              icon2={require("../../images/material-icons/productWhite.png")}
              selected={tab === "products" ? true : false}
              questionAmount={true}
            />

            <div style={{ height: 5 }} />
            {tab === "products" ? (
              <BaseButtons
                // selected={subSelection === "productList" ? true : false}
                onClick={() => setSubSelection("productList")}
                marginTopFalse={true}
                label={"ITEMS"}
                mini={true}
                invert={subSelection === "productList" ? false : true}
                height={40}
                width={"100%"}
                size={"95%"}
                fontSize={shrink ? 10 : null}
                borderRadius={8}
                menuButton={true}
              />
            ) : null}
            {tab === "products" ? (
              <BaseButtons
                // selected={subSelection === "productList" ? true : false}
                onClick={() => setSubSelection("encyclopedia")}
                marginTopFalse={false}
                label={"PRODUCT INFO"}
                mini={true}
                invert={subSelection === "encyclopedia" ? false : true}
                height={40}
                width={"100%"}
                size={"95%"}
                marginTop={5}
                fontSize={shrink ? 10 : null}
                borderRadius={8}
                menuButton={true}
              />
            ) : null}
            {tab === "products" ? (
              <BaseButtons
                // selected={subSelection === "productList" ? true : false}
                onClick={() => setSubSelection("attribute")}
                marginTopFalse={false}
                label={"ATTRIBUTES"}
                mini={true}
                invert={subSelection === "attribute" ? false : true}
                height={40}
                width={"100%"}
                size={"95%"}
                marginTop={5}
                fontSize={shrink ? 10 : null}
                borderRadius={8}
                menuButton={true}
              />
            ) : null}
            {tab === "products" ? (
              <BaseButtons
                // selected={subSelection === "productList" ? true : false}
                onClick={() => setSubSelection("labels")}
                marginTopFalse={false}
                label={"LABELS"}
                mini={true}
                invert={subSelection === "labels" ? false : true}
                height={40}
                width={"100%"}
                size={"95%"}
                marginTop={5}
                fontSize={shrink ? 10 : null}
                borderRadius={8}
                menuButton={true}
              />
            ) : null}
            {tab === "products" ? (
              <BaseButtons
                // selected={subSelection === "productList" ? true : false}
                onClick={() => setSubSelection("offers")}
                marginTopFalse={false}
                label={"OFFERS"}
                mini={true}
                invert={subSelection === "offers" ? false : true}
                height={40}
                width={"100%"}
                size={"95%"}
                marginTop={5}
                fontSize={shrink ? 10 : null}
                borderRadius={8}
                menuButton={true}
              />
            ) : null}
          </>
        ) : null}
        {tab === "inventory" || tab === "dashboard" ? (
          <>
            <QuestionButton
              // notAvailable={true}
              borderRadius={shrink ? "100%" : 8}
              borderOn={tab === "inventory" ? true : false}
              title={shrink ? null : "INVENTORY"}
              height={50}
              onClick={
                tab === "inventory"
                  ? null
                  : () => {
                      setTab("inventory");
                      setSubSelection("dashboard");
                    }
              }
              backgroundColor={
                tab === "inventory"
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary
              }
              backgroundColorAlt={BlackAndWhite.primary}
              color={
                tab === "inventory"
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary
              }
              colorAlt={BlackAndWhite.secondary}
              width={shrink ? 50 : "95%"}
              icon={
                tab === "inventory"
                  ? require("../../images/material-icons/inventoryWhite.png")
                  : require("../../images/material-icons/inventoryBlack.png")
              }
              icon2={require("../../images/material-icons/inventoryWhite.png")}
              selected={tab === "inventory" ? true : false}
              questionAmount={true}
            />
            <div style={{ height: 5 }} />
            {tab === "inventory" ? (
              <BaseButtons
                // selected={subSelection === "productList" ? true : false}
                onClick={() => setSubSelection("dashboard")}
                marginTopFalse={true}
                label={"DASHBOARD"}
                mini={true}
                invert={subSelection === "dashboard" ? false : true}
                height={40}
                width={"100%"}
                size={"95%"}
                fontSize={shrink ? 10 : null}
                borderRadius={8}
                menuButton={true}
              />
            ) : null}
            <div style={{ height: 5 }} />
            {tab === "inventory" ? (
              <BaseButtons
                // selected={subSelection === "productList" ? true : false}
                onClick={() => setSubSelection("myInventory")}
                marginTopFalse={true}
                label={"MY INVENTORY"}
                mini={true}
                invert={subSelection === "myInventory" ? false : true}
                height={40}
                width={"100%"}
                size={"95%"}
                fontSize={shrink ? 10 : null}
                borderRadius={8}
                menuButton={true}
                marginTop={5}
              />
            ) : null}
            {/* {tab === "inventory" ? (
              <BaseButtons
                // selected={subSelection === "productList" ? true : false}
                onClick={() => setSubSelection("countStockTake")}
                // marginTopFalse={true}
                label={"COUNT.STOCK TAKE"}
                mini={true}
                invert={subSelection === "countStockTake" ? false : true}
                height={40}
                width={"100%"}
                size={"95%"}
                fontSize={shrink ? 10 : null}
                borderRadius={8}
                marginTop={5}
                menuButton={true}
              />
            ) : null} */}
            {tab === "inventory" ? (
              <BaseButtons
                // selected={subSelection === "productList" ? true : false}
                onClick={() => setSubSelection("transfers")}
                // marginTopFalse={true}
                label={"TRANSFER DOCS"}
                mini={true}
                invert={subSelection === "transfers" ? false : true}
                height={40}
                width={"100%"}
                size={"95%"}
                fontSize={shrink ? 10 : null}
                borderRadius={8}
                marginTop={5}
                menuButton={true}
              />
            ) : null}
          </>
        ) : null}
        {tab === "patients" || tab === "dashboard" ? (
          <>
            {" "}
            <QuestionButton
              borderRadius={shrink ? "100%" : 8}
              borderOn={tab === "patients" ? true : false}
              title={shrink ? null : "REGISTRY"}
              height={50}
              onClick={
                tab === "patients"
                  ? () => setTab("dashboard")
                  : () => setTab("patients")
              }
              backgroundColor={
                tab === "patients"
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary
              }
              backgroundColorAlt={BlackAndWhite.primary}
              color={
                tab === "patients"
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary
              }
              colorAlt={BlackAndWhite.secondary}
              width={shrink ? 50 : "95%"}
              icon={
                tab === "patients"
                  ? require("../../images/material-icons/profileWhite.png")
                  : require("../../images/material-icons/profileBlack.png")
              }
              icon2={require("../../images/material-icons/profileWhite.png")}
              selected={tab === "patients" ? true : false}
              questionAmount={true}
            />
            {/* <div style={{ height: 5 }} /> */}
            {/* {tab === "patients" ? (
              <BaseButtons
                // selected={subSelection === "productList" ? true : false}
                onClick={() => setSubSelection("patients")}
                marginTopFalse={false}
                label={"PATIENTS"}
                mini={true}
                invert={subSelection === "patients" ? false : true}
                height={40}
                width={"100%"}
                size={"95%"}
                fontSize={shrink ? 10 : null}
                borderRadius={8}
                marginTop={5}
                menuButton={true}
              />
            ) : null}
            {tab === "patients" ? (
              <BaseButtons
                // selected={subSelection === "productList" ? true : false}
                onClick={() => setSubSelection("prescriptions")}
                marginTopFalse={false}
                label={"PRESCRIPTIONS"}
                mini={true}
                invert={subSelection === "prescriptions" ? false : true}
                height={40}
                width={"100%"}
                size={"95%"}
                fontSize={shrink ? 10 : null}
                borderRadius={8}
                marginTop={5}
                menuButton={true}
              />
            ) : null} */}
          </>
        ) : null}
        <div style={{ height: 5 }} />
        {tab === "reporting" || tab === "dashboard" ? (
          <>
            <QuestionButton
              // notAvailable={true}
              borderRadius={shrink ? "100%" : 8}
              borderOn={tab === "reporting" ? true : false}
              title={shrink ? null : "REPORTING"}
              height={50}
              onClick={
                tab === "reporting"
                  ? () => setTab("dashboard")
                  : () => {
                      setTab("reporting");
                      setSubSelection("dashboard");
                    }
              }
              backgroundColor={
                tab === "reporting"
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary
              }
              backgroundColorAlt={BlackAndWhite.primary}
              color={
                tab === "reporting"
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary
              }
              colorAlt={BlackAndWhite.secondary}
              width={shrink ? 50 : "95%"}
              icon={
                tab === "reporting"
                  ? require("../../images/material-icons/reportWhite.png")
                  : require("../../images/material-icons/reportBlack.png")
              }
              icon2={require("../../images/material-icons/reportWhite.png")}
              selected={tab === "reporting" ? true : false}
              questionAmount={true}
            />
            <div style={{ height: 5 }} />
            {tab === "reporting" ? (
              <BaseButtons
                // selected={subSelection === "productList" ? true : false}
                onClick={() => setSubSelection("dashboard")}
                marginTopFalse={true}
                label={"DASHBOARD"}
                mini={true}
                invert={subSelection === "dashboard" ? false : true}
                height={40}
                width={"100%"}
                size={"95%"}
                fontSize={shrink ? 10 : null}
                borderRadius={8}
                menuButton={true}
              />
            ) : null}
            <div style={{ height: 5 }} />
            {tab === "reporting" ? (
              <BaseButtons
                // selected={subSelection === "productList" ? true : false}
                onClick={() => setSubSelection("sales")}
                marginTopFalse={true}
                label={"SALES"}
                mini={true}
                invert={subSelection === "sales" ? false : true}
                height={40}
                width={"100%"}
                size={"95%"}
                fontSize={shrink ? 10 : null}
                borderRadius={8}
                menuButton={true}
              />
            ) : null}
            {tab === "reporting" ? (
              <BaseButtons
                // selected={subSelection === "productList" ? true : false}
                onClick={() => setSubSelection("inventoryReport")}
                // marginTopFalse={true}
                label={"INVENTORY"}
                mini={true}
                invert={subSelection === "inventoryReport" ? false : true}
                height={40}
                width={"100%"}
                size={"95%"}
                fontSize={shrink ? 10 : null}
                borderRadius={8}
                marginTop={5}
                menuButton={true}
              />
            ) : null}
            {tab === "reporting" ? (
              <BaseButtons
                // selected={subSelection === "productList" ? true : false}
                onClick={() => setSubSelection("register")}
                // marginTopFalse={true}
                label={"REGISTER"}
                mini={true}
                invert={subSelection === "regsiter" ? false : true}
                height={40}
                width={"100%"}
                size={"95%"}
                fontSize={shrink ? 10 : null}
                borderRadius={8}
                marginTop={5}
                menuButton={true}
              />
            ) : null}
            {tab === "reporting" ? (
              <BaseButtons
                // selected={subSelection === "productList" ? true : false}
                onClick={() => setSubSelection("payments")}
                // marginTopFalse={true}
                label={"PAYMENTS"}
                mini={true}
                invert={subSelection === "payments" ? false : true}
                height={40}
                width={"100%"}
                size={"95%"}
                fontSize={shrink ? 10 : null}
                borderRadius={8}
                marginTop={5}
                menuButton={true}
              />
            ) : null}
            {tab === "reporting" ? (
              <BaseButtons
                // selected={subSelection === "productList" ? true : false}
                onClick={() => setSubSelection("customerReporting")}
                // marginTopFalse={true}
                label={"CUSTOMERS"}
                mini={true}
                invert={subSelection === "customerReporting" ? false : true}
                height={40}
                width={"100%"}
                size={"95%"}
                fontSize={shrink ? 10 : null}
                borderRadius={8}
                marginTop={5}
                menuButton={true}
              />
            ) : null}
            {tab === "reporting" ? (
              <BaseButtons
                // selected={subSelection === "productList" ? true : false}
                onClick={() => setSubSelection("faciltiesReporting")}
                // marginTopFalse={true}
                label={"FACILITIES"}
                mini={true}
                invert={subSelection === "faciltiesReporting" ? false : true}
                height={40}
                width={"100%"}
                size={"95%"}
                fontSize={shrink ? 10 : null}
                borderRadius={8}
                marginTop={5}
                menuButton={true}
              />
            ) : null}
          </>
        ) : null}
        {tab === "setupManage" || tab === "dashboard" ? (
          <>
            <QuestionButton
              // notAvailable={true}
              borderRadius={shrink ? "100%" : 8}
              borderOn={tab === "setupManage" ? true : false}
              title={shrink ? null : "SETUP AND MANAGE"}
              height={50}
              onClick={
                tab === "setupManage"
                  ? () => setTab("dashboard")
                  : () => {
                      setTab("setupManage");
                      setSubSelection("All FACILITES");
                    }
              }
              backgroundColor={
                tab === "setupManage"
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary
              }
              backgroundColorAlt={BlackAndWhite.primary}
              color={
                tab === "setupManage"
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary
              }
              colorAlt={BlackAndWhite.secondary}
              width={shrink ? 50 : "95%"}
              icon={
                tab === "setupManage"
                  ? require("../../images/material-icons/setupAndMangeWhite.png")
                  : require("../../images/material-icons/setupAndMangeBlack.png")
              }
              icon2={require("../../images/material-icons/setupAndMangeWhite.png")}
              selected={tab === "setupManage" ? true : false}
              questionAmount={true}
            />
            <div style={{ height: 5 }} />
            {tab === "setupManage" ? (
              <BaseButtons
                // selected={subSelection === "productList" ? true : false}
                onClick={() => setSubSelection("All FACILITES")}
                marginTopFalse={true}
                label={"FACILITIES"}
                mini={true}
                invert={subSelection === "All FACILITES" ? false : true}
                height={40}
                width={"100%"}
                size={"95%"}
                fontSize={shrink ? 10 : null}
                borderRadius={8}
                menuButton={true}
              />
            ) : null}
            {tab === "setupManage" ? (
              <BaseButtons
                // selected={subSelection === "productList" ? true : false}
                onClick={() => setSubSelection("ALL USERS")}
                // marginTopFalse={true}
                label={"USERS"}
                mini={true}
                invert={subSelection === "ALL USERS" ? false : true}
                height={40}
                width={"100%"}
                size={"95%"}
                fontSize={shrink ? 10 : null}
                borderRadius={8}
                marginTop={5}
                menuButton={true}
              />
            ) : null}

            {tab === "setupManage" ? (
              <BaseButtons
                // selected={subSelection === "productList" ? true : false}
                onClick={() => setSubSelection("suppliers")}
                // marginTopFalse={true}
                label={"SUPPLIERS"}
                mini={true}
                invert={subSelection === "suppliers" ? false : true}
                height={40}
                width={"100%"}
                size={"95%"}
                fontSize={shrink ? 10 : null}
                borderRadius={8}
                marginTop={5}
                menuButton={true}
              />
            ) : null}
          </>
        ) : null}

        {tab === "website" || tab === "dashboard" ? (
          <>
            <QuestionButton
              borderRadius={shrink ? "100%" : 8}
              borderOn={tab === "website" ? true : false}
              title={shrink ? null : "WEBSITE"}
              height={50}
              onClick={() => {
                setTab("website");
                setSubSelection("website");
              }}
              backgroundColor={
                tab === "website"
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary
              }
              backgroundColorAlt={BlackAndWhite.primary}
              color={
                tab === "website"
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary
              }
              colorAlt={BlackAndWhite.secondary}
              width={shrink ? 50 : "95%"}
              icon={
                tab === "website"
                  ? require("../../images/material-icons/webWhite.png")
                  : require("../../images/material-icons/webBlack.png")
              }
              icon2={require("../../images/material-icons/webWhite.png")}
              selected={tab === "website" ? true : false}
              questionAmount={true}
            />

            <div style={{ height: 5 }} />

            {tab === "website" ? (
              <BaseButtons
                // selected={subSelection === "productList" ? true : false}
                onClick={() => setSubSelection("website")}
                label={"WEB SITE"}
                mini={true}
                invert={subSelection === "website" ? false : true}
                height={40}
                width={"100%"}
                size={"95%"}
                fontSize={shrink ? 10 : null}
                borderRadius={8}
                marginTop={1}
                marginTopFalse={false}
                menuButton={true}
              />
            ) : null}
            {tab === "website" ? (
              <BaseButtons
                // selected={subSelection === "productList" ? true : false}
                onClick={() => setSubSelection("displayScwereens")}
                label={"POP UP SCREENS"}
                mini={true}
                invert={subSelection === "displayScreens" ? false : true}
                height={40}
                width={"100%"}
                size={"95%"}
                fontSize={shrink ? 10 : null}
                borderRadius={8}
                marginTop={5}
                marginTopFalse={false}
                menuButton={true}
              />
            ) : null}

            {tab === "website" ? (
              <BaseButtons
                // selected={subSelection === "productList" ? true : false}
                onClick={() => setSubSelection("webAppProductMenu")}
                label={"WEB APP PRODUCT MENU"}
                mini={true}
                invert={subSelection === "webAppProductMenu" ? false : true}
                height={40}
                width={"100%"}
                size={"95%"}
                fontSize={shrink ? 10 : null}
                borderRadius={8}
                marginTop={5}
                marginTopFalse={false}
                menuButton={true}
              />
            ) : null}
          </>
        ) : null}

        {tab === "dashboard" ? (
          <>
            <QuestionButton
              borderOn={tab === "support" ? true : false}
              title={shrink ? null : "SUPPORT"}
              height={50}
              onClick={() => GoToSupport()}
              backgroundColor={
                tab === "support"
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary
              }
              backgroundColorAlt={BlackAndWhite.primary}
              color={
                tab === "support"
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary
              }
              colorAlt={BlackAndWhite.secondary}
              width={shrink ? 50 : "95%"}
              icon={
                tab === "support"
                  ? require("../../images/material-icons/qrcodeWhite.png")
                  : require("../../images/material-icons/qrCodeBlack.png")
              }
              icon2={require("../../images/material-icons/qrcodeWhite.png")}
              selected={tab === "support" ? true : false}
              questionAmount={true}
              borderRadius={shrink ? "100%" : 8}
            />
            <div style={{ height: 5 }} />
          </>
        ) : null}

        {tab === "menus" || tab === "dashboard" ? (
          <>
            <QuestionButton
              borderRadius={shrink ? "100%" : 8}
              borderOn={tab === "menus" ? true : false}
              title={shrink ? null : "MENUS"}
              height={50}
              onClick={
                tab === "menus"
                  ? () => setTab("dashboard")
                  : () => {
                      setTab("menus");
                      setSubSelection("MENU 1");
                    }
              }
              backgroundColor={
                tab === "menus"
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary
              }
              backgroundColorAlt={BlackAndWhite.primary}
              color={
                tab === "menus"
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary
              }
              colorAlt={BlackAndWhite.secondary}
              width={shrink ? 50 : "95%"}
              icon={
                tab === "menus"
                  ? require("../../images/material-icons/menuWhite.png")
                  : require("../../images/material-icons/menuBlack.png")
              }
              icon2={require("../../images/material-icons/menuWhite.png")}
              selected={tab === "menus" ? true : false}
              questionAmount={true}
            />
            <MenuDropDown
              display={tab === "menus" ? true : false}
              subSelection={subSelection}
              setSubSelection={setSubSelection}
              buttonData={["MENU 1", "MENU 2", "MENU 3"]}
              setUserdisplay={setUserdisplay}
            />
          </>
        ) : null}
        <div style={{ height: 5 }} />
      </div>
      {/* screen displayed */}
      <div
        style={{
          height: "95%",
          flex: 1,
          overflowY: "hidden",
          marginTop: 85,
          position: "relative",
          backgroundColor: BlackAndWhite.secondary,
          overflowX: "hidden",
          width: "100%",
          display: "flex",
          flexDirection:
            subSelection === "ALL USERS" || subSelection === "ADD USERS"
              ? "column"
              : "row",
          // marginTop: 50,
        }}
      >
        {tab === "inventory" ? (
          <Inventory
            subSelection={subSelection}
            setSubSelection={setSubSelection}
            auth={admin ? admin.adminUserData : null}
          />
        ) : null}
        {tab === "website" ? <WebsiteDisplayEdit /> : null}
        {tab === "products" ? (
          <MainScreenHolder
            subSelection={subSelection}
            userDisplay={userDisplay}
            setUserdisplay={setUserdisplay}
            setSubSelection={setSubSelection}
            shrink={shrink}
          />
        ) : null}
        {tab === "patients" ? (
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <div
              style={{
                height: 55,
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* search section */}
              <div style={{ marginLeft: 10, marginRight: 10, marginBottom: 5 }}>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                    // width: 300,

                    borderWidth: 0.5,
                  }}
                >
                  <TextInputFieldSearch
                    placeholder={"Type here to search"}
                    height={30}
                    borderRadius={4}
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                  />
                  <div
                    style={{
                      height: 35,
                      width: 70,
                      backgroundColor: BlackAndWhite.primary,
                      borderRadius: 4,
                      display: "flex",
                      color: BlackAndWhite.secondary,
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      marginLeft: 5,
                    }}
                  >
                    <div
                      onClick={() => {
                        setSearch("");
                        setCustomers(null);
                      }}
                      style={{ fontSize: 12, fontWeight: "bold" }}
                    >
                      CLEAR
                    </div>
                  </div>
                  <div
                    style={{
                      height: 35,
                      width: 70,
                      backgroundColor: BlackAndWhite.primary,
                      borderRadius: 4,
                      display: "flex",
                      color: BlackAndWhite.secondary,
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: search === "" ? "not-allowed" : "pointer",
                      marginLeft: 10,
                    }}
                  >
                    <div
                      onClick={search === "" ? null : () => OnUserSearch()}
                      style={{
                        fontSize: 12,
                        fontWeight: "bold",
                        opacity: search === "" ? 0.5 : 1,
                      }}
                    >
                      SEARCH
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ flex: 1 }}></div>
            </div>
            <HeadersPatients />
            <AdminCustomerTab
              setData={setCsvExport}
              csvExport={csvExport}
              width={width}
              customers={customers}
              setSelectedPatient={setSelectedPatient}
              setPatientProfilePop={setPatientProfilePop}
            />
          </div>
        ) : null}
        {tab === "setupManage" &&
        (subSelection === "ALL USERS" || subSelection === "ADD USERS") ? (
          <Users
            subSelection={subSelection}
            userDisplay={userDisplay}
            setUserdisplay={setUserdisplay}
            setSubSelection={setSubSelection}
          />
        ) : null}
        {tab === "setupManage" ? (
          <Facilities
            height={height}
            width={width}
            subSelection={subSelection}
            userDisplay={userDisplay}
            setUserdisplay={setUserdisplay}
            setSubSelection={setSubSelection}
          />
        ) : null}
        {tab === "menus" ? (
          <Menus
            subSelection={subSelection}
            userDisplay={userDisplay}
            setUserdisplay={setUserdisplay}
            setSubSelection={setSubSelection}
          />
        ) : null}
        {tab === "dashboard" ? (
          <DashBoard
            height={height}
            patientStatsAvalible={patientStatsAvalible}
            prescriptionStats={prescriptionStats}
            patientStats={patientStats}
            onRefreshClick={() => setRefresh(refresh ? false : true)}
            loading={loading}
            staffStats={staffStats}
            storeStats={storeStats}
            productStats={productStats}
          />
        ) : null}
        {tab === "reporting" ? (
          <Reporting
            subSelection={subSelection}
            userDisplay={userDisplay}
            setUserdisplay={setUserdisplay}
            setSubSelection={setSubSelection}
            auth={admin ? admin.adminUserData : null}
          />
        ) : null}
      </div>
      {loading ? (
        <div
          style={{
            width: width,
            // height: "100%",
            zIndex: 20000000,
            position: "absolute",
            top: 0,
          }}
        >
          <LoadingPopUp display={loading} />
        </div>
      ) : null}
      <PatientProfilePopUp
        display={patientProfilePop}
        selectedPatient={selectedPatient}
        onCloseClick={() => {
          setPatientProfilePop(false);
          setSelectedPatient(null);
        }}
        loading={loading}
        setLoading={setLoading}
      />
      <ProfileMenu
        display={onMenu}
        username={
          admin.adminUserData ? admin.adminUserData.userName : "loading"
        }
        idNum={admin.adminUserData ? admin.adminUserData.idDoc : "loading"}
        store={admin.adminUserData ? admin.adminUserData.storeName : "loading"}
        province={
          admin.adminUserData ? admin.adminUserData.province : "loading"
        }
        onCancelClick={() => setOnMenu(false)}
      />
    </div>
  );
};

export default AdminEditor;
