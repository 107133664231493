import React from "react";

const NumberSpace = ({ value }) => {
  let checkValue;
  //   if (value) {
  //     return value.substring(0, 1) + " " + value.substring(2, 20);
  //   } else {
  //   return Number(new Intl.NumberFormat().format(value)).toFixed(2);
  if (value.match(Number(value).toFixed(2))) {
    checkValue = value.replace(
      /(?:(^\d{1,3})(?=(?:\d{3})*$)|(\d{3}))(?!$)/gm,
      "$1 $2"
    );
  } else {
    checkValue =
      value.replace(/(?:(^\d{1,3})(?=(?:\d{3})*$)|(\d{3}))(?!$)/gm, "$1 $2") +
      ".00";
  }

  return checkValue;
};

export default NumberSpace;
