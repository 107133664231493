import React, { useState, useEffect, useContext } from "react";
import { BlackAndWhite } from "../../themes/Colors";
import useWindowDimensions from "../../components/get-diemensions/UseWindowDiemensions";
import {
  BaseButtons,
  SectionTitle,
  TextAreaInput,
  TextInputField,
} from "../../components/atoms";
import { AdminContext } from "../../context/admin-context";
import { useNavigate } from "react-router-dom";
import AdminCustomerTab from "./components/AdminCustomerTab";
import QuestionButton from "../customer-portal/components/medical-history/components/button/QuestionButton";
import Users from "./components/Users";
import { LoadingPopUp, MenuDropDown } from "../../components";
import DropDownButton from "../../components/drop-down-button/DropDownButton";
import Facilities from "./components/Facilities";
import Menus from "./components/Menus";
import { CSVLink } from "react-csv";
import DashBoard from "./components/DashBoard";
import MultiSelectorDate from "./components/multi-selector-date/MultiSelectorDate";
import MainScreenHolder from "./components/product-uploader/MainScreenHolder";
import ExcelExport from "./components/ExcelExport";
import { ProfileMenu } from "../canna-pos/components";

// import { useNavigate } from "react-router-dom";

const OwnerAdminReader = () => {
  const { width, height } = useWindowDimensions();
  const [shrink, setShrink] = useState(false);

  const [onMenu, setOnMenu] = useState(false);
  const [patientStats, setPatientsStats] = useState(null);
  const [prescriptionStats, setPrescriptionStats] = useState(null);

  const [patientCsvDownloadPopUp, setPatientCsvDownloadPopUp] = useState(false);
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [dayDate, setDayDate] = useState("");

  const [dayRegList, setDayRegList] = useState(null);

  const [tab, setTab] = useState("dashboard");
  const [subSelection, setSubSelection] = useState("none");
  const [userDisplay, setUserdisplay] = useState(false);
  const [csvExport, setCsvExport] = useState(null);

  const [websiteData, setWebsiteData] = useState(null);
  // const [urlPath, setUrlPath] = useState("");
  const admin = useContext(AdminContext);
  const navigate = useNavigate();

  //website welcome update fields
  const [welcomParaGraph1, setWelcomParagraph1] = useState("");
  const [welcomParaGraph2, setWelcomParagraph2] = useState("");
  const [welcomParaGraph3, setWelcomParagraph3] = useState("");

  const [merryFacesInfo, setMerryFacesInfo] = useState("");

  const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(null);

  const [refresh, setRefresh] = useState(false);

  const downloadListfFetch = async () => {
    setLoading(true);

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/user/getPatintientList",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            date: day + " " + month + " " + dayDate + " " + "2024",
          }),
        }
      );

      const responseData = await response.json();
      if (!responseData.message) {
        setLoading(false);
        setDayRegList(responseData);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };

  useEffect(() => {
    if (
      !admin.isloggedIn ||
      admin.adminUserData.type === "Customer" ||
      admin.adminUserData.type === "Doctor" ||
      admin.adminUserData.type === "Manager" ||
      admin.adminUserData.type === "Support" ||
      admin.adminUserData.type === "Investor"
    ) {
      navigate("/adminPortal");
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    fetch("https://merry-jane-api.onrender.com/admin/getPatientStats")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setPatientsStats(resData);
        setLoading(false);
      });
    fetch("https://merry-jane-api.onrender.com/prescription/prescriptionStats")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setPrescriptionStats(resData);
      });
  }, [refresh]);
  useEffect(() => {
    fetch(
      "https://merry-jane-api.onrender.com/website/adminFetch/65784e47833b40e2a6f54c47"
    )
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setWebsiteData(resData);
      });
  }, [loading]);
  useEffect(() => {
    if (day !== "" && month !== "" && dayDate !== "") {
      //here will be the download trigger
      downloadListfFetch();
    }
  }, [day, month, dayDate]);

  // let paresedFile = JSON.parse(csvExport.users);

  return (
    <div
      style={{
        height: height,
        width: width,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        backgroundColor: BlackAndWhite.secondary,
        position: "relative",
      }}
    >
      <div
        style={{
          fontSize: 30,
          fontWeight: "700",
          color: BlackAndWhite.secondary,
          position: "fixed",
          width: "100%",
          backgroundColor: BlackAndWhite.primary,
          top: 0,
          height: 60,
          display: "flex",
          alignItems: "center",
          zIndex: 2000,
          WebkitBoxShadow: "0px 0px 10px 1px #00000033",
        }}
      >
        <div
          onClick={() => setShrink(shrink ? false : true)}
          style={{
            marginLeft: shrink ? 15 : 5,
            backgroundColor: shrink ? "#FFFFFFcc" : BlackAndWhite.secondary,
            height: shrink ? 95 : 135,
            width: shrink ? 95 : 202.5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: shrink ? 200 : 20,
            position: "absolute",
            top: shrink ? 10 : 5,
            WebkitBoxShadow: "0px 0px 10px 1px #00000033",
            transition: "all 0.3s",
            // borderStyle: "solid",
            // borderColor: BlackAndWhite.primary,
            // borderWidth: 5,
          }}
        >
          <div
            onClick={() => setShrink(shrink ? false : true)}
            style={{
              // marginLeft: shrink ? 5 : 5,
              backgroundColor: shrink
                ? BlackAndWhite.primary
                : BlackAndWhite.secondary,
              height: shrink ? 95 : 135,
              width: shrink ? 95 : 202.5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: shrink ? 200 : 8,
              // position: "absolute",
              // top: shrink ? 10 : 5,
              WebkitBoxShadow: "0px 0px 10px 1px #00000033",
              transition: "all 0.3s",
              // borderStyle: "solid",
              // borderColor: BlackAndWhite.primary,
              // borderWidth: 5,
            }}
          >
            <img
              alt="load"
              src={
                shrink
                  ? require("../../images/newLogos/2. Merry-Jane Logo White for Dark Background.png")
                  : require("../../images/newLogos/1. MJ Logo Black with White Surround.png")
              }
              style={{
                height: shrink ? 65 : 120,
                transition: "all 0.3s",
              }}
            />
          </div>
        </div>
        <div
          style={{
            flex: 1,
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginLeft: shrink ? 125 : 235,
              fontWeight: "bolder",
              transition: "all 0.3s",
              fontSize: 15,
            }}
          >
            {tab ? tab : "loading"} Read-only(Admin)
          </div>
        </div>

        <div
          style={{
            marginRight: 20,
            fontSize: 12,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "normal",
          }}
        >
          <div style={{ marginRight: 4 }}>
            {admin.adminUserData ? admin.adminUserData.firstName : "loading"}
          </div>
          <div style={{ marginRight: 12 }}>
            {admin.adminUserData ? admin.adminUserData.lastName : "loading"}
          </div>
          {/* //menu button */}
          <div
            onClick={() => setOnMenu(onMenu ? false : true)}
            style={{
              height: 25,
              width: 25,
              backgroundColor: onMenu
                ? BlackAndWhite.primary
                : BlackAndWhite.secondary,
              borderRadius: 100,
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              transition: "0.3s",
            }}
          >
            <div
              style={{
                height: 3,
                width: 3,
                borderRadius: 20,
                backgroundColor: onMenu
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary,
                transition: "0.3s",
              }}
            />
            <div
              style={{
                height: 3,
                width: 3,
                borderRadius: 20,
                backgroundColor: onMenu
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary,
                transition: "0.3s",
              }}
            />
            <div
              style={{
                height: 3,
                width: 3,
                borderRadius: 20,
                backgroundColor: onMenu
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary,
                transition: "0.3s",
              }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: 250,
          backgroundColor: "#FFFFFF",
          height: "100%",
          marginTop: 10,
          alignItems: "center",
          transition: "all 0.3s",
          WebkitBoxShadow: "0px 0px 10px 1px #00000033",
          zIndex: 1000,
          marginLeft: 0,
        }}
      >
        <div style={{ height: shrink ? 110 : 140, transition: "all 0.3s" }} />
        {tab === "dashboard" ? null : (
          <DropDownButton
            title={"BACK"}
            width={"95%"}
            height={40}
            onClick={() => {
              setTab("dashboard");
              setUserdisplay(false);
              setSubSelection("none");
            }}
          />
        )}
        {tab === "dashboard" ? (
          <>
            <QuestionButton
              borderOn={tab === "dashboard" ? true : false}
              title={"DASHBOARD"}
              height={60}
              onClick={() => setTab("dashboard")}
              backgroundColor={
                tab === "dashboard"
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary
              }
              backgroundColorAlt={BlackAndWhite.primary}
              color={
                tab === "dashboard"
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary
              }
              colorAlt={BlackAndWhite.secondary}
              width={"90%"}
              icon={
                tab === "dashboard"
                  ? require("../../images/material-icons/dashboardWhite.png")
                  : require("../../images/material-icons/dashboardBlack.png")
              }
              selected={tab === "dashboard" ? true : false}
              questionAmount={true}
              borderRadius={10}
            />
            <div style={{ height: 5 }} />
          </>
        ) : null}
        {tab === "website" || tab === "dashboard" ? (
          <>
            <QuestionButton
              borderRadius={10}
              borderOn={tab === "website" ? true : false}
              title={"WEBSITE"}
              height={60}
              onClick={() => setTab("website")}
              backgroundColor={
                tab === "website"
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary
              }
              backgroundColorAlt={BlackAndWhite.primary}
              color={
                tab === "website"
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary
              }
              colorAlt={BlackAndWhite.secondary}
              width={"95%"}
              icon={
                tab === "website"
                  ? require("../../images/material-icons/webWhite.png")
                  : require("../../images/material-icons/webBlack.png")
              }
              selected={tab === "website" ? true : false}
              questionAmount={true}
            />
            <MenuDropDown
              display={tab === "website" ? true : false}
              subSelection={subSelection}
              setSubSelection={setSubSelection}
              setUserdisplay={setUserdisplay}
              buttonData={["MAIN PAGE", "MERRY FACES", "IMAGES"]}
            />
            <div style={{ height: 5 }} />
          </>
        ) : null}
        {tab === "user" || tab === "dashboard" ? (
          <>
            <QuestionButton
              borderRadius={10}
              borderOn={tab === "user" ? true : false}
              title={"USERS"}
              height={60}
              onClick={
                tab === "user"
                  ? () => setTab("dashboard")
                  : () => setTab("user")
              }
              backgroundColor={
                tab === "user" ? BlackAndWhite.primary : BlackAndWhite.secondary
              }
              backgroundColorAlt={BlackAndWhite.primary}
              color={
                tab === "user" ? BlackAndWhite.secondary : BlackAndWhite.primary
              }
              colorAlt={BlackAndWhite.secondary}
              width={"95%"}
              icon={
                tab === "user"
                  ? require("../../images/material-icons/usersWhite.png")
                  : require("../../images/material-icons/userBlack.png")
              }
              selected={tab === "user" ? true : false}
              questionAmount={true}
            />
            <MenuDropDown
              display={tab === "user" ? true : false}
              subSelection={subSelection}
              setSubSelection={setSubSelection}
              setUserdisplay={setUserdisplay}
              buttonData={["ALL USERS", "ADD USERS", "STATS"]}
            />
            <div style={{ height: 5 }} />
          </>
        ) : null}
        {tab === "products" || tab === "dashboard" ? (
          <>
            <QuestionButton
              inProgress={true}
              borderRadius={10}
              borderOn={tab === "products" ? true : false}
              title={"PRODUCTS"}
              height={60}
              onClick={() => {
                setTab("products");
                setSubSelection("PRODUCT KNOWLEDGE");
              }}
              backgroundColor={
                tab === "products"
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary
              }
              backgroundColorAlt={BlackAndWhite.primary}
              color={
                tab === "products"
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary
              }
              colorAlt={BlackAndWhite.secondary}
              width={"95%"}
              icon={
                tab === "products"
                  ? require("../../images/material-icons/productWhite.png")
                  : require("../../images/material-icons/productBlack.png")
              }
              selected={tab === "products" ? true : false}
              questionAmount={true}
            />
            <MenuDropDown
              display={tab === "products" ? true : false}
              subSelection={subSelection}
              setSubSelection={setSubSelection}
              setUserdisplay={setUserdisplay}
              buttonData={["PRODUCT KNOWLEDGE"]}
            />
            <div style={{ height: 5 }} />
          </>
        ) : null}
        {tab === "patients" || tab === "dashboard" ? (
          <>
            {" "}
            <QuestionButton
              borderRadius={10}
              borderOn={tab === "patients" ? true : false}
              title={"PATIENTS"}
              height={60}
              onClick={
                tab === "patients"
                  ? () => setTab("dashboard")
                  : () => setTab("patients")
              }
              backgroundColor={
                tab === "patients"
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary
              }
              backgroundColorAlt={BlackAndWhite.primary}
              color={
                tab === "patients"
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary
              }
              colorAlt={BlackAndWhite.secondary}
              width={"95%"}
              icon={
                tab === "patients"
                  ? require("../../images/material-icons/profileWhite.png")
                  : require("../../images/material-icons/profileBlack.png")
              }
              selected={tab === "patients" ? true : false}
              questionAmount={true}
            />
            <div style={{ height: 5 }} />
          </>
        ) : null}
        {tab === "pos" || tab === "dashboard" ? (
          <QuestionButton
            notAvailable={true}
            borderRadius={10}
            borderOn={tab === "pos" ? true : false}
            title={"POS"}
            height={60}
            onClick={
              tab === "pos" ? () => setTab("dashboard") : () => setTab("pos")
            }
            backgroundColor={
              tab === "pos" ? BlackAndWhite.primary : BlackAndWhite.secondary
            }
            backgroundColorAlt={BlackAndWhite.primary}
            color={
              tab === "pos" ? BlackAndWhite.secondary : BlackAndWhite.primary
            }
            colorAlt={BlackAndWhite.secondary}
            width={"95%"}
            icon={
              tab === "pos"
                ? require("../../images/material-icons/posWhite.png")
                : require("../../images/material-icons/posBlack.png")
            }
            selected={tab === "pos" ? true : false}
            questionAmount={true}
          />
        ) : null}
        <div style={{ height: 5 }} />
        {tab === "facilities" || tab === "dashboard" ? (
          <>
            <QuestionButton
              borderRadius={10}
              borderOn={tab === "facilities" ? true : false}
              title={"FACILITIES"}
              height={60}
              onClick={
                tab === "facilities"
                  ? () => setTab("dashboard")
                  : () => {
                      setTab("facilities");
                      setSubSelection("All FACILITES");
                    }
              }
              backgroundColor={
                tab === "facilities"
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary
              }
              backgroundColorAlt={BlackAndWhite.primary}
              color={
                tab === "facilities"
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary
              }
              colorAlt={BlackAndWhite.secondary}
              width={"95%"}
              icon={
                tab === "facilities"
                  ? require("../../images/material-icons/shopWhite.png")
                  : require("../../images/material-icons/shopBlack.png")
              }
              selected={tab === "facilities" ? true : false}
              questionAmount={true}
              inProgress={true}
            />
            <MenuDropDown
              display={tab === "facilities" ? true : false}
              subSelection={subSelection}
              setSubSelection={setSubSelection}
              buttonData={["All FACILITES"]}
              setUserdisplay={setUserdisplay}
            />
          </>
        ) : null}
        <div style={{ height: 5 }} />
        {tab === "menus" || tab === "dashboard" ? (
          <>
            <QuestionButton
              borderRadius={10}
              borderOn={tab === "menus" ? true : false}
              title={"MENUS"}
              height={60}
              onClick={
                tab === "menus"
                  ? () => setTab("dashboard")
                  : () => {
                      setTab("menus");
                      setSubSelection("MENU 1");
                    }
              }
              backgroundColor={
                tab === "menus"
                  ? BlackAndWhite.primary
                  : BlackAndWhite.secondary
              }
              backgroundColorAlt={BlackAndWhite.primary}
              color={
                tab === "menus"
                  ? BlackAndWhite.secondary
                  : BlackAndWhite.primary
              }
              colorAlt={BlackAndWhite.secondary}
              width={"95%"}
              icon={
                tab === "menus"
                  ? require("../../images/material-icons/shopWhite.png")
                  : require("../../images/material-icons/shopBlack.png")
              }
              selected={tab === "menus" ? true : false}
              questionAmount={true}
            />
            <MenuDropDown
              display={tab === "menus" ? true : false}
              subSelection={subSelection}
              setSubSelection={setSubSelection}
              buttonData={["MENU 1", "MENU 2", "MENU 3"]}
              setUserdisplay={setUserdisplay}
            />
          </>
        ) : null}
      </div>

      {/* screen section not menu buttons */}
      <div
        style={{
          height: "95%",
          width: "100%",
          overflow: "scroll",
          marginTop: 85,
          position: "relative",
          backgroundColor: BlackAndWhite.secondary,
          // marginTop: 50,
        }}
      >
        {tab === "website" ? (
          <>
            <SectionTitle
              marker={true}
              title={"welcome TExt  editor section"}
              colorSwap={false}
            />
            {/* // welcome description changes */}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: 300,
                  color: BlackAndWhite.primary,
                }}
              >
                <div>
                  {websiteData ? (
                    <div style={{ width: 300, height: 170 }}>
                      {websiteData.welcomParaGraph1}
                    </div>
                  ) : null}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: 300,
                  color: BlackAndWhite.primary,
                }}
              >
                <div>
                  {websiteData ? (
                    <div style={{ width: 300, height: 170 }}>
                      {websiteData.welcomParaGraph2}
                    </div>
                  ) : null}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: 300,
                  color: BlackAndWhite.primary,
                }}
              >
                <div>
                  {websiteData ? (
                    <div style={{ width: 300, height: 170 }}>
                      {websiteData.welcomParaGraph3}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            {/* // merrjane face text description */}
            <SectionTitle
              marker={true}
              title={"Merry jane faces"}
              colorSwap={false}
            />

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: 300,
                  color: BlackAndWhite.primary,
                  // marginTop: 40,
                  marginBottom: 40,
                }}
              >
                <div>
                  {websiteData ? (
                    <div
                      style={{
                        width: 300,
                        height: 250,
                        marginBottom: 10,
                      }}
                    >
                      {websiteData.merryFacesInfo}
                    </div>
                  ) : null}
                </div>

                <div
                  style={{
                    // width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                ></div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: 700,
                  color: BlackAndWhite.primary,
                  // marginTop: 40,
                  marginBottom: 40,
                }}
              >
                <div>
                  {websiteData ? (
                    <div
                      style={{
                        width: 700,
                        height: 350,
                        marginBottom: 10,
                      }}
                    >
                      {/* {websiteData.patientText} */}
                    </div>
                  ) : null}
                </div>
              </div>
              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: 300,
                  color: BlackAndWhite.secondary,
                  // marginTop: 40,
                  marginBottom: 40,
                }}
              >
                <div>
                  {websiteData ? (
                    <div
                      style={{
                        width: 300,
                        height: 350,
                        marginBottom: 10,
                      }}
                    >
                      {websiteData.merryFacesInfo}
                    </div>
                  ) : null}
                </div>
                <TextAreaInput
                  labelTitle={"merry-jane faces text"}
                  size={300}
                  height={200}
                />
              </div> */}
            </div>
            <div
              style={{
                // width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: 0,
                right: 40,
              }}
            >
              <div
                style={{
                  color: "#FFFFFFcc",
                  marginRight: 20,
                  marginTop: 15,
                }}
              >
                save changes for welcome paragraphs
              </div>
            </div>
          </>
        ) : null}
        {tab === "products" ? (
          <MainScreenHolder
            readOnly={true}
            subSelection={subSelection}
            userDisplay={userDisplay}
            setUserdisplay={setUserdisplay}
            setSubSelection={setSubSelection}
          />
        ) : null}
        {tab === "patients" ? (
          <>
            <div
              style={{
                position: "fixed",
                top: patientCsvDownloadPopUp ? 20 : 0,
                zIndex: 1000000,
                right: 400,
                transition: "all 0.5s",
              }}
            >
              {patientCsvDownloadPopUp ? (
                <div
                  style={{
                    height: 300,
                    width: 450,
                    backgroundColor: BlackAndWhite.secondary,
                    borderStyle: "solid",
                    borderRadius: 20,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    overflow: "hidden",

                    // justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      marginTop: 20,
                      marginBottom: 10,
                      fontWeight: "bold",
                      fontSize: 20,
                      width: "80%",
                      textAlign: "left",
                    }}
                  >
                    USER DATABASE
                  </div>
                  <div
                    style={{ fontSize: 12, width: "80%", textAlign: "left" }}
                  >
                    select a date and you can download the users that register
                    on that day
                  </div>

                  <MultiSelectorDate
                    day={day}
                    setDay={setDay}
                    month={month}
                    setMonth={setMonth}
                    setDayDate={setDayDate}
                    dayDate={dayDate}
                    year={"2024"}
                  />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      width: "100%",
                    }}
                  >
                    {day !== "" && month !== "" && dayDate !== "" ? (
                      <ExcelExport
                        excelData={dayRegList ? dayRegList.data : []}
                        fileName={"excel export"}
                        title={"DOWNLOAD DAY"}
                      />
                    ) : (
                      // <CSVLink
                      //   style={{ textDecoration: "none" }}
                      //   data={dayRegList ? dayRegList.data : []}
                      //   headers={headersInfo}
                      // >
                      //   <BaseButtons label={"SEARCH DOWNLOAD"} size={250} />
                      // </CSVLink>
                      <ExcelExport
                        excelData={csvExport ? csvExport.users : []}
                        fileName={"excel export"}
                        title={"DOWNLOAD ALL"}
                      />
                      // <CSVLink
                      //   style={{ textDecoration: "none" }}
                      //   data={csvExport ? csvExport.users : []}
                      //   headers={headersInfo}
                      // >
                      //   <BaseButtons label={"DOWNLOAD"} size={150} />
                      // </CSVLink>
                    )}
                    {/* <div style={{ width: 10 }} /> */}
                    <BaseButtons
                      label={"CANCEL"}
                      size={150}
                      onClick={() => {
                        setPatientCsvDownloadPopUp(false);
                        setDay("");
                        setMonth("");
                        setDayDate("");
                      }}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div style={{ height: 7.5 }} />
                  <BaseButtons
                    label={"DOWNLOAD INFO"}
                    onClick={() => setPatientCsvDownloadPopUp(true)}
                    marginTopFalse={true}
                    invert={true}
                    height={45}
                  />
                </>
              )}
            </div>
            <AdminCustomerTab
              setData={setCsvExport}
              csvExport={csvExport}
              width={width}
            />
          </>
        ) : null}
        {tab === "user" ? (
          <Users
            readOnly={true}
            subSelection={subSelection}
            userDisplay={userDisplay}
            setUserdisplay={setUserdisplay}
            setSubSelection={setSubSelection}
          />
        ) : null}
        {tab === "facilities" ? (
          <Facilities
            readOnly={true}
            subSelection={subSelection}
            userDisplay={userDisplay}
            setUserdisplay={setUserdisplay}
            setSubSelection={setSubSelection}
          />
        ) : null}
        {tab === "menus" ? (
          <Menus
            readOnly={true}
            subSelection={subSelection}
            userDisplay={userDisplay}
            setUserdisplay={setUserdisplay}
            setSubSelection={setSubSelection}
          />
        ) : null}
        {tab === "dashboard" ? (
          <DashBoard
            readOnly={true}
            prescriptionStats={prescriptionStats}
            patientStats={patientStats}
            onRefreshClick={() => setRefresh(refresh ? false : true)}
            loading={loading}
          />
        ) : null}
      </div>
      {loading ? (
        <div
          style={{
            width: "100%",
            // height: "100%",
            zIndex: 20000000,
            position: "absolute",
            top: 0,
          }}
        >
          <LoadingPopUp display={loading} />
        </div>
      ) : null}
      <ProfileMenu
        display={onMenu}
        username={
          admin.adminUserData ? admin.adminUserData.userName : "loading"
        }
        idNum={admin.adminUserData ? admin.adminUserData.idDoc : "loading"}
        store={admin.adminUserData ? admin.adminUserData.storeName : "loading"}
        province={
          admin.adminUserData ? admin.adminUserData.province : "loading"
        }
        onCancelClick={() => setOnMenu(false)}
      />
    </div>
  );
};

export default OwnerAdminReader;
