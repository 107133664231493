import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../../../../../themes/Colors";
import {
  BaseButtons,
  TextInputField,
} from "../../../../../../../../components/atoms";
import StockTakeListItem from "../stock-take-list-item/StockTakeListItem";

const InventoryProductCard = ({ index, product, auth, info, store }) => {
  const [hover, setHover] = useState(false);
  const [productInfo, setProductInfo] = useState(false);

  const [stockTakeHistory, setStockTakeHistory] = useState(false);
  const [historyArray, setHistoryArray] = useState(null);

  const [loading, setLoading] = useState(false);

  // product value needs to be added as extra check for adjustment

  const [stockValue, setStockValue] = useState(0);

  const [stockCheckValue, setStockCheckValue] = useState(null);

  // value={stockValue ? stockValue : product.currentStock}
  const NameAssemble = ({ product }) => {
    if (product) {
      let name =
        product.productName +
        " | " +
        product.productSubType +
        " | " +
        product.thcType +
        " | " +
        product.thcContent;
      return name;
    }
  };
  // const storeInventoryItem = async () => {
  //   // authentication or login function heal
  //   //this code is used for Ui test
  //   // https://merry-jane-api.onrender.com/cannaPos/reg

  //   try {
  //     const response = await fetch(
  //       "https://merry-jane-api.onrender.com/inventory/storeInventoryItem",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           productId: product.productId,
  //         }),
  //       }
  //     );

  //     const respnseData = await response.json();

  //     if (respnseData.success) {
  //       // const wait = await updateListFetch();

  //       setProductInfo(respnseData.product);
  //     }
  //   } catch (err) {
  //     // console.log("err", err);
  //     return;
  //   }
  // };

  const getProductStockHistory = async () => {
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/inventory/getStoreStockTakeTicketProduct",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: auth.userId,
            storeId: store.storeId,
            productId: info._id,
          }),
        }
      );

      const respnseData = await response.json();

      if (respnseData.success) {
        setHistoryArray(respnseData.data);
        // const wait = await updateListFetch();
      }
    } catch (err) {
      // console.log("err", err);
      return;
    }
  };
  // const AddTo = () => {
  //   const value = Number(stockCheckValue) + 1;
  //   const valueCheck = Number(stockValue) + 1;
  //   if (stockValue >= stockCheckValue) {
  //     setStockValue(valueCheck);
  //   } else {
  //     setStockValue(value);
  //   }
  // };
  // const MinusFrom = () => {
  //   const value = Number(stockCheckValue) - 1;
  //   const valueCheck = Number(stockValue) - 1;
  // };

  useEffect(() => {
    // storeInventoryItem();

    setStockCheckValue(product.currentStock);
  }, []);

  useEffect(() => {
    // storeInventoryItem();
    if (stockTakeHistory) {
      getProductStockHistory();
    }
  }, [stockTakeHistory]);

  useEffect(() => {
    getProductStockHistory();
  }, [productInfo]);
  return (
    <>
      <div
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        onClick={() => {
          setStockTakeHistory(stockTakeHistory ? false : true);
        }}
        style={{
          width: "100%",
          height: 60,
          // borderStyle: "solid",
          display: "flex",
          fontSize: 12,
          backgroundColor: hover ? "#9E3AC350" : BlackAndWhite.secondary,
          backgroundColor: hover ? "#00000020" : BlackAndWhite.secondary,
          cursor: "pointer",
          position: "relative",
          transition: "all 0.3s",
          alignItems: "center",
          // borderBottomWidth: stockTakeHistory ? 2 : 0,
          // borderBottomStyle: "solid",
          // borderBottomColor: "#00000050",
          WebkitBoxShadow: stockTakeHistory
            ? "-2px 0px 7px 2px #00000030"
            : "0px 0px 0px 0px #00000030",
        }}
      >
        <div
          style={{
            flex: 0.05,

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 11,
          }}
        >
          <img
            alt="product"
            src={info ? info.imageUrl : null}
            style={{ height: 50 }}
          />
        </div>
        <div
          style={{
            flex: 0.2,

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 11,
          }}
        >
          <div style={{ marginLeft: 15, width: "100%", textAlign: "left" }}>
            {info ? NameAssemble({ product: info }) : "loading"}
          </div>
        </div>

        <div
          style={{
            flex: 0.2,

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 11,
          }}
        >
          <div style={{ marginLeft: 0, width: "100%", textAlign: "center" }}>
            {info ? info.sku : "loading"}
          </div>
        </div>
        <div
          style={{
            flex: 0.125,

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 11,
          }}
        >
          <div style={{ marginLeft: 25, width: "100%", textAlign: "left" }}>
            {info ? info.supplier : "loading"}
          </div>
        </div>
        <div
          style={{
            flex: 0.125,

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 11,
            position: "relative",
          }}
        >
          <div style={{ marginTop: 15 }}>
            {product ? product.stockTakeDate.substring(0, 10) : "loading"}
          </div>

          <div
            style={{
              position: "absolute",
              top: 0,
              fontSize: 10,
              fontWeight: "bold",
              opacity: 0.6,
            }}
          >
            LAST STOCK TAKE
          </div>
        </div>
        <div
          style={{
            flex: 0.15,

            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            fontSize: 11,
          }}
        >
          {/* <div
            style={{
              fontSize: 14,
              marginTop: 15,
              marginRight: 5,
            }}
          >
            UNIT
          </div> */}

          <div>
            <TextInputField
              invert={true}
              mini={true}
              height={10}
              marginTop={-5}
              size={80}
              labelTitle={"In Store's"}
              type={"Number"}
              readOnly={true}
              value={stockValue ? stockValue : product.currentStock}
              // onChange={(e) => setStockValue(e.target.value)}
            />
          </div>

          {/* <BaseButtons
          label={"MINUS"}
          mini={true}
          height={30}
          borderRadius={8}
          marginRight={10}
          marginTopFalse={true}
          size={100}
          marginLeft={10}
          invert={true}
          borderWidth={2}
          onClick={() => MinusFrom()}
        />
        <BaseButtons
          label={"ADD"}
          mini={true}
          height={30}
          borderRadius={8}
          marginRight={10}
          marginTopFalse={true}
          size={100}
          invert={true}
          borderWidth={2}
          onClick={() => AddTo()}
        /> */}
        </div>

        <div
          style={{
            flex: 0.15,

            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 11,
          }}
        >
          <BaseButtons
            label={"HISTORY"}
            mini={true}
            marginTopFalse={true}
            borderRadius={8}
            height={35}
            size={120}

            // onClick={() => AdjustStockLevel()}
          />
        </div>
      </div>
      {stockTakeHistory
        ? historyArray
          ? historyArray.map((ticket, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <StockTakeListItem ticket={ticket} />
                </div>
              );
            })
          : null
        : null}
    </>
  );
};

export default InventoryProductCard;
