import React, { useState } from "react";
import { BaseButtons, CloseButton, TextInputField } from "../atoms";
import useWindowDimensions from "../get-diemensions/UseWindowDiemensions";
import LoadingPopUp from "../loading-pop-up/LoadingPopUp";

const ForgotPasswordPopUp = ({
  display,
  onCloseClick,
  setToastPop,
  setMessage,
}) => {
  const { width, height } = useWindowDimensions();
  const [changePop, setChangePop] = useState(false);

  // fields to be filled in
  const [requestEmail, setRequestEmail] = useState("");

  const [changeEmail, setChangeEmail] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("error");
  const [loading, setLoading] = useState(false);

  //functions
  const requestCodeHandler = async () => {
    setLoading(true);
    // authentication or login function heal
    //this code is used for Ui test
    //
    // "https://merry-jane-api.onrender.com/user/login"
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/user/forgotPasswordEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: requestEmail.toLowerCase(),
          }),
        }
      );

      const respnseData = await response.json();
      if (respnseData.message === "an email has been sent to your account") {
        setLoading(false);
        setMessage(respnseData.message);
        setToastPop(true);
      } else {
        setLoading(false);
        setMessage(respnseData.message);
        setToastPop(true);
      }
    } catch (err) {
      setMessage("invalid credentials");
      setToastPop(true);
      setError("invalid credentials");
    }
  };
  const changePasswordHandler = async () => {
    //here we will take the users info in and make changes to his password

    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/user/changePassword",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: changeEmail,
            code: code,
            idNumber: idNumber,
            password: password,
          }),
        }
      );

      const responseData = await response.json();

      if (
        responseData.message === "Your password has been change successfully"
      ) {
        setMessage(responseData.message);
        setToastPop(true);
        setLoading(false);
        setChangePop(false);
      } else {
        setMessage(responseData.message);
        setToastPop(true);
        setLoading(false);
      }
    } catch (err) {
      setMessage("Could not change password, try agin in a few minutes");
      setToastPop(true);

      setLoading(false);
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        height: display ? height : 0,
        width: width,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // backgroundColor: "#00000066",
        overflow: "hidden",
        fontFamily: "Montserrat",
      }}
    >
      {changePop ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "#FFFFFFcc",
            borderRadius: 8,
            position: "relative",
            // display: "flex",
            // flexDirection: "column",
            // justifyContent: "center",
            alignItems: "center",
            overflow: "scroll",
          }}
        >
          <div
            style={{
              width: "100%",
              textAlign: "center",
              fontSize: 22,
              fontWeight: "bold",
              marginBottom: 40,
              marginTop: 20,
            }}
          >
            Got your code?
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            Please provide the following details:
          </div>
          <TextInputField
            labelTitle={"EMAIL ADDRESS:"}
            invert={true}
            onChange={(e) => setChangeEmail(e.target.value)}
            value={changeEmail}
          />
          <TextInputField
            labelTitle={"ID-NUMBER / PASSPORT:"}
            invert={true}
            onChange={(e) => setIdNumber(e.target.value)}
          />
          <TextInputField
            labelTitle={"CODE:"}
            invert={true}
            onChange={(e) => setCode(e.target.value)}
          />
          <TextInputField
            labelTitle={"NEW PASSWORD:"}
            invert={true}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextInputField
            labelTitle={"CONFIRM NEW PASSWORD:"}
            invert={true}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />

          <div style={{ position: "absolute", top: 20, left: 20 }}>
            <CloseButton label={"X"} onClick={() => setChangePop(false)} />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <div style={{ minWidth: 300, maxWidth: 300, textAlign: "center" }}>
              If you are happy with your new password and all your information
              is filled out go ahead and Submit.
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              //   marginBottom: 20,
              marginTop: 20,
            }}
          >
            <BaseButtons
              label={"SUBMIT"}
              onClick={() => changePasswordHandler()}
              disable={
                password === confirmPassword &&
                changeEmail.length > 5 &&
                idNumber.length > 6 &&
                code.length === 6
                  ? false
                  : true
              }
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            <BaseButtons label={"CANCEL"} onClick={() => setChangePop(false)} />
          </div>
        </div>
      ) : (
        <div
          style={{
            height: "80%",
            width: "95%",
            backgroundColor: "#FFFFFFcc",
            borderRadius: 8,
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "80%",
              textAlign: "center",
              fontSize: 18,
              fontWeight: "bold",
              marginBottom: 20,
            }}
          >
            Forgot your Password?
          </div>
          <div
            style={{
              minWidth: 300,
              maxWidth: 300,
              textAlign: "center",
              fontSize: 14,
            }}
          >
            Provide the email email adress that you previously used to register
            and we will send a reset code to your email.
          </div>
          <TextInputField
            labelTitle={"EMAIL"}
            invert={true}
            size={"100%"}
            onChange={(e) => setRequestEmail(e.target.value)}
          />
          <BaseButtons
            label={"REQUEST CODE"}
            onClick={() => requestCodeHandler()}
            size={260}
          />
          <div
            style={{
              width: "80%",
              textAlign: "center",
              fontSize: 16,
              marginTop: 30,
            }}
          >
            Already have your code?
          </div>
          <div
            style={{
              width: "80%",
              textAlign: "center",
              fontSize: 14,
              marginTop: 10,
            }}
          >
            Click here!
          </div>
          <BaseButtons
            size={260}
            label={"RESET PASSWORD"}
            onClick={() => {
              setChangePop(true);
              setRequestEmail("");
              setChangeEmail("");
            }}
          />
          <div style={{ position: "absolute", top: 20, left: 20 }}>
            <CloseButton label={"X"} onClick={onCloseClick} />
          </div>
        </div>
      )}
      <LoadingPopUp display={loading} />
    </div>
  );
};

export default ForgotPasswordPopUp;
