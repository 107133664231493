import React from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import {
  CloseButton,
  TextInputField,
} from "../../../../../../components/atoms";

const ViewTicketModal = ({ display, onCloseClick }) => {
  return (
    <div
      style={{
        position: "absolute",
        height: display ? "100%" : 0,
        width: "100%",
        zIndex: 2,
        backgroundColor: BlackAndWhite.secondary,
        overflow: "hidden",
        transition: "all 0.3s",
        bottom: 0,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 20,
          left: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CloseButton label={"X"} onClick={onCloseClick} />
        <div style={{ width: 20 }} />
        <div style={{ marginRight: 10, fontWeight: "bold" }}>
          SUPPORT TICKET{" "}
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          top: 20,
          right: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div>
          <div style={{ fontSize: 12, fontWeight: "bold", opacity: 0.6 }}>
            STATUS
          </div>
          <div>{display ? display.status : "loading"}</div>
        </div>
      </div>
      <div
        style={{
          flex: 1,

          height: "95%",
          overflowY: "scroll",
          overflowX: "hidden",
          scrollbarWidth: "none",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "100%",
            minHeight: 400,
            marginTop: 80,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ marginLeft: 40, marginBottom: 10, width: "90%" }}>
            <div
              style={{
                fontSize: 12,
                fontWeight: "bold",
                opacity: 0.6,
                marginBottom: 10,
              }}
            >
              CREATED ON
            </div>
            <div>{display ? display.createdOn : null}</div>
          </div>
          <div style={{ marginLeft: 40, marginBottom: 10, width: "90%" }}>
            <div
              style={{
                fontSize: 12,
                fontWeight: "bold",
                opacity: 0.6,
                marginBottom: 10,
              }}
            >
              TICKET REF
            </div>

            <div>{display ? display.id : "loading"}</div>
          </div>
          <div style={{ marginLeft: 40, marginBottom: 10, width: "90%" }}>
            <div
              style={{
                fontSize: 12,
                fontWeight: "bold",
                opacity: 0.6,
                marginBottom: 10,
              }}
            >
              ISSUE TYPE
            </div>

            <div>{display ? display.issueType : "loading"}</div>
          </div>
          <div
            style={{
              marginLeft: 40,
              marginBottom: 10,
              width: "90%",
            }}
          >
            <div
              style={{
                fontSize: 12,
                fontWeight: "bold",
                opacity: 0.6,
                marginBottom: 10,
              }}
            >
              ISSUE DESCRIPTION
            </div>

            <div>{display ? display.issueDescription : "loading"}</div>
          </div>
          <div
            style={{
              marginLeft: 40,
              marginBottom: 10,
              width: "90%",
            }}
          >
            <div
              style={{
                fontSize: 12,
                fontWeight: "bold",
                opacity: 0.6,
                marginBottom: 10,
              }}
            >
              SECTION ISSUE
            </div>

            <div>{display ? display.section : "loading"}</div>
          </div>
          <div
            style={{
              marginLeft: 40,
              marginBottom: 10,
              width: "90%",
            }}
          >
            <div
              style={{
                fontSize: 12,
                fontWeight: "bold",
                opacity: 0.6,
                marginBottom: 10,
              }}
            >
              SECTION ISSUE DESCRIPTION
            </div>

            <div>{display ? display.sectionErrorDescription : "loading"}</div>
          </div>
          <div
            style={{
              marginLeft: 40,
              marginBottom: 10,
              width: "90%",
            }}
          >
            <div
              style={{
                fontSize: 12,
                fontWeight: "bold",
                opacity: 0.6,
                marginBottom: 10,
              }}
            >
              SUPPORT LAST VIEWED
            </div>

            <div>{display ? display.lastAccesed : "loading"}</div>
          </div>
          <div
            style={{
              marginLeft: 40,
              marginBottom: 10,
              width: "90%",
            }}
          >
            <div
              style={{
                fontSize: 12,
                fontWeight: "bold",
                opacity: 0.6,
                marginBottom: 10,
              }}
            >
              SUPPORT EDITED LAST
            </div>

            <div>{display ? display.editedOn : "loading"}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewTicketModal;
