import React from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";

const SalesHistoryHeader = () => {
  return (
    <div
      style={{
        width: "100%",
        height: 50,
        display: "flex",
        borderTopColor: BlackAndWhite.primary,
        backgroundColor: BlackAndWhite.primary,
        color: BlackAndWhite.secondary,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
      }}
    >
      <div
        style={{
          flex: 0.15,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
          width: "100%",
        }}
      >
        <div style={{ marginLeft: 15, width: "100%" }}>DATE:</div>
      </div>
      <div
        style={{
          flex: 0.25,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "left",
        }}
      >
        <div style={{ width: "95%" }}> ID:</div>
      </div>
      <div
        style={{
          flex: 0.25,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "center",
        }}
      >
        <div style={{ width: "95%" }}> CREATED BY</div>
      </div>
      <div
        style={{
          flex: 0.15,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          textAlign: "center",
        }}
      >
        <div style={{ width: "95%" }}>AMOUNT</div>
      </div>

      <div
        style={{
          flex: 0.1,
          height: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            height: "60%",
            width: "100%",
            paddingRight: 10,
          }}
        >
          ITEMS
        </div>
      </div>
      <div
        style={{
          flex: 0.1,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60%",
            width: "100%",
            paddingRight: 10,
          }}
        >
          ACTION
        </div>
      </div>
    </div>
  );
};

export default SalesHistoryHeader;
