import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../themes/Colors";
import { CloseButton } from "../../../components/atoms";
import { CannaMenuButton } from "../../canna-pos/components";
import { LoadingPopUp, NewPrescriptionCard } from "../../../components";

const PatientProfilePopUp = ({
  display,
  onCloseClick,
  selectedPatient,
  loading,
  setLoading,
}) => {
  const [selectedPrescriptions, setSelectedPrescriptions] =
    useState("approved");
  const [data, setData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [agreements, setAgreements] = useState([]);

  const [validatedId, setValidatedId] = useState(null);

  const [approvedPrescriptionStatus, setApprovedPrescriptionStatus] =
    useState(null);

  const StatusCheck = () => {
    if (data.data.length >= 1) {
      setApprovedPrescriptionStatus(true);
    } else {
      setApprovedPrescriptionStatus(false);
    }
  };

  const PrescriptionFecth = async () => {
    setRefresh(true);
    await fetch(
      "https://merry-jane-api.onrender.com/prescription/fetchUserPrescriptions",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: selectedPatient ? selectedPatient.idDoc : "",
        }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setData(resData);
        setLoading(false);
        setRefresh(false);
      });
  };

  const GetUserValidateId = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/user/getValidateId",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: selectedPatient ? selectedPatient.email : "",
          }),
        }
      );

      const responseData = await response.json();
      if (!responseData.message) {
        setValidatedId(responseData.userId);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const checkForAgreements = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/user/getAgreements",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: validatedId ? validatedId : selectedPatient._id,
          }),
        }
      );
      const respnseData = await response.json();
      if (respnseData.agreements) {
        setAgreements(respnseData.agreements);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (selectedPatient) {
      if (validatedId) {
        checkForAgreements();
      } else {
        checkForAgreements();
      }
    }
  }, [validatedId]);
  useEffect(() => {
    if (selectedPatient) {
      GetUserValidateId();
    } else {
      setAgreements([]);
      setValidatedId(null);
    }
  }, [selectedPatient, loading]);
  useEffect(() => {
    PrescriptionFecth();
    if (data) {
      StatusCheck();
    }
  }, [loading]);
  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: display ? "100%" : 0,
        overflow: "hidden",
        zIndex: 4000000000000,
        backgroundColor: BlackAndWhite.primary,
        transition: "all 0.2s",
        top: 0,
        display: "flex",
      }}
    >
      {/* section one */}
      <div
        style={{
          flex: 0.4,
          height: "100%",

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "90%",
            width: "85%",
            borderRadius: 20,
            backgroundColor: BlackAndWhite.secondary,
            WebkitBoxShadow: "0px 0px 10px 1px #00000033",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
          }}
        >
          <div
            style={{
              height: 250,
              width: 250,
              backgroundColor: BlackAndWhite.primary,
              borderRadius: 250,
              marginTop: 30,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderStyle: "solid",
              borderColor: agreements
                ? agreements.length >= 1 && validatedId
                  ? "#599F68"
                  : BlackAndWhite.orange
                : BlackAndWhite.orange,
              borderWidth: 5,
              WebkitBoxShadow: "0px 0px 5px 5px #00000033",
              position: "relative",
              color: BlackAndWhite.secondary,
            }}
          >
            Profile image
            <div
              style={{
                position: "absolute",
                bottom: -10,
                display: "flex",
                flexDirection: "column",
                backgroundColor: agreements
                  ? agreements.length >= 1 && validatedId
                    ? "#599F68"
                    : BlackAndWhite.orange
                  : BlackAndWhite.orange,
                color: BlackAndWhite.secondary,
                borderStyle: "solid",
                borderWidth: 4,
                borderRadius: 80,
                width: 280,
                height: 55,
                justifyContent: "center",
                alignItems: "center",
                WebkitBoxShadow: "0px 0px 5px 5px #00000033",
                borderColor: agreements
                  ? agreements.length >= 1 && validatedId
                    ? "#599F68"
                    : BlackAndWhite.orange
                  : BlackAndWhite.orange,
              }}
            >
              <div
                style={{
                  color: BlackAndWhite.primary,
                  fontSize: selectedPatient
                    ? selectedPatient.firstName > 12
                      ? 16
                      : 20
                    : 20,
                  textAlign: "center",
                }}
              >
                {selectedPatient
                  ? (
                      selectedPatient.firstName +
                      " " +
                      selectedPatient.lastName
                    ).toLocaleUpperCase()
                  : "loading"}
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: 90,
            }}
          >
            <div
              style={{
                flex: 0.5,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ fontSize: 14, marginBottom: 10 }}>GENDER:</div>
              <div style={{ fontSize: 22, fontWeight: "bolder" }}>
                {selectedPatient
                  ? selectedPatient.gender === "male"
                    ? "MALE"
                    : "FEMALE"
                  : "loading"}
              </div>
            </div>
            <div
              style={{
                flex: 0.5,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontSize: 14,
                  marginBottom: 10,
                  width: "80%",
                  textAlign: "left",
                }}
              >
                DATE OF BIRTH:
              </div>
              <div
                style={{
                  fontSize: 22,
                  fontWeight: "bolder",
                  width: "80%",
                  textAlign: "left",
                }}
              >
                {selectedPatient ? selectedPatient.dateOfBirth : "loading"}
              </div>
            </div>
          </div>

          <div
            style={{
              fontSize: 12,
              marginBottom: 6,
              width: "70%",
              textAlign: "left",
            }}
          >
            ID-number:
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bolder",
              width: "70%",
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            {selectedPatient ? selectedPatient.idDoc : "loading"}
          </div>
          <div
            style={{
              fontSize: 12,
              marginBottom: 6,
              width: "70%",
              textAlign: "left",
            }}
          >
            Email:
          </div>
          <div
            style={{
              fontSize: selectedPatient
                ? selectedPatient.email.length > 24
                  ? 14
                  : 16
                : 16,
              fontWeight: "bolder",
              width: "70%",
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            {selectedPatient ? selectedPatient.email : "loading"}
          </div>
          <div
            style={{
              fontSize: 12,
              marginBottom: 6,
              width: "70%",
              textAlign: "left",
            }}
          >
            Cell-number:
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bolder",
              width: "70%",
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            {selectedPatient ? selectedPatient.cellNumber : "loading"}
          </div>
          <div
            style={{
              fontSize: 12,
              marginBottom: 6,
              width: "70%",
              textAlign: "left",
            }}
          >
            Address:
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bolder",
              width: "70%",
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            {selectedPatient
              ? selectedPatient.street + ", " + selectedPatient.suburb
              : "loading"}
          </div>
          <div
            style={{
              fontSize: 12,
              marginBottom: 6,
              width: "70%",
              textAlign: "left",
            }}
          >
            City:
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bolder",
              width: "70%",
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            {selectedPatient ? selectedPatient.town : "loading"}
          </div>

          {agreements.length >= 1 ? null : (
            <div
              style={{
                fontSize: 16,
                fontWeight: "bolder",
                width: "70%",
                textAlign: "left",
                marginBottom: 10,
                borderRadius: 8,
                borderStyle: "solid",
                paddingTop: 10,
                paddingBottom: 10,
                display: "flex",
                borderColor: "red",
              }}
            >
              <div style={{ width: 10 }} />
              THIS PATIENT IS MISSING THE NEW TERMS AND CONDITIONS
            </div>
          )}
          {validatedId ? null : (
            <div
              style={{
                fontSize: 16,
                fontWeight: "bolder",
                width: "70%",
                textAlign: "left",
                marginBottom: 10,
                borderRadius: 8,
                borderStyle: "solid",
                paddingTop: 10,
                paddingBottom: 10,
                display: "flex",
                borderColor: "red",
              }}
            >
              <div style={{ width: 10 }} />
              THIS PATIENT NEEDS TO VALIDATE THIER ACCOUNT
            </div>
          )}
          <div style={{ position: "absolute", top: 10, left: 10 }}>
            <CloseButton label={"X"} onClick={onCloseClick} />
          </div>
        </div>
      </div>
      {/* section 2 */}
      <div
        style={{
          flex: 0.6,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "90%",
            width: "95%",
            borderRadius: 20,
            backgroundColor: BlackAndWhite.secondary,
            WebkitBoxShadow: "0px 0px 10px 1px #00000033",
            display: "flex",
            flexDirection: "column",
            marginRight: 40,
            justifyContent: "flex-start",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              height: 60,
              width: "100%",
              backgroundColor: agreements
                ? agreements.length >= 1 && validatedId
                  ? "#599F68"
                  : BlackAndWhite.orange
                : BlackAndWhite.orange,
              position: "relative",
              display: "flex",
            }}
          >
            <div
              style={{
                flex: 0.25,

                height: "100%",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ position: "absolute", bottom: -30 }}>
                <CannaMenuButton
                  selected={selectedPrescriptions === "approved" ? true : false}
                  iconImage={require("../../../images/material-icons/instagramWhite.png")}
                  height={35}
                  title={"APPROVED"}
                  width={140}
                  fontColor={
                    selectedPrescriptions === "approved"
                      ? BlackAndWhite.secondary
                      : BlackAndWhite.primary
                  }
                  onClick={() => setSelectedPrescriptions("approved")}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.25,

                height: "100%",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ position: "absolute", bottom: -30 }}>
                <CannaMenuButton
                  selected={selectedPrescriptions === "pending" ? true : false}
                  iconImage={require("../../../images/material-icons/instagramWhite.png")}
                  height={35}
                  title={"PENDING"}
                  width={140}
                  fontColor={
                    selectedPrescriptions === "pending"
                      ? BlackAndWhite.secondary
                      : BlackAndWhite.primary
                  }
                  onClick={() => setSelectedPrescriptions("pending")}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.25,

                height: "100%",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ position: "absolute", bottom: -30 }}>
                <CannaMenuButton
                  selected={selectedPrescriptions === "declined" ? true : false}
                  iconImage={require("../../../images/material-icons/instagramWhite.png")}
                  height={35}
                  title={"DECLINED"}
                  width={140}
                  fontColor={
                    selectedPrescriptions === "declined"
                      ? BlackAndWhite.secondary
                      : BlackAndWhite.primary
                  }
                  onClick={() => setSelectedPrescriptions("declined")}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.25,

                height: "100%",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ position: "absolute", bottom: -30 }}>
                <CannaMenuButton
                  selected={selectedPrescriptions === "expired" ? true : false}
                  iconImage={require("../../../images/material-icons/instagramWhite.png")}
                  height={35}
                  title={"EXPIRED"}
                  width={140}
                  fontColor={
                    selectedPrescriptions === "expired"
                      ? BlackAndWhite.secondary
                      : BlackAndWhite.primary
                  }
                  onClick={() => setSelectedPrescriptions("expired")}
                />
              </div>
            </div>
          </div>
          <div style={{ height: 25 }}></div>
          <div
            style={{
              flex: 1,
              overflow: "scroll",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              paddingBottom: 20,
            }}
          >
            {selectedPrescriptions === "approved" ? (
              data ? (
                data.data.length >= 1 ? (
                  data.data.map((prescription, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <NewPrescriptionCard
                          patientNo={prescription ? prescription.id : "loading"}
                          patientId={
                            prescription ? prescription.patientId : "loading"
                          }
                          doctor={
                            prescription ? prescription.doctor : "loading"
                          }
                          prescriptionNo={
                            prescription
                              ? prescription.prescriptionNum
                              : "loading"
                          }
                          createdDate={
                            prescription ? prescription.date : "loading"
                          }
                          validUntil={
                            prescription ? prescription.validUntil : "loading"
                          }
                          status={
                            prescription ? prescription.approved : "loading"
                          }
                          diagonosis={
                            prescription ? prescription.diagnosis : "loading"
                          }
                          prescribed={
                            prescription ? prescription.prescription : "loading"
                          }
                        />
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      marginTop: 50,
                      fontSize: 24,
                      fontWeight: "bold",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    NO APPROVED PRESCRIPTIONS
                  </div>
                )
              ) : (
                <LoadingPopUp display={true} newWidth={"100%"} />
              )
            ) : null}
            {selectedPrescriptions === "pending" ? (
              data ? (
                data.dataPending.length >= 1 ? (
                  data.dataPending.map((prescription, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {prescription ? prescription.id : "loading"}
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      marginTop: 50,
                      fontSize: 24,
                      fontWeight: "bold",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    No pending prescriptions
                  </div>
                )
              ) : (
                <LoadingPopUp display={true} newWidth={"100%"} />
              )
            ) : null}
            {selectedPrescriptions === "expired" ? (
              data ? (
                data.dataExpired.length >= 1 ? (
                  data.dataExpired.map((prescription, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <NewPrescriptionCard
                          createdDate={
                            prescription ? prescription.date : "loading"
                          }
                          patientNo={prescription ? prescription.id : "loading"}
                          patientId={
                            prescription ? prescription.patientId : "loading"
                          }
                          doctor={
                            prescription ? prescription.doctor : "loading"
                          }
                          prescriptionNo={
                            prescription
                              ? prescription.prescriptionNum
                              : "loading"
                          }
                          validUntil={
                            prescription ? prescription.validUntil : "loading"
                          }
                          status={
                            prescription ? prescription.approved : "loading"
                          }
                          diagonosis={
                            prescription ? prescription.diagnosis : "loading"
                          }
                          prescribed={
                            prescription ? prescription.prescription : "loading"
                          }
                        />
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      marginTop: 50,
                      fontSize: 24,
                      fontWeight: "bold",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    No Expired prescriptions
                  </div>
                )
              ) : (
                <LoadingPopUp display={true} newWidth={"100%"} />
              )
            ) : null}
            {selectedPrescriptions === "declined" ? (
              data ? (
                data.dataDeclined.length >= 1 ? (
                  data.dataDeclined.map((prescription, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {prescription ? prescription.id : "loading"}
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      marginTop: 50,
                      fontSize: 24,
                      fontWeight: "bold",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    No Declined prescriptions
                  </div>
                )
              ) : (
                <LoadingPopUp display={true} newWidth={"100%"} />
              )
            ) : null}
          </div>
        </div>
      </div>
      {/* <LoadingPopUp display={refresh} newWidth={"100%"} /> */}
    </div>
  );
};

export default PatientProfilePopUp;
