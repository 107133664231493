import React, { useState } from "react";
import JsPDF from "jspdf";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import { BaseButtons, CloseButton } from "../../../../../../components/atoms";

const PdfDisplay = ({ display, onCloseClick, selectedReportIndex }) => {
  const [downloading, setDownloading] = useState(false);
  const generatePDF = async () => {
    // prescriptionPdf();
    setDownloading(true);
    const report = await new JsPDF("portrait", "px", "a4");
    report.html(document.querySelector("#report")).then(() => {
      report.getFontList();

      report.addFont(
        "ArialMS",
        "ArialMS-Bold",
        "Arial",
        "Arial-bold",
        "Courier-Bold",
        "Times",
        "Courier",
        "courier",
        "Helvetica",
        "Helvetica-Bold",
        "normal",
        "bold"
      );
      // report.addFont(
      //   "Montserrat-Medium-normal.ttf",
      //   "Montserrat-Medium",
      //   "normal"
      // );
      report.setFont("courier", "normal");
      report.addFont(
        "Montserrat-Black-normal.ttf",
        "Montserrat-Black",
        "normal",
        "bold"
      );
      report.save("report.pdf");
    });
    setDownloading(false);
  };
  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        height: display ? "100%" : 0,
        backgroundColor: BlackAndWhite.secondary,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        overflowX: "hidden",
        top: 0,
        left: 0,
        zIndex: 10000000000000,
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Arial",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 20,
          left: 20,
          display: "flex",
          alignItems: "center",
        }}
      >
        <CloseButton label={"X"} onClick={onCloseClick} />
        <div
          style={{
            marginLeft: 10,
            fontWeight: "normal",
            fontSize: 16,
            display: "flex",
          }}
        >
          CASH REGISTER REPORT FOR{" "}
          <div style={{ marginLeft: 5, fontWeight: "bold" }}>
            {display
              ? display.openedAt.substring(0, 10) +
                " / " +
                display.openedAt.substring(11, 16) +
                " TO " +
                display.closedAt.substring(0, 10) +
                " / " +
                display.closedAt.substring(11, 16)
              : null}
          </div>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          top: 20,
          right: 20,
          display: "flex",
          alignItems: "center",
        }}
      >
        <BaseButtons
          mini={true}
          label={"DOWNLOAD"}
          marginTop={-1}
          //   invert={true}
          height={40}
          borderRadius={8}
          onClick={() => generatePDF()}
        />
      </div>
      <div style={{ minHeight: 60 }}></div>
      <div
        style={{
          display: "flex",

          flex: 1,
          width: "100%",
        }}
      >
        <div
          style={{
            flex: 1,

            height: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: BlackAndWhite.secondary,
            zIndex: 2,
          }}
        >
          <div
            style={{
              height: "98%",
              borderTopRightRadius: 10,
              width: "100%",
              // WebkitBoxShadow: "0px 0px 7px 2px #00000050",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                width: "90%",
                marginTop: 20,
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: 10,
                    opacity: 0.6,
                    marginBottom: 5,
                  }}
                >
                  OPENED BY:
                </div>
                <div>{display ? display.openedBy : null}</div>
              </div>

              <div style={{ flex: 1 }} />
              <div>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: 10,
                    opacity: 0.6,
                    marginBottom: 5,
                  }}
                >
                  CLOSED BY:
                </div>
                <div>{display ? display.closedBy : null}</div>
              </div>
            </div>
            <div
              style={{
                width: "90%",
                marginTop: 20,
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              <div style={{ fontWeight: "bold" }}>PAYMENT TYPES:</div>
              <div style={{ flex: 1 }} />
              <div style={{ width: 100, textAlign: "right", fontSize: 14 }}>
                EXPECTED
              </div>
              <div style={{ width: 100, textAlign: "right", fontSize: 14 }}>
                COUNTED
              </div>
              <div style={{ width: 100, textAlign: "right", fontSize: 14 }}>
                DIFF.
              </div>
            </div>
            {/* cash net sales */}
            <div
              style={{
                width: "90%",
                marginTop: 15,
                display: "flex",
                alignItems: "center",
                fontSize: 12,
              }}
            >
              <div style={{}}>Cash (net cash payments): </div>
              <div style={{ flex: 1 }} />
              <div style={{ width: 100, textAlign: "right" }}>
                {" "}
                {display
                  ? "R" +
                    (
                      Number(display.cash) + Number(display.tillOpeningAmount)
                    ).toFixed(2)
                  : null}
              </div>
              <div style={{ width: 100, textAlign: "right" }}>
                {" "}
                {display ? "R" + Number(display.cashCounted).toFixed(2) : null}
              </div>
              <div style={{ width: 100, textAlign: "right" }}>
                {" "}
                {display
                  ? "R" +
                    (
                      (
                        Number(display.cash) + Number(display.tillOpeningAmount)
                      ).toFixed(2) - Number(display.cashCounted).toFixed(2)
                    ).toFixed(2)
                  : null}
              </div>
            </div>
            {/* card */}
            <div
              style={{
                width: "90%",
                marginTop: 15,
                display: "flex",
                alignItems: "center",
                fontSize: 12,
              }}
            >
              <div style={{}}>Card: </div>
              <div style={{ flex: 1 }} />
              <div style={{ width: 100, textAlign: "right" }}>
                {display ? "R" + Number(display.card).toFixed(2) : null}
              </div>
              <div style={{ width: 100, textAlign: "right" }}>
                {display ? "R" + Number(display.cardCounted).toFixed(2) : null}
              </div>
              <div style={{ width: 100, textAlign: "right" }}>
                {display
                  ? "R" +
                    (
                      Number(display.card) - Number(display.cardCounted)
                    ).toFixed(2)
                  : null}
              </div>
            </div>
            {/* credit */}
            <div
              style={{
                width: "90%",
                marginTop: 15,
                display: "flex",
                alignItems: "center",
                fontSize: 12,
              }}
            >
              <div style={{}}>Store credit: </div>
              <div style={{ flex: 1 }} />
              <div style={{ width: 100, textAlign: "right" }}>
                {display ? "R" + Number(display.storeCredit).toFixed(2) : null}
              </div>
              <div style={{ width: 100, textAlign: "right" }}>
                {display ? "R" + Number(display.storeCredit).toFixed(2) : null}
              </div>
              <div style={{ width: 100, textAlign: "right" }}>R0.00</div>
            </div>
            <div
              style={{
                width: "90%",
                borderStyle: "solid",
                marginTop: 15,
                borderWidth: 0.5,
                opacity: 0.5,
                borderRadius: 8,
              }}
            />
            {/* payment toaals */}
            <div
              style={{
                width: "90%",
                marginTop: 15,
                display: "flex",
                alignItems: "center",
                fontSize: 12,
              }}
            >
              <div style={{ flex: 1 }} />
              <div style={{ width: 100, textAlign: "right" }}>Payments</div>
              <div style={{ width: 200, textAlign: "right" }}>
                {display ? "R" + Number(display.TotalInc).toFixed(2) : null}
              </div>
            </div>
            {/* refund totals */}
            <div
              style={{
                width: "90%",
                marginTop: 15,
                display: "flex",
                alignItems: "center",
                fontSize: 12,
              }}
            >
              <div style={{ flex: 1 }} />
              <div style={{ width: 100, textAlign: "right" }}>Refunds</div>
              <div style={{ width: 200, textAlign: "right" }}>
                {display ? "R" + Number(display.refunds).toFixed(2) : null}
              </div>
            </div>
            {/* net receipts */}
            <div
              style={{
                width: "90%",
                marginTop: 15,
                display: "flex",
                alignItems: "center",
                fontSize: 12,
              }}
            >
              <div style={{ flex: 1 }} />
              <div style={{ width: 100, textAlign: "right" }}>Net receipts</div>
              <div style={{ width: 200, textAlign: "right" }}>
                {display
                  ? "R" +
                    (
                      Number(display.TotalInc) - Number(display.refunds)
                    ).toFixed(2)
                  : null}
              </div>
            </div>
            <div
              style={{
                width: "90%",
                borderStyle: "solid",
                marginTop: 15,
                borderWidth: 0.5,
                opacity: 0.5,
                borderRadius: 8,
              }}
            />

            {/* Total (Incl) Sales: */}

            <div
              style={{
                width: "90%",
                marginTop: 15,
                display: "flex",
                alignItems: "center",
                fontSize: 12,
              }}
            >
              <div style={{ width: 100, textAlign: "left" }}>
                Total (Incl) Sales:
              </div>
              <div style={{ flex: 1 }} />

              <div style={{ width: 200, textAlign: "right" }}>
                {display ? "R " + Number(display.TotalInc).toFixed(2) : null}
              </div>
            </div>
            {/* Total (Ex) Sales: */}

            <div
              style={{
                width: "90%",
                marginTop: 15,
                display: "flex",
                alignItems: "center",
                fontSize: 12,
              }}
            >
              <div style={{ width: 100, textAlign: "left" }}>
                Total (Ex) Sales:
              </div>
              <div style={{ flex: 1 }} />

              <div style={{ width: 200, textAlign: "right" }}>
                {display
                  ? "R" +
                    (Number(display.TotalInc) - Number(display.vat)).toFixed(2)
                  : null}
              </div>
            </div>
            {/* Total Vat */}

            <div
              style={{
                width: "90%",
                marginTop: 15,
                display: "flex",
                alignItems: "center",
                fontSize: 12,
              }}
            >
              <div style={{ width: 100, textAlign: "left" }}>Total Vat:</div>
              <div style={{ flex: 1 }} />

              <div style={{ width: 200, textAlign: "right" }}>
                {display ? "R " + Number(display.vat).toFixed(2) : null}
              </div>
            </div>
            {/* Discounts: */}

            <div
              style={{
                width: "90%",
                marginTop: 15,
                display: "flex",
                alignItems: "center",
                fontSize: 12,
              }}
            >
              <div style={{ width: 100, textAlign: "left" }}>Discounts:</div>
              <div style={{ flex: 1 }} />

              <div style={{ width: 200, textAlign: "right" }}>
                {display ? "R" + Number(display.discounts).toFixed(2) : null}
              </div>
            </div>
            {/* Till Opening Amt.:: */}

            <div
              style={{
                width: "90%",
                marginTop: 15,
                display: "flex",
                alignItems: "center",
                fontSize: 12,
              }}
            >
              <div style={{ width: 100, textAlign: "left" }}>
                Till Opening Amt.:
              </div>
              <div style={{ flex: 1 }} />

              <div style={{ width: 200, textAlign: "right" }}>
                {display
                  ? "R" + Number(display.tillOpeningAmount).toFixed(2)
                  : null}
              </div>
            </div>
            <div
              style={{
                width: "90%",
                borderStyle: "solid",
                marginTop: 15,
                borderWidth: 0.5,
                opacity: 0.5,
                borderRadius: 8,
              }}
            />
            {/* transactions.:: */}

            <div
              style={{
                width: "90%",
                marginTop: 15,
                display: "flex",
                alignItems: "center",
                fontSize: 12,
              }}
            >
              <div style={{ width: 150, textAlign: "left" }}>
                Number of Transactions:
              </div>
              <div style={{ flex: 1 }} />

              <div style={{ width: 200, textAlign: "right" }}>
                Average Transaction
              </div>
            </div>
            <div
              style={{
                width: "90%",
                borderStyle: "solid",
                marginTop: 15,
                borderWidth: 0.5,
                opacity: 0.5,
                borderRadius: 8,
              }}
            />
            <div
              style={{
                width: "90%",
                marginTop: 15,
                display: "flex",
                alignItems: "center",
                fontSize: 12,
              }}
            >
              <div style={{ width: 150, textAlign: "left" }}>
                {display ? display.receiptsArray.length : null}
              </div>
              <div style={{ flex: 1 }} />

              <div style={{ width: 200, textAlign: "right" }}>
                {display ? "R" + Number(display.averageSale).toFixed(2) : null}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            zIndex: 1,

            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <div
              style={{
                WebkitBoxShadow: "0px 0px 7px 2px #00000050",
                maxWidth: 450,
              }}
            >
              <div
                key={selectedReportIndex}
                id="report"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: 450,
                  position: "relative",
                  minHeight: 625,
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 20,
                    left: 20,
                    display: "flex",
                    flexDirection: "column",
                    fontSize: 12,
                  }}
                >
                  CASH REGISTER{" "}
                  {selectedReportIndex ? selectedReportIndex : null}
                  <div style={{ fontSize: downloading ? 14 : 10 }}>
                    {display ? display.storeName : null}
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 20,
                    left: 210,

                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right",
                    fontSize: 12,
                  }}
                >
                  REGISTER SUMMARY{" "}
                  {selectedReportIndex ? selectedReportIndex : null}
                  <div style={{ fontSize: 10 }}>
                    {display
                      ? display.openedAt.substring(0, 10) +
                        " - " +
                        display.openedAt.substring(11, 19) +
                        "  TO  "
                      : null}

                    {display
                      ? display.closedAt.substring(0, 10) +
                        " - " +
                        display.closedAt.substring(11, 19)
                      : null}
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 80,
                    left: 20,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "90%",
                  }}
                >
                  <div
                    style={{ display: "flex", width: "105%", marginBottom: 2 }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: 10,
                        flex: 1,
                      }}
                    >
                      <div style={{ marginLeft: 10 }}> Payment Type</div>
                    </div>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: 10,
                        width: 60,
                        textAlign: "right",
                      }}
                    >
                      <div style={{ marginLeft: 3 }}>Expected</div>
                    </div>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: 10,
                        width: 60,
                        textAlign: "right",
                      }}
                    >
                      <div style={{ marginLeft: 3 }}>Counted</div>
                    </div>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: 10,
                        width: 60,
                        textAlign: "right",
                        paddingLeft: 3,
                      }}
                    >
                      <div style={{ marginLeft: 3 }}>Difference</div>
                    </div>
                  </div>

                  <div
                    style={{
                      borderStyle: "solid",
                      width: "100%",
                      borderWidth: 0.8,
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 105,
                    left: 20,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "90%",
                  }}
                >
                  <div
                    style={{ display: "flex", width: "100%", marginBottom: 2 }}
                  >
                    <div style={{ fontWeight: "normal", fontSize: 8, flex: 1 }}>
                      Cash (net cash payments)
                    </div>
                    <div
                      style={{
                        fontWeight: "normal",
                        fontSize: 8,
                        width: 60,
                        textAlign: "right",
                      }}
                    >
                      {display
                        ? "R" +
                          (
                            Number(display.cash) +
                            Number(display.tillOpeningAmount)
                          ).toFixed(2)
                        : null}
                    </div>
                    <div
                      style={{
                        fontWeight: "normal",
                        fontSize: 8,
                        width: 60,
                        textAlign: "right",
                      }}
                    >
                      {display
                        ? "R" + Number(display.cashCounted).toFixed(2)
                        : null}
                    </div>
                    <div
                      style={{
                        fontWeight: "normal",
                        fontSize: 8,
                        width: 60,
                        textAlign: "right",
                      }}
                    >
                      {display
                        ? "R" +
                          (
                            (
                              Number(display.cash) +
                              Number(display.tillOpeningAmount)
                            ).toFixed(2) -
                            Number(display.cashCounted).toFixed(2)
                          ).toFixed(2)
                        : null}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 125,
                    left: 20,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "90%",
                  }}
                >
                  <div
                    style={{ display: "flex", width: "100%", marginBottom: 2 }}
                  >
                    <div style={{ fontWeight: "normal", fontSize: 8, flex: 1 }}>
                      Card
                    </div>
                    <div
                      style={{
                        fontWeight: "normal",
                        fontSize: 8,
                        width: 60,
                        textAlign: "right",
                      }}
                    >
                      {display ? "R" + Number(display.card).toFixed(2) : null}
                    </div>
                    <div
                      style={{
                        fontWeight: "normal",
                        fontSize: 8,
                        width: 60,
                        textAlign: "right",
                      }}
                    >
                      {display
                        ? "R" + Number(display.cardCounted).toFixed(2)
                        : null}
                    </div>
                    <div
                      style={{
                        fontWeight: "normal",
                        fontSize: 8,
                        width: 60,
                        textAlign: "right",
                      }}
                    >
                      {display
                        ? "R" +
                          (
                            Number(display.card) - Number(display.cardCounted)
                          ).toFixed(2)
                        : null}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 145,
                    left: 20,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "90%",
                  }}
                >
                  <div
                    style={{ display: "flex", width: "100%", marginBottom: 2 }}
                  >
                    <div style={{ fontWeight: "normal", fontSize: 8, flex: 1 }}>
                      Credit
                    </div>
                    <div
                      style={{
                        fontWeight: "normal",
                        fontSize: 8,
                        width: 60,
                        textAlign: "right",
                      }}
                    >
                      {display
                        ? "R" + Number(display.storeCredit).toFixed(2)
                        : null}
                    </div>
                    <div
                      style={{
                        fontWeight: "normal",
                        fontSize: 8,
                        width: 60,
                        textAlign: "right",
                      }}
                    >
                      {display
                        ? "R" + Number(display.storeCredit).toFixed(2)
                        : null}
                    </div>
                    <div
                      style={{
                        fontWeight: "normal",
                        fontSize: 8,
                        width: 60,
                        textAlign: "right",
                      }}
                    >
                      R0.00
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 165,
                    left: 20,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "90%",
                  }}
                >
                  <div
                    style={{
                      borderStyle: "solid",
                      width: "100%",
                      borderWidth: 0.8,
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 175,
                    left: 20,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "90%",
                  }}
                >
                  <div
                    style={{ display: "flex", width: "100%", marginBottom: 2 }}
                  >
                    <div
                      style={{ fontWeight: "normal", fontSize: 8, flex: 1 }}
                    ></div>

                    <div
                      style={{
                        fontWeight: "normal",
                        fontSize: 8,
                        width: 120,
                        textAlign: "right",
                        display: "flex",
                        flexDirection: "row",
                        position: "relative",
                      }}
                    >
                      Payments:
                      <div style={{ flex: 1 }} />
                      {display
                        ? "R" + Number(display.TotalInc).toFixed(2)
                        : null}
                      <div
                        style={{
                          position: "absolute",
                          borderStyle: "solid",
                          borderWidth: 0.5,
                          width: 120,
                          bottom: -5,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 195,
                    left: 20,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "90%",
                  }}
                >
                  <div
                    style={{ display: "flex", width: "100%", marginBottom: 2 }}
                  >
                    <div
                      style={{ fontWeight: "normal", fontSize: 8, flex: 1 }}
                    ></div>

                    <div
                      style={{
                        fontWeight: "normal",
                        fontSize: 8,
                        width: 120,
                        textAlign: "right",
                        display: "flex",
                        flexDirection: "row",
                        position: "relative",
                      }}
                    >
                      Refunds:
                      <div style={{ flex: 1 }} />
                      {display
                        ? "R" + Number(display.refunds).toFixed(2)
                        : null}
                      <div
                        style={{
                          position: "absolute",
                          borderStyle: "solid",
                          borderWidth: 0.5,
                          width: 120,
                          bottom: -5,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 215,
                    left: 20,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "90%",
                  }}
                >
                  <div
                    style={{ display: "flex", width: "100%", marginBottom: 2 }}
                  >
                    <div
                      style={{ fontWeight: "normal", fontSize: 8, flex: 1 }}
                    ></div>

                    <div
                      style={{
                        fontWeight: "normal",
                        fontSize: 8,
                        width: 120,
                        textAlign: "right",
                        display: "flex",
                        flexDirection: "row",
                        position: "relative",
                      }}
                    >
                      Net receipts:
                      <div style={{ flex: 1 }} />
                      {display
                        ? "R" +
                          (
                            Number(display.TotalInc) - Number(display.refunds)
                          ).toFixed(2)
                        : null}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 230,
                    left: 20,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "90%",
                  }}
                >
                  <div
                    style={{
                      borderStyle: "solid",
                      width: "100%",
                      borderWidth: 0.8,
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 240,
                    left: 20,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "90%",
                  }}
                >
                  <div
                    style={{ display: "flex", width: "100%", marginBottom: 2 }}
                  >
                    <div
                      style={{ fontWeight: "normal", fontSize: 8, flex: 1 }}
                    ></div>

                    <div
                      style={{
                        fontWeight: "normal",
                        fontSize: 8,
                        minWidth: 120,
                        textAlign: "right",
                        display: "flex",
                        flexDirection: "row",
                        position: "relative",
                      }}
                    >
                      Total (Incl) Sales:
                      <div style={{ flex: 1 }} />
                      {display
                        ? "R" + Number(display.TotalInc).toFixed(2)
                        : null}
                      {/* <div
                  style={{
                    position: "absolute",
                    borderStyle: "solid",
                    borderWidth: 0.5,
                    width: 120,
                    bottom: -5,
                  }}
                /> */}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 260,
                    left: 20,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "90%",
                  }}
                >
                  <div
                    style={{ display: "flex", width: "100%", marginBottom: 2 }}
                  >
                    <div
                      style={{ fontWeight: "normal", fontSize: 8, flex: 1 }}
                    ></div>

                    <div
                      style={{
                        fontWeight: "normal",
                        fontSize: 8,
                        minWidth: 120,
                        textAlign: "right",
                        display: "flex",
                        flexDirection: "row",
                        position: "relative",
                        alignItems: "center",
                      }}
                    >
                      Total (Ex) Sales: <div style={{ flex: 1 }} />
                      {display
                        ? "R" +
                          (
                            Number(display.TotalInc) - Number(display.vat)
                          ).toFixed(2)
                        : null}
                      {/* <div
                  style={{
                    position: "absolute",
                    borderStyle: "solid",
                    borderWidth: 0.5,
                    width: 120,
                    bottom: -5,
                  }}
                /> */}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 280,
                    left: 20,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "90%",
                  }}
                >
                  <div
                    style={{ display: "flex", width: "100%", marginBottom: 2 }}
                  >
                    <div
                      style={{ fontWeight: "normal", fontSize: 8, flex: 1 }}
                    ></div>

                    <div
                      style={{
                        fontWeight: "normal",
                        fontSize: 8,
                        minWidth: 120,
                        textAlign: "right",
                        display: "flex",
                        flexDirection: "row",
                        position: "relative",
                        alignItems: "center",
                      }}
                    >
                      Total Vat:{" "}
                      <div style={{ fontSize: 7, marginLeft: 3 }}>
                        {display
                          ? "(R" + Number(display.TotalInc).toFixed(2) + ")"
                          : null}
                      </div>
                      <div style={{ flex: 1 }} />
                      {display ? "R" + Number(display.vat).toFixed(2) : null}
                      {/* <div
                  style={{
                    position: "absolute",
                    borderStyle: "solid",
                    borderWidth: 0.5,
                    width: 120,
                    bottom: -5,
                  }}
                /> */}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 300,
                    left: 20,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "90%",
                  }}
                >
                  <div
                    style={{ display: "flex", width: "100%", marginBottom: 2 }}
                  >
                    <div
                      style={{ fontWeight: "normal", fontSize: 8, flex: 1 }}
                    ></div>

                    <div
                      style={{
                        fontWeight: "normal",
                        fontSize: 8,
                        minWidth: 120,
                        textAlign: "right",
                        display: "flex",
                        flexDirection: "row",
                        position: "relative",
                        alignItems: "center",
                      }}
                    >
                      Discounts:
                      <div style={{ flex: 1 }} />
                      {display
                        ? "R" + Number(display.discounts).toFixed(2)
                        : null}
                      {/* <div
                  style={{
                    position: "absolute",
                    borderStyle: "solid",
                    borderWidth: 0.5,
                    width: 120,
                    bottom: -5,
                  }}
                /> */}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 320,
                    left: 20,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "90%",
                  }}
                >
                  <div
                    style={{ display: "flex", width: "100%", marginBottom: 2 }}
                  >
                    <div
                      style={{ fontWeight: "normal", fontSize: 8, flex: 1 }}
                    ></div>

                    <div
                      style={{
                        fontWeight: "normal",
                        fontSize: 8,
                        minWidth: 120,
                        textAlign: "right",
                        display: "flex",
                        flexDirection: "row",
                        position: "relative",
                        alignItems: "center",
                      }}
                    >
                      Till Opening Amt.:
                      <div style={{ flex: 1 }} />
                      {display
                        ? "R" + Number(display.tillOpeningAmount).toFixed(2)
                        : null}
                      {/* <div
                  style={{
                    position: "absolute",
                    borderStyle: "solid",
                    borderWidth: 0.5,
                    width: 120,
                    bottom: -5,
                  }}
                /> */}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 340,
                    left: 20,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "90%",
                  }}
                >
                  <div
                    style={{
                      borderStyle: "solid",
                      width: "100%",
                      borderWidth: 0.8,
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 360,
                    left: 20,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "90%",
                  }}
                >
                  <div
                    style={{ display: "flex", width: "100%", marginBottom: 2 }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: 8,
                        flex: 1,
                        display: "flex",
                      }}
                    >
                      <div>Transaction</div>
                      <div style={{ marginLeft: 40, fontWeight: "bold" }}>
                        User
                      </div>
                    </div>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: 8,

                        display: "flex",
                      }}
                    >
                      Action
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 380,
                    left: 20,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "90%",
                  }}
                >
                  <div
                    style={{
                      borderStyle: "solid",
                      width: "100%",
                      borderWidth: 0.8,
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 400,
                    left: 20,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "90%",
                  }}
                >
                  <div
                    style={{ display: "flex", width: "100%", marginBottom: 2 }}
                  >
                    <div
                      style={{
                        fontWeight: "normal",
                        fontSize: 7,
                        flex: 1,
                        display: "flex",
                      }}
                    >
                      <div>Opening float</div>
                      <div style={{ marginLeft: 40 }}>
                        {display ? display.openedBy : null}
                      </div>
                    </div>
                    <div
                      style={{
                        fontWeight: "normal",
                        fontSize: 8,

                        display: "flex",
                      }}
                    >
                      +{" "}
                      {display
                        ? "R" + Number(display.tillOpeningAmount).toFixed(2)
                        : null}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 420,
                    left: 20,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "90%",
                  }}
                >
                  <div
                    style={{
                      borderStyle: "solid",
                      width: "100%",
                      borderWidth: 0.8,
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 440,
                    left: 20,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "90%",
                  }}
                >
                  <div
                    style={{ display: "flex", width: "100%", marginBottom: 2 }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: 8,
                        flex: 1,
                        display: "flex",
                      }}
                    >
                      <div>Num. of transctions</div>
                    </div>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: 8,

                        display: "flex",
                      }}
                    >
                      Average Sale
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 460,
                    left: 20,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "90%",
                  }}
                >
                  <div
                    style={{
                      borderStyle: "solid",
                      width: "100%",
                      borderWidth: 0.8,
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 480,
                    left: 20,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "90%",
                  }}
                >
                  <div
                    style={{ display: "flex", width: "100%", marginBottom: 2 }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: 8,
                        flex: 1,
                        display: "flex",
                      }}
                    >
                      <div>{display ? display.receiptsArray.length : null}</div>
                    </div>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: 8,

                        display: "flex",
                      }}
                    >
                      {display
                        ? "R" + Number(display.averageSale).toFixed(2)
                        : null}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 500,
                    left: 20,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "90%",
                  }}
                >
                  <div
                    style={{
                      borderStyle: "solid",
                      width: "100%",
                      borderWidth: 0.8,
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 520,
                    left: 20,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "90%",
                  }}
                >
                  <div
                    style={{ display: "flex", width: "100%", marginBottom: 2 }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: 8,
                        flex: 1,
                      }}
                    >
                      <div style={{ marginBottom: 10 }}>
                        {display ? "Opened by:" + display.openedBy : null}
                      </div>
                      <div>
                        {display ? "Closed by:" + display.closedBy : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PdfDisplay;
