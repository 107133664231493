import React, { useState } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";

const StoreCard = ({ storeName, onClick }) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      onClick={onClick}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{
        width: "90%",
        minHeight: 100,
        borderRadius: 8,
        backgroundColor: hover ? "#00000030" : BlackAndWhite.secondary,
        WebkitBoxShadow: "0px 0px 7px 2px #00000050",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "bold",
        fontSize: 20,
        cursor: "pointer",
      }}
    >
      {storeName}
    </div>
  );
};

export default StoreCard;
