import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../../../../../themes/Colors";
import ProductListItem from "../product-list-item/ProductListItem";

const SubCatItemCard = ({
  sub,
  index,
  onDeleteClick,
  onEditClick,
  setLoading,
  setToastPop,
  setMessage,
  selectedProduct,
  setSelectedProd,
  refresh,
}) => {
  const [hover, setHover] = useState(null);
  const [deleteHover, setDeleteHover] = useState(false);
  const [manageHover, setManageHover] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [productLines, setProductLines] = useState(null);
  const [selectedSubCat, setSelectedSubCat] = useState(null);
  const [total, setTotal] = useState(0);
  const updateProductDisplay = async ({ info }) => {
    setLoading(true);
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/getProductsBySubCategory",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subCategoryId: sub.subCategoryId,
          }),
        }
      );

      const respnseData = await response.json();
      if (respnseData.product) {
        // const wait = await updateListFetch();
        setProductLines(respnseData.product);
        setLoading(false);
      } else if (respnseData.message) {
        setToastPop(true);
        setMessage(respnseData.message);
        setLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      setToastPop(true);
      setMessage("Error occured please try again in a few minutes");
      setLoading(false);
    }
  };
  const getProductAmount = async (searchId) => {
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/productLine/getProductsAmount",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            searchType: "sub",
            searchId: searchId,
          }),
        }
      );

      const respnseData = await response.json();
      if (respnseData.subCategory) {
        // const wait = await updateListFetch();
        setTotal(respnseData.subCategory);
      } else {
        setTotal("error");
      }
    } catch (err) {
      console.log("err", err);
      setToastPop(true);
      setMessage("Error occured please try again in a few minutes");
      setLoading(false);
    }
  };

  useEffect(() => {
    getProductAmount(sub.subCategoryId);
    if (dropDown) {
      updateProductDisplay(sub);
    }
  }, [dropDown, refresh]);
  return (
    <>
      <div
        onClick={
          deleteHover || manageHover
            ? null
            : () => {
                setDropDown(dropDown ? false : true);
                setSelectedSubCat(dropDown ? null : index);
              }
        }
        onMouseOver={() => setHover(index)}
        onMouseOut={() => setHover(null)}
        key={index}
        style={{
          width: "100%",
          height: 50,
          cursor: "pointer",
          backgroundColor:
            selectedSubCat === index
              ? "#6792AC80"
              : hover === index
              ? "#00000090"
              : "#00000010",
          display: "flex",
          alignItems: "center",
          marginTop: 3,
          //   borderRadius: 8,
        }}
      >
        <div
          style={{
            marginLeft: 20,
            color:
              hover === index ? BlackAndWhite.secondary : BlackAndWhite.primary,
            fontSize: 12,
            fontWeight:
              hover === index
                ? "bold"
                : selectedSubCat === index
                ? "bold"
                : "normal",
          }}
        >
          Sub-Category : {sub.subCategoryName}
        </div>
        <div
          style={{
            marginLeft: 20,
            color:
              hover === index ? BlackAndWhite.secondary : BlackAndWhite.primary,
            fontSize: 12,
          }}
        >
          description : {sub.description}
        </div>
        <div style={{ flex: 1 }}></div>
        <div style={{ fontSize: 14, marginRight: 10, transition: "all 0.3s" }}>
          Total Products : {total === "error" ? 0 : total}
        </div>
        {/* edit button */}
        {selectedSubCat === index ? null : (
          <button
            onClick={onEditClick}
            onMouseOver={() => setManageHover(true)}
            onMouseOut={() => setManageHover(false)}
            style={{
              height: "100%",
              width: 80,
              backgroundColor: manageHover
                ? BlackAndWhite.primary
                : "#ffffff70",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderWidth: 0,
            }}
          >
            <img
              alt="icon"
              src={
                manageHover
                  ? require("../../../../../../../../images/material-icons/editWhite.png")
                  : require("../../../../../../../../images/material-icons/editBlack.png")
              }
              style={{
                height: 15,

                transition: "all 0.3s",
              }}
            />
          </button>
        )}
        {/* dlete butto */}
        {/* <button
          onClick={onDeleteClick}
          disabled={true}
          onMouseOver={() => setDeleteHover(true)}
          onMouseOut={() => setDeleteHover(false)}
          style={{
            height: "100%",
            width: 80,
            backgroundColor: deleteHover
              ? BlackAndWhite.primary
              : BlackAndWhite.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderWidth: 0,
          }}
        >
          <img
            alt="icon"
            src={
              deleteHover
                ? require("../../../../../../../../images/material-icons/deleteWhite.png")
                : require("../../../../../../../../images/material-icons/deleteBlack.png")
            }
            style={{
              height: 20,

              transition: "all 0.3s",
            }}
          />
        </button> */}
      </div>
      <ProductListItem
        selectedProduct={selectedProduct}
        setSelectedProd={setSelectedProd}
        display={dropDown ? true : false}
        data={productLines ? productLines : []}
      />
    </>
  );
};

export default SubCatItemCard;
