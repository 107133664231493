import React, { useState } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import { BaseButtons, CloseButton } from "../../../../../../components/atoms";
import TabCategoryOptions from "../tab-category-options/TabCategoryOptions";

const ProductInfoPopUp = ({
  display,
  onCloseClick,
  selectedProduct,
  mobile,
  onAddClick,
}) => {
  const [descriptionDisplay, setDescriptionDisplay] = useState("description");

  return (
    <div
      style={{
        width: "100%",
        height: display ? "100%" : 0,
        position: "absolute",
        backgroundColor: BlackAndWhite.secondary,
        transition: "all 0.4s",
        display: "flex",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
          backgroundColor: BlackAndWhite.secondary,
          display: "flex",
        }}
      >
        <div style={{ position: "absolute", left: 10, top: 10, zIndex: 200 }}>
          <CloseButton label={"X"} onClick={onCloseClick} />
        </div>
        {mobile ? (
          <div style={{ flex: 1, overflow: "scroll", zIndex: 1 }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <div style={{ position: "relative" }}>
                <img
                  alt="loading"
                  src={require("../../../../../../images/marijuana-buds-with-marijuana-joints-cannabis-oil.jpg")}
                  style={{ width: "100%" }}
                />
                <div
                  style={{
                    position: "absolute",
                    height: 100,
                    width: 100,
                    borderRadius: "100%",
                    backgroundColor: BlackAndWhite.secondary,
                    bottom: -40,
                    right: 15,
                    borderStyle: "solid",
                    borderWidth: 5,
                    borderColor: "#FAA919",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontSize: 14 }}>RETAIL</div>
                  <div style={{ fontSize: 20, fontWeight: "bold" }}>
                    R {selectedProduct ? selectedProduct.retailPrice : null}
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    height: 80,
                    width: 80,
                    borderRadius: "100%",
                    backgroundColor: BlackAndWhite.secondary,
                    bottom: 50,
                    right: 10,
                    borderStyle: "solid",
                    borderWidth: 5,
                    borderColor: "#FAA919",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontSize: 12, textAlign: "center" }}>THC</div>
                  <div
                    style={{
                      fontSize: 15,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {selectedProduct ? selectedProduct.thcContent : null}
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    height: 60,
                    width: 60,
                    borderRadius: "100%",
                    backgroundColor: BlackAndWhite.secondary,
                    bottom: 130,
                    right: 10,
                    borderStyle: "solid",
                    borderWidth: 5,
                    borderColor: "#FAA919",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontSize: 9, textAlign: "center" }}>
                    Strain:
                  </div>
                  <div
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {selectedProduct ? selectedProduct.strain : null}
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    height: 50,
                    width: 150,
                    borderRadius: 100,
                    backgroundColor: "#FFFFFF",
                    bottom: -20,
                    left: 20,
                    borderStyle: "solid",
                    borderWidth: 5,
                    borderColor: "#FAA919",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontSize: 10, textAlign: "center" }}>
                    Grow type:
                  </div>
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {selectedProduct ? selectedProduct.growType : null}
                  </div>
                </div>
              </div>

              <div
                style={{
                  // marginLeft: 35,
                  marginTop: 25,
                  width: "90%",
                  fontSize: 14,
                  // position: "absolute",
                  // top: 0,
                  fontWeight: "bold",
                  color: BlackAndWhite.primary,
                  backgroundColor: "#FFFFFFcc",
                  // padding: 5,
                  borderRadius: 8,
                }}
              >
                <div style={{ marginLeft: 5, marginTop: 10 }}>
                  Product name:
                </div>
              </div>
              <div
                style={{
                  // marginLeft: 35,
                  marginTop: 5,
                  width: "90%",
                  fontSize: 24,
                  // position: "absolute",
                  // top: 0,
                  fontWeight: "bold",
                  color: BlackAndWhite.primary,
                  backgroundColor: "#FFFFFFcc",
                  // padding: 5,
                  borderRadius: 8,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div style={{ marginLeft: 5, width: "100%" }}>
                  {selectedProduct ? selectedProduct.productName : null}
                </div>
                <div
                  style={{
                    width: "100%",
                    height: 55,
                    fontSize: 14,
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <BaseButtons
                    label={"Description"}
                    size={135}
                    height={40}
                    onClick={() => setDescriptionDisplay("description")}
                    selected={
                      descriptionDisplay === "description" ? true : false
                    }
                    invert={descriptionDisplay === "description" ? false : true}
                  />
                  <BaseButtons
                    label={"How to use?"}
                    size={135}
                    height={40}
                    onClick={() => setDescriptionDisplay("how")}
                    selected={descriptionDisplay === "how" ? true : false}
                    invert={descriptionDisplay === "how" ? false : true}
                  />
                </div>
                <div style={{ width: "85%" }}>
                  {descriptionDisplay === "description" ? (
                    <div
                      style={{
                        fontSize: 16,
                        fontWeight: "normal",
                        marginTop: 20,
                        marginBottom: 20,
                      }}
                    >
                      {selectedProduct ? selectedProduct.description : null}
                    </div>
                  ) : null}
                  {descriptionDisplay === "how" ? (
                    <div
                      style={{
                        fontSize: 16,
                        fontWeight: "normal",
                        marginTop: 20,
                        marginBottom: 20,
                      }}
                    >
                      {selectedProduct ? selectedProduct.useDescription : null}
                    </div>
                  ) : null}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BaseButtons label={"ADD TO CART"} onClick={onAddClick} />
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {mobile ? null : (
          <div
            style={{
              flex: 0.5,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",

              borderWidth: 3,
            }}
          >
            <div
              style={{
                width: "80%",
                textAlign: "left",
                fontSize: 30,
                marginTop: 40,
                fontWeight: "bold",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{ marginLeft: 20, fontSize: 16, fontWeight: "normal" }}
              >
                Product name:
              </div>
              <div style={{ marginLeft: 20, marginTop: 5 }}>
                {selectedProduct ? selectedProduct.productName : null}
              </div>
              <div
                style={{
                  marginLeft: 20,
                  fontSize: 16,
                  fontWeight: "normal",
                  marginTop: 10,
                }}
              >
                Product type:
              </div>
              <div style={{ marginLeft: 20, marginTop: 5 }}>
                {selectedProduct ? selectedProduct.productType : null}
              </div>
              <div
                style={{
                  marginLeft: 20,
                  fontSize: 16,
                  fontWeight: "normal",
                  marginTop: 10,
                }}
              >
                Strain:
              </div>
              <div style={{ marginLeft: 20, marginTop: 5 }}>
                {selectedProduct ? selectedProduct.strain : null}
              </div>
            </div>
            <div
              style={{
                flex: 1,

                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: 60,
                  width: "105%",
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  marginTop: 40,
                  marginBottom: 10,
                  backgroundColor: "#FAA919",
                }}
              >
                <BaseButtons
                  label={"Description"}
                  marginTopFalse={true}
                  height={40}
                  size={125}
                  selected={descriptionDisplay === "description" ? true : false}
                  onClick={() => setDescriptionDisplay("description")}
                  invert={descriptionDisplay === "description" ? false : true}
                />
                <BaseButtons
                  label={"How to"}
                  marginTopFalse={true}
                  height={40}
                  size={120}
                  selected={descriptionDisplay === "how" ? true : false}
                  onClick={() => setDescriptionDisplay("how")}
                  invert={descriptionDisplay === "how" ? false : true}
                />
                <BaseButtons
                  label={"Quick"}
                  marginTopFalse={true}
                  height={40}
                  size={120}
                  selected={descriptionDisplay === "quick" ? true : false}
                  onClick={() => setDescriptionDisplay("quick")}
                  invert={descriptionDisplay === "quick" ? false : true}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "80%",
                }}
              >
                {descriptionDisplay === "description" ? (
                  <>
                    <div
                      style={{
                        marginTop: 20,
                        marginBottom: 10,
                        fontSize: 18,
                        fontWeight: "bold",
                      }}
                    >
                      Description:
                    </div>
                    <div>
                      {selectedProduct ? selectedProduct.description : null}
                    </div>
                  </>
                ) : null}
                {descriptionDisplay === "how" ? (
                  <>
                    <div
                      style={{
                        marginTop: 20,
                        marginBottom: 10,
                        fontSize: 18,
                        fontWeight: "bold",
                      }}
                    >
                      How to use:
                    </div>
                    <div
                      style={{
                        width: "100%",

                        height: 200,
                        overflow: "scroll",
                      }}
                    >
                      {selectedProduct ? selectedProduct.useDescription : null}
                    </div>
                  </>
                ) : null}
                {descriptionDisplay === "quick" ? (
                  <>
                    <div
                      style={{
                        marginTop: 20,
                        marginBottom: 10,
                        fontSize: 18,
                        fontWeight: "bold",
                      }}
                    >
                      Quick description:
                    </div>
                    <div>
                      {selectedProduct ? selectedProduct.menuDescription : null}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        )}
        {mobile ? null : (
          <div
            style={{
              flex: 0.5,
              height: "100%",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              paddingTop: 50,
            }}
          >
            <div
              style={{
                height: 450,
                width: 450,
                backgroundColor: BlackAndWhite.primary,
                borderRadius: "100%",
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                WebkitBoxShadow: "0px 0px 10px 1px #00000033",
              }}
            >
              <img
                alt="loading"
                src={require("../../../../../../images/marijuana-buds-with-marijuana-joints-cannabis-oil.jpg")}
                style={{
                  height: "100%",
                  position: "relative",
                  borderRadius: "100%",
                }}
              ></img>
            </div>
            <div
              style={{
                height: 450,
                width: 450,

                borderRadius: "100%",
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                //   overflow: "hidden",
              }}
            >
              <div
                style={{
                  height: 150,
                  width: 150,
                  borderRadius: "100%",
                  backgroundColor: "#FFFFFF",
                  position: "absolute",
                  bottom: -100,
                  borderStyle: "solid",
                  borderWidth: 5,
                  borderColor: "#FAA919",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: 30,
                  flexDirection: "column",
                }}
              >
                <div style={{ fontSize: 16, fontWeight: "normal" }}>
                  RETAIL{" "}
                </div>
                {selectedProduct
                  ? "R" + " " + selectedProduct.retailPrice
                  : "loading"}
              </div>
              <div
                style={{
                  height: 120,
                  width: 120,
                  borderRadius: "100%",
                  backgroundColor: "#FFFFFF",
                  position: "absolute",
                  bottom: 0,
                  borderStyle: "solid",
                  borderWidth: 5,
                  borderColor: "#FAA919",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: 20,
                  flexDirection: "column",
                  right: 0,
                }}
              >
                <div style={{ fontSize: 13, fontWeight: "normal" }}>THC: </div>
                {selectedProduct ? selectedProduct.thcContent : "loading"}
              </div>
              <div
                style={{
                  height: 90,
                  width: 90,
                  borderRadius: "100%",
                  backgroundColor: "#FFFFFF",
                  position: "absolute",
                  //   bottom: 80,
                  borderStyle: "solid",
                  borderWidth: 5,
                  borderColor: "#FAA919",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: 13,
                  flexDirection: "column",
                  right: -60,
                }}
              >
                <div style={{ fontSize: 10, fontWeight: "normal" }}>
                  Grow type:{" "}
                </div>
                {selectedProduct ? selectedProduct.growType : "loading"}
              </div>
            </div>
          </div>
        )}

        {/* <div style={{ flex: 0.35, height: "100%" }}>section</div> */}
      </div>
    </div>
  );
};

export default ProductInfoPopUp;
