import React, { useState, useEffect, useRef } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";
import {
  BaseButtons,
  CloseButton,
  TextInputField,
} from "../../../../components/atoms";
import EmailConfirmPopUp from "./components/email-confirm-pop-up/EmailConfirmPopUp";
import {
  ImageUploadComp,
  LoadingPopUp,
  ToastNotification,
} from "../../../../components";
import ProvinceSelector from "./components/province-selector/ProvinceSelector";
import StoreSelector from "./components/store-selector/StoreSelector";
import { useNavigate } from "react-router-dom";

const ValidateProfile = ({ display, onCloseClick, auth, setValidateEdit }) => {
  const [validationSteps, setValidationSteps] = useState(1);
  const [imageUploadHover, setImageUploadHover] = useState(false);

  const navigate = useNavigate();

  const [email, setEmail] = useState(null);
  const [emailConfirm, setEmailConfirm] = useState(null);

  const [cellNumber, setCellNumber] = useState("");
  const [idDoc, setIdDoc] = useState("");

  const [unit, setUnit] = useState("");
  const [street, setStreet] = useState("");
  const [suburb, setSuburb] = useState("");
  const [city, setCity] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [emailUpdate, setEmailUpdate] = useState(false);
  const [newEmail, setNewEmail] = useState(null);

  const [verificationCode, setVerificationCode] = useState("");

  const [loading, setLoading] = useState(false);

  const [toastPop, setToastPop] = useState(false);
  const [message, setMessage] = useState(null);

  const [file, setFile] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);

  const [province, setProvince] = useState(false);
  const [provinceOpen, setProvinceOpen] = useState(false);

  const [store, setStore] = useState(false);
  const [storeOpen, setStoreOpen] = useState(false);

  const filepickerRef = useRef();
  const Logout = () => {
    auth.logout();
    navigate("/");
  };

  const handleCapture = (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const pickedImageFile = target.files[0];
        setFile(pickedImageFile);
        setIsValid(true);
      } else {
        setIsValid(false);
        console.log("error");
      }
    }
  };
  const pickedImage = (e) => {
    if (e.target.files || e.target.files.length === 1) {
      const pickedImageFile = e.target.files[0];
      setFile(pickedImageFile);
      setIsValid(true);
    } else {
      setIsValid(false);
      console.log("error");
    }
  };

  const pickedImageHandler = () => {
    filepickerRef.current.click();
  };

  const EmailUpdateConfimForm = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/user/emailUpdateUserValidation",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: auth.customerUserData.userId,
            email: email,
          }),
        }
      );
      const respnseData = await response.json();
      if (respnseData.data) {
        setLoading(false);
        setEmailUpdate(false);
        setMessage("your email address has been updated");
        setToastPop(true);
        setEmail(null);
      } else {
        setLoading(false);
        setMessage("your email Could not be updated please try again later");
        setToastPop(true);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const EmailValidationCode = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/user/emailSendValidationCode",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: auth.customerUserData.userId,
            email: email,
          }),
        }
      );
      const respnseData = await response.json();

      if (respnseData.message === "success") {
        setLoading(false);
        setEmailUpdate(false);
        setMessage("verification code sent, please check your email");
        setToastPop(true);
        setValidationSteps(2);
        setEmail(null);
      } else {
        setLoading(false);
        setMessage(respnseData.message);
        setToastPop(true);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const VerifyCode = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/user/accountCheck",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: auth.customerUserData.userId,
            email: email,
            code: verificationCode,
          }),
        }
      );
      const respnseData = await response.json();

      if (respnseData.data === "success") {
        setLoading(false);
        setEmailUpdate(false);
        setMessage("Your email has been verified, were almost done.");
        setToastPop(true);
        setValidationSteps(3);
        setEmail(null);
      } else {
        setLoading(false);
        setMessage(respnseData.message);
        setToastPop(true);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const CompleteValidation = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("userId", auth.customerUserData.userId);
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("street", unit + " " + street);
      formData.append("suburb", suburb);
      formData.append("town", city);
      formData.append("province", province);
      formData.append("store", store);
      formData.append("cellNumber", cellNumber);
      formData.append("email", newEmail || email);
      formData.append("idDoc", idDoc);
      formData.append("image", file);

      // https://merry-jane-api.onrender.com/user/createVerifiedUser"
      const response = await fetch(
        "https://merry-jane-api.onrender.com/user/createVerifiedUser",
        {
          method: "POST",
          body: formData,
        }
      );
      const respnseData = await response.json();
      if (respnseData.data === "success") {
        setValidationSteps(1);
        setToastPop(true);
        setMessage(
          "Success, You will automatically be logged out, dont worry it just means your have been validated and we just need you to log back in."
        );
        setTimeout(() => {
          setLoading(false);
          Logout();
        }, 10000);
      } else {
        setToastPop(true);
        setMessage(
          "Your account is already validated please contact support if you wish to reset your validation"
        );
        setLoading(false);
        setValidationSteps(1);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };

  const DisplayValidationStep = () => {
    switch (validationSteps) {
      case 1:
        return (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                width: "100%",
                position: "absolute",
                top: 0,
                height: 60,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 20,
                fontWeight: "bold",
              }}
            >
              EMAIL VALIDATION
            </div>
            {emailUpdate ? (
              <EmailConfirmPopUp
                email={email}
                onChangeConfirm={(e) => {
                  setEmailConfirm(e.target.value);
                  setNewEmail(e.target.value);
                }}
                onCancelClick={() => {
                  setEmailUpdate(false);
                  setEmailConfirm(null);
                }}
                confirmEmail={emailConfirm}
                onUpdateClick={() => EmailUpdateConfimForm()}
              />
            ) : (
              <>
                <div
                  style={{ width: 300, textAlign: "center", marginBottom: 18 }}
                >
                  "Merry-Jane says..."
                </div>
                <div
                  style={{ width: 280, textAlign: "center", marginBottom: 18 }}
                >
                  Validating your account is important, it allows access to
                  future updates. Our online store is coming soon...
                </div>
                <div style={{ width: 280, textAlign: "center" }}>
                  Make sure you have access to your email and confirm the email
                  address below is correct, We will send a validation code to
                  your mail box.
                </div>

                <TextInputField
                  labelTitle={"Email address:"}
                  invert={true}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <div
                  style={{
                    width: 280,
                    textAlign: "center",
                    fontSize: 12,
                    marginTop: 10,
                  }}
                >
                  Change the email above if incorrect, then click the update
                  email button.
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-evenly",
                    marginTop: 10,
                  }}
                >
                  <BaseButtons
                    label={"Update email"}
                    disable={email ? false : true}
                    onClick={() => setEmailUpdate(true)}
                  />
                  <BaseButtons
                    label={"Validate Account"}
                    disable={email ? false : true}
                    onClick={() => EmailValidationCode()}
                  />
                </div>
                <BaseButtons
                  label={"Already got your code?"}
                  disable={email ? false : true}
                  onClick={() => setValidationSteps(2)}
                />
              </>
            )}
          </div>
        );

      case 2:
        return (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                width: "100%",
                position: "absolute",
                top: 0,
                height: 60,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 20,
                fontWeight: "bold",
              }}
            >
              ENTER CODE
            </div>
            <div style={{ width: 300, textAlign: "center", marginBottom: 10 }}>
              "Merry-Jane says..."
            </div>
            <div style={{ width: 300, textAlign: "center" }}>
              We have sent you a unique code linked to your email, please check
              your email for your verfication code and enter it below.
            </div>

            <TextInputField
              labelTitle={"Verification code:"}
              invert={true}
              onChange={(e) => setVerificationCode(e.target.value)}
              value={verificationCode}
            />

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <BaseButtons
                label={"Back"}
                onClick={() => {
                  setValidationSteps(1);
                  setVerificationCode("");
                }}
              />
              <BaseButtons
                label={"Next"}
                onClick={() => VerifyCode()}
                disable={verificationCode.length === 6 ? false : true}
              />
            </div>
          </div>
        );
      // case 3:
      //   return (
      //     <div
      //       style={{
      //         height: "100%",
      //         width: "100%",
      //         display: "flex",
      //         flexDirection: "column",
      //         justifyContent: "center",
      //         alignItems: "center",
      //         position: "relative",
      //       }}
      //     >
      //       <div
      //         style={{
      //           width: "100%",
      //           position: "absolute",
      //           top: 0,
      //           height: 60,
      //           display: "flex",
      //           justifyContent: "center",
      //           alignItems: "center",
      //           fontSize: 20,
      //           fontWeight: "bold",
      //         }}
      //       >
      //         VALIDATE YOUR IDENTITY
      //       </div>
      //       <div
      //         style={{
      //           width: imageUploadHover ? 0 : 300,
      //           textAlign: "center",
      //           marginTop: imageUploadHover ? 0 : 10,
      //           overflow: "hidden",
      //           transition: "width 0.3s",
      //           marginBottom: imageUploadHover ? 0 : 10,
      //         }}
      //       >
      //         <div style={{ marginBottom: 10 }}>"Merry-Jane says..."</div>
      //         To ensure compliance please confirm you are 18 years or older by
      //         taking a picture of a valid form of identification such as a South
      //         African ID, passport, or driver's license.
      //         <div style={{ marginTop: 10 }}>
      //           Please make sure your identification fits the frame
      //           and it is legible.
      //         </div>
      //       </div>

      //       <div
      //         onMouseOver={() => setImageUploadHover(true)}
      //         onMouseOut={() => setImageUploadHover(false)}
      //         style={{
      //           // position: "absolute",
      //           // top: 150,
      //           // right: 20,
      //           display: "flex",
      //           flexDirection: "column",
      //           justifyContent: "flex-start",
      //           alignItems: "center",
      //         }}
      //       >
      //         <div
      //           onClick={() =>
      //             setImageUploadHover(imageUploadHover ? false : true)
      //           }
      //           style={{
      //             color: BlackAndWhite.primary,
      //             width: 300,
      //             borderStyle: "solid",
      //             height: 40,
      //             borderRadius: 8,
      //             display: "flex",
      //             flexDirection: "row",
      //             justifyContent: "center",
      //             alignItems: "center",
      //           }}
      //         >
      //           TAKE PHOTO NOW
      //         </div>
      //         <form
      //           style={{
      //             color: BlackAndWhite.primary,
      //             width: 300,
      //             borderStyle: previewUrl ? "none" : "solid",
      //             height: imageUploadHover ? 300 : 0,
      //             borderRadius: 8,
      //             display: "flex",
      //             flexDirection: "column",
      //             justifyContent: "center",
      //             alignItems: "center",
      //             backgroundColor: BlackAndWhite.secondary,
      //             overflow: "hidden",
      //             transition: "all 0.5s",
      //             borderWidth: imageUploadHover ? 1.5 : 0,
      //             marginTop: 10,
      //           }}
      //         >
      //           <label
      //             onClick={() => pickedImageHandler()}
      //             style={{
      //               // borderStyle: "solid",

      //               borderRadius: 8,

      //               flexDirection: "column",
      //               justifyContent: "center",
      //               alignItems: "center",
      //               textAlign: "center",
      //               // marginBottom: 10,
      //               width: "100%",
      //               height: "100%",
      //               display: "flex",
      //               overflow: "hidden",
      //             }}
      //           >
      //             {previewUrl ? (
      //               <img
      //                 alt="Preview"
      //                 src={previewUrl ? previewUrl : null}
      //                 style={{ height: "100%", width: "100%" }}
      //               />
      //             ) : (
      //               <div style={{ width: "80%" }}>
      //                 Click here to open camera and take photo...
      //               </div>
      //             )}
      //           </label>
      //           <input
      //             style={{
      //               display: "none",
      //               borderStyle: "solid",

      //               borderRadius: 8,

      //               flexDirection: "column",
      //               justifyContent: "center",
      //               alignItems: "center",
      //             }}
      //             type="file"
      //             accept=".jpg,.png,.jpeg"
      //             ref={filepickerRef}
      //             onChange={(e) => handleCapture(e.target)}
      //             capture={"environment"}
      //           ></input>
      //         </form>
      //       </div>
      //       <div
      //         style={{
      //           width: "100%",
      //           display: "flex",
      //           justifyContent: "space-evenly",
      //           marginBottom: 20,
      //         }}
      //       >
      //         <BaseButtons
      //           label={"Back"}
      //           onClick={() => {
      //             setValidationSteps(2);
      //             setPreviewUrl(null);
      //           }}
      //         />
      //         <BaseButtons
      //           label={"Next"}
      //           onClick={() => setValidationSteps(4)}
      //           disable={previewUrl ? false : true}
      //         />
      //       </div>
      //     </div>
      //   );
      case 3:
        return (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                width: "100%",
                position: "absolute",
                top: 0,
                height: 60,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 20,
                fontWeight: "bold",
              }}
            >
              ADDRESS VALIDATION
            </div>
            <div style={{ width: 280, textAlign: "center", marginTop: 60 }}>
              "Merry-Jane says..."
            </div>
            <div style={{ width: 280, textAlign: "center", marginTop: 10 }}>
              In order for us to issue a valid subscription, we need a formal
              address.
            </div>
            <div style={{ width: 280, textAlign: "center", marginTop: 10 }}>
              Please complete or amend the fields below as required.
            </div>
            <div
              style={{
                width: "100%",

                height: "100%",
                overflow: "scroll",
              }}
            >
              <TextInputField
                labelTitle={"Unit number"}
                invert={true}
                // size={100}
                onChange={(e) => setUnit(e.target.value)}
                placeholder={"e.g 123"}
              />
              <TextInputField
                labelTitle={"Street address"}
                invert={true}
                // size={100}
                placeholder={"e.g Hogart street"}
                onChange={(e) => setStreet(e.target.value)}
                value={street}
              />
              <TextInputField
                labelTitle={"Suburb"}
                invert={true}
                // size={130}
                // size={"100%"}
                placeholder={"e.g Malmesbury"}
                onChange={(e) => setSuburb(e.target.value)}
                value={suburb}
              />
              <TextInputField
                labelTitle={"City"}
                invert={true}
                // size={130}
                // size={"100%"}
                value={city}
                placeholder={"e.g Cape town"}
                onChange={(e) => setCity(e.target.value)}
              />
              <ProvinceSelector
                open={provinceOpen}
                setProvince={setProvince}
                province={province}
                onProvinceClick={() => setProvinceOpen(true)}
                setProvinceOpen={setProvinceOpen}
              />
              <StoreSelector
                open={storeOpen}
                setStore={setStore}
                store={store}
                setStoreOPen={setStoreOpen}
                onStoreClick={() => setStoreOpen(true)}
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-evenly",
                  marginBottom: 20,
                }}
              >
                <BaseButtons
                  label={"Back"}
                  onClick={() => setValidationSteps(2)}
                />
                <BaseButtons
                  label={"Next"}
                  onClick={() => setValidationSteps(4)}
                  disable={
                    unit.length >= 1 &&
                    street.length >= 2 &&
                    suburb.length >= 2 &&
                    city.length >= 2 &&
                    province.length >= 2 &&
                    store.length >= 2
                      ? false
                      : true
                  }
                />
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                width: "100%",
                position: "absolute",
                top: 0,
                height: 60,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 20,
                fontWeight: "bold",
              }}
            >
              Confirm Your Information
            </div>

            <div
              style={{
                width: "100%",
                marginTop: 60,
                height: "100%",
                overflow: "scroll",
              }}
            >
              <TextInputField
                labelTitle={"First name:"}
                invert={true}
                // size={100}
                placeholder={"e.g Timothy"}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <TextInputField
                labelTitle={"Last name:"}
                invert={true}
                // size={100}
                placeholder={"e.g. Lang"}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              <TextInputField
                labelTitle={"Cellnumber"}
                invert={true}
                // size={100}
                value={cellNumber}
                placeholder={"e.g 0797278182"}
                onChange={(e) => setCellNumber(e.target.value)}
              />
              <TextInputField
                labelTitle={"ID Number/Passport"}
                invert={true}
                value={idDoc}
                // size={100}
                placeholder={"e.g 13 digits "}
                onChange={(e) => setIdDoc(e.target.value)}
              />

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-evenly",
                  marginBottom: 20,
                  marginTop: 20,
                }}
              >
                <BaseButtons
                  label={"Back"}
                  onClick={() => setValidationSteps(3)}
                />
                <BaseButtons
                  label={"Submit"}
                  onClick={() => CompleteValidation()}
                />
              </div>
            </div>
          </div>
        );

      default:
        break;
    }
  };

  useEffect(() => {
    if (toastPop) {
      setTimeout(() => {
        setToastPop(false);
      }, 5000);
    }
  }, [toastPop]);
  useEffect(() => {
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  }, [file]);
  useEffect(() => {
    if (auth) {
      setEmail(auth.customerUserData.email);
      setNewEmail(auth.customerUserData.email);
      setFirstName(auth.customerUserData.firstName);
      setLastName(auth.customerUserData.lastName);
      setCellNumber(auth.customerUserData.cellNumber);
      setIdDoc(auth.customerUserData.idDoc);
      setStreet(auth.customerUserData.street);
      setSuburb(auth.customerUserData.suburb);
      setCity(auth.customerUserData.town);
    }
  }, []);
  return (
    <div
      style={{
        height: "100%",
        width: display ? "100%" : 0,
        backgroundColor: BlackAndWhite.secondary,
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        top: 0,
        overflow: "hidden",
        zIndex: 900000000000,
        transition: "width 0.4s",
      }}
    >
      {/* {validationSteps === 1 ? (
        <div
          style={{
            position: "absolute",
            top: 10,
            left: 10,
            zIndex: 1000000000,
          }}
        >
          <CloseButton label={"X"} onClick={onCloseClick} />
        </div>
      ) : null} */}

      {DisplayValidationStep()}
      <LoadingPopUp newWidth={"100%"} display={loading} />
      <ToastNotification
        display={toastPop}
        message={message ? message.toUpperCase() : null}
      />
    </div>
  );
};

export default ValidateProfile;
