import React, { useState } from "react";

import { ItemDisplayCard } from "./components";
import { Bar, Pie, Line, Stacked, Doughnut } from "react-chartjs-2";
import { BlackAndWhite } from "../../../../../themes/Colors";

const StatCardType = ({
  title,
  title1,
  type,
  icon,
  iconHeight,
  iconWidth,
  item1Title,
  item1Value,
  item2Title,
  item2Value,
  item3Title,
  item3Value,
  item4Title,
  item4Value,
  item5Title,
  item5Value,
  item6Title,
  item6Value,
  onClick,
  marginTop,
  productStats,
}) => {
  const labels = [
    `FSC Flower`,
    `FSC Pre-roll `,
    `Infused Beverages `,
    `Infused Edibles `,
    `Vape & Refill `,
  ];
  const data = {
    labels: labels,
    datasets: [
      {
        // label: labels, // Setting up the label for the dataset
        // labels: labels,
        backgroundColor: [
          "#48A14D",
          "#008080",
          "#B33F40",
          "#049BE5",
          "#9E3AC3",
        ], // Setting up the background color for the dataset
        borderColor: ["#48A14D", "#008080", "#B33F40", "#049BE5", "#9E3AC3"], // Setting up the border color for the dataset
        data: [
          productStats ? productStats.flower : 0,
          productStats ? productStats.preRoll : 0,
          productStats ? productStats.beverages : 0,
          productStats ? productStats.edibles : 0,
          productStats ? productStats.vapeRefill : 0,
        ],
        // Setting up the data for the dataset
      },
    ],
  };
  const [hover, setHover] = useState(false);
  const [hover2, setHover2] = useState(false);
  const GetHeight = () => {
    if (type === "registration") {
      return 100;
    } else if (type === "products") {
      return 320;
    } else if (type === "sales") {
      return 400;
    }
  };
  return (
    <div
      style={{
        width: "100%",
        height: GetHeight(),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: marginTop ? marginTop : 20,
      }}
    >
      {type === "registration" ? (
        <div
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
          onClick={onClick}
          style={{
            height: "90%",
            width: "90%",
            backgroundColor: BlackAndWhite.secondary,
            borderRadius: 8,
            display: "flex",
            position: "relative",
            overflow: "hidden",
            WebkitBoxShadow: hover
              ? "0px 0px 12px 2px #00000083"
              : "0px 0px 15px 2px #00000050 ",
            // borderWidth: hover ? 4 : 0,
            transition: "all 0.3s",
            // borderStyle: "solid",
            cursor: "pointer",
            // borderColor: "#00000090",
            borderColor: "#00000090",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* card title */}
          <div
            style={{
              // position: "absolute",

              fontWeight: "bold",
              fontSize: 20,
              height: "100%",

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 300,
              textAlign: "left",
              marginRight: 10,
              color: "#00000090",
            }}
          >
            <img
              alt="Icon"
              src={icon}
              style={{
                height: iconHeight,
                width: iconWidth,
                marginLeft: 20,
                opacity: 0.6,
              }}
            />
            <div style={{ width: "100%", marginLeft: 20, fontSize: 18 }}>
              {title}
            </div>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",

              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <ItemDisplayCard
              title={item1Title}
              value={item1Value}
              width={100}
            />
            <ItemDisplayCard
              title={item2Title}
              value={item2Value}
              width={100}
            />
            <ItemDisplayCard
              title={item3Title}
              value={item3Value}
              width={100}
            />
            {item4Title ? (
              <ItemDisplayCard
                title={item4Title}
                value={item4Value}
                width={100}
              />
            ) : null}
            {item4Title ? <div style={{ width: 15 }} /> : null}
          </div>
        </div>
      ) : null}
      {type === "products" ? (
        <div
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
          onClick={onClick}
          style={{
            height: "90%",
            width: "90%",
            backgroundColor: BlackAndWhite.secondary,
            borderRadius: 20,
            display: "flex",
            position: "relative",
            overflow: "hidden",
            WebkitBoxShadow: hover
              ? "0px 0px 12px 2px #00000083"
              : "0px 0px 10px 1px #00000033",
            // borderWidth: hover ? 4 : 0,
            transition: "all 0.3s",
            // borderStyle: "solid",
            cursor: "pointer",
            borderColor: "#00000090",
          }}
        >
          {/* card title */}
          <div
            style={{
              // position: "absolute",

              fontWeight: "bold",
              fontSize: 20,

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 350,
              textAlign: "left",
              marginRight: 10,
              position: "absolute",
              top: 20,
            }}
          >
            <img
              alt="Icon"
              src={icon}
              style={{
                height: iconHeight,
                width: iconWidth,
                marginLeft: 20,
                opacity: 0.6,
              }}
            />
            <div style={{ width: "100%", marginLeft: 20, color: "#00000090" }}>
              {title}
            </div>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",

              alignItems: "center",
              justifyContent: "space-evenly",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                flex: 0.6,
                height: "100%",

                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  marginTop: 70,
                  justifyContent: "space-evenly",
                }}
              >
                <ItemDisplayCard
                  title={item1Title}
                  value={item1Value}
                  height={100}
                />
                <ItemDisplayCard
                  title={item2Title}
                  value={item2Value}
                  height={100}
                />
                <ItemDisplayCard
                  title={item3Title}
                  value={item3Value}
                  height={100}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  marginTop: 0,
                  justifyContent: "space-evenly",
                }}
              >
                <ItemDisplayCard
                  title={item4Title}
                  value={item4Value}
                  height={100}
                />
                <ItemDisplayCard
                  title={item5Title}
                  value={item5Value}
                  height={100}
                />
                <ItemDisplayCard
                  title={item6Title}
                  value={item6Value}
                  height={100}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.4,
                height: "100%",
                alignItems: "center",
                display: "flex",
              }}
            >
              <div
                style={{
                  height: "90%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pie
                  width={"100%"}
                  height={"100%"}
                  //   options={}
                  data={data}
                  // style={{ height: 300, width: "20%" }}
                />
                {/* <Pie
                  width={"100%"}
                  height={"100%"}
                  //   options={}
                  data={data}
                  // style={{ height: 300, width: "20%" }}
                /> */}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {type === "sales" ? (
        <>
          <div
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
            style={{
              flex: 0.6,
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              onClick={onClick}
              style={{
                height: "90%",
                width: "90%",
                backgroundColor: BlackAndWhite.secondary,
                borderRadius: 20,
                display: "flex",
                position: "relative",
                overflow: "hidden",
                WebkitBoxShadow: hover
                  ? "0px 0px 12px 2px #00000083"
                  : "0px 0px 10px 1px #00000033",
                // borderWidth: hover ? 4 : 0,
                transition: "all 0.3s",
                // borderStyle: "solid",
                cursor: "pointer",
                borderColor: "#00000090",
              }}
            >
              {/* card title */}
              <div
                style={{
                  // position: "absolute",

                  fontWeight: "bold",
                  fontSize: 20,

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 350,
                  textAlign: "left",
                  marginRight: 10,
                  position: "absolute",
                  top: 20,
                }}
              >
                <img
                  alt="Icon"
                  src={icon}
                  style={{
                    height: iconHeight,
                    width: iconWidth,
                    marginLeft: 20,
                    opacity: 0.6,
                  }}
                />
                <div
                  style={{ width: "100%", marginLeft: 20, color: "#00000090" }}
                >
                  {title}
                </div>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",

                  alignItems: "center",
                  justifyContent: "center",
                  overflow: "hidden",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-evenly",
                    marginTop: 60,
                  }}
                >
                  <ItemDisplayCard
                    title={item1Title}
                    value={item1Value}
                    height={120}
                  />
                  <ItemDisplayCard
                    title={item2Title}
                    value={item2Value}
                    height={120}
                  />
                  <ItemDisplayCard
                    title={item3Title}
                    value={item3Value}
                    height={120}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-evenly",
                  }}
                >
                  <ItemDisplayCard
                    title={item4Title}
                    value={item4Value}
                    height={120}
                  />
                  <ItemDisplayCard
                    title={item5Title}
                    value={item5Value}
                    height={120}
                  />
                  <ItemDisplayCard
                    title={item6Title}
                    value={item6Value}
                    height={120}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            onMouseOver={() => setHover2(true)}
            onMouseOut={() => setHover2(false)}
            style={{
              flex: 0.4,
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              onClick={onClick}
              style={{
                height: "90%",
                width: "90%",
                backgroundColor: BlackAndWhite.secondary,
                borderRadius: 20,
                display: "flex",
                position: "relative",
                overflow: "hidden",
                WebkitBoxShadow: hover2
                  ? "0px 0px 12px 2px #00000083"
                  : "0px 0px 10px 1px #00000033",
                // borderWidth: hover ? 4 : 0,
                transition: "all 0.3s",
                // borderStyle: "solid",
                cursor: "pointer",
                borderColor: "#00000090",
                marginRight: 20,
              }}
            >
              {/* card title */}
              <div
                style={{
                  // position: "absolute",

                  fontWeight: "bold",
                  fontSize: 20,

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 350,
                  textAlign: "left",
                  marginRight: 10,
                  position: "absolute",
                  top: 20,
                }}
              >
                <img
                  alt="Icon"
                  src={icon}
                  style={{
                    height: iconHeight,
                    width: iconWidth,
                    marginLeft: 20,
                    opacity: 0.6,
                  }}
                />
                <div
                  style={{ width: "100%", marginLeft: 20, color: "#00000090" }}
                >
                  {title1}
                </div>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",

                  alignItems: "center",
                  justifyContent: "center",
                  overflow: "hidden",
                  flexDirection: "column",
                }}
              >
                <div style={{ height: "80%", width: 280, marginTop: 40 }}>
                  <Pie
                    width={"100%"}
                    height={"90%"}
                    //   options={}
                    data={data}
                    // style={{ height: 300, width: "20%" }}
                  />
                </div>

                {/* <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-evenly",
                    marginTop: 60,
                  }}
                >
                  <ItemDisplayCard
                    title={"GAUTENG"}
                    value={item1Value}
                    height={80}
                  />
                  <ItemDisplayCard
                    title={"WESTERN CAPE"}
                    value={item1Value}
                    height={80}
                  />
                  <ItemDisplayCard
                    title={"FREE STATE"}
                    value={item1Value}
                    height={80}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-evenly",
                  }}
                >
                  <ItemDisplayCard
                    title={"EASTERN CAPE"}
                    value={item1Value}
                    height={80}
                  />
                  <ItemDisplayCard
                    title={"NORTHERN CAPE"}
                    value={item1Value}
                    height={80}
                  />
                  <ItemDisplayCard
                    title={"NORTH WEST"}
                    value={item1Value}
                    height={80}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-evenly",
                  }}
                >
                  <ItemDisplayCard
                    title={"KWA-ZULU NATAL"}
                    value={item1Value}
                    height={80}
                  />
                  <ItemDisplayCard
                    title={"LIMPOPO"}
                    value={item1Value}
                    height={80}
                  />
                  <ItemDisplayCard
                    title={"MPUMALANGA"}
                    value={item1Value}
                    height={80}
                  />
                </div> */}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default StatCardType;
