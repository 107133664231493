import React from "react";
import { BlackAndWhite } from "../../../../themes/Colors";

const StaffDisplay = ({ employeesArray }) => {
  return (
    <div
      style={{
        flex: 1,
        height: "98%",
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
        overflowY: "scroll",
      }}
    >
      <div
        style={{
          marginTop: 20,
          fontSize: 20,
          fontWeight: "bold",
          marginLeft: 30,
          marginBottom: 20,
        }}
      >
        STORE INFORMATION
      </div>
      {/* this is the store owner and support options panel */}
      <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
        <div
          style={{
            minHeight: 200,
            minWidth: 450,
            width: 450,
            borderRadius: 8,
            WebkitBoxShadow: "0px 0px 7px 2px #00000030",
            marginTop: 20,
            marginLeft: 30,
            paddingTop: 0.1,
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: 18,
              marginTop: 10,
              marginLeft: 10,
            }}
          >
            STORE OWNER
          </div>
          {employeesArray
            ? employeesArray.map((staff, index) => {
                return staff.type === "Owner" ? (
                  <div key={index} style={{ paddingBottom: 10 }}>
                    <div style={{ marginLeft: 10 }}>
                      <div
                        style={{
                          fontSize: 10,
                          fontWeight: "bold",
                          opacity: 0.5,

                          marginTop: 10,
                          marginBottom: 3,
                        }}
                      >
                        NAME
                      </div>
                      <div>{staff.firstName + " " + staff.lastName}</div>
                    </div>
                    <div style={{ marginLeft: 10 }}>
                      <div
                        style={{
                          fontSize: 10,
                          fontWeight: "bold",
                          opacity: 0.5,

                          marginTop: 10,
                          marginBottom: 3,
                        }}
                      >
                        ACCOUNT NUMBER
                      </div>
                      <div>{staff.accountNumber}</div>
                    </div>
                    <div style={{ marginLeft: 10 }}>
                      <div
                        style={{
                          fontSize: 10,
                          fontWeight: "bold",
                          opacity: 0.5,

                          marginTop: 10,
                          marginBottom: 3,
                        }}
                      >
                        EMAIL
                      </div>
                      <div>{staff.email}</div>
                    </div>
                    <div style={{ display: "flex", width: "100%" }}>
                      <div style={{ marginLeft: 10 }}>
                        <div
                          style={{
                            fontSize: 10,
                            fontWeight: "bold",
                            opacity: 0.5,

                            marginTop: 10,
                            marginBottom: 3,
                          }}
                        >
                          CELL NUMBER
                        </div>
                        <div>{staff.cellNumber}</div>
                      </div>
                      <div style={{ flex: 1 }} />
                      <div style={{ marginRight: 20 }}>
                        <div
                          style={{
                            fontSize: 10,
                            fontWeight: "bold",
                            opacity: 0.5,

                            marginTop: 10,
                            marginBottom: 3,
                          }}
                        >
                          PIN
                        </div>
                        <div>{staff.pin}</div>
                      </div>
                    </div>
                  </div>
                ) : null;
              })
            : "loading"}
        </div>
      </div>
      <div
        style={{
          marginTop: 40,
          fontSize: 20,
          fontWeight: "bold",
          marginLeft: 30,
        }}
      >
        STAFF
      </div>
      {/* this will be a list of staff and there information */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          justifyContent: "center",
          marginTop: 20,
        }}
      >
        {employeesArray
          ? employeesArray.map((staff, index) => {
              return staff.type === "Admin" ? null : (
                <div
                  key={index}
                  style={{
                    width: 450,

                    height: 235,
                    margin: 20,
                    borderRadius: 8,
                    WebkitBoxShadow: "0px 0px 7px 2px #00000030",
                    backgroundColor: BlackAndWhite.secondary,
                  }}
                >
                  <div key={index} style={{ paddingBottom: 10 }}>
                    <div style={{ marginLeft: 10 }}>
                      <div
                        style={{
                          fontSize: 10,
                          fontWeight: "bold",
                          opacity: 0.5,

                          marginTop: 10,
                          marginBottom: 3,
                        }}
                      >
                        NAME
                      </div>
                      <div>{staff.firstName + " " + staff.lastName}</div>
                    </div>
                    <div style={{ marginLeft: 10 }}>
                      <div
                        style={{
                          fontSize: 10,
                          fontWeight: "bold",
                          opacity: 0.5,

                          marginTop: 10,
                          marginBottom: 3,
                        }}
                      >
                        TYPE
                      </div>
                      <div>{staff.type}</div>
                    </div>
                    <div style={{ marginLeft: 10 }}>
                      <div
                        style={{
                          fontSize: 10,
                          fontWeight: "bold",
                          opacity: 0.5,

                          marginTop: 10,
                          marginBottom: 3,
                        }}
                      >
                        ACCOUNT NUMBER
                      </div>
                      <div>{staff.accountNumber}</div>
                    </div>
                    <div style={{ marginLeft: 10 }}>
                      <div
                        style={{
                          fontSize: 10,
                          fontWeight: "bold",
                          opacity: 0.5,

                          marginTop: 10,
                          marginBottom: 3,
                        }}
                      >
                        EMAIL
                      </div>
                      <div>{staff.email}</div>
                    </div>
                    <div style={{ display: "flex", width: "100%" }}>
                      <div style={{ marginLeft: 10 }}>
                        <div
                          style={{
                            fontSize: 10,
                            fontWeight: "bold",
                            opacity: 0.5,

                            marginTop: 10,
                            marginBottom: 3,
                          }}
                        >
                          CELL NUMBER
                        </div>
                        <div>{staff.cellNumber}</div>
                      </div>
                      <div style={{ flex: 1 }} />
                      <div style={{ marginRight: 20 }}>
                        <div
                          style={{
                            fontSize: 10,
                            fontWeight: "bold",
                            opacity: 0.5,

                            marginTop: 10,
                            marginBottom: 3,
                          }}
                        >
                          PIN
                        </div>
                        <div>{staff.pin}</div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          : "loading"}
      </div>
    </div>
  );
};

export default StaffDisplay;
