import React from "react";
import FieldDisplay from "../field-display/FieldDisplay";
import { BlackAndWhite } from "../../../../themes/Colors";
import { BaseButtons } from "../../../../components/atoms";

const FormFields = ({
  selectedDisplay,
  patientNumber,
  firstName,
  lastName,
  idDoc,
  dateOfBirth,
  cellNumber,
  email,
  gender,
  suburb,
  town,
  profileProgress,
  accountType,
  onTermsClick,
  agreed,
  termsBackgroundColorButton,
}) => {
  return selectedDisplay ? (
    <div style={{ color: BlackAndWhite.secondary }}>
      <div
        style={{
          flex: 0.4,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          //   paddingRight: 10,
        }}
      >
        {/* <div
          style={{
            height: 30,
            width: "90%",
            backgroundColor: BlackAndWhite.secondary,
            borderRadius: 4,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginBottom: 10,
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: `${profileProgress}%`,
              background: "#000000",
              zIndex: 0,
              borderRadius: 4,
            }}
          />
          <div
            style={{
              color: BlackAndWhite.secondary,
              fontSize: 14,
              marginLeft: 10,
              zIndex: 1,
            }}
          >
            {profileProgress} %
          </div>
        </div>

        <div style={{ width: "90%", textAlign: "left", fontSize: 14 }}>
          Profile completion
        </div> */}
      </div>
      {/* <div
        style={{
          height: 240,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "80%", textAlign: "left", marginBottom: 20 }}>
          SCAN AT THE TILL
        </div>
        <img
          alt="Edit"
          src={require("../../../../images/material-icons/qrcodeWhite.png")}
          style={{ height: 200 }}
        />
      </div> */}

      <div
        style={{
          color: "#FFFFFF",
          height: 60,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <FieldDisplay
          field={patientNumber}
          fieldName={"PATIENT NUMBER"}
          cantEdit={true}
        />
      </div>
    </div>
  ) : (
    <div
      style={{
        height: "100%",
        width: "100%",
        overflow: "scroll",
      }}
    >
      <div
        style={{
          flex: 0.4,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          //   paddingRight: 10,
        }}
      >
        {/* <div
          style={{
            height: 30,
            width: "90%",
            backgroundColor: BlackAndWhite.secondary,
            borderRadius: 4,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginBottom: 10,
            position: "relative",
            marginTop: 10,
          }}
        >
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: `${profileProgress}%`,
              background: "#FFFFFF",
              zIndex: 0,
              borderRadius: 4,
            }}
          />
          <div
            style={{
              color: BlackAndWhite.primary,
              fontSize: 14,
              marginLeft: 10,
              zIndex: 1,
              fontWeight: "bold",
            }}
          >
            {profileProgress} %
          </div>
        </div>

        <div
          style={{
            width: "90%",
            textAlign: "left",
            fontSize: 14,
            color: BlackAndWhite.secondary,
            marginBottom: 20,
          }}
        >
          Profile completion
        </div> */}
        <div
          style={{
            color: "#FFFFFF",
            height: 50,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10,
            position: "relative",
          }}
        >
          <FieldDisplay
            field={accountType === "Customer" ? "Member" : accountType}
            fieldName={"ACCOUNT TYPE"}
            cantEdit={true}
          />
          <div style={{ position: "absolute", right: 20, top: 0 }}>
            <BaseButtons
              mini={true}
              label={"TERMS"}
              marginTopFalse={true}
              height={35}
              width={100}
              size={100}
              borderRadius={4}
              invert={true}
              onClick={onTermsClick}
              disable={agreed}
              backgroundColor={termsBackgroundColorButton}
            />
          </div>
        </div>
      </div>
      {/* <div
        style={{
          height: 240,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "80%",
            textAlign: "left",
            marginBottom: 20,
            color: BlackAndWhite.secondary,
          }}
        >
          SCAN AT THE TILL
        </div>
        <img
          alt="Edit"
          src={require("../../../../images/material-icons/qrcodeWhite.png")}
          style={{ height: 200 }}
        />
      </div> */}

      <div
        style={{
          color: "#FFFFFF",
          height: 50,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <FieldDisplay
          field={patientNumber}
          fieldName={"MEMBER NUMBER"}
          cantEdit={true}
        />
      </div>
      <div
        style={{
          color: "#FFFFFF",
          height: 50,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <FieldDisplay
          field={firstName}
          fieldName={"FIRST NAME"}
          cantEdit={true}
        />
      </div>
      <div
        style={{
          color: "#FFFFFF",
          height: 50,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <FieldDisplay
          field={lastName}
          fieldName={"LAST NAME"}
          cantEdit={true}
        />
      </div>
      <div
        style={{
          color: "#FFFFFF",
          height: 50,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <FieldDisplay
          field={idDoc}
          fieldName={"ID NO/ PASSPORT NO"}
          cantEdit={true}
        />
      </div>

      <div
        style={{
          color: "#FFFFFF",
          height: 50,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <FieldDisplay
          field={dateOfBirth}
          fieldName={"DATE OF BIRTH"}
          cantEdit={true}
        />
      </div>
      <div
        style={{
          color: "#FFFFFF",
          height: 50,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <FieldDisplay
          field={cellNumber}
          fieldName={"CELL NUMBER"}
          cantEdit={true}
        />
      </div>
      <div
        style={{
          color: "#FFFFFF",
          height: 50,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <FieldDisplay field={email} fieldName={"EMAIL"} cantEdit={true} />
      </div>
    </div>
  );
};

export default FormFields;
