import { useEffect, useState } from "react";
import React from "react";
import { useSpring, animated } from "react-spring";
import { leaves } from "../../../images/weed leafs/leafArray";

const FallingLeaf = ({
  fall,
  postion,
  delay,
  friction,
  tension,
  from,
  to,
  changeSize,
  timer,
  menu,
}) => {
  const styles = useSpring({
    loop: true,
    from: { y: from },
    to: { y: to },
    delay: delay,
    config: {
      tension: tension,
      friction: friction,
    },
  });
  const [randomInt, setRandomInt] = useState(randomIntFromInterval(0, 4));
  function randomIntFromInterval(min, max) {
    // min and max includeds
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  useEffect(() => {
    setTimeout(() => {
      setRandomInt(randomIntFromInterval(0, 4));
    }, timer);
  }, [randomInt]);

  return (
    <animated.div
      style={{
        position: "absolute",
        left: postion,
        zIndex: -100,
        ...styles,
      }}
    >
      <img
        alt="leaf"
        src={leaves[randomInt].image}
        style={{
          height: changeSize ? changeSize : 80,
          opacity: menu ? 0.7 : 0.3,
          // boxShadow: menu ? "2px 2px 10px 10px #FFFFFF" : null,
        }}
      />
    </animated.div>
  );
};

export default FallingLeaf;
