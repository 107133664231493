import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../../../../../themes/Colors";
import {
  BaseButtons,
  CheckInput,
  TextInputField,
} from "../../../../../../../../components/atoms";

const IngredientDisplayItem = ({
  ingredient,
  NameAssemble,
  index,
  ingredientList,
  setProductMakeUpArray,
  removeFromIngredients,
  UpDateInfoHandler,
}) => {
  const [fullUnit, setFullUnit] = useState(false);
  const [qtyAmt, setQtyAmt] = useState("");
  const [refresh, setRefresh] = useState(false);

  const EditQty = ({ idIndex }) => {
    const array = [...ingredientList];

    //find index of item to be replaced
    const targetIndex = ingredientList.findIndex(
      (f, index) => index === idIndex
    );

    //replace the object with a new one.
    array[targetIndex].qtyCard.qty = qtyAmt;

    setProductMakeUpArray(array);
    UpDateInfoHandler(Number(ingredient.product.baseCostPrice));
  };

  useEffect(() => {
    setQtyAmt(ingredient.qtyCard.qty);
    setFullUnit(ingredient.qtyCard.fullUnit);
  }, [ingredientList]);
  return (
    <div
      style={{
        width: "95%",
        minHeight: 80,
        borderRadius: 8,
        backgroundColor: BlackAndWhite.secondary,
        WebkitBoxShadow: "0px 0px 7px 2px #00000050",
        marginBottom: 20,
        // marginTop: index === 0 ? 20 : 0,
        display: "flex",
        alignItems: "center",
      }}
    >
      <div>
        {" "}
        <img
          alt="icon"
          style={{ height: 60, marginLeft: 10 }}
          // src={ingredient ? ingredient.product.imageUrl : null}
        />
      </div>
      <div style={{ fontSize: 12, width: 200 }}>
        {ingredient ? NameAssemble({ product: ingredient.product }) : null}
      </div>
      <div
        style={{
          flex: 0.5,
          display: "flex",
          flexDirection: "column",
          height: 57,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontSize: 10,
            fontWeight: "bold",
            opacity: 0.7,
            marginBottom: 4,
          }}
        >
          MEASURE
        </div>
        <div
          style={{
            fontSize: 12,
            fontWeight: "bold",
            opacity: 0.7,
            marginBottom: 4,
          }}
        >
          {ingredient.product.size +
            " " +
            ingredient.product.quantityDenomiation}
        </div>
      </div>

      <div style={{ flex: 1, display: "flex" }}>
        <div style={{ flex: 1, marginBottom: 10 }}>
          <TextInputField
            labelTitle={`QTY`}
            mini={true}
            invert={true}
            height={10}
            size={50}
            type={"number"}
            placeholder={"0.00"}
            value={qtyAmt}
            onChange={(e) => {
              setQtyAmt(e.target.value);
            }}
          />
        </div>
        <div
          style={{
            width: 120,
            marginRight: 10,
            marginLeft: 20,
            marginTop: 5,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              marginBottom: 3,
              marginTop: 10,
              fontWeight: "bold",
              fontSize: 12,
              opacity: 0.5,
            }}
          >
            TRACKED
          </div>
          <div style={{ fontWeight: 14 }}>{ingredient.product.trackStock}</div>
          {/* <CheckInput
            question={"FULL UNIT"}
            invert={true}
            onClick={() => setFullUnit(fullUnit ? false : true)}
            check={fullUnit}
          /> */}
        </div>

        <div
          style={{
            width: 140,
            marginRight: 10,
            marginTop: 5,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              marginBottom: 3,
              marginTop: 10,
              fontWeight: "bold",
              fontSize: 12,
              opacity: 0.5,
            }}
          >
            PRICE
          </div>
          <div style={{ fontWeight: 14 }}>
            R{" "}
            {(Number(ingredient.product.costPrice) * Number(qtyAmt)).toFixed(2)}
          </div>
          {/* <CheckInput
            question={"FULL UNIT"}
            invert={true}
            onClick={() => setFullUnit(fullUnit ? false : true)}
            check={fullUnit}
          /> */}
        </div>
      </div>
      {Number(qtyAmt) === Number(ingredient.qtyCard.qty) ? null : (
        <BaseButtons
          label={"SAVE"}
          fontSize={18}
          size={75}
          height={35}
          mini={true}
          marginTopFalse={true}
          borderRadius={4}
          marginRight={10}
          onClick={() => EditQty({ idIndex: index })}
        />
      )}

      <BaseButtons
        label={"X"}
        fontSize={18}
        size={35}
        height={35}
        mini={true}
        marginTopFalse={true}
        borderRadius={4}
        marginRight={20}
        onClick={() => removeFromIngredients(index)}
        // onClick={onAddClick}
      />
    </div>
  );
};

export default IngredientDisplayItem;
