import React from "react";
import { BlackAndWhite } from "../../../themes/Colors";

const TextAreaInput = ({
  labelTitle,
  onChange,
  width,
  size,
  height,
  color,
  value,
  mini,
  readOnly,
  borderRadius,
}) => {
  return (
    <form
      style={{
        width: width,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: mini ? 10 : 20,
      }}
    >
      <label
        style={{
          width: size ? size : 240,
          fontWeight: mini ? null : "bold",
          fontSize: mini ? 14 : 16,
          color: color ? color : BlackAndWhite.secondary,
          marginLeft: 2,
          // fontFamily: "Montserrat",
        }}
      >
        {labelTitle}
      </label>
      <textarea
        style={{
          height: mini ? (height ? height : 100) : height ? height : 200,
          width: size ? size : 220,
          marginTop: mini ? 5 : 10,
          borderRadius: mini ? (borderRadius ? borderRadius : 0) : 10,
          padding: 10,
          paddingLeft: 10,
          fontSize: 12,
          borderWidth: mini ? 0.5 : null,
          // fontFamily: "Montserrat",
        }}
        type="text"
        name={labelTitle}
        onChange={onChange}
        value={value}
        readOnly={readOnly}
      />
    </form>
  );
};

export default TextAreaInput;
