import React from "react";
import { BlackAndWhite } from "../../../../../../../themes/Colors";

const ItemDisplayCard = ({ value, title, height, width, date }) => {
  return (
    <div
      style={{
        height: height ? height : "100%",
        width: width ? width : 120,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: BlackAndWhite.primary,
      }}
    >
      <div
        style={{
          fontSize: date
            ? 12
            : value === "loading"
            ? 12
            : value
            ? value.length >= 10
              ? 16
              : 16
            : 16,
          fontWeight: "bold",
          marginBottom: 5,
          textAlign: "center",
          width: value ? (value.length > 10 ? 200 : null) : null,
        }}
      >
        {value}
      </div>
      <div
        style={{
          fontSize: 13,
          fontWeight: "bold",
          marginBottom: 5,
          textAlign: "center",
          width: 100,
          color: "#00000090",
        }}
      >
        {title}
      </div>
    </div>
  );
};

export default ItemDisplayCard;
