import React from "react";
import { BlackAndWhite } from "../../../themes/Colors";

const TextInputField = ({
  labelTitle,
  onChange,
  width,
  size,
  invert,
  rule,
  reqiredCheck,
  showPassword,
  autoFocus,
  value,
  placeholder,
  onClick,
  mini,
  height,
  marginTop,
  enter,
  type,
  min,
  readOnly,
  labelWidth,
  fontsize,
  onKeyUp,
  tabIndex,
  maxLength,
  max,
  ref,
  tenderedAmountBlock,
  isDisplay,
  borderRadius,
}) => {
  const OnKeyDown = (e) => {
    if (e.key === "Tab") {
      enter();
    }
  };
  return (
    <form
      onKeyDown={enter ? (e) => OnKeyDown(e) : null}
      onSubmit={
        enter
          ? (e) => {
              e.preventDefault();
              enter();
            }
          : (e) => e.preventDefault()
      }
      style={{
        width: width,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginTop: mini ? (marginTop ? marginTop : 10) : 20,
      }}
    >
      <label
        style={{
          width: labelWidth ? labelWidth : size ? size : 240,
          fontWeight: "500",
          fontSize: mini ? 14 : 16,
          color: invert ? BlackAndWhite.primary : BlackAndWhite.secondary,
          fontFamily: "Montserrat",
          position: "relative",
        }}
      >
        {labelTitle ? labelTitle : null}
        {reqiredCheck ? (
          <div
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              color: rule ? "#5EEB5B" : "#FFFFFF",
            }}
          >
            {rule ? "complete" : "required"}
          </div>
        ) : null}
      </label>

      <input
        ref={ref}
        tabIndex={tabIndex}
        onKeyUp={onKeyUp}
        autoFocus={autoFocus}
        maxLength={maxLength}
        max={max}
        min={min}
        style={{
          height: height ? height : 30,
          width: size ? size : 220,
          marginTop: mini ? 5 : 10,
          borderRadius: borderRadius
            ? borderRadius
            : mini
            ? tenderedAmountBlock
              ? 8
              : null
            : 50,
          paddingRight: 10,
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: isDisplay ? 10 : 20,
          fontSize: fontsize ? fontsize : mini ? 12 : 18,
          fontFamily: "Montserrat",
          borderWidth: tenderedAmountBlock ? 2 : 0.2,
          borderColor: "#000000",
        }}
        type={type ? type : showPassword ? "password" : "text"}
        name={labelTitle}
        onChange={onChange}
        value={value}
        placeholder={placeholder ? placeholder : "type here"}
        readOnly={readOnly ? true : false}
      />
    </form>
  );
};

export default TextInputField;
