import React, { useState, useEffect } from "react";
import { BaseButtons } from "../../../../../../components/atoms";
import { BlackAndWhite } from "../../../../../../themes/Colors";

// get all categories
//get all sub categories

const FilterDropDown = ({
  setLoading,
  selectedCategory,
  setSelectedCategory,
  selectedSubCategory,
  setSelectedSubCategory,
  filterOption,
  setFilterOption,
}) => {
  const [filterSubOption, setFilterSubOption] = useState("NONE");

  const [categoryArray, setCategoryArray] = useState(null);
  const [subCategoryArray, setSubCategoryArray] = useState(null);

  const [open, setOpen] = useState(false);
  const [openSubCategory, setOpenSubCategory] = useState(false);

  const GetCategories = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/processSale/getCategories",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          //   body: JSON.stringify({
          //     storeName: user.adminUserData.storeName,
          //     userId: user.adminUserData.userId,
          //     pin: pin1,
          //   }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setCategoryArray(responseData.categories);
        setSubCategoryArray(responseData.subcategories);
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    GetCategories();
  }, []);
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {filterOption === "CATEGORY" ? null : (
        <BaseButtons
          label={"GROUP"}
          mini={true}
          marginTopFalse={true}
          borderRadius={8}
          height={35}
          onClick={open ? null : () => setOpenSubCategory(true)}
          size={120}
          marginRight={10}
          fontSize={14}
        />
      )}
      <BaseButtons
        label={"CATEGORY"}
        mini={true}
        marginTopFalse={true}
        borderRadius={8}
        height={35}
        onClick={open ? null : () => setOpen(true)}
        size={120}
        fontSize={14}
      />
      <div
        style={{
          right: 0,
          height: open ? 540 : 0,
          width: 250,
          position: "absolute",
          bottom: -505,
          backgroundColor: BlackAndWhite.secondary,
          overflowX: "hidden",
          WebkitBoxShadow: open
            ? "0px 0px 7px 2px #00000050"
            : "0px 0px 0px 0px #00000050",
          zIndex: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          overflowY: "scroll",
          // scrollbarWidth:'none'
        }}
      >
        <div style={{ minHeight: 40, width: "100%" }}>
          <BaseButtons
            height={40}
            mini={true}
            label={"CATEGORY"}
            marginTopFalse={true}
            width={"100%"}
            size={"100%"}
            onClick={() => {
              setSelectedCategory(null);
              setFilterOption("CATEGORY");
              setOpen(false);
              setSelectedSubCategory(null);
              setFilterSubOption("NONE");
            }}
          />
        </div>
        {categoryArray
          ? categoryArray.map((category, index) => {
              return (
                <div key={index} style={{ width: "100%" }}>
                  <BaseButtons
                    mini={true}
                    label={category.categoryName}
                    marginTopFalse={true}
                    width={"100%"}
                    size={"100%"}
                    onClick={() => {
                      setSelectedCategory(category);
                      setFilterOption(category.categoryName);
                      setOpen(false);
                      setSelectedSubCategory(null);
                      setFilterSubOption("NONE");
                    }}
                  />
                </div>
              );
            })
          : null}
      </div>
      <div
        style={{
          right: 0,
          height: openSubCategory ? 400 : 0,
          width: 250,
          position: "absolute",
          bottom: -365,
          backgroundColor: BlackAndWhite.secondary,
          overflowX: "hidden",
          WebkitBoxShadow: open
            ? "0px 0px 7px 2px #00000050"
            : "0px 0px 0px 0px #00000050",
          zIndex: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          overflowY: openSubCategory ? "scroll" : "hidden",
        }}
      >
        <BaseButtons
          mini={true}
          label={"GROUP"}
          marginTopFalse={true}
          width={"100%"}
          size={"100%"}
          onClick={() => {
            setSelectedSubCategory(null);
            setFilterSubOption("NONE");
            setOpenSubCategory(false);
          }}
        />
        {subCategoryArray
          ? subCategoryArray.map((category, index) => {
              return filterOption !== "CATEGORY" ? (
                category.categoryId === selectedCategory.categoryId ? (
                  <div key={index} style={{ width: "100%" }}>
                    <BaseButtons
                      mini={true}
                      label={category.subCategoryName}
                      marginTopFalse={true}
                      width={"100%"}
                      size={"100%"}
                      onClick={() => {
                        setSelectedSubCategory(category);
                        setFilterSubOption(category.subCategoryName);
                        setOpenSubCategory(false);
                      }}
                    />
                  </div>
                ) : null
              ) : null;
            })
          : null}
      </div>
    </div>
  );
};

export default FilterDropDown;
