import React, { useState, useEffect, useContext } from "react";
import { BlackAndWhite } from "../../themes/Colors";
import useWindowDimensions from "../../components/get-diemensions/UseWindowDiemensions";

import { AdminContext } from "../../context/admin-context";
import { useNavigate } from "react-router-dom";
import {
  BaseButtons,
  TextInputField,
  TextInputFieldSearch,
} from "../../components/atoms";
import InquiryCard from "./components/InquiryCard";
import AdminCustomerTab from "../admin-portal/components/AdminCustomerTab";
import ExcelExport from "../admin-portal/components/ExcelExport";
import MultiSelectorDate from "../admin-portal/components/multi-selector-date/MultiSelectorDate";
import FilterPopUp from "../canna-pos/components/patient-display/FilterPopUp";
import UserInfoPopUp from "./components/user-info-pop-up/UserInfoPopUp";
import { LoadingPopUp, ToastNotification } from "../../components";
import PosSupportDisplay from "./components/pos-support-display/PosSupportDisplay";
import { MessageSupportDisplay } from "./components/pos-support-display/components";
import Filter from "./components/pos-support-display/components/top-tab-controller/filter/Filter";
import FilterMessage from "./components/pos-support-display/components/top-tab-controller/filter-message/FilterMessage";
import { HeadersPatients } from "../canna-pos/components/patient-display/components";
// import { useNavigate } from "react-router-dom";
const testData = [
  { id: 1 },
  { id: 2 },
  { id: 3 },
  { id: 4 },
  { id: 5 },
  { id: 6 },
  { id: 7 },
];

const SupportAccess = () => {
  const { width, height } = useWindowDimensions();

  const [filterOpen, setFliterOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("NONE");
  const [search, setSearch] = useState("");
  const [csvExport, setCsvExport] = useState(null);
  const [patientCsvDownloadPopUp, setPatientCsvDownloadPopUp] = useState(false);
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [dayDate, setDayDate] = useState("");

  const [selectedPatient, setSelectedPatient] = useState(null);
  const [patientProfilePopUp, setPatientProfilePop] = useState(false);

  const [dayRegList, setDayRegList] = useState(null);

  const [tab, setTab] = useState("New Tickets");
  const [shrink, setShrink] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [message, setMessage] = useState(null);
  const [toastPop, setToastPop] = useState(false);

  const [data, setData] = useState(false);

  //add product info

  const [inquiries, setInquiries] = useState(null);
  const [urlPath, setUrlPath] = useState("");
  const admin = useContext(AdminContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [messageSearch, setMessageSearch] = useState("");
  const [messageFilter, setMessageFilter] = useState("");

  const [messageCreatePopUp, setMessageCreatePopUp] = useState(false);
  const [customers, setCustomers] = useState(null);
  const [customersTwo, setCustomersTwo] = useState(null);

  const check = async () => {
    await fetch("https://merry-jane-api.onrender.com/user/adminGetAllCustomers")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setCsvExport(resData);
        if (data) {
          setData(customers);
        }
      });
  };
  const [patientSearch, setPatientSearch] = useState("");

  //message sending pop up and variables

  const OnUserSearch = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/user/searchForUserPos",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            searchParameter: search,
            userId: admin.adminUserData.userId,
          }),
        }
      );

      const responseData = await response.json();

      setLoading(false);
      if (responseData.success) {
        setCustomers(responseData.data);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };

  const downloadListfFetch = async () => {
    setLoading(true);

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/user/getPatintientList",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            date: day + " " + month + " " + dayDate + " " + "2024",
          }),
        }
      );

      const responseData = await response.json();
      if (!responseData.message) {
        setLoading(false);
        setDayRegList(responseData);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };

  useEffect(() => {
    if (!admin.isloggedIn || admin.adminUserData.type === "Customer") {
      navigate("/adminPortal");
    } else if (!admin.isloggedIn || admin.adminUserData.type === "Doc") {
      navigate("/adminPortal");
    } else if (!admin.isloggedIn || admin.adminUserData.type === "Cashier") {
      navigate("/adminPortal");
    } else if (!admin.isloggedIn || admin.adminUserData.type === "Manager") {
      navigate("/adminPortal");
    }
  }, []);
  useEffect(() => {
    fetch("https://merry-jane-api.onrender.com/support/fetchInquiries")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setInquiries(resData);
      });
  }, [loading]);
  useEffect(() => {
    if (day !== "" && month !== "" && dayDate !== "") {
      //here will be the download trigger
      downloadListfFetch();
    }
  }, [day, month, dayDate]);
  useEffect(() => {
    if (!customers) {
      check();
    }

    if (customers && !csvExport) {
      if (data) {
        setData(customers);
      }
    }
  }, [customers, refresh]);
  useEffect(() => {
    if (toastPop) {
      setTimeout(() => {
        setToastPop(false);
      }, 2500);
    }
  }, [toastPop]);
  return (
    <div
      style={{
        height: height,
        width: width,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        backgroundColor: BlackAndWhite.secondary,
        position: "relative",
      }}
    >
      <div
        style={{
          fontSize: 30,
          fontWeight: "700",
          color: BlackAndWhite.secondary,
          // position: "fixed",
          width: "100%",
          backgroundColor: BlackAndWhite.primary,

          height: 40,
          display: "flex",
        }}
      >
        <div
          onClick={() => setShrink(shrink ? false : true)}
          style={{
            marginLeft: shrink ? 15 : 5,
            backgroundColor: shrink ? "#FFFFFFcc" : BlackAndWhite.secondary,
            height: shrink ? 95 : 135,
            width: shrink ? 95 : 202.5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: shrink ? 200 : 20,
            // position: "absolute",
            top: shrink ? 10 : 5,
            WebkitBoxShadow: "0px 0px 10px 1px #00000033",
            transition: "all 0.3s",
            // borderStyle: "solid",
            // borderColor: BlackAndWhite.primary,
            // borderWidth: 5,
            marginTop: 5,
          }}
        >
          <div
            onClick={() => setShrink(shrink ? false : true)}
            style={{
              // marginLeft: shrink ? 5 : 5,
              backgroundColor: shrink
                ? BlackAndWhite.primary
                : BlackAndWhite.secondary,
              height: shrink ? 95 : 135,
              width: shrink ? 95 : 202.5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: shrink ? 200 : 8,
              // position: "absolute",
              // top: shrink ? 10 : 5,
              WebkitBoxShadow: "0px 0px 10px 1px #00000033",
              transition: "all 0.3s",
              zIndex: 10000001,
              // borderStyle: "solid",
              // borderColor: BlackAndWhite.primary,
              // borderWidth: 5,
            }}
          >
            <img
              alt="load"
              src={
                shrink
                  ? require("../../images/newLogos/2. Merry-Jane Logo White for Dark Background.png")
                  : require("../../images/newLogos/1. MJ Logo Black with White Surround.png")
              }
              style={{
                height: shrink ? 65 : 120,
                transition: "all 0.3s",
                zIndex: 10000001,
              }}
            />
          </div>
        </div>
        <div
          style={{
            flex: 1,
            paddingLeft: 20,

            fontSize: 16,
            display: "flex",
            alignItems: "center",
          }}
        >
          SUPPORT
        </div>

        <div style={{ display: "flex" }}></div>
      </div>
      <div style={{ display: "flex", flex: 1, width: "100%" }}>
        {width > 899 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              minWidth: shrink ? 80 : 210,
              maxWidth: shrink ? 80 : 210,
              backgroundColor: "#FFFFFF",
              height: "100%",
              // marginTop: 10,
              alignItems: "center",
              transition: "all 0.3s",
              WebkitBoxShadow: "0px 0px 10px 1px #00000033",
              marginLeft: shrink ? 20 : 0,
              justifyContent: "flex-start",
              zIndex: 1000000,
            }}
          >
            <div style={{ height: shrink ? 40 : 80, transition: "all 0.3s" }} />
            <BaseButtons
              label={"BACK"}
              onClick={() => navigate("/adminEditor")}
              mini={true}
              width={"100%"}
              size={"90%"}
              height={40}
              fontSize={shrink ? 11 : 12}
              borderRadius={4}
            />
            {/* <div style={{ height: 10 }} /> */}
            <BaseButtons
              label={"New Support Tickets"}
              onClick={() => {
                setTab("New Tickets");
                setMessageCreatePopUp(false);
              }}
              mini={true}
              width={"100%"}
              size={"90%"}
              fontSize={shrink ? 11 : null}
              marginTopFalse={false}
              marginTop={10}
              invert={tab === "New Tickets" ? false : true}
              borderRadius={8}
            />

            <BaseButtons
              label={"Complete Tickets"}
              onClick={() => {
                setTab("Complete Tickets");
                setMessageCreatePopUp(false);
              }}
              mini={true}
              marginTopFalse={false}
              marginTop={10}
              width={"100%"}
              size={"90%"}
              fontSize={shrink ? 11 : null}
              invert={tab === "Complete Tickets" ? false : true}
              borderRadius={8}
            />

            <BaseButtons
              label={"Customer Related"}
              onClick={() => {
                setTab("Patient Registry");
                setMessageCreatePopUp(false);
              }}
              mini={true}
              marginTopFalse={false}
              marginTop={10}
              width={"100%"}
              size={"90%"}
              fontSize={shrink ? 11 : null}
              invert={tab === "Patient Registry" ? false : true}
              borderRadius={8}
            />
            <BaseButtons
              label={"Point of Sale"}
              onClick={() => {
                setTab("posSupport");
                setMessageCreatePopUp(false);
              }}
              mini={true}
              marginTopFalse={false}
              marginTop={10}
              width={"100%"}
              size={"90%"}
              fontSize={shrink ? 11 : null}
              invert={tab === "posSupport" ? false : true}
              borderRadius={8}
            />
            <BaseButtons
              label={"Messaging"}
              onClick={() => setTab("message")}
              mini={true}
              marginTopFalse={false}
              marginTop={10}
              width={"100%"}
              size={"90%"}
              fontSize={shrink ? 11 : null}
              invert={tab === "message" ? false : true}
              borderRadius={8}
            />
            <div style={{ height: 10 }} />
          </div>
        ) : null}

        {tab === "posSupport" ? (
          <PosSupportDisplay
            setMessage={setMessage}
            setToastPop={setToastPop}
            setLoading={setLoading}
            user={admin}
            height={height - 40}
          />
        ) : (
          <div
            style={{
              flex: 1,
              height: height - 40,
              width: "100%",
              overflowY: "scroll",
              overflowX: "hidden",
              // marginTop: 100,
              position: "relative",
              backgroundColor: "#00000010",
            }}
          >
            {tab === "message" && !messageCreatePopUp ? (
              <div
                style={{
                  width: "100%",
                  height: 55,
                  minHeight: 60,
                  maxHeight: 60,

                  borderWidth: 2,
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: BlackAndWhite.secondary,
                  borderBottomStyle: "solid",
                  borderColor: "#00000030",
                  position: "absolute",
                  zIndex: 200000,
                }}
              >
                {/* <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginRight: 150,
                    width: 300,

                    borderWidth: 0.5,
                    marginLeft: 10,
                  }}
                >
                  <TextInputFieldSearch
                    placeholder={"Type here to search"}
                    height={25}
                    borderRadius={4}
                    onChange={(e) => setMessageSearch(e.target.value)}
                    value={messageSearch}
                  />
                  <div
                    style={{
                      height: 30,
                      width: 70,
                      backgroundColor: BlackAndWhite.primary,
                      borderRadius: 4,
                      display: "flex",
                      color: BlackAndWhite.secondary,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      onClick={() => setMessageSearch("")}
                      style={{ fontSize: 12, fontWeight: "bold" }}
                    >
                      CLEAR
                    </div>
                  </div>
                </div> */}
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    fontSize: 12,
                  }}
                >
                  <FilterMessage
                    filterName={"FILTER"}
                    setScreen={setMessageFilter}
                  />
                  <div style={{ minWidth: 15 }} />
                  <BaseButtons
                    label={"+ CREATE NEW"}
                    mini={true}
                    height={30}
                    borderRadius={4}
                    marginTopFalse={true}
                    onClick={() => setMessageCreatePopUp(true)}
                  />
                  <div style={{ minWidth: 15 }} />
                </div>
              </div>
            ) : null}
            {tab === "New Tickets" ? (
              inquiries ? (
                inquiries.inquiries.length >= 1 ? (
                  inquiries.inquiries.map((inquiries, index) => {
                    return inquiries.checked === "yes" ? null : (
                      <InquiryCard
                        firstName={inquiries.firstName}
                        lastName={inquiries.lastName}
                        key={index}
                        emailAddress={inquiries.emailAddress}
                        cellNumber={inquiries.cellNumber}
                        inquiryMessage={inquiries.inquiry}
                        whatsapp={inquiries.canWhatsApp}
                        idData={inquiries.id}
                        loading={loading}
                        setLoading={setLoading}
                      />
                    );
                  })
                ) : (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: BlackAndWhite.secondary,
                      fontSize: 20,
                      fontWeight: "bold",
                    }}
                  >
                    NO TICKETS
                  </div>
                )
              ) : (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: BlackAndWhite.secondary,
                    fontSize: 20,
                    fontWeight: "bold",
                  }}
                >
                  loading
                </div>
              )
            ) : null}
            {tab === "Complete Tickets" ? (
              inquiries ? (
                inquiries.inquiries.map((inquiries, index) => {
                  return inquiries.checked === "no" ? null : (
                    <InquiryCard
                      firstName={inquiries.firstName}
                      lastName={inquiries.lastName}
                      key={index}
                      emailAddress={inquiries.emailAddress}
                      cellNumber={inquiries.cellNumber}
                      inquiryMessage={inquiries.inquiry}
                      whatsapp={inquiries.canWhatsApp}
                      idData={inquiries.id}
                      loading={loading}
                      setLoading={setLoading}
                      inquiries={inquiries}
                      responded={inquiries.response}
                    />
                  );
                })
              ) : (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: BlackAndWhite.secondary,
                    fontSize: 20,
                    fontWeight: "bold",
                  }}
                >
                  loading
                </div>
              )
            ) : null}
            {tab === "Patient Registry" ? (
              <>
                <div
                  style={{
                    position: "fixed",
                    top: patientCsvDownloadPopUp ? 50 : 40,
                    zIndex: 100000000,
                    right: patientCsvDownloadPopUp ? 10 : 10,
                    transition: "all 0.5s",
                  }}
                >
                  {patientCsvDownloadPopUp ? (
                    <div
                      style={{
                        height: 300,
                        width: 450,
                        backgroundColor: BlackAndWhite.secondary,
                        borderStyle: "solid",
                        borderRadius: 20,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        overflow: "hidden",

                        // justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          marginTop: 20,
                          marginBottom: 10,
                          fontWeight: "bold",
                          fontSize: 20,
                          width: "80%",
                          textAlign: "left",
                        }}
                      >
                        USER DATABASE
                      </div>
                      <div
                        style={{
                          fontSize: 12,
                          width: "80%",
                          textAlign: "left",
                        }}
                      >
                        select a date and you can download the users that
                        register on that day
                      </div>

                      <MultiSelectorDate
                        day={day}
                        setDay={setDay}
                        month={month}
                        setMonth={setMonth}
                        setDayDate={setDayDate}
                        dayDate={dayDate}
                        year={"2024"}
                      />

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          width: "100%",
                        }}
                      >
                        {day !== "" && month !== "" && dayDate !== "" ? (
                          <ExcelExport
                            excelData={dayRegList ? dayRegList.data : []}
                            fileName={"excel export"}
                            title={"DOWNLOAD DAY"}
                          />
                        ) : (
                          // <CSVLink
                          //   style={{ textDecoration: "none" }}
                          //   data={dayRegList ? dayRegList.data : []}
                          //   headers={headersInfo}
                          // >
                          //   <BaseButtons label={"SEARCH DOWNLOAD"} size={250} />
                          // </CSVLink>
                          <ExcelExport
                            excelData={csvExport ? csvExport.users : []}
                            fileName={"excel export"}
                            title={"DOWNLOAD ALL"}
                          />
                          // <CSVLink
                          //   style={{ textDecoration: "none" }}
                          //   data={csvExport ? csvExport.users : []}
                          //   headers={headersInfo}
                          // >
                          //   <BaseButtons label={"DOWNLOAD"} size={150} />
                          // </CSVLink>
                        )}
                        {/* <div style={{ width: 10 }} /> */}
                        <BaseButtons
                          label={"CANCEL"}
                          size={150}
                          onClick={() => {
                            setPatientCsvDownloadPopUp(false);
                            setDay("");
                            setMonth("");
                            setDayDate("");
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div style={{ zIndex: 100000000009000 }}>
                      <div style={{ height: 7.5 }} />
                      <BaseButtons
                        label={"DOWNLOAD"}
                        onClick={() => setPatientCsvDownloadPopUp(true)}
                        marginTopFalse={true}
                        // invert={true}
                        height={40}
                        size={120}
                        mini={true}
                        borderRadius={8}
                      />
                    </div>
                  )}
                </div>
                <div
                  style={{
                    height: 55,
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* search section */}
                  <div
                    style={{ marginLeft: 10, marginRight: 10, marginBottom: 5 }}
                  >
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                        // width: 300,

                        borderWidth: 0.5,
                      }}
                    >
                      <TextInputFieldSearch
                        placeholder={"Type here to search"}
                        height={30}
                        borderRadius={4}
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                      />
                      <div
                        style={{
                          height: 35,
                          width: 70,
                          backgroundColor: BlackAndWhite.primary,
                          borderRadius: 4,
                          display: "flex",
                          color: BlackAndWhite.secondary,
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          marginLeft: 5,
                        }}
                      >
                        <div
                          onClick={() => {
                            setSearch("");
                            setCustomers(null);
                          }}
                          style={{ fontSize: 12, fontWeight: "bold" }}
                        >
                          CLEAR
                        </div>
                      </div>
                      <div
                        style={{
                          height: 35,
                          width: 70,
                          backgroundColor: BlackAndWhite.primary,
                          borderRadius: 4,
                          display: "flex",
                          color: BlackAndWhite.secondary,
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: search === "" ? "not-allowed" : "pointer",
                          marginLeft: 10,
                        }}
                      >
                        <div
                          onClick={() => OnUserSearch()}
                          style={{
                            fontSize: 12,
                            fontWeight: "bold",
                            opacity: 1,
                          }}
                        >
                          SEARCH
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ flex: 1 }}></div>
                </div>
                <HeadersPatients />
                <AdminCustomerTab
                  setRefresh={setRefresh}
                  refresh={refresh}
                  setData={setCsvExport}
                  csvExport={csvExport}
                  width={width}
                  setSelectedPatient={setSelectedPatient}
                  selectedPatient={selectedPatient}
                  customers={customers}
                  setPatientProfilePop={setPatientProfilePop}
                />
              </>
            ) : null}
            {tab === "message" ? (
              <MessageSupportDisplay
                user={admin ? admin.adminUserData : null}
                messageCreatePopUp={messageCreatePopUp}
                setMessageCreatePopUp={setMessageCreatePopUp}
                setLoading={setLoading}
                setMessage={setMessage}
                setToastPop={setToastPop}
              />
            ) : null}
          </div>
        )}

        <UserInfoPopUp
          loading={loading}
          setLoading={setLoading}
          setMessage={setMessage}
          setToastPop={setToastPop}
          selectedPatient={selectedPatient}
          display={selectedPatient ? true : false}
          onCloseClick={() => setSelectedPatient(null)}
          setRefresh={setRefresh}
          refresh={refresh}
          message={message}
          toastPop={toastPop}
        />
      </div>
      {tab === "Patient Registry" ? <LoadingPopUp display={loading} /> : null}
    </div>
  );
};

export default SupportAccess;
