import React, { useState } from "react";
import TextInputField from "../../../text-input-field/TextInputField";
import BaseButtons from "../../../base-buttons/BaseButtons";
import CheckInput from "../../../check-input/CheckInput";
import TextAreaInput from "../../../textarea-input-field/TextAreaInput";
import DropDown from "../../../drop-down/DropDown";
import LoadingPopUp from "../../../../loading-pop-up/LoadingPopUp";

const GeneralEnquiry = ({ setEnquireTab, width, height }) => {
  const [whatsapp, setWhatsapp] = useState("no");
  const [provice, setProvice] = useState("Click here");
  const [provinceDropDown, setProvinceDropDown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [cellNumber, setCellNumber] = useState("");
  const [canWhatsApp, setCanWhatsApp] = useState("");
  const [inquiry, setInquiry] = useState("");

  const submitInquireGeneralHandler = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/support/send-inquiry",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `bearer ${token}`,
          },
          body: JSON.stringify({
            firstName,
            lastName,
            emailAddress,
            cellNumber,
            canWhatsApp: whatsapp,
            province: provice,
            opportunity: "General",
            inquiry,
            checked: "no",
            response: "nothing",
          }),
        }
      );

      const respnseData = await response.json();
      if (respnseData) {
        setLoading(false);
        setEnquireTab(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      // setRegistration(false);
      // setDisplayButtons(true);
      setErrorMessage("Unable to resgister account");
    }
  };

  return (
    <div
      style={{
        height:
          width > 899
            ? height > 739
              ? 600
              : 450
            : height > 639
            ? "100%"
            : "100%",
        width: "100%",
        color: "#FFFFFF",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        bottom: 0,
      }}
    >
      <div
        style={{
          height: width > 899 ? "100%" : height > 639 ? 500 : 450,
          width: "100%",
          color: "#FFFFFF",
          overflowY: "scroll",
          position: "relative",
          position: "absolute",
          scrollbarWidth: "none",
          bottom: 0,
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              textAlign: "left",
              fontFamily: "Montserrat",
              width: width > 899 ? "50%" : "90%",
            }}
          >
            If you've got burning questions or just want to pass the time, drop
            your name, last name, email, and phone number. Got a message? Pass
            it our way! If you're on the lookout for some dank opportunities,
            hit up the opportunity tab in the menu. Let's keep it moving!
          </p>
        </div>

        <TextInputField
          labelTitle={"First name :"}
          size={width > 899 ? "50%" : "80%"}
          onChange={(event) => setFirstName(event.target.value)}
        />
        <TextInputField
          labelTitle={"Lastname :"}
          size={width > 899 ? "50%" : "80%"}
          onChange={(event) => setLastName(event.target.value)}
        />
        <TextInputField
          labelTitle={"Email address :"}
          size={width > 899 ? "50%" : "80%"}
          onChange={(event) => setEmailAddress(event.target.value)}
        />
        <TextInputField
          labelTitle={"Cell number :"}
          size={width > 899 ? "50%" : "80%"}
          onChange={(event) => setCellNumber(event.target.value)}
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CheckInput
            width={width}
            question={"Can we contact you on whatsapp?"}
            check={whatsapp === "yes" ? true : false}
            onClick={() => setWhatsapp(whatsapp === "yes" ? "no" : "yes")}
            size={width > 899 ? "50%" : "95%"}
          />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DropDown
            size={"80%"}
            width={width}
            drop={provinceDropDown}
            onDropClick={() => setProvinceDropDown(true)}
            onGautengClick={() => {
              setProvinceDropDown(false);
              setProvice("Gauteng");
            }}
            onFreeStateClick={() => {
              setProvinceDropDown(false);
              setProvice("Free state");
            }}
            onLimpopoClick={() => {
              setProvinceDropDown(false);
              setProvice("Limpopo");
            }}
            onWesternCapeClick={() => {
              setProvinceDropDown(false);
              setProvice("Western Cape");
            }}
            onKwaZuluNatalClick={() => {
              setProvinceDropDown(false);
              setProvice("Kwa-zulu natal");
            }}
            onEasternCapeClick={() => {
              setProvinceDropDown(false);
              setProvice("Eastern Cape");
            }}
            onNorthernCapeClick={() => {
              setProvinceDropDown(false);
              setProvice("Northern Cape");
            }}
            onNorthWestClick={() => {
              setProvinceDropDown(false);
              setProvice("North West");
            }}
            onMpumalangaClick={() => {
              setProvinceDropDown(false);
              setProvice("Mpumalanga");
            }}
            selection={provice}
          />
        </div>

        <TextAreaInput
          labelTitle={"How can we assit"}
          size={width > 899 ? "50%" : "80%"}
          onChange={(event) => setInquiry(event.target.value)}
        />
        <div style={{ height: 40 }} />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              textAlign: "left",
              fontFamily: "Montserrat",
              width: width > 899 ? "50%" : "90%",
            }}
          >
            BY SUBMITTING YOUR INFORMATION, YOU'RE GIVING US PERMISSION TO
            CONTACT YOU AND SUPPLYING YOU WITH THE REQUESTED INFORMATION.
          </p>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 20,
            marginBottom: 20,
            flexDirection: width > 899 ? "row" : "column",
          }}
        >
          {/* <BaseButtons
            label={"CANCEL"}
            onClick={() => setSelected("nothing")}
          />
          <div style={{ width: width > 899 ? 40 : 20 }} /> */}
          <BaseButtons
            label={"SUBMIT"}
            invert={true}
            onClick={() => submitInquireGeneralHandler()}
          />
        </div>

        <div style={{ height: 50 }} />
      </div>
      <LoadingPopUp display={loading} />
    </div>
  );
};

export default GeneralEnquiry;
