import React, { useState, useEffect } from "react";
import { BaseButtons, CloseButton } from "../../../../components/atoms";
import { BlackAndWhite } from "../../../../themes/Colors";
import CannaMenuButton from "../canna-menu-button/CannaMenuButton";
import { LoadingPopUp, NewPrescriptionCard } from "../../../../components";
import { color } from "chart.js/helpers";

const PatientProfilePopUp = ({
  display,
  onCloseClick,
  selectedPatient,
  loading,
  setLoading,
  setSelectedUser,
  setTab,
  setPatientProfilePop,
  setSelectedPatient,
  cashRegisterOpenCheck,
}) => {
  const [selectedPrescriptions, setSelectedPrescriptions] =
    useState("approved");
  const [data, setData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [agreements, setAgreements] = useState([]);

  const [validatedId, setValidatedId] = useState(null);

  const [receiptsArray, setReceiptsArray] = useState();

  const [approvedPrescriptionStatus, setApprovedPrescriptionStatus] =
    useState(null);

  const StatusCheck = () => {
    if (data.data.length >= 1) {
      setApprovedPrescriptionStatus(true);
    } else {
      setApprovedPrescriptionStatus(false);
    }
  };

  const PrescriptionFecth = async () => {
    setRefresh(true);
    await fetch(
      "https://merry-jane-api.onrender.com/prescription/fetchUserPrescriptions",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: selectedPatient ? selectedPatient.idDoc : "",
        }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setData(resData);
        setLoading(false);
        setRefresh(false);
      });
  };

  const GetUserValidateId = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/user/getValidateId",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: selectedPatient ? selectedPatient.email : "",
          }),
        }
      );

      const responseData = await response.json();

      if (!responseData.message) {
        setValidatedId(responseData.userId);
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  const checkForAgreements = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/user/getAgreements",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: validatedId ? validatedId : selectedPatient._id,
          }),
        }
      );
      const respnseData = await response.json();

      if (respnseData.agreements) {
        setAgreements(respnseData.agreements);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getPatientReceipts = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/processSale/getReciptForPatient",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: selectedPatient._id,
          }),
        }
      );
      const respnseData = await response.json();

      if (respnseData.success) {
        setReceiptsArray(respnseData.receipts);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (selectedPatient) {
      if (validatedId) {
        checkForAgreements();
      } else {
        checkForAgreements();
      }
    }
  }, [loading]);
  useEffect(() => {
    if (selectedPatient) {
      GetUserValidateId();
      getPatientReceipts();
    } else {
      setAgreements([]);
      setValidatedId(null);
      setReceiptsArray(null);
    }
  }, [selectedPatient, loading]);
  useEffect(() => {
    PrescriptionFecth();
    if (data) {
      StatusCheck();
    }
  }, [loading]);
  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: display ? "100%" : 0,
        overflow: "hidden",
        zIndex: 4000000,
        backgroundColor: BlackAndWhite.primary,
        transition: "all 0.2s",
        top: 0,
        display: "flex",
      }}
    >
      {/* section one */}
      <div
        style={{
          flex: 0.4,
          height: "100%",

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "90%",
            width: "85%",
            borderRadius: 20,
            backgroundColor: BlackAndWhite.secondary,
            WebkitBoxShadow: "0px 0px 10px 1px #00000033",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
          }}
        >
          <div
            style={{
              height: 200,
              width: 250,
              backgroundColor: BlackAndWhite.primary,
              borderTopRightRadius: 100,
              borderTopLeftRadius: 100,
              marginTop: 30,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderStyle: "solid",
              borderColor: agreements
                ? agreements.length >= 1 && validatedId
                  ? "#599F68"
                  : BlackAndWhite.orange
                : BlackAndWhite.orange,
              borderWidth: 5,
              WebkitBoxShadow: "0px 0px 5px 5px #00000033",
              position: "relative",
              color: BlackAndWhite.secondary,
            }}
          >
            Profile image
            <div
              style={{
                position: "absolute",
                bottom: -20,
                display: "flex",
                flexDirection: "column",
                backgroundColor: agreements
                  ? agreements.length >= 1 && validatedId
                    ? "#599F68"
                    : BlackAndWhite.orange
                  : BlackAndWhite.orange,
                color: BlackAndWhite.secondary,
                borderStyle: "solid",
                borderWidth: 4,
                borderRadius: 10,
                width: 300,
                height: 35,
                justifyContent: "center",
                alignItems: "center",
                WebkitBoxShadow: "0px 0px 5px 5px #00000033",
                borderColor: agreements
                  ? agreements.length >= 1 && validatedId
                    ? "#599F68"
                    : BlackAndWhite.orange
                  : BlackAndWhite.orange,
              }}
            >
              <div
                style={{
                  color: agreements
                    ? agreements.length >= 1 && validatedId
                      ? BlackAndWhite.secondary
                      : BlackAndWhite.primary
                    : BlackAndWhite.primary,
                  fontSize: selectedPatient
                    ? selectedPatient.firstName > 12
                      ? 16
                      : 20
                    : 20,
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {selectedPatient
                  ? (
                      selectedPatient.firstName +
                      " " +
                      selectedPatient.lastName
                    ).toLocaleUpperCase()
                  : "loading"}
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: 90,
              marginTop: 30,
            }}
          >
            <div
              style={{
                flex: 0.5,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ fontSize: 14, marginBottom: 10 }}>GENDER:</div>
              <div style={{ fontSize: 22, fontWeight: "bolder" }}>
                {selectedPatient
                  ? selectedPatient.gender === "male"
                    ? "MALE"
                    : "FEMALE"
                  : "loading"}
              </div>
            </div>
            <div
              style={{
                flex: 0.5,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontSize: 14,
                  marginBottom: 10,
                  width: "80%",
                  textAlign: "left",
                }}
              >
                DATE OF BIRTH:
              </div>
              <div
                style={{
                  fontSize: 22,
                  fontWeight: "bolder",
                  width: "80%",
                  textAlign: "left",
                }}
              >
                {selectedPatient ? selectedPatient.dateOfBirth : "loading"}
              </div>
            </div>
          </div>

          <div
            style={{
              fontSize: 12,
              marginBottom: 6,
              width: "70%",
              textAlign: "left",
            }}
          >
            ID-number:
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bolder",
              width: "70%",
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            {selectedPatient ? selectedPatient.idDoc : "loading"}
          </div>
          <div
            style={{
              fontSize: 12,
              marginBottom: 6,
              width: "70%",
              textAlign: "left",
            }}
          >
            Email:
          </div>
          <div
            style={{
              fontSize: selectedPatient
                ? selectedPatient.email.length > 24
                  ? 14
                  : 16
                : 16,
              fontWeight: "bolder",
              width: "70%",
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            {selectedPatient ? selectedPatient.email : "loading"}
          </div>
          <div
            style={{
              fontSize: 12,
              marginBottom: 6,
              width: "70%",
              textAlign: "left",
            }}
          >
            Cell-number:
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bolder",
              width: "70%",
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            {selectedPatient ? selectedPatient.cellNumber : "loading"}
          </div>
          <div
            style={{
              fontSize: 12,
              marginBottom: 6,
              width: "70%",
              textAlign: "left",
            }}
          >
            Address:
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bolder",
              width: "70%",
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            {selectedPatient
              ? selectedPatient.street + ", " + selectedPatient.suburb
              : "loading"}
          </div>
          <div
            style={{
              fontSize: 12,
              marginBottom: 6,
              width: "70%",
              textAlign: "left",
            }}
          >
            City:
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bolder",
              width: "70%",
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            {selectedPatient ? selectedPatient.town : "loading"}
          </div>
          <div
            style={{
              fontSize: 12,
              marginBottom: 6,
              width: "70%",
              textAlign: "left",
            }}
          >
            Store credit:
          </div>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bolder",
              width: "70%",
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            {selectedPatient
              ? Number(selectedPatient.rewardPoints).toFixed(2)
              : "loading"}
          </div>

          {agreements.length >= 1 ? (
            validatedId ? (
              <>
                {cashRegisterOpenCheck !== "No Register Found" &&
                cashRegisterOpenCheck !== null ? (
                  <BaseButtons
                    label={"PROCEED TO CART"}
                    mini={true}
                    size={"80%"}
                    borderRadius={8}
                    onClick={() => {
                      setSelectedUser(selectedPatient);
                      setTab("PROCESS SALE");
                      setPatientProfilePop(false);
                      setSelectedPatient(null);
                    }}
                  />
                ) : (
                  <div
                    style={{
                      width: "75%",
                      textAlign: "left",
                      marginTop: 5,
                      padding: 5,
                      borderRadius: 8,
                      // borderStyle: "solid",
                      // borderColor: "red",
                      borderWidth: 3,
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      fontSize: 14,
                    }}
                  >
                    <img
                      alt="logo"
                      src={require("../../../../images/new-material-icons/warningMarkerRed.png")}
                      style={{ height: 40 }}
                    />
                    <div style={{ minWidth: 15 }} /> OPEN REGISTER TO PROCEED TO
                    POS
                  </div>
                )}
                <div style={{ minHeight: 10 }} />
              </>
            ) : null
          ) : (
            <div
              style={{
                fontSize: 14,
                fontWeight: "bolder",
                width: "70%",
                textAlign: "left",
                marginBottom: 10,
                borderRadius: 8,
                // borderStyle: "solid",
                paddingTop: 10,
                paddingBottom: 10,
                display: "flex",
                // borderColor: "red",
                alignItems: "center",
              }}
            >
              <img
                alt="logo"
                src={require("../../../../images/new-material-icons/warningMarkerRed.png")}
                style={{ height: 40 }}
              />
              <div style={{ minWidth: 15 }} />
              THIS PATIENT IS MISSING THE NEW TERMS AND CONDITIONS
            </div>
          )}
          {validatedId ? null : (
            <div
              style={{
                fontSize: 14,
                fontWeight: "bolder",
                width: "70%",
                textAlign: "left",
                marginBottom: 10,
                borderRadius: 8,
                // borderStyle: "solid",
                paddingTop: 10,
                paddingBottom: 10,
                display: "flex",
                // borderColor: "red",
                alignItems: "center",
              }}
            >
              <img
                alt="logo"
                src={require("../../../../images/new-material-icons/warningMarkerRed.png")}
                style={{ height: 40 }}
              />
              <div style={{ minWidth: 15 }} />
              THIS PATIENT NEEDS TO VALIDATE THEIR ACCOUNT
            </div>
          )}
          <div style={{ position: "absolute", top: 10, left: 10 }}>
            <CloseButton label={"X"} onClick={onCloseClick} />
          </div>
        </div>
      </div>
      {/* section 2 */}
      <div
        style={{
          flex: 0.6,
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "90%",
            width: "95%",
            borderRadius: 10,
            backgroundColor: BlackAndWhite.secondary,
            WebkitBoxShadow: "0px 0px 10px 1px #00000033",
            display: "flex",
            flexDirection: "column",
            marginRight: 40,
            justifyContent: "flex-start",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              height: 60,
              width: "100%",
              backgroundColor: agreements
                ? agreements.length >= 1 && validatedId
                  ? "#599F68"
                  : BlackAndWhite.orange
                : BlackAndWhite.orange,
              position: "relative",
              display: "flex",
            }}
          >
            <div
              style={{
                flex: 0.2,

                height: "100%",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ position: "absolute" }}>
                <CannaMenuButton
                  selected={selectedPrescriptions === "approved" ? true : false}
                  iconImage={require("../../../../images/material-icons/instagramWhite.png")}
                  height={35}
                  title={"APPROVED"}
                  width={140}
                  fontColor={
                    selectedPrescriptions === "approved"
                      ? BlackAndWhite.secondary
                      : BlackAndWhite.primary
                  }
                  onClick={() => setSelectedPrescriptions("approved")}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.2,

                height: "100%",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ position: "absolute" }}>
                <CannaMenuButton
                  selected={selectedPrescriptions === "pending" ? true : false}
                  iconImage={require("../../../../images/material-icons/instagramWhite.png")}
                  height={35}
                  title={"PENDING"}
                  width={140}
                  fontColor={
                    selectedPrescriptions === "pending"
                      ? BlackAndWhite.secondary
                      : BlackAndWhite.primary
                  }
                  onClick={() => setSelectedPrescriptions("pending")}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.2,

                height: "100%",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ position: "absolute" }}>
                <CannaMenuButton
                  selected={selectedPrescriptions === "declined" ? true : false}
                  iconImage={require("../../../../images/material-icons/instagramWhite.png")}
                  height={35}
                  title={"DECLINED"}
                  width={140}
                  fontColor={
                    selectedPrescriptions === "declined"
                      ? BlackAndWhite.secondary
                      : BlackAndWhite.primary
                  }
                  onClick={() => setSelectedPrescriptions("declined")}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.2,

                height: "100%",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ position: "absolute" }}>
                <CannaMenuButton
                  selected={selectedPrescriptions === "expired" ? true : false}
                  iconImage={require("../../../../images/material-icons/instagramWhite.png")}
                  height={35}
                  title={"EXPIRED"}
                  width={140}
                  fontColor={
                    selectedPrescriptions === "expired"
                      ? BlackAndWhite.secondary
                      : BlackAndWhite.primary
                  }
                  onClick={() => setSelectedPrescriptions("expired")}
                />
              </div>
            </div>
            <div
              style={{
                flex: 0.2,

                height: "100%",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ position: "absolute" }}>
                <CannaMenuButton
                  selected={selectedPrescriptions === "reciepts" ? true : false}
                  iconImage={require("../../../../images/material-icons/instagramWhite.png")}
                  height={35}
                  title={"RECEIPTS"}
                  width={140}
                  fontColor={
                    selectedPrescriptions === "reciepts"
                      ? BlackAndWhite.secondary
                      : BlackAndWhite.primary
                  }
                  onClick={() => setSelectedPrescriptions("reciepts")}
                />
              </div>
            </div>
          </div>
          <div style={{ height: 25 }}></div>
          <div
            style={{
              flex: 1,
              overflow: "scroll",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              paddingBottom: 20,
            }}
          >
            {selectedPrescriptions === "approved" ? (
              data ? (
                data.data.length >= 1 ? (
                  data.data.map((prescription, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <NewPrescriptionCard
                          patientNo={prescription ? prescription.id : "loading"}
                          patientId={
                            prescription ? prescription.patientId : "loading"
                          }
                          doctor={
                            prescription ? prescription.doctor : "loading"
                          }
                          prescriptionNo={
                            prescription
                              ? prescription.prescriptionNum
                              : "loading"
                          }
                          createdDate={
                            prescription ? prescription.date : "loading"
                          }
                          validUntil={
                            prescription ? prescription.validUntil : "loading"
                          }
                          status={
                            prescription ? prescription.approved : "loading"
                          }
                          diagonosis={
                            prescription ? prescription.diagnosis : "loading"
                          }
                          prescribed={
                            prescription ? prescription.prescription : "loading"
                          }
                        />
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      marginTop: 50,
                      fontSize: 24,
                      fontWeight: "bold",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    NO APPROVED PRESCRIPTIONS
                  </div>
                )
              ) : (
                <LoadingPopUp display={true} newWidth={"100%"} />
              )
            ) : null}
            {selectedPrescriptions === "pending" ? (
              data ? (
                data.dataPending.length >= 1 ? (
                  data.dataPending.map((prescription, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {prescription ? prescription.id : "loading"}
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      marginTop: 50,
                      fontSize: 24,
                      fontWeight: "bold",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    No pending prescriptions
                  </div>
                )
              ) : (
                <LoadingPopUp display={true} newWidth={"100%"} />
              )
            ) : null}
            {selectedPrescriptions === "expired" ? (
              data ? (
                data.dataExpired.length >= 1 ? (
                  data.dataExpired.map((prescription, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <NewPrescriptionCard
                          createdDate={
                            prescription ? prescription.date : "loading"
                          }
                          patientNo={prescription ? prescription.id : "loading"}
                          patientId={
                            prescription ? prescription.patientId : "loading"
                          }
                          doctor={
                            prescription ? prescription.doctor : "loading"
                          }
                          prescriptionNo={
                            prescription
                              ? prescription.prescriptionNum
                              : "loading"
                          }
                          validUntil={
                            prescription ? prescription.validUntil : "loading"
                          }
                          status={
                            prescription ? prescription.approved : "loading"
                          }
                          diagonosis={
                            prescription ? prescription.diagnosis : "loading"
                          }
                          prescribed={
                            prescription ? prescription.prescription : "loading"
                          }
                        />
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      marginTop: 50,
                      fontSize: 24,
                      fontWeight: "bold",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    No Expired prescriptions
                  </div>
                )
              ) : (
                <LoadingPopUp display={true} newWidth={"100%"} />
              )
            ) : null}
            {selectedPrescriptions === "declined" ? (
              data ? (
                data.dataDeclined.length >= 1 ? (
                  data.dataDeclined.map((prescription, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {prescription ? prescription.id : "loading"}
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      marginTop: 50,
                      fontSize: 24,
                      fontWeight: "bold",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    No Declined prescriptions
                  </div>
                )
              ) : (
                <LoadingPopUp display={true} newWidth={"100%"} />
              )
            ) : null}
            {selectedPrescriptions === "reciepts" ? (
              receiptsArray ? (
                receiptsArray.length >= 1 ? (
                  receiptsArray.map((receipt, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: 20,
                        }}
                      >
                        <div
                          style={{
                            height: 170,
                            minWidth: "90%",
                            backgroundColor: BlackAndWhite.secondary,
                            borderRadius: 20,
                            WebkitBoxShadow: "0px 0px 7px 2px #00000050",
                            position: "relative",
                            display: "flex",
                            flexDirection: "column",
                            overflow: "hidden",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginTop: 10,
                              marginLeft: 15,
                            }}
                          >
                            <div
                              style={{
                                fontSize: 10,
                                fontWeight: "bold",
                                opacity: 0.6,
                                marginBottom: 3,
                              }}
                            >
                              SLIP NO
                            </div>
                            <div style={{ fontSize: 14 }}>
                              {" "}
                              {receipt ? receipt.slipNo : "loading"}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginTop: 10,
                              right: 15,
                              width: "100%",
                              textAlign: "right",
                              position: "absolute",
                              top: 0,
                            }}
                          >
                            <div
                              style={{
                                fontSize: 10,
                                fontWeight: "bold",
                                opacity: 0.6,
                              }}
                            >
                              SHOP
                            </div>
                            <div style={{ fontSize: 14 }}>
                              {" "}
                              {receipt ? receipt.storeName : "loading"}
                            </div>
                          </div>
                          <div
                            style={{
                              flex: 1,
                              position: "relative",
                              display: "flex",
                              alignItems: "center",
                              overflowX: "scroll",
                            }}
                          >
                            {receipt
                              ? receipt.productsArray.map((product, index) => {
                                  return (
                                    <div
                                      key={index}
                                      style={{
                                        height: 70,
                                        width: 70,
                                        borderRadius: 8,
                                        borderStyle: "solid",
                                        marginTop: 10,
                                        marginLeft: 15,
                                        position: "relative",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img
                                        alt="logo"
                                        src={product.product.imageUrl}
                                        style={{ height: 50 }}
                                      />
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          minHeight: 30,
                                          minWidth: 30,
                                          backgroundColor:
                                            BlackAndWhite.primary,
                                          borderRadius: 50,
                                          color: BlackAndWhite.secondary,
                                          position: "absolute",
                                          bottom: -10,
                                          right: -10,
                                          fontSize: 12,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        X {product.qtyCard.qty}
                                      </div>
                                    </div>
                                  );
                                })
                              : "loading"}

                            <div
                              style={{
                                position: "absolute",
                                top: 5,
                                right: 15,
                                fontSize: 12,
                                fontWeight: "bold",
                              }}
                            >
                              TOTAL: R {Number(receipt.total).toFixed(2)}
                            </div>
                            <div
                              style={{
                                position: "absolute",
                                top: 5,
                                left: 15,
                                fontSize: 12,
                                fontWeight: "bold",
                              }}
                            >
                              DATE: {receipt.dateTime}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    style={{
                      marginTop: 50,
                      fontSize: 24,
                      fontWeight: "bold",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    No receipts history
                  </div>
                )
              ) : (
                <LoadingPopUp display={true} newWidth={"100%"} />
              )
            ) : null}
          </div>
        </div>
      </div>
      {/* <LoadingPopUp display={refresh} newWidth={"100%"} /> */}
    </div>
  );
};

export default PatientProfilePopUp;
