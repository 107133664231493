import React, { useState } from "react";
import { BaseButtons } from "../../../../../../../../components/atoms";
import { BlackAndWhite } from "../../../../../../../../themes/Colors";

const StockTakeListItem = ({ ticket }) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{
        width: "100%",
        minHeight: 65,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: 12,
        backgroundColor: hover ? "#00000020" : BlackAndWhite.secondary,
      }}
    >
      <div style={{ marginLeft: 20 }}>
        <div
          style={{
            marginBottom: 4,
            fontSize: 10,
            fontWeight: "bold",
            opacity: 0.6,
          }}
        >
          Date counted:
        </div>
        <div style={{ fontSize: 14 }}>{ticket.dateCounted}</div>
      </div>

      <div style={{ flex: 1 }} />
      <div style={{ marginLeft: 20, width: 150 }}>
        <div
          style={{
            marginBottom: 4,
            fontSize: 10,
            fontWeight: "bold",
            opacity: 0.6,
          }}
        >
          Expected:
        </div>
        <div style={{ fontSize: 14 }}>{ticket.expected}</div>
      </div>
      <div style={{ marginLeft: 20, width: 150 }}>
        <div
          style={{
            marginBottom: 4,
            fontSize: 10,
            fontWeight: "bold",
            opacity: 0.6,
          }}
        >
          Counted:
        </div>
        <div style={{ fontSize: 14 }}>{ticket.stockCount}</div>
      </div>
      <div style={{ marginLeft: 20, width: 150 }}>
        <div
          style={{
            marginBottom: 4,
            fontSize: 10,
            fontWeight: "bold",
            opacity: 0.6,
          }}
        >
          Counted by:
        </div>
        <div style={{ fontSize: 14 }}>{ticket.countedBy}</div>
      </div>

      <BaseButtons
        mini={true}
        marginTop={-1}
        label={"MANAGE"}
        borderRadius={8}
        height={35}
        size={120}
        marginRight={50}
      />
    </div>
  );
};

export default StockTakeListItem;
