import React, { useState, useEffect } from "react";
import { ItemDisplayCard } from "../dashboard-pos/stat-card/components";
import { BlackAndWhite } from "../../../../themes/Colors";
import {
  BaseButtons,
  PinTextInputField,
  TextInputField,
} from "../../../../components/atoms";
import PinPopUpCheck from "../pin-pop-up-check/PinPopUpCheck";
import { CashRegisterCount, CashRegisterViewPopUp } from "./components";
import PdfDisplay from "./components/pdf-display/PdfDisplay";
import NumberSpace from "../../../global-functions/NumberSpace";

const CashRegister = ({
  user,
  refresh,
  setLoading,

  setToastPop,
  setMessage,
  setRefresh,
  storeInfo,
  setCashRegisterOpenCheck,
  cashRegisterOpenCheck,
  setCashRegisterReset,
  cashRegisterReset,
}) => {
  const [cashRegisterOpen, setCashRegisterOpen] = useState(false);
  const [pin1, setPin1] = useState("");

  // this is to check if the cash RegsoterIs ipen

  const [pinCheckPopUp, setPinCheckPopUp] = useState(false);
  const [floatAmount, setFloatAmount] = useState(false);
  const [closeCashRegister, setCloseCashRegister] = useState(false);
  const [cashRegsiterStatus, setCashRegsiterStatus] = useState("closed");
  const [openedBy, setOPenedBy] = useState("closed");
  const [dateAndTimeOpened, setDateAndTimeOpened] = useState("closed");
  const [storeUsers, setStoreUsers] = useState(null);
  const [tillamount, setTillAmount] = useState("");

  const [countTill, setCountTill] = useState(false);

  const [selectedReport, setSelectedReport] = useState(null);
  const [selectedReportIndex, setSelectedReportIndex] = useState(null);

  const [cashRegisterHistoryArray, setCashRegisterHistoryArray] =
    useState(null);

  const [pinPopUp, setPinPopUp] = useState(false);

  const [selectedBudtender, setSelectedBudTender] = useState(null);

  const [userVerfiied, setUserVerified] = useState("closed");
  const [hover, setHover] = useState(false);

  const GetStoreUsers = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/processSale/getAllStoreUsers",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storeName: user.adminUserData.storeName,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setStoreUsers(responseData.storeUsers);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };

  const OpenCashRegoster = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/cashRegister/openRegister",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storeName: storeInfo.storeName,
            storeId: storeInfo.storeId,
            userId: selectedBudtender._id,
            openedBy:
              selectedBudtender.firstName + " " + selectedBudtender.lastName,
            tillAmount: tillamount,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setCashRegisterOpenCheck(responseData.cashRegsiter);
        setCashRegisterOpen(false);
        setCashRegsiterStatus(responseData.cashRegsiter.status);
        setOPenedBy(responseData.cashRegsiter.openedBy);
        setDateAndTimeOpened(responseData.cashRegsiter.openedAt);
        setMessage(responseData.success);
        setToastPop(true);
        setLoading(false);
      } else {
        setMessage(responseData.message);
        setToastPop(true);
        setLoading(false);
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  const CloseCashRegister = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/cashRegister/closeRegister",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storeName: storeInfo.storeName,
            storeId: storeInfo.storeId,
            userId: selectedBudtender._id,
            closedBy:
              selectedBudtender.firstName + " " + selectedBudtender.lastName,
            cashRegisterId: cashRegisterOpenCheck.registerId,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setCountTill(responseData.cashRegsiterreport);
        setMessage(
          "CASH REGISTER CLOSED, PLEASE COUNT CASH IN TILL AND CARD SLIPS"
        );
        setToastPop(true);
        setLoading(false);
      } else {
        setMessage(responseData.message);
        setToastPop(true);
        setLoading(false);
      }
    } catch (err) {
      console.log("error", err);
      setMessage("Error occured check your network connection");
      setToastPop(true);
      setLoading(false);
    }
  };

  const getRegisterHistory = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/cashRegister/getCashRegisterHistory",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storeId: storeInfo.storeId,
            userId: user.adminUserData.userId,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setCashRegisterHistoryArray(responseData.storeCahsRegisterHistory);
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  const ConfirmAccess = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/processSale/checkUserAccessPin",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storeName: user.adminUserData.storeName,
            userId: selectedBudtender._id,
            pin: pin1,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setCashRegisterOpen(true);
        setPinPopUp(false);
        setUserVerified(true);
        setLoading(false);
        setPin1("");
      } else {
        setLoading(false);
        setMessage(responseData.message);
        setToastPop(true);
      }
    } catch (err) {
      setLoading(false);
      setMessage("No network try again later");
      setToastPop(true);
      console.log("error", err);
    }
  };

  const ConfirmAccessCloseREgister = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/processSale/checkUserAccessPin",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storeName: user.adminUserData.storeName,
            userId: selectedBudtender._id,
            pin: pin1,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        CloseCashRegister();
        setPinPopUp(false);
        setUserVerified(true);
        setLoading(false);
        setPin1("");
      } else {
        setLoading(false);
        setMessage(responseData.message);
        setToastPop(true);
      }
    } catch (err) {
      setLoading(false);
      setMessage("No network try again later");
      setToastPop(true);
      console.log("error", err);
    }
  };

  useEffect(() => {
    if (pin1.length === 4 && cashRegsiterStatus === "closed") {
      ConfirmAccess();
    } else if (pin1.length === 4 && cashRegsiterStatus === "open") {
      ConfirmAccessCloseREgister();
    }
  }, [pin1]);
  useEffect(() => {
    GetStoreUsers();

    //get all store users
  }, []);
  useEffect(() => {
    getRegisterHistory();
    if (cashRegisterOpenCheck !== "No Register Found") {
      if (cashRegisterOpenCheck) {
        setCashRegsiterStatus(cashRegisterOpenCheck.status);
        setOPenedBy(cashRegisterOpenCheck.openedBy);
        setDateAndTimeOpened(cashRegisterOpenCheck.openedAt);
      }
    }

    //get all store users
  }, [cashRegisterOpenCheck]);
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          height: 120,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 20,
        }}
      >
        {" "}
        <div
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
          style={{
            height: "90%",
            width: "90%",
            backgroundColor: BlackAndWhite.secondary,
            borderRadius: 8,
            display: "flex",
            position: "relative",
            overflow: "hidden",
            WebkitBoxShadow: hover
              ? "0px 0px 12px 2px #00000083"
              : "0px 0px 15px 2px #00000050 ",
            // borderWidth: hover ? 4 : 0,
            transition: "all 0.3s",
            // borderStyle: "solid",
            cursor: "pointer",
            // borderColor: "#00000090",
            borderColor: "#00000090",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* card title */}
          <div
            style={{
              // position: "absolute",

              fontWeight: "bold",
              fontSize: 20,
              height: "100%",

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // width: 300,
              textAlign: "left",
              marginRight: 10,
              color: "#00000090",
            }}
          >
            <img
              alt="Icon"
              src={require("../../../../images/new-material-icons/salesREgBlack.png")}
              style={{
                height: 70,
                width: 70,
                marginLeft: 20,
                opacity: 0.6,
              }}
            />
            <div style={{ width: "100%", marginLeft: 20, fontSize: 18 }}>
              SALES REGISTER
            </div>
          </div>
          <div style={{ flex: 1 }} />
          <div
            style={{
              flex: 1,
              display: "flex",

              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <ItemDisplayCard
              title={"STATUS"}
              value={cashRegsiterStatus.toLocaleUpperCase()}
              width={150}
            />
            {cashRegsiterStatus === "closed" ? null : (
              <ItemDisplayCard
                title={"OPENED BY"}
                value={openedBy.toLocaleUpperCase()}
                width={170}
              />
            )}

            {cashRegsiterStatus === "closed" ? null : (
              <ItemDisplayCard
                // date={true}
                title={"OPENED ON"}
                value={
                  dateAndTimeOpened === "closed"
                    ? "CLOSED"
                    : dateAndTimeOpened.substring(0, 10) +
                      " at " +
                      dateAndTimeOpened.substring(11, 16)
                }
                width={200}
              />
            )}

            {cashRegisterOpenCheck !== "No Register Found" &&
            cashRegisterOpenCheck !== null ? (
              <BaseButtons
                label={"CLOSE"}
                mini={true}
                marginTopFalse={true}
                borderRadius={10}
                onClick={() => setPinPopUp(true)}
                size={100}
              />
            ) : (
              <BaseButtons
                size={100}
                label={"OPEN"}
                mini={true}
                marginTopFalse={true}
                borderRadius={10}
                onClick={() => {
                  // setCashRegisterOpen(true);

                  setPinPopUp(true);
                }}
              />
            )}
            <div style={{ minWidth: 20 }} />
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 20,
          maxHeight: 700,
          minHeight: 400,
          height: 800,
        }}
      >
        <div
          style={{
            height: "90%",
            width: "90%",
            backgroundColor: BlackAndWhite.secondary,
            borderRadius: 8,
            display: "flex",
            position: "relative",
            overflow: "hidden",
            WebkitBoxShadow: hover
              ? "0px 0px 12px 2px #00000083"
              : "0px 0px 15px 2px #00000050 ",
            // borderWidth: hover ? 4 : 0,
            transition: "all 0.3s",
            // borderStyle: "solid",
            cursor: "pointer",
            // borderColor: "#00000090",
            borderColor: "#00000090",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              // position: "absolute",
              // top: 20,
              // left: 20,
              fontSize: 18,
              opacity: 0.6,
              fontWeight: "bold",
              display: "flex",
              justifyContent: "centre",
              alignItems: "center",
              height: 40,
              width: "90%",
              marginTop: 10,
            }}
          >
            SALES REGISTER REPORT HISTORY
          </div>
          <div
            style={{
              flex: 1,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              overflowX: "hidden",
              alignItems: "center",
              marginBottom: 10,
            }}
          >
            <div style={{ minHeight: 10 }} />
            {cashRegisterHistoryArray ? (
              cashRegisterHistoryArray.length >= 1 ? (
                cashRegisterHistoryArray.map((report, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        width: "95%",
                        minHeight: 70,
                        backgroundColor: BlackAndWhite.secondary,

                        display: "flex",
                        alignItems: "center",

                        marginTop: index === 0 ? 0 : 10,
                        borderRadius: 8,
                        WebkitBoxShadow: "0px 0px 7px 2px #00000050",
                        marginBottom:
                          index === cashRegisterHistoryArray.length - 1
                            ? 20
                            : 0,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          marginLeft: 10,
                          fontSize: 15,
                          flex: 0.1,
                        }}
                      >
                        <div style={{ fontSize: 10, marginBottom: 3 }}>
                          Count
                        </div>
                        {index + 1}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",

                          marginLeft: 10,
                          fontSize: 12,
                          flex: 0.2,
                        }}
                      >
                        <div style={{ fontSize: 10, marginBottom: 3 }}>
                          Opened
                        </div>
                        {report.openedAt.substring(0, 10) +
                          " " +
                          report.openedAt.substring(11, 16)}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",

                          marginLeft: 10,
                          fontSize: 12,
                          flex: 0.2,
                        }}
                      >
                        <div style={{ fontSize: 10, marginBottom: 3 }}>
                          Opened by
                        </div>
                        {report.openedBy}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",

                          marginLeft: 10,
                          fontSize: 12,
                          flex: 0.2,
                        }}
                      >
                        <div style={{ fontSize: 10, marginBottom: 3 }}>
                          Closed
                        </div>
                        {report.closedAt.substring(0, 10) +
                          " " +
                          report.closedAt.substring(11, 16)}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",

                          marginLeft: 10,
                          fontSize: 12,
                          flex: 0.2,
                        }}
                      >
                        <div style={{ fontSize: 10, marginBottom: 3 }}>
                          Closed by
                        </div>
                        {report.closedBy}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",

                          marginLeft: 15,
                          fontSize: 15,
                          flex: 0.2,
                        }}
                      >
                        <div style={{ fontSize: 10, marginBottom: 3 }}>
                          Total Sales
                        </div>
                        R {Number(report.TotalInc).toFixed(2)}
                      </div>
                      {/* <div
                        style={{
                          display: "flex",
                          flexDirection: "column",

                          marginLeft: 15,
                          fontSize: 15,
                        }}
                      >
                        <div style={{ fontSize: 10, marginBottom: 3 }}>
                          Expected
                        </div>
                        R{" "}
                        {(
                          Number(report.cashCounted) +
                          Number(report.cardCounted) -
                          Number(report.tillOpeningAmount)
                        ).toFixed(2)}
                      </div> */}

                      {/* <div style={{ flex: 1 }} /> */}
                      <div style={{ marginRight: 10 }}>
                        <BaseButtons
                          label={"VIEW"}
                          mini={true}
                          marginTop={-1}
                          height={40}
                          borderRadius={8}
                          size={80}
                          onClick={() => {
                            setSelectedReport(report);
                            setSelectedReportIndex(index);
                          }}
                        />
                      </div>
                    </div>
                  );
                })
              ) : (
                <div style={{ marginTop: 40, fontSize: 18 }}>
                  ITS A LITTLE EMPTY HERE, TRY TO OPEN AND CLOSE YOUR FIRST
                  REGISTER
                </div>
              )
            ) : null}
          </div>
        </div>
      </div>

      {pinPopUp ? (
        <div
          style={{
            position: "fixed",
            backgroundColor: "#00000050",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: 0,
            left: 0,
            zIndex: 1000000,
          }}
        >
          <div
            style={{
              minHeight: 700,
              maxHeight: 700,
              width: 500,
              backgroundColor: BlackAndWhite.secondary,
              borderRadius: 10,
              display: "flex",
              flexDirection: "column",
              position: "relative",
              overflow: "hidden",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "90%",
                marginTop: 20,
                marginBottom: 10,
                fontSize: 18,
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              SELECT USER AND ENTER YOUR PIN
            </div>
            {selectedBudtender ? null : (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  minHeight: 300,
                  maxHeight: 600,
                  flex: 1,
                  flexDirection: "column",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  scrollbarWidth: "none",
                }}
              >
                {storeUsers
                  ? storeUsers.map((user, index) => {
                      return (
                        <div
                          onClick={() => setSelectedBudTender(user)}
                          key={index}
                          style={{
                            width: "99%",
                            minHeight: 70,

                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            marginBottom: 10,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "95%",
                              width: "90%",
                              backgroundColor: BlackAndWhite.secondary,
                              WebkitBoxShadow: "0px 0px 7px 2px #00000050",
                              borderRadius: 8,
                              minHeight: 70,
                              marginTop: 10,
                            }}
                          >
                            {user.firstName + " " + user.lastName}
                          </div>
                        </div>
                      );
                    })
                  : "Loading"}
              </div>
            )}

            {selectedBudtender ? (
              <>
                <div
                  onClick={() => {
                    setSelectedBudTender(null);
                    setPin1("");
                  }}
                  style={{
                    height: 100,
                    width: "100%",

                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "85%",
                      borderStyle: "solid",
                      height: 90,
                      borderRadius: 8,
                      display: "flex",
                      alignItems: "center",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        height: "100%",
                        width: 100,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        alt="image"
                        src={require("../../../../images/material-icons/profileBlack.png")}
                        style={{ height: 70 }}
                      />
                    </div>
                    {selectedBudtender
                      ? selectedBudtender.firstName +
                        " " +
                        selectedBudtender.lastName
                      : "loading"}
                  </div>
                </div>

                <div style={{ width: "85%", fontSize: 14, marginTop: 20 }}>
                  PLEASE ENTER YOUR PIN
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    marginBottom: 20,
                  }}
                >
                  <div style={{ marginLeft: 20 }} />
                  <PinTextInputField
                    value={pin1}
                    readOnly={pin1.length >= 4 ? true : false}
                    onChange={(e) => setPin1(e.target.value)}
                    placeholder={"0000"}
                    autoFocus={true}
                  />
                </div>

                <div
                  style={{
                    flex: 1,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      flex: 0.25,

                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <BaseButtons
                      label={"1"}
                      fontSize={25}
                      mini={true}
                      marginTopFalse={true}
                      size={90}
                      height={70}
                      borderRadius={8}
                      onClick={() => setPin1(pin1 + "1")}
                      marginRight={10}
                    />
                    <BaseButtons
                      label={"2"}
                      fontSize={25}
                      mini={true}
                      marginTopFalse={true}
                      size={90}
                      height={70}
                      borderRadius={8}
                      onClick={() => setPin1(pin1 + "2")}
                      marginRight={10}
                    />
                    <BaseButtons
                      label={"3"}
                      fontSize={25}
                      mini={true}
                      marginTopFalse={true}
                      size={90}
                      height={70}
                      borderRadius={8}
                      onClick={() => setPin1(pin1 + "3")}
                    />
                  </div>
                  <div
                    style={{
                      flex: 0.25,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <BaseButtons
                      label={"4"}
                      fontSize={25}
                      mini={true}
                      marginTopFalse={true}
                      size={90}
                      height={70}
                      borderRadius={8}
                      onClick={() => setPin1(pin1 + "4")}
                      marginRight={10}
                    />
                    <BaseButtons
                      label={"5"}
                      fontSize={25}
                      mini={true}
                      marginTopFalse={true}
                      size={90}
                      height={70}
                      borderRadius={8}
                      onClick={() => setPin1(pin1 + "5")}
                      marginRight={10}
                    />
                    <BaseButtons
                      label={"6"}
                      fontSize={25}
                      mini={true}
                      marginTopFalse={true}
                      size={90}
                      height={70}
                      borderRadius={8}
                      onClick={() => setPin1(pin1 + "6")}
                    />
                  </div>
                  <div
                    style={{
                      flex: 0.25,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <BaseButtons
                      label={"7"}
                      fontSize={25}
                      mini={true}
                      marginTopFalse={true}
                      size={90}
                      height={70}
                      borderRadius={8}
                      onClick={() => setPin1(pin1 + "7")}
                      marginRight={10}
                    />
                    <BaseButtons
                      label={"8"}
                      fontSize={25}
                      mini={true}
                      marginTopFalse={true}
                      size={90}
                      height={70}
                      borderRadius={8}
                      onClick={() => setPin1(pin1 + "8")}
                      marginRight={10}
                    />
                    <BaseButtons
                      label={"9"}
                      fontSize={25}
                      mini={true}
                      marginTopFalse={true}
                      size={90}
                      height={70}
                      borderRadius={8}
                      onClick={() => setPin1(pin1 + "9")}
                    />
                  </div>
                  <div
                    style={{
                      flex: 0.25,

                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <BaseButtons
                      label={"0"}
                      fontSize={25}
                      mini={true}
                      marginTopFalse={true}
                      size={200}
                      height={70}
                      borderRadius={8}
                      onClick={() => setPin1(pin1 + "0")}
                      marginRight={14}
                    />
                    <BaseButtons
                      label={"BACK"}
                      fontSize={20}
                      mini={true}
                      marginTopFalse={true}
                      size={77}
                      height={70}
                      borderRadius={8}
                      onClick={() =>
                        setPin1(pin1.substring(0, pin1.length - 1))
                      }
                    />
                  </div>
                </div>
              </>
            ) : null}

            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20,
                marginBottom: 15,
              }}
            >
              <BaseButtons
                label={"CANCEL"}
                mini={true}
                marginTopFalse={true}
                // marginRight={selectedBudtender ? 20 : 0}
                borderRadius={8}
                size={"90%"}
                onClick={() => {
                  setPinPopUp(false);
                  setSelectedBudTender(null);
                  setPin1("");
                }}
              />
              {/* {selectedBudtender ? (
              <BaseButtons
                label={"CONFIRM"}
                mini={true}
                marginTopFalse={true}
                borderRadius={8}
                size={"45%"}
                onClick={() => setUserVerified("true")}
              />
            ) : null} */}
            </div>
          </div>
        </div>
      ) : null}
      {/* <pinCheckPopUp
        display={pinCheckPopUp}
        setPinCheckPopUp={setPinCheckPopUp}
      /> */}
      <CashRegisterViewPopUp
        setTillAmount={setTillAmount}
        tillamount={tillamount}
        display={cashRegisterOpen}
        setPinCheckPopUp={setPinCheckPopUp}
        setCashRegisterOpen={setCashRegisterOpen}
        user={user}
        setLoading={setLoading}
        setToastPop={setToastPop}
        setMessage={setMessage}
        onOpenClick={() => OpenCashRegoster()}
      />
      <PdfDisplay
        display={selectedReport}
        onCloseClick={() => {
          setSelectedReport(null);
          setSelectedReportIndex(null);
        }}
        selectedReportIndex={selectedReportIndex}
      />
      <CashRegisterCount
        setCashRegisterOpenCheck={setCashRegisterOpenCheck}
        setLoading={setLoading}
        setMessage={setMessage}
        setToastPop={setToastPop}
        setCashRegisterReset={setCashRegisterReset}
        cashRegisterReset={cashRegisterReset}
        selectedReport={selectedReport}
        selectedBudtender={selectedBudtender}
        setCashRegsiterStatus={setCashRegsiterStatus}
        setOPenedBy={setOPenedBy}
        setDateAndTimeOpened={setDateAndTimeOpened}
        display={countTill}
        setCountTill={setCountTill}
        onCloseClick={() => {
          setCountTill(null);
        }}
      />
    </div>
  );
};

export default CashRegister;
