import React, { useState } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";

const ProductHeaderKnowledge = ({ onClick, marginTop }) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      style={{
        width: "100%",
        minHeight: 50,
        maxHeight: 50,
        display: "flex",
        borderTopColor: BlackAndWhite.primary,
        backgroundColor: BlackAndWhite.primary,
        color: BlackAndWhite.secondary,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        marginTop: marginTop,
        alignItems: "center",
        fontSize: 12,
      }}
    >
      <div
        style={{
          marginLeft: 20,
          height: "100%",
          width: 50,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: 0.05,
        }}
      ></div>
      <div style={{ marginLeft: 20, flex: 0.3, textAlign: "left" }}>
        PRODUCT NAME
      </div>
      <div style={{ flex: 0.2, textAlign: "center" }}>CATEGORY</div>
      <div style={{ flex: 0.2 }}>SUB-TYPE</div>
      <div style={{ flex: 0.15 }}>SKU</div>

      <div style={{ flex: 0.1, textAlign: "center", marginRight: 5 }}>
        PREFRENECE
      </div>
      <div style={{ flex: 0.1, textAlign: "center" }}>FEATURE</div>
      <div style={{ flex: 0.1, textAlign: "center" }}>SIZE</div>
      <div style={{ flex: 0.1, textAlign: "center" }}>MEASURE</div>
      <div style={{ flex: 0.15, textAlign: "center" }}>PRICE</div>
    </div>
  );
};

export default ProductHeaderKnowledge;
