import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import {
  BaseButtons,
  CloseButton,
  PinTextInputField,
  TextInputField,
} from "../../../../../../components/atoms";
import ChangeCreditTip from "../change-credit-tip/ChangeCreditTip";
import NumberSpace from "../../../../../global-functions/NumberSpace";

const CompleteSalePopUp = ({
  display,
  total,
  setCompleteSale,
  setLoading,
  user,
  setMessage,
  setToastPop,
  selectedUser,
  setRecieptList,
  setSelectedUser,
  setRefresh,
  refresh,
  setSelectedSubCategory,
  selectedSubCategory,
  selectedCategory,
  setSelectedCategory,
  setSearch,
  setCustomers,
  filterOption,
  setFilterOption,
  productsArray,
  discount,
  tax,
  subTotal,
  setDiscount,
  setDiscountAmount,
  storeInfo,
  setStoreInfo,
  cashRegisterOpenCheck,
  promotionDiscount,
  setPromotionDiscount,
}) => {
  const [paidSoFar, setPaidSoFar] = useState(0);
  const [amount, setAmount] = useState(0);
  const [selectedPayment, seSelectedPayment] = useState("CASH");

  let discountComplete = Number(discount) + Number(promotionDiscount);

  const [selectedBudtender, setSelectedBudTender] = useState(null);

  const [clicked, setClicked] = useState(false);

  const [excessPayment, setExcessPayment] = useState(false);

  const [storeUsers, setStoreUsers] = useState(null);

  const [method, setMethod] = useState("change");

  const [pin1, setPin1] = useState("");
  const [pin2, setPin2] = useState("");
  const [pin3, setPin3] = useState("");
  const [pin4, setPin4] = useState("");

  const [pin1Focus, setPin1Focus] = useState(true);
  const [pin2Focus, setPin2Focus] = useState(false);
  const [pin3Focus, setPin3Focus] = useState(false);
  const [pin4Focus, setPin4Focus] = useState(false);

  const [userVerfiied, setUserVerified] = useState(null);

  const [paymentStructureArray, setPaymentStructureArray] = useState([]);
  //   let paymentStructureArray = [];

  const NextPayment = () => {
    let amountDesin = Number(amount);
    let amountToBePaid = total - amountDesin;

    paymentStructureArray.push({
      item: { amount: amountDesin, type: selectedPayment },
    });

    setPaidSoFar(Number(paidSoFar) + amountDesin);
    setAmount(0);
  };

  const checkCreditBeenUsed = () => {
    let indicator = false;
    for (let index = 0; index < paymentStructureArray.length; index++) {
      const element = paymentStructureArray[index];
      if (element.item.type === "CREDIT") {
        indicator = true;
      }
    }
    return indicator;
  };

  const GetStoreUsers = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/processSale/getAllStoreUsers",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storeName: user.adminUserData.storeName,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setStoreUsers(responseData.storeUsers);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };
  const ConfirmAccess = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/processSale/checkUserAccessPin",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storeName: user.adminUserData.storeName,
            userId: selectedBudtender._id,
            pin: pin1,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setUserVerified(true);
        setLoading(false);
      } else {
        setLoading(false);
        setMessage(responseData.message);
        setToastPop(true);
      }
    } catch (err) {
      setLoading(false);
      setMessage("No network try again later");
      setToastPop(true);
      console.log("error", err);
    }
  };

  const CompletSaleCreateReciept = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/processSale/createRecipet",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storeName: user.adminUserData.storeName,
            userId: selectedBudtender._id,
            storeId: storeInfo.storeId,
            // cashier rates
            name:
              selectedBudtender.firstName + " " + selectedBudtender.lastName,

            Address:
              storeInfo.unitNumber +
              " , " +
              storeInfo.streetAddress +
              " , " +
              storeInfo.suburb +
              " , " +
              storeInfo.city +
              " , " +
              storeInfo.province +
              " , " +
              storeInfo.postalCode,
            contactNumber: storeInfo.landLine,
            //customer info
            customerName: selectedUser.firstName + " " + selectedUser.lastName,
            customerUserId: selectedUser._id,
            //products array
            productsArray: productsArray,
            //payment info
            discount: String(discountComplete),
            promotions: ["in progress"],
            paymentsStructure: paymentStructureArray,
            total: total,
            tax: tax,
            subTotal: subTotal,
            changeAmount: (paidSoFar.toFixed(2) - total.toFixed(2)).toFixed(2),
            changeOption:
              Number(paidSoFar).toFixed(2) === Number(total).toFixed(2)
                ? "NONE"
                : method,
            linkedRegisterId: cashRegisterOpenCheck.registerId,
          }),
        }
      );

      const responseData = await response.json();
      if (responseData.success) {
        setRecieptList([]);
        setSelectedUser(null);
        setExcessPayment(false);
        setUserVerified(false);
        setSelectedBudTender(null);
        setCompleteSale(false);
        setPin1("");
        setPaidSoFar(0);
        setAmount(0);
        setSelectedCategory(null);
        setSelectedSubCategory(null);
        setSearch("");
        setCustomers(null);
        setRefresh(refresh ? false : true);
        setFilterOption("CATEGORY");
        setLoading(false);
        setPaymentStructureArray([]);
        setDiscountAmount(0);
        setDiscount(0);
        seSelectedPayment("CASH");
        setPromotionDiscount(0);
        setClicked(false);
      } else {
        setLoading(false);
        setMessage(responseData.message);
        setToastPop(true);
        setClicked(false);
      }
    } catch (err) {
      setLoading(false);
      setMessage("No network try again later");
      setToastPop(true);
      setClicked(false);
    }
  };

  useEffect(() => {
    if (pin1.length === 4) {
      ConfirmAccess();
    }
  }, [pin1]);

  useEffect(() => {
    GetStoreUsers();

    setAmount(total);

    //get all store users
  }, [display]);
  useEffect(() => {
    if (selectedPayment === "CREDIT" && selectedUser) {
      if (Number(selectedUser.rewardPoints) >= total) {
        setAmount(Number(total - paidSoFar).toFixed(2));
      } else if (Number(selectedUser.rewardPoints) < total - paidSoFar) {
        setAmount(Number(selectedUser.rewardPoints).toFixed(2));
      }
    }

    if (selectedPayment === "CASH" && selectedUser) {
      setAmount(Number(total - paidSoFar).toFixed(2));
    }

    if (selectedPayment === "CARD" && selectedUser) {
      setAmount(Number(total - paidSoFar).toFixed(2));
    }
    //get all store users
  }, [selectedPayment]);
  return (
    <div
      style={{
        position: "fixed",
        height: display ? "100%" : 0,
        width: "100%",
        backgroundColor: "#00000050",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        top: 0,
        left: 0,
        zIndex: 30000000000,
      }}
    >
      {selectedBudtender && userVerfiied ? (
        excessPayment ? (
          <ChangeCreditTip
            method={method}
            setMethod={setMethod}
            CompletSaleCreateReciept={CompletSaleCreateReciept}
            setRefresh={setRefresh}
            refresh={refresh}
            setRecieptList={setRecieptList}
            setSelectedUser={setSelectedUser}
            paidSoFar={paidSoFar}
            total={total}
            setExcessPayment={setExcessPayment}
            setUserVerified={setUserVerified}
            setPin1={setPin1}
            setPaidSoFar={setPaidSoFar}
            setAmount={setAmount}
            setSelectedBudTender={setSelectedBudTender}
            setCompleteSale={setCompleteSale}
            setSelectedSubCategory={setSelectedSubCategory}
            selectedSubCategory={selectedSubCategory}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            setSearch={setSearch}
            setCustomers={setCustomers}
            setFilterOption={setFilterOption}
            filterOption={filterOption}
            clicked={clicked}
            setClicked={setClicked}
          />
        ) : (
          <>
            <div
              style={{
                paddingTop: 20,
                paddingBottom: 20,
                backgroundColor: BlackAndWhite.secondary,
                borderRadius: 10,
                display: "flex",
                flexDirection: "row",
                position: "relative",
                overflow: "hidden",
                width: 600,
                minHeight: selectedPayment === "CREDIT" ? null : 800,
                // paddingRight: 20,
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 20,
                  left: 20,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CloseButton
                  label={"X"}
                  onClick={
                    userVerfiied
                      ? () => {
                          setUserVerified(false);
                          setSelectedBudTender(null);
                          setCompleteSale(false);
                          setPin1("");
                          setPaidSoFar(0);
                          setAmount(0);
                          seSelectedPayment("CASH");
                        }
                      : () => setCompleteSale(false)
                  }
                />
                <div style={{ marginLeft: 20, fontSize: 20 }}>
                  SELECT AMOUNT & PAYMENT OPTION
                </div>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div style={{ minHeight: 70 }} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "75%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      marginBottom: 10,
                      width: "100%",
                    }}
                  >
                    <div style={{ flex: 0.5 }}>
                      <div style={{ fontSize: 14, marginBottom: 10 }}>
                        AMOUNT OUTSTANDING
                      </div>{" "}
                      <div style={{ fontSize: 38 }}>
                        R
                        {NumberSpace({
                          value: String(
                            (total.toFixed(2) - Number(paidSoFar)).toFixed(2)
                          ),
                        })}
                      </div>
                    </div>
                    <div
                      style={{
                        flex: 0.5,

                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        textAlign: "right",
                      }}
                    >
                      <div style={{ fontSize: 14, marginBottom: 10 }}>
                        AMOUNT PAID
                      </div>{" "}
                      <div style={{ fontSize: 38 }}>
                        R{" "}
                        {NumberSpace({
                          value: String(paidSoFar.toFixed(2)),
                        })}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: 54,
                    }}
                  >
                    <div style={{ marginTop: 35, marginRight: 20 }}>R</div>{" "}
                    <TextInputField
                      tenderedAmountBlock={true}
                      labelTitle={"AMOUNT PAYING"}
                      mini={true}
                      invert={true}
                      type={"number"}
                      value={Number(amount).toFixed(2)}
                      fontsize={54}
                      onChange={(e) => setAmount(e.target.value)}
                      height={100}
                      size={300}
                      autoFocus={true}
                    />
                  </div>
                </div>
                {selectedPayment === "CREDIT" ? (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "75%",
                        alignItems: "center",
                        justifyContent: "center",
                        height: 60,
                        WebkitBoxShadow: "0px 0px 7px 2px #00000050",
                        borderRadius: 8,
                      }}
                    >
                      <div style={{ marginRight: 20 }}>
                        {selectedUser
                          ? selectedUser.firstName + " " + selectedUser.lastName
                          : null}
                      </div>

                      {selectedUser
                        ? "CREDIT AVALIBLE: R " +
                          Number(selectedUser.rewardPoints).toFixed(2)
                        : null}
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      flex: 0.9,
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 20,
                    }}
                  >
                    <div
                      style={{
                        flex: 0.33,

                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <BaseButtons
                        label={"1"}
                        fontSize={25}
                        mini={true}
                        marginTopFalse={true}
                        size={90}
                        height={70}
                        borderRadius={8}
                        onClick={() => setAmount(amount + "1")}
                        marginRight={10}
                      />
                      <BaseButtons
                        label={"2"}
                        fontSize={25}
                        mini={true}
                        marginTopFalse={true}
                        size={90}
                        height={70}
                        borderRadius={8}
                        onClick={() => setAmount(amount + "2")}
                        marginRight={10}
                      />
                      <BaseButtons
                        label={"3"}
                        fontSize={25}
                        mini={true}
                        marginTopFalse={true}
                        size={90}
                        height={70}
                        borderRadius={8}
                        onClick={() => setAmount(amount + "3")}
                        marginRight={10}
                      />
                      <BaseButtons
                        label={"0"}
                        fontSize={25}
                        mini={true}
                        marginTopFalse={true}
                        size={90}
                        height={70}
                        borderRadius={8}
                        onClick={() => setAmount(amount + "0")}
                      />
                    </div>
                    <div
                      style={{
                        flex: 0.33,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <BaseButtons
                        label={"4"}
                        fontSize={25}
                        mini={true}
                        marginTopFalse={true}
                        size={90}
                        height={70}
                        borderRadius={8}
                        onClick={() => setAmount(amount + "4")}
                        marginRight={10}
                      />
                      <BaseButtons
                        label={"5"}
                        fontSize={25}
                        mini={true}
                        marginTopFalse={true}
                        size={90}
                        height={70}
                        borderRadius={8}
                        onClick={() => setAmount(amount + "5")}
                        marginRight={10}
                      />
                      <BaseButtons
                        label={"6"}
                        fontSize={25}
                        mini={true}
                        marginTopFalse={true}
                        size={90}
                        height={70}
                        borderRadius={8}
                        onClick={() => setAmount(amount + "6")}
                        marginRight={10}
                      />

                      <BaseButtons
                        label={"."}
                        fontSize={35}
                        mini={true}
                        marginTopFalse={true}
                        size={90}
                        height={70}
                        borderRadius={8}
                        onClick={() => setAmount(amount + ".")}
                        // marginRight={10}
                      />
                    </div>
                    <div
                      style={{
                        flex: 0.33,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <BaseButtons
                        label={"7"}
                        fontSize={25}
                        mini={true}
                        marginTopFalse={true}
                        size={90}
                        height={70}
                        borderRadius={8}
                        onClick={() => setAmount(amount + "7")}
                        marginRight={10}
                      />
                      <BaseButtons
                        label={"8"}
                        fontSize={25}
                        mini={true}
                        marginTopFalse={true}
                        size={90}
                        height={70}
                        borderRadius={8}
                        onClick={() => setAmount(amount + "8")}
                        marginRight={10}
                      />
                      <BaseButtons
                        label={"9"}
                        fontSize={25}
                        mini={true}
                        marginTopFalse={true}
                        size={90}
                        height={70}
                        borderRadius={8}
                        onClick={() => setAmount(amount + "9")}
                        marginRight={10}
                      />

                      <BaseButtons
                        label={"REMOVE"}
                        fontSize={12}
                        mini={true}
                        marginTopFalse={true}
                        size={90}
                        height={70}
                        borderRadius={8}
                        onClick={() => setAmount("0")}
                      />
                    </div>
                  </div>
                )}
                <div style={{ display: "flex", marginTop: 20, width: "75%" }}>
                  PAYMENT OPTION
                </div>
                <div
                  style={{ display: "flex", paddingLeft: 20, paddingRight: 20 }}
                >
                  <BaseButtons
                    mini={true}
                    label={"CASH"}
                    onClick={() => seSelectedPayment("CASH")}
                    invert={selectedPayment === "CASH" ? false : true}
                    borderRadius={8}
                    marginRight={10}
                  />
                  <BaseButtons
                    mini={true}
                    label={"CARD"}
                    onClick={() => seSelectedPayment("CARD")}
                    invert={selectedPayment === "CARD" ? false : true}
                    borderRadius={8}
                    marginRight={10}
                  />
                  {checkCreditBeenUsed() ? null : (
                    <BaseButtons
                      mini={true}
                      label={"CREDIT"}
                      onClick={() => seSelectedPayment("CREDIT")}
                      invert={selectedPayment === "CREDIT" ? false : true}
                      borderRadius={8}
                      // marginRight={10}
                    />
                  )}
                </div>

                {Number(paidSoFar) < total - 1 &&
                Number(paidSoFar) + Number(amount) <= Number(total) ? (
                  Number(paidSoFar) + Number(amount) === total ? null : (
                    <BaseButtons
                      borderRadius={8}
                      mini={true}
                      label={"NEXT PAYMENT"}
                      onClick={() => NextPayment()}
                      size={"75%"}
                      disable={
                        selectedPayment === "CREDIT" &&
                        Number(selectedUser.rewardPoints) <= 0
                          ? true
                          : false
                      }
                    />
                  )
                ) : null}

                {paidSoFar === total ||
                Number(amount) >= total ||
                Number(amount) + paidSoFar >= total ? (
                  <BaseButtons
                    mini={true}
                    label={"PROCESS SALE"}
                    size={"75%"}
                    onClick={() => {
                      setExcessPayment(true);
                      NextPayment();
                    }}
                    borderRadius={8}
                  />
                ) : null}
              </div>
            </div>
          </>
        )
      ) : (
        <div
          style={{
            height: 700,
            width: 500,
            backgroundColor: BlackAndWhite.secondary,
            borderRadius: 10,
            display: "flex",
            flexDirection: "column",
            position: "relative",
            overflow: "hidden",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "90%",
              marginTop: 20,
              marginBottom: 10,
              fontSize: 18,
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            SELECT USER AND ENTER YOUR PIN
          </div>
          {selectedBudtender ? null : (
            <div
              style={{
                display: "flex",
                width: "100%",
                minHeight: 300,
                maxHeight: 700,
                flex: 1,
                flexDirection: "column",
              }}
            >
              {storeUsers
                ? storeUsers.map((user, index) => {
                    return (
                      <div
                        onClick={() => setSelectedBudTender(user)}
                        key={index}
                        style={{
                          width: "99%",
                          height: 70,

                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          marginBottom: 10,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "95%",
                            width: "90%",
                            backgroundColor: BlackAndWhite.secondary,
                            WebkitBoxShadow: "0px 0px 7px 2px #00000050",
                            borderRadius: 8,
                          }}
                        >
                          {user.firstName + " " + user.lastName}
                        </div>
                      </div>
                    );
                  })
                : "Loading"}
            </div>
          )}

          {selectedBudtender ? (
            <>
              <div
                onClick={() => {
                  setSelectedBudTender(null);
                  setPin1("");
                }}
                style={{
                  height: 100,
                  width: "100%",

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "85%",
                    borderStyle: "solid",
                    height: 90,
                    borderRadius: 8,
                    display: "flex",
                    alignItems: "center",
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      height: "100%",
                      width: 100,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt="image"
                      src={require("../../../../../../images/material-icons/profileBlack.png")}
                      style={{ height: 70 }}
                    />
                  </div>
                  {selectedBudtender
                    ? selectedBudtender.firstName +
                      " " +
                      selectedBudtender.lastName
                    : "loading"}
                </div>
              </div>

              <div style={{ width: "85%", fontSize: 14, marginTop: 20 }}>
                INPUT PIN
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  marginBottom: 20,
                }}
              >
                <div style={{ marginLeft: 20 }} />
                <PinTextInputField
                  value={pin1}
                  readOnly={pin1.length >= 4 ? true : false}
                  onChange={(e) => setPin1(e.target.value)}
                  placeholder={"_ _ _ _"}
                  autoFocus={true}
                />
                {/* <TextInputField
                  mini={true}
                  size={30}
                  invert={true}
                  width={80}
                  height={60}
                  type={"number"}
                  fontsize={24}
                  value={pin1}
                  onChange={(e) => OnchangeReturnPin1({ e })}
                  placeholder={"0"}
                  autoFocus={pin1Focus}
                /> */}
                {/* <TextInputField
                  mini={true}
                  size={30}
                  invert={true}
                  width={80}
                  height={60}
                  type={"number"}
                  fontsize={24}
                  value={pin2}
                  onChange={(e) => setPin2(e.target.value)}
                  placeholder={"0"}
                />
                <TextInputField
                  mini={true}
                  size={30}
                  invert={true}
                  width={80}
                  height={60}
                  type={"number"}
                  fontsize={24}
                  value={pin3}
                  onChange={(e) => setPin3(e.target.value)}
                  placeholder={"0"}
                />
                <TextInputField
                  mini={true}
                  size={30}
                  invert={true}
                  width={80}
                  height={60}
                  type={"number"}
                  fontsize={24}
                  value={pin4}
                  onChange={(e) => setPin4(e.target.value)}
                  placeholder={"0"}
                  autoFocus={pin4Focus}
                /> */}
              </div>

              <div
                style={{
                  flex: 1,
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    flex: 0.25,

                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BaseButtons
                    label={"1"}
                    fontSize={25}
                    mini={true}
                    marginTopFalse={true}
                    size={90}
                    height={70}
                    borderRadius={8}
                    onClick={() => setPin1(pin1 + "1")}
                    marginRight={10}
                  />
                  <BaseButtons
                    label={"2"}
                    fontSize={25}
                    mini={true}
                    marginTopFalse={true}
                    size={90}
                    height={70}
                    borderRadius={8}
                    onClick={() => setPin1(pin1 + "2")}
                    marginRight={10}
                  />
                  <BaseButtons
                    label={"3"}
                    fontSize={25}
                    mini={true}
                    marginTopFalse={true}
                    size={90}
                    height={70}
                    borderRadius={8}
                    onClick={() => setPin1(pin1 + "3")}
                  />
                </div>
                <div
                  style={{
                    flex: 0.25,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BaseButtons
                    label={"4"}
                    fontSize={25}
                    mini={true}
                    marginTopFalse={true}
                    size={90}
                    height={70}
                    borderRadius={8}
                    onClick={() => setPin1(pin1 + "4")}
                    marginRight={10}
                  />
                  <BaseButtons
                    label={"5"}
                    fontSize={25}
                    mini={true}
                    marginTopFalse={true}
                    size={90}
                    height={70}
                    borderRadius={8}
                    onClick={() => setPin1(pin1 + "5")}
                    marginRight={10}
                  />
                  <BaseButtons
                    label={"6"}
                    fontSize={25}
                    mini={true}
                    marginTopFalse={true}
                    size={90}
                    height={70}
                    borderRadius={8}
                    onClick={() => setPin1(pin1 + "6")}
                  />
                </div>
                <div
                  style={{
                    flex: 0.25,
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BaseButtons
                    label={"7"}
                    fontSize={25}
                    mini={true}
                    marginTopFalse={true}
                    size={90}
                    height={70}
                    borderRadius={8}
                    onClick={() => setPin1(pin1 + "7")}
                    marginRight={10}
                  />
                  <BaseButtons
                    label={"8"}
                    fontSize={25}
                    mini={true}
                    marginTopFalse={true}
                    size={90}
                    height={70}
                    borderRadius={8}
                    onClick={() => setPin1(pin1 + "8")}
                    marginRight={10}
                  />
                  <BaseButtons
                    label={"9"}
                    fontSize={25}
                    mini={true}
                    marginTopFalse={true}
                    size={90}
                    height={70}
                    borderRadius={8}
                    onClick={() => setPin1(pin1 + "9")}
                  />
                </div>
                <div
                  style={{
                    flex: 0.25,

                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BaseButtons
                    label={"0"}
                    fontSize={25}
                    mini={true}
                    marginTopFalse={true}
                    size={200}
                    height={70}
                    borderRadius={8}
                    onClick={() => setPin1(pin1 + "0")}
                    marginRight={14}
                  />
                  <BaseButtons
                    label={"REMOVE"}
                    fontSize={12}
                    mini={true}
                    marginTopFalse={true}
                    size={77}
                    height={70}
                    borderRadius={8}
                    onClick={() => setPin1(pin1.substring(0, pin1.length - 1))}
                  />
                </div>
              </div>
            </>
          ) : null}

          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
              marginBottom: 15,
            }}
          >
            <BaseButtons
              label={"CANCEL"}
              mini={true}
              marginTopFalse={true}
              // marginRight={selectedBudtender ? 20 : 0}
              borderRadius={8}
              size={"90%"}
              onClick={() => {
                setCompleteSale(false);
                setSelectedBudTender(null);
                setPin1("");
              }}
            />
            {/* {selectedBudtender ? (
              <BaseButtons
                label={"CONFIRM"}
                mini={true}
                marginTopFalse={true}
                borderRadius={8}
                size={"45%"}
                onClick={() => setUserVerified("true")}
              />
            ) : null} */}
          </div>
        </div>
      )}
    </div>
  );
};

export default CompleteSalePopUp;
