import React, { useState, useEffect } from "react";
import { InventoryProductCard } from "./components";
import { BaseButtons } from "../../../../../../components/atoms";

const StoresInventoryDisplayCard = ({
  data,
  storeName,
  productTotal,
  inStoreValue,
  type,
  store,
  auth,
  warehouseInventoryList,
  search,
}) => {
  const [hover, setHover] = useState(false);
  const [drop, setDrop] = useState(false);

  const [storeInventoryList, setStoreInventoryList] = useState([]);
  const [loading, setloading] = useState(false);

  const ReturnStockAmount = ({ productId }) => {
    let stock = 0;
    if (storeInventoryList) {
      storeInventoryList.map((item) =>
        item.productId === productId ? (stock = item) : null
      );
    }

    return stock;
  };

  const NameAssemble = ({ product }) => {
    if (product) {
      let name =
        product.productName +
        " | " +
        product.productSubType +
        " | " +
        product.thcType +
        " | " +
        product.thcContent;
      return name;
    }
  };

  const getStoreProducts = async () => {
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/inventory/getStoreInventory",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storeId: store.storeId,
          }),
        }
      );

      const respnseData = await response.json();

      if (respnseData.success) {
        // const wait = await updateListFetch();
        setStoreInventoryList(respnseData.inventoryStoreList);
      } else if (respnseData.message) {
        setStoreInventoryList([]);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const ActivateInventory = async () => {
    // authentication or login function heal

    // https://merry-jane-api.onrender.com/cannaPos/reg
    setloading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/inventory/createStoreInventory",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storeId: store.storeId,
            userId: auth.userId,
          }),
        }
      );

      const respnseData = await response.json();

      if (respnseData.success) {
        // const wait = await updateListFetch();

        setloading(false);
      } else if (respnseData.message) {
        setStoreInventoryList([]);
        setloading(false);
      }
    } catch (err) {
      console.log("err", err);
      setloading(false);
    }
  };

  useEffect(() => {
    if (!loading) {
      getStoreProducts();
    }
  }, [loading]);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        marginBottom: 2,
      }}
    >
      <div
        onClick={() => setDrop(drop ? false : true)}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        style={{
          width: "100%",
          height: 50,
          backgroundColor: hover
            ? drop
              ? "#Faa91980"
              : "#00000075"
            : drop
            ? "#FAA91970"
            : "#00000040",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          transition: "all 0.3s",
          fontWeight: hover ? "bold" : "normal",
        }}
      >
        <div style={{ marginLeft: 20, marginRight: 20 }}>{storeName}</div>
        <div style={{ flex: 1 }}></div>
        <div
          style={{
            fontSize: 14,
            marginRight: 20,
            display: "flex",
            fontWeight: "normal",
          }}
        >
          TYPE : <div style={{ marginLeft: 5 }}>{type}</div>
        </div>
        {storeInventoryList.length === 0 ? null : (
          <div
            style={{
              fontSize: 14,
              marginRight: 20,
              display: "flex",
              fontWeight: "normal",
            }}
          >
            IN STORE VALUE : <div style={{ marginLeft: 5 }}>"number here"</div>
          </div>
        )}
        {storeInventoryList.length === 0 ? null : (
          <div
            style={{
              fontSize: 14,
              marginRight: 20,
              display: "flex",
              fontWeight: "normal",
            }}
          >
            TOTAL PRODUCTS :{" "}
            <div style={{ marginLeft: 5 }}>
              {storeInventoryList ? storeInventoryList.length : "loading"}
            </div>
          </div>
        )}

        <BaseButtons
          label={
            storeInventoryList.length >= 1
              ? loading
                ? "LOADING"
                : "UPDATE"
              : loading
              ? "LOADING"
              : "ACTIVATE"
          }
          mini={true}
          marginTopFalse={true}
          borderRadius={8}
          onClick={() => ActivateInventory()}
          disable={loading}
          height={35}
          marginRight={10}
        />
      </div>
      {drop
        ? warehouseInventoryList
          ? warehouseInventoryList.map((product, index) => {
              return NameAssemble({ product })
                .toLocaleLowerCase()
                .match(search) ? (
                <div
                  style={{ width: "100%", transition: "all 0.3s" }}
                  key={index}
                >
                  <InventoryProductCard
                    info={product}
                    index={index}
                    product={ReturnStockAmount({ productId: product._id })}
                    auth={auth}
                    store={store}
                  />
                </div>
              ) : null;
            })
          : null
        : null}
    </div>
  );
};

export default StoresInventoryDisplayCard;
