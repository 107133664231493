import React, { useState } from "react";
import Calendar from "react-calendar";
import { BlackAndWhite } from "../../themes/Colors";

// type ValuePiece = Date | null;

// type Value = ValuePiece | [ValuePiece, ValuePiece];

const CalenderSelector = ({
  startingDate,
  setStartingDate,
  setDateSelectorPopUp,
}) => {
  //   const [value, setStartingDate] = useState(new Date());

  return (
    <div
      style={{
        position: "absolute",
        width: 300,
        backgroundColor: BlackAndWhite.secondary,
        padding: 10,
        borderRadius: 8,
        WebkitBoxShadow: "0px 0px 7px 2px #00000050",
        zIndex: 200,
      }}
    >
      <Calendar
        onChange={(e) => {
          setStartingDate(e);
          setDateSelectorPopUp(false);
        }}
        value={startingDate}
      />
    </div>
  );
};

export default CalenderSelector;
