import React, { useEffect, useState } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import {
  BaseButtons,
  CloseButton,
  TextAreaInput,
  TextInputField,
} from "../../../../../../components/atoms";

const EditPopUp = ({
  display,
  onCloseClick,
  selectedProduct,
  setLoading,
  setMessage,
  setToastPop,
  setSectionDisplay,
  setDisplayEditPopUp,
}) => {
  const [productName, setProduct] = useState(null);
  const [strain, setStrain] = useState(null);
  const [growType, setGrowType] = useState(null);
  const [thcContent, setThcContent] = useState(null);
  const [productType, setProductType] = useState(null);
  const [description, setDescription] = useState(null);
  const [menuDescription, setMenuDescription] = useState(null);
  const [useDescription, setUseDescription] = useState(null);
  const [costPrice, setCostPrice] = useState(null);
  const [retailPrice, setRetailPrice] = useState(null);

  const editProductHandler = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/productLine/updateProducts",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            // subCategoryId: info.subCategoryId,
            productId: selectedProduct.id,
            productName: productName || selectedProduct.productName,
            description: description || selectedProduct.description,
            menuDescription: menuDescription || selectedProduct.menuDescription,
            useDescription: useDescription || selectedProduct.useDescription,
            moreInfo: "nothing",
            strain: strain || selectedProduct.strain,
            growType: growType || selectedProduct.growType,
            thcContent: thcContent || selectedProduct.thcContent,
            productNo: selectedProduct.productNo,
            productType: productType || selectedProduct.productType,
            categoryId: selectedProduct.categoryId,
            subCategoryId: selectedProduct.subCategoryId,
            addedBy: selectedProduct.addedBy,
            retailPrice: retailPrice || selectedProduct.retailPrice,
            costPrice: costPrice || selectedProduct.costPrice,
          }),
        }
      );

      const respnseData = await response.json();

      if (respnseData.updateProduct) {
        // const wait = await updateListFetch();
        setSectionDisplay("catDisplay");
        setLoading(false);
        setDisplayEditPopUp(false);
      } else if (respnseData.message) {
        setToastPop(true);
        setMessage(respnseData.message);
        setLoading(false);
      } else {
        setToastPop(true);
        setMessage("error occured please contact support");
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setMessage(
        "could not edit product please try again later, if process is not working contact support."
      );
      setToastPop(true);
    }
  };

  useEffect(() => {
    if (selectedProduct) {
      setProduct(selectedProduct.productName);
      setStrain(selectedProduct.strain);
      setGrowType(selectedProduct.growType);
      setThcContent(selectedProduct.thcContent);
      setProductType(selectedProduct.productType);
      setDescription(selectedProduct.description);
      setMenuDescription(selectedProduct.menuDescription);
      setUseDescription(selectedProduct.useDescription);
      setCostPrice(selectedProduct.costPrice);
      setRetailPrice(selectedProduct.retailPrice);
    }
  }, [display, selectedProduct]);
  return (
    <div
      style={{
        height: display ? "100%" : 0,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        overflow: "hidden",
        backgroundColor: "#000000cc",
        transition: "all 0.4s",
      }}
    >
      <div
        style={{
          height: "95%",
          width: "95%",
          backgroundColor: BlackAndWhite.secondary,
          borderRadius: 8,
          position: "relative",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={{ flex: 0.5 }}>
          <TextInputField
            labelTitle={"Product name:"}
            invert={true}
            size={"70%"}
            value={productName ? productName : "loading"}
            onChange={(e) => setProduct(e.target.value)}
          />
          <TextInputField
            labelTitle={"Strain :"}
            invert={true}
            size={"70%"}
            value={strain ? strain : "loading"}
            onChange={(e) => setStrain(e.target.value)}
          />
          <TextInputField
            labelTitle={"Grow type :"}
            invert={true}
            size={"70%"}
            value={growType ? growType : "loading"}
            onChange={(e) => setGrowType(e.target.value)}
          />
          <TextInputField
            labelTitle={"THC Content :"}
            invert={true}
            size={"70%"}
            value={thcContent ? thcContent : "loading"}
            onChange={(e) => setThcContent(e.target.value)}
          />
          <TextInputField
            labelTitle={"Product Type:"}
            invert={true}
            size={"70%"}
            value={productType ? productType : "loading"}
            onChange={(e) => setProductType(e.target.value)}
          />
        </div>
        <div style={{ flex: 0.5 }}>
          <TextAreaInput
            labelTitle={"description:"}
            invert={true}
            size={"70%"}
            value={description ? description : "loading"}
            color={BlackAndWhite.primary}
            height={100}
            onChange={(e) => setDescription(e.target.value)}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <TextAreaInput
              labelTitle={"Menu Description:"}
              invert={true}
              size={140}
              value={menuDescription ? menuDescription : "loading"}
              color={BlackAndWhite.primary}
              height={100}
              onChange={(e) => setMenuDescription(e.target.value)}
            />
            <TextAreaInput
              labelTitle={"Usage description:"}
              invert={true}
              size={140}
              value={useDescription ? useDescription : "loading"}
              color={BlackAndWhite.primary}
              height={100}
              onChange={(e) => setUseDescription(e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <TextInputField
              labelTitle={"Cost:"}
              invert={true}
              size={120}
              value={costPrice ? costPrice : "loading"}
              onChange={(e) => setCostPrice(e.target.value)}
            />
            <TextInputField
              labelTitle={"Retail:"}
              invert={true}
              size={120}
              value={retailPrice ? retailPrice : "loading"}
              onChange={(e) => setRetailPrice(e.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <BaseButtons label={"CANCEL"} onClick={onCloseClick} />
            <BaseButtons
              label={"SAVE CHANGES"}
              onClick={() => editProductHandler()}
            />
          </div>
        </div>
        <div style={{ position: "absolute", top: 10, left: 10 }}>
          <CloseButton label={"X"} onClick={onCloseClick} />
        </div>
      </div>
    </div>
  );
};

export default EditPopUp;
