import React, { useState } from "react";
import { BlackAndWhite } from "../../../themes/Colors";

const BaseButtons = ({
  width,
  label,
  onClick,
  height,
  invert,
  size,
  tab,
  selected,
  fontSize,
  padding,
  disable,
  marginTopFalse,
  marginRight,
  marginLeft,
  mini,
  marginTop,
  borderRadius,
  menuButton,
  backgroundColor,
  minHeight,
  borderWidth,
  fontWeight,
  fontColor,
}) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      onMouseOver={disable ? null : () => setHover(true)}
      onMouseOut={disable ? null : () => setHover(false)}
      onClick={disable ? null : onClick}
      style={{
        height: height ? height : 50,
        minHeight: minHeight,
        width: size ? size : 150,
        fontWeight: fontWeight,
        backgroundColor: backgroundColor
          ? backgroundColor
          : tab
          ? selected
          : invert
          ? hover
            ? BlackAndWhite.primary
            : BlackAndWhite.secondary
          : hover
          ? BlackAndWhite.secondary
          : BlackAndWhite.primary,
        marginTop: marginTopFalse
          ? null
          : mini
          ? marginTop
            ? marginTop
            : 30
          : 20,
        borderRadius: mini
          ? borderRadius
            ? borderRadius
            : 0
          : borderRadius
          ? borderRadius
          : 30,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: "all .3s",
        borderStyle: hover
          ? borderWidth !== 0
            ? "solid"
            : null
          : borderWidth
          ? "solid"
          : null,
        borderWidth: borderWidth ? borderWidth : 0.3,
        padding: padding,
        cursor: "pointer",
        opacity: disable ? 0.5 : 1,
        marginRight: marginRight ? marginRight : null,
        marginLeft: marginLeft ? marginLeft : null,
      }}
    >
      <div
        style={{
          color: invert
            ? hover
              ? BlackAndWhite.secondary
              : BlackAndWhite.primary
            : hover
            ? BlackAndWhite.primary
            : BlackAndWhite.secondary,
          fontWeight: "500",
          fontSize: fontSize ? fontSize : 14,
          fontFamily: "Montserrat",
          textAlign: menuButton ? "left" : "center",
          width: "100%",
        }}
      >
        <div
          style={{
            marginLeft: menuButton ? 20 : 0,
            fontWeight: fontWeight,
            color: fontColor,
          }}
        >
          {label}
        </div>
      </div>
    </div>
  );
};

export default BaseButtons;
