import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";
import {
  BaseButtons,
  CloseButton,
  TextInputField,
} from "../../../../components/atoms";
import {
  ProvinceSelectDropDown,
  StatusSelectDropDown,
  StoreDropDown,
  TypeSelectDropDown,
} from "./components";

const ManageUserPopUp = ({
  display,
  onCloseClick,
  selectedUser,
  setLoading,
  setRefresh,
  refresh,
  setMessage,
  setToastPop,
  setError,
  setUserSelected,
}) => {
  const [storeArray, setStoreArray] = useState(null);
  const [addUserPopUp, setAddUSerPopUp] = useState(false);
  const [selectedType, setSelectedType] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [cellNumber, setCellNumber] = useState("");
  const [email, setEmail] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [province, setProvince] = useState("none");
  const [accountNumber, setAccountNumber] = useState("");
  const [pin, setPin] = useState("");
  const [status, setStatus] = useState("none");
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedStoreId, setSelectedStoreId] = useState(null);

  const UpdateUser = async () => {
    setLoading(true);
    // setError("Loading");
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/cannaPos/editUser",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: selectedUser._id,
            firstName: firstName,
            lastName: lastName,
            cellNumber: cellNumber,
            email: email,
            idDoc: idNumber,
            province: province || selectedUser.province,
            storeName:
              selectedType === "owner"
                ? "Owner"
                : selectedType === "Admin"
                ? "N/A"
                : selectedStore,
            accountNumber: accountNumber,
            userName: email,
            type: selectedType,
            profileImage: "coming soon",
            storeId: selectedStoreId ? selectedStoreId.storeId : "NA",
            pin: pin,
            status: status,
          }),
        }
      );

      const respnseData = await response.json();

      if (respnseData.message === "user has been updated and saved") {
        setLoading(false);
        setToastPop(true);
        setUserSelected(null);
        setMessage(respnseData.message);
        setSelectedType("none");
        setRefresh(refresh ? false : true);
      } else {
        setToastPop(true);
        setMessage("Unable to update user" + " ," + respnseData.message);
        setError("invalid Data");
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setError("invalid credentials");
    }
  };

  useEffect(() => {
    fetch("https://merry-jane-api.onrender.com/facilities/getFacilities")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setStoreArray(resData);
      });
  }, [display]);
  useEffect(() => {
    if (selectedUser) {
      // selectedUser
      setFirstName(selectedUser.firstName);
      setLastName(selectedUser.lastName);
      setSelectedType(selectedUser.type);
      setCellNumber(selectedUser.cellNumber);
      setEmail(selectedUser.email);
      setSelectedStore(selectedUser.storeName);
      setSelectedStoreId(selectedUser.storeId);
      setIdNumber(selectedUser.idDoc);
      setProvince(selectedUser.province);
      setStatus(selectedUser.status);
      setPin(selectedUser.pin);
      setAccountNumber(selectedUser.accountNumber);
    }
  }, [display]);
  return (
    <div
      style={{
        width: "100%",
        height: display ? "100%" : 0,
        position: "absolute",
        top: 0,
        transition: "all 0.5s",
        backgroundColor: BlackAndWhite.secondary,
        overflow: "hidden",
        display: "flex",
      }}
    >
      <div style={{ position: "absolute", top: 10, left: 10 }}>
        <CloseButton label={"X"} onClick={onCloseClick} />
      </div>
      <div
        style={{ position: "absolute", top: 10, right: 10, display: "flex" }}
      >
        <BaseButtons
          label={"SAVE CHANGES"}
          onClick={() => UpdateUser()}
          marginTopFalse={true}
          height={40}
          // disable={
          //   selectedType !== "none" &&
          //   firstName.length >= 2 &&
          //   lastName.length >= 2 &&
          //   cellNumber.length === 10 &&
          //   email.length >= 7 &&
          //   idNumber.length === 13 &&
          //   province &&
          //   accountNumber.length >= 3 &&
          //   password === confirmPassword
          //     ? false
          //     : true
          // }
          marginRight={20}
        />
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          overflowY: "scroll",
          overflowX: "hidden",
          height: "100%",
          width: "100%",
          marginTop: 40,
        }}
      >
        <div
          style={{
            flex: 0.7,
          }}
        >
          <div
            style={{
              height: 160,
              width: "90%",
              WebkitBoxShadow: "0px 0px 10px 5px #00000030",
              marginTop: 30,
              display: "flex",
              marginLeft: 40,
              flexDirection: "column",
              //   justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div style={{ width: "100%", display: "flex" }}>
              <div
                style={{
                  marginTop: 20,
                  marginLeft: 20,
                  fontSize: 18,
                  fontWeight: "bold",
                  color: BlackAndWhite.primary,
                  marginBottom: 20,
                }}
              >
                ACCOUNT TYPE
              </div>
            </div>
            <TypeSelectDropDown
              data={["Cashier", "Admin", "Sub-Admin", "Doctor", "Owner"]}
              setSelectedType={setSelectedType}
              selectedType={selectedType}
            />
          </div>
          <div
            style={{
              height: 400,
              width: "90%",
              WebkitBoxShadow: "0px 0px 10px 5px #00000030",
              marginTop: 30,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              marginLeft: 40,
            }}
          >
            <div style={{ width: "100%", display: "flex" }}>
              <div
                style={{
                  marginTop: 20,
                  marginLeft: 20,
                  fontSize: 18,
                  fontWeight: "bold",
                  color: BlackAndWhite.primary,
                  marginBottom: 30,
                }}
              >
                PRIMARY INFORMATION
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextInputField
                labelTitle={"First Name"}
                mini={true}
                invert={true}
                width={"50%"}
                size={"70%"}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <TextInputField
                labelTitle={"Last Name"}
                mini={true}
                invert={true}
                width={"50%"}
                size={"70%"}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <TextInputField
                labelTitle={"Cell Number"}
                mini={true}
                invert={true}
                width={"50%"}
                size={"70%"}
                value={cellNumber}
                onChange={(e) => setCellNumber(e.target.value)}
              />
              <TextInputField
                labelTitle={"Email"}
                mini={true}
                invert={true}
                width={"50%"}
                size={"70%"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <TextInputField
                labelTitle={"Id-Number"}
                mini={true}
                invert={true}
                width={"50%"}
                size={"70%"}
                value={idNumber}
                onChange={(e) => setIdNumber(e.target.value)}
              />
              <div style={{ width: "50%" }}>
                <ProvinceSelectDropDown
                  province={province}
                  setProvince={setProvince}
                  data={[
                    "Gauteng",
                    "Free State",
                    "Western Cape",
                    "Eastern Cape",
                    "KwaZulu-Natal",
                    "Limpopo",
                    "Mpumalanga",
                    "Northern Cape",
                    "North West",
                  ]}
                />
              </div>
            </div>
          </div>
          {selectedType === "Owner" || selectedType === "Admin" ? null : (
            <div
              style={{
                height: 200,
                width: "90%",
                WebkitBoxShadow: "0px 0px 10px 5px #00000030",
                marginTop: 30,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: 40,
              }}
            >
              <div style={{ width: "100%", display: "flex" }}>
                <div
                  style={{
                    marginTop: 20,
                    marginLeft: 20,
                    fontSize: 18,
                    fontWeight: "bold",
                    color: BlackAndWhite.primary,
                    marginBottom: 30,
                  }}
                >
                  STORE INFORMATION
                </div>
              </div>
              <StoreDropDown
                data={storeArray ? storeArray.store : []}
                setSelectedStore={setSelectedStore}
                selectedStore={selectedStore}
                setSelectedStoreId={setSelectedStoreId}
              />
            </div>
          )}
        </div>
        {/* right sub sections */}
        <div
          style={{
            flex: 0.3,

            // display: "flex",
            // flexDirection: "column",
            // height: "100%",
            // justifyContent: "flex-start",
            // alignItems: "center",
          }}
        >
          <div
            style={{
              height:
                selectedType === "Owner" || selectedType === "Admin"
                  ? 585
                  : 920,
              width: "90%",
              WebkitBoxShadow: "0px 0px 10px 5px #00000030",
              marginTop: 30,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              transition: "all 0.4s",
            }}
          >
            <div style={{ width: "100%", display: "flex" }}>
              <div
                style={{
                  marginTop: 20,
                  marginLeft: 20,
                  fontSize: 18,
                  fontWeight: "bold",
                  color: BlackAndWhite.primary,
                  marginBottom: 30,
                }}
              >
                TERTIARY INFORMATION
              </div>
            </div>
            <StatusSelectDropDown
              data={[
                "Pending",
                "Active",
                "Blocked",
                "Inactive",
                "Frozen",
                "Fired",
                "Resigned",
              ]}
              setProvince={setStatus}
              province={status}
            />
            <div
              style={{
                width: "80%",
                textAlign: "left",
                fontSize: 12,
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              All Accounts will be pending untill first login by new user which
              activates account ...
            </div>
            <TextInputField
              invert={true}
              labelTitle={"Username" + " " + "(Read only field)"}
              readOnly={true}
              mini={true}
              value={email}
              width={"100%"}
              size={"70%"}
              labelWidth={200}
            />
            <TextInputField
              invert={true}
              labelTitle={"Account number"}
              mini={true}
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
              width={"100%"}
              size={"70%"}
              labelWidth={200}
            />
            <TextInputField
              invert={true}
              labelTitle={"Pin"}
              mini={true}
              width={"100%"}
              size={"70%"}
              labelWidth={200}
              value={pin}
              onChange={(e) => setPin(e.target.value)}
            />
          </div>
          <div style={{ padding: 50 }} />
        </div>
      </div>
    </div>
  );
};

export default ManageUserPopUp;
