import React, { useState, useEffect } from "react";
import { BaseButtons, CloseButton } from "../../../../../../components/atoms";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import { RecievingItem } from "./components";

const MultiSelectReceivingPopUp = ({
  display,
  setMultiRecieve,
  multiRecieveArray,
  multiRecieveArrayItem,
  auth,
  setMultiRecieveArrayItem,
  setUploadItemPopUp,
  setMessage,
  setToastPop,
  toastPop,
}) => {
  const [qtyArray, setqtyArray] = useState([]);
  const [stateCheck, setStateCheck] = useState(false);

  const removeSelectedItems = (e, index) => {
    var array = [...qtyArray]; // make a separate copy of the array

    array.splice(index, 1);
    setqtyArray(array);
  };
  const AddNewItemToArray = (product) => {
    let baseArray = qtyArray;
    // qtyArray.push(product);
    baseArray.push(product);
    setStateCheck(stateCheck ? false : true);
  };

  const ReturnArray = () => {
    return qtyArray;
  };

  const RecieveStock = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/inventory/warehouseRecieving",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            recievingStockProducts: qtyArray,
            createdBy: auth.firstName + " " + auth.lastName,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setMultiRecieveArrayItem([]);
        setqtyArray([]);
        setUploadItemPopUp(false);
        setMultiRecieve(false);
        setMessage(responseData.success);
        setToastPop(true);
      } else {
        setMessage(responseData.message);
        setToastPop(true);
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  useEffect(() => {
    if (qtyArray.length >= 1) ReturnArray();
  }, [stateCheck]);
  return (
    <div
      style={{
        position: "absolute",
        bottom: 0,
        height: display ? "100%" : 0,
        width: "100%",
        backgroundColor: BlackAndWhite.secondary,
        overflow: "hidden",
        transition: "all 0.3s",
        display: "flex",
      }}
    >
      <div style={{ position: "absolute", top: 20, left: 20 }}>
        <CloseButton
          label={"X"}
          onClick={() => {
            setMultiRecieve(false);
          }}
        />
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            minHeight: 70,
          }}
        >
          <div style={{ marginLeft: 80, fontSize: 20, fontWeight: "bold" }}>
            RECIEVING INTO WAREHOUSE
          </div>
          <div style={{ flex: 1 }} />
          <div style={{ marginRight: 10, fontSize: 12, width: 300 }}>
            Make sure all stock has a value so that you can complete the
            recieving process
          </div>
          <BaseButtons
            mini={true}
            label={"ACCEPT STOCK"}
            borderRadius={8}
            marginTopFalse={true}
            height={35}
            disable={
              qtyArray.length === multiRecieveArrayItem.length ? false : true
            }
            onClick={() => RecieveStock()}
          />
          <div style={{ minWidth: 10 }} />
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          {multiRecieveArrayItem.length >= 1 ? (
            multiRecieveArrayItem.map((product, index) => {
              return (
                <div
                  key={index}
                  style={{
                    minHeight: 90,
                    marginTop: index === 0 ? 20 : null,
                    marginBottom:
                      index === multiRecieveArrayItem.length - 1 ? 80 : 15,
                    // overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <RecievingItem
                    removeSelectedItems={removeSelectedItems}
                    AddNewItemToArray={AddNewItemToArray}
                    product={product}
                    productName={product.productName}
                    category={product.productType}
                    subCategory={product.productSubType}
                    warehouseAmount={"50"}
                    qty={"1000"}
                    image={product.imageUrl}
                    index={index}
                  />
                </div>
              );
            })
          ) : (
            <div>No slelected products</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MultiSelectReceivingPopUp;
