import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import { BaseButtons, TextAreaInput } from "../../../../../../components/atoms";
import { LoadingPopUp, TextDisplayOnly } from "../../../../../../components";
import { MessageDisplayItem } from "./components";

const RequestCard = ({
  onViewProfileClick,
  setRequestedPrescriptionData,
  onCreatePreClick,
  setSelectedUser,
  request,
  admin,
  onStatusClickChange,
}) => {
  const [sendMessagePopUp, setSendMessagePopUp] = useState(false);
  const [areYouSure, setAreYouSure] = useState(false);
  const [loading, setLoading] = useState(false);

  const [communicationHistory, setCommunicationHistory] = useState([]);
  const [userRequested, setUserRequested] = useState(null);

  const [refresh, setRefresh] = useState(false);

  const [messageInfo, setMessageInfo] = useState("");

  const getMessagesRequest = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/requestPrescriptionUser/getMessagesDoc",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            messageId: request.messageId,
          }),
        }
      );

      const responseData = await response.json();
      if (responseData.success) {
        setCommunicationHistory(responseData.messages);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const sendMessage = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/requestPrescriptionUser/sendMessage",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            messageId: request.messageId,
            patientUserId: request.patientUserId,
            docId: admin.userId,
            message: messageInfo,
            sentBy: admin.userId,
          }),
        }
      );

      const responseData = await response.json();
      if (responseData.success) {
        setLoading(false);
        setMessageInfo("");
        setSendMessagePopUp(false);
        setRefresh(refresh ? false : true);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMessagesRequest();
  }, [refresh]);
  return (
    <div
      style={{
        height: 350,
        width: "100%",
        display: "flex",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100%",
          flex: 0.3,

          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "100%",
            width: "100%",

            // borderRadius: 8,
            backgroundColor: BlackAndWhite.secondary,
            WebkitBoxShadow: "0px 0px 10px 1px #00000033",
            display: "flex",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              flex: 0.5,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <TextDisplayOnly
              marginTop={20}
              title={"MEMBER SINCE"}
              value={request ? request.patientData.dateCreated : "loading"}
            />
            <TextDisplayOnly
              marginTop={0}
              title={"PATIENT NAME"}
              value={
                request
                  ? request.patientData.firstName +
                    " " +
                    request.patientData.lastName
                  : "loading"
              }
            />
            <TextDisplayOnly
              title={"PATIENT ID"}
              value={request ? request.patientData.idDoc : "loading"}
            />
            <TextDisplayOnly
              title={"STATUS"}
              value={request ? request.status : "loading"}
            />
            <TextDisplayOnly
              title={"MEMBER TYPE"}
              value={
                request
                  ? request.patientData.type === "Customer"
                    ? "SOCIAL CLUB MEMBER"
                    : request.patientData.type
                  : "loading"
              }
            />
            <TextDisplayOnly
              title={"DATE REQUESTED"}
              value={request ? request.patientData.dateCreated : "loading"}
            />
          </div>
          <div
            style={{
              flex: 0.5,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <TextDisplayOnly
              title={"REQUEST REASON"}
              value={request ? request.requestReason : "loading"}
              marginTop={20}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          height: "100%",
          flex: 0.7,
          width: "100%",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            // borderRadius: 8,
            backgroundColor: BlackAndWhite.primary,
            display: "flex",
            overflow: "hidden",
            WebkitBoxShadow: "0px 0px 10px 1px #00000033",
          }}
        >
          <div
            style={{
              flex: 0.7,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                height: "92%",
                width: "95%",
                backgroundColor: BlackAndWhite.secondary,
                position: "relative",
                borderRadius: 8,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {sendMessagePopUp ? (
                <TextAreaInput
                  labelTitle={"NEW MESSAGE"}
                  color={BlackAndWhite.primary}
                  mini={true}
                  width={"100%"}
                  height={150}
                  size={"85%"}
                  value={messageInfo}
                  onChange={(e) => setMessageInfo(e.target.value)}
                />
              ) : (
                <>
                  <div
                    style={{
                      marginLeft: 10,
                      marginTop: 10,
                      fontSize: 12,
                      marginBottom: 10,
                      fontWeight: "bold",
                    }}
                  >
                    COMUNICATION HISTORY
                  </div>
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      overflowY: "hidden",
                      overflowX: "auto",
                      alignItems: "center",
                      width: "100%",
                      maxWidth: 650,
                    }}
                  >
                    {communicationHistory.length >= 1
                      ? communicationHistory.map((message, index) => {
                          return (
                            <div key={index} style={{ height: "100%" }}>
                              <MessageDisplayItem
                                isUser={
                                  message.sentBy === message.patientUserId
                                    ? false
                                    : true
                                }
                                name={
                                  message.sentBy === message.patientUserId
                                    ? "Patient"
                                    : "You"
                                }
                                message={message.message}
                                dateSent={new Date().toISOString()}
                              />
                            </div>
                          );
                        })
                      : null}
                  </div>
                </>
              )}
            </div>
            <LoadingPopUp newWidth={"100%"} display={loading} />
          </div>

          {sendMessagePopUp ? (
            <div
              style={{
                flex: 0.3,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: "center",
                minWidth: 150,
              }}
            >
              <BaseButtons
                label={"CANCEL"}
                mini={true}
                marginTopFalse={true}
                height={40}
                width={"100%"}
                size={"85%"}
                borderRadius={8}
                invert={true}
                onClick={() => setSendMessagePopUp(false)}
              />
              <BaseButtons
                label={"SEND MESSAGE"}
                mini={true}
                marginTopFalse={true}
                height={40}
                width={"100%"}
                size={"85%"}
                borderRadius={8}
                invert={true}
                onClick={() => sendMessage()}
              />
            </div>
          ) : areYouSure ? (
            <div
              style={{
                flex: 0.3,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: "center",
                minWidth: 150,
              }}
            >
              <div
                style={{
                  width: "80%",
                  fontSize: 14,
                  color: BlackAndWhite.secondary,
                }}
              >
                DO YOU WANT TO DECLINE REQUEST ?
              </div>
              <BaseButtons
                label={"YES"}
                mini={true}
                marginTopFalse={true}
                height={40}
                width={"100%"}
                size={"85%"}
                borderRadius={8}
                invert={true}
                onClick={() => {
                  onStatusClickChange();
                  setAreYouSure(false);
                }}
              />
              <BaseButtons
                label={"NO"}
                mini={true}
                marginTopFalse={true}
                height={40}
                width={"100%"}
                size={"85%"}
                borderRadius={8}
                invert={true}
                onClick={() => setAreYouSure(false)}
              />
            </div>
          ) : (
            <div
              style={{
                flex: 0.3,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: "center",
                minWidth: 150,
              }}
            >
              <BaseButtons
                label={"VIEW PROFILE"}
                mini={true}
                marginTopFalse={true}
                height={40}
                width={"100%"}
                size={"85%"}
                borderRadius={8}
                invert={true}
                onClick={onViewProfileClick}
              />
              <BaseButtons
                label={"DECLINE REQUEST"}
                mini={true}
                marginTopFalse={true}
                height={40}
                width={"100%"}
                size={"85%"}
                borderRadius={8}
                invert={true}
                onClick={() => setAreYouSure(true)}
              />
              <BaseButtons
                label={"SEND MESSAGE"}
                mini={true}
                marginTopFalse={true}
                height={40}
                width={"100%"}
                size={"85%"}
                borderRadius={8}
                invert={true}
                onClick={() => setSendMessagePopUp(true)}
              />
              <BaseButtons
                label={"CREATE PRESCRIPTION"}
                mini={true}
                marginTopFalse={true}
                height={40}
                width={"100%"}
                size={"85%"}
                borderRadius={8}
                invert={true}
                onClick={onCreatePreClick}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RequestCard;
