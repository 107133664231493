import React, { useState } from "react";
import { BlackAndWhite } from "../../../../../themes/Colors";
import {
  BaseButtons,
  CheckInput,
  CloseButton,
  TextInputField,
} from "../../../../../components/atoms";

const InvitePractioner = ({
  display,
  onCloseClick,
  onSubmitClick,
  admin,
  loading,
  setLoading,
  refresh,
  setRefresh,
  setMessage,
  setToastPop,
  setInvitePractionerPopUp,
}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [cellNumber, setCellNumber] = useState("");
  const [type, setType] = useState("");
  const InviteHandler = async () => {
    setLoading(true);

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/practioner/createInvite",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            firstName: firstName,
            lastName: lastName,
            emailAddress: emailAddress,
            cellNumber: cellNumber,
            type: type,
            createdBy:
              admin.adminUserData.firstName +
              " " +
              admin.adminUserData.lastName,
          }),
        }
      );
      const respnseData = await response.json();

      if (respnseData.newTicket) {
        setToastPop(true);
        setMessage(respnseData.success);
        setLoading(false);
        setInvitePractionerPopUp(false);
        setRefresh(refresh ? false : true);
      } else if (respnseData.error) {
        setToastPop(true);
        setMessage(respnseData.error);
        setLoading(false);
        setRefresh(refresh ? false : true);
      }
    } catch (err) {
      console.log("error", err);
      setToastPop(true);
      setMessage("Please contact support after trying again");
      setLoading(false);
    }
  };
  return (
    <div
      style={{
        position: "absolute",
        transition: "all 0.3s",
        overflow: "hidden",
        height: display ? "100%" : 0,
        width: "100%",
        backgroundColor: BlackAndWhite.secondary,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          position: "absolute",
          left: 20,
          top: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CloseButton label={"X"} onClick={onCloseClick} />
        <div style={{ marginLeft: 20 }}>Invite Practioner</div>
      </div>
      <div style={{ position: "absolute", right: 20, top: 20 }}>
        <BaseButtons
          label={"SEND INVITE"}
          onClick={() => InviteHandler()}
          mini={true}
          marginTopFalse={true}
          height={40}
          borderRadius={8}
          disable={
            firstName.length >= 2 &&
            lastName.length >= 2 &&
            emailAddress.length >= 6 &&
            cellNumber.length === 10 &&
            type
              ? false
              : true
          }
        />
      </div>
      <div
        style={{
          width: "70%",
          paddingTop: 15,
          paddingBottom: 30,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div style={{ flex: 0.5 }}>
          <TextInputField
            labelTitle={"First name"}
            invert={true}
            mini={true}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextInputField
            labelTitle={"Last name"}
            invert={true}
            mini={true}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <TextInputField
            labelTitle={"Email"}
            invert={true}
            mini={true}
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
          />
          <TextInputField
            labelTitle={"Contact Nr"}
            invert={true}
            mini={true}
            value={cellNumber}
            onChange={(e) => setCellNumber(e.target.value)}
          />
        </div>
        <div
          style={{
            flex: 0.5,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <div style={{ marginBottom: 10, fontSize: 14, marginTop: 10 }}>
            Practioner Type:
          </div>
          <CheckInput
            question={"M.D"}
            mini={true}
            invert={true}
            check={type === "M.D" ? true : false}
            onClick={() => setType(type === "M.D" ? null : "M.D")}
          />
          <CheckInput
            question={"Traditionnal healer"}
            mini={true}
            invert={true}
            check={type === "Traditionnal healer" ? true : false}
            onClick={() =>
              setType(
                type === "Traditionnal healer" ? null : "Traditionnal healer"
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

export default InvitePractioner;
