import React, { useState } from "react";
import JsPDF from "jspdf";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import {
  BaseButtons,
  CloseButton,
  TextInputField,
} from "../../../../../../components/atoms";
import NumberSpace from "../../../../../global-functions/NumberSpace";

const CashRegisterCount = ({
  display,
  onCloseClick,
  selectedReportIndex,
  setLoading,
  setMessage,
  setToastPop,
  setCashRegisterOpenCheck,
  selectedReport,
  selectedBudtender,
  setCashRegisterReset,
  cashRegisterReset,
  setCashRegsiterStatus,
  setOPenedBy,
  setDateAndTimeOpened,
  setCountTill,
}) => {
  const [downloading, setDownloading] = useState(false);
  const [cashCounted, setCashCounted] = useState("");
  const [cardCounted, setCardCounted] = useState("");

  let ExVatCheck = display
    ? (Number(display.TotalInc) - Number(display.vat)).toFixed(2)
    : 0;

  const generatePDF = async () => {
    // prescriptionPdf();
    setDownloading(true);
    const report = await new JsPDF("portrait", "px", "a4");
    report.html(document.querySelector("#reportClosedReg")).then(() => {
      // report.getFontList();
      report.setFont("Lato-Regular", "normal");
      report.save(`report${new Date().toDateString()}.pdf`);
    });
    setDownloading(false);
  };
  const updateCashRegisterReportCount = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/cashRegister/updateReportCounted",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            registerId: display.registerId,
            cashCounted,
            cardCounted,
            userId: selectedBudtender._id,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        await generatePDF();
        setCashRegisterOpenCheck(null);
        setMessage("CASH REGISTER CLOSED");
        setToastPop(true);
        setCountTill(null);
        setLoading(false);
        setCashRegisterReset(cashRegisterReset ? false : true);
        setCashRegsiterStatus("closed");
        setOPenedBy("closed");
        setDateAndTimeOpened("closed");
        setCardCounted("");
        setCashCounted("");
      } else {
        setMessage(responseData.message);
        setToastPop(true);
        setLoading(false);
      }
    } catch (err) {
      console.log("error", err);
      setMessage("Error occured check your network connection");
      setToastPop(true);
      setLoading(false);
    }
  };
  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        height: display ? "100%" : 0,
        backgroundColor: BlackAndWhite.secondary,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        overflowX: "hidden",
        top: 0,
        left: 0,
        zIndex: 10000000000000,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 20,
          left: 20,
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* <CloseButton label={"X"} onClick={onCloseClick} /> */}
        <div
          style={{
            marginLeft: 10,
            fontWeight: "normal",
            fontSize: 16,
            display: "flex",
          }}
        >
          CASH REGISTER REPORT FOR{" "}
          <div style={{ marginLeft: 5, fontWeight: "bold" }}>
            {display
              ? display.openedAt.substring(0, 10) +
                " / " +
                display.openedAt.substring(11, 16) +
                " TO " +
                display.closedAt.substring(0, 10) +
                " / " +
                display.closedAt.substring(11, 16)
              : null}
          </div>
        </div>
      </div>
      {/* <div
        style={{
          position: "absolute",
          top: 20,
          right: 20,
          display: "flex",
          alignItems: "center",
        }}
      >
        <BaseButtons
          mini={true}
          label={"DOWNLOAD"}
          marginTop={-1}
          //   invert={true}
          height={40}
          borderRadius={8}
          onClick={() => generatePDF()}
        />
      </div> */}
      <div style={{ minHeight: 60 }}></div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            WebkitBoxShadow: "0px 0px 7px 2px #00000050",
          }}
        >
          <div
            // key={selectedReportIndex}
            id="reportClosedReg"
            style={{
              display: "flex",
              flexDirection: "column",
              width: 450,
              position: "relative",
              minHeight: 625,
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 20,
                left: 20,
                display: "flex",
                flexDirection: "column",
                fontSize: 12,
              }}
            >
              CASH REGISTER {selectedReportIndex ? selectedReportIndex : null}
              <div style={{ fontSize: downloading ? 14 : 10 }}>
                {display ? display.storeName : null}
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                top: 20,
                left: 210,

                display: "flex",
                flexDirection: "column",
                textAlign: "right",
                fontSize: 12,
              }}
            >
              REGISTER SUMMARY{" "}
              {selectedReportIndex ? selectedReportIndex : null}
              <div style={{ fontSize: 10 }}>
                {display
                  ? display.openedAt.substring(0, 10) +
                    " - " +
                    display.openedAt.substring(11, 19) +
                    "  TO  "
                  : null}

                {display
                  ? display.closedAt.substring(0, 10) +
                    " - " +
                    display.closedAt.substring(11, 19)
                  : null}
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                top: 80,
                left: 20,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "90%",
              }}
            >
              <div style={{ display: "flex", width: "105%", marginBottom: 2 }}>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: 10,
                    flex: 1,
                  }}
                >
                  <div style={{ marginLeft: 10 }}> Payment Type</div>
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: 10,
                    width: 60,
                    textAlign: "right",
                  }}
                >
                  <div style={{ marginLeft: 3 }}>Expected</div>
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: 10,
                    width: 60,
                    textAlign: "right",
                  }}
                >
                  <div style={{ marginLeft: 3 }}>Counted</div>
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: 10,
                    width: 60,
                    textAlign: "right",
                    paddingLeft: 3,
                  }}
                >
                  <div style={{ marginLeft: 3 }}>Difference</div>
                </div>
              </div>

              <div
                style={{
                  borderStyle: "solid",
                  width: "100%",
                  borderWidth: 0.8,
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                top: 105,
                left: 20,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "90%",
              }}
            >
              <div style={{ display: "flex", width: "100%", marginBottom: 2 }}>
                <div style={{ fontWeight: "normal", fontSize: 8, flex: 1 }}>
                  Cash (net cash payments) (float:{" "}
                  {display
                    ? "R" + NumberSpace({ value: display.tillOpeningAmount })
                    : null}
                  )
                </div>
                <div
                  style={{
                    fontWeight: "normal",
                    fontSize: 8,
                    width: 60,
                    textAlign: "right",
                  }}
                >
                  {display
                    ? "R" +
                      (
                        Number(display.cash) + Number(display.tillOpeningAmount)
                      ).toFixed(2)
                    : null}
                </div>
                <div
                  style={{
                    fontWeight: "normal",
                    fontSize: 8,
                    width: 60,
                    textAlign: "right",
                  }}
                >
                  {display ? "R" + Number(cashCounted).toFixed(2) : null}
                </div>
                <div
                  style={{
                    fontWeight: "normal",
                    fontSize: 8,
                    width: 60,
                    textAlign: "right",
                  }}
                >
                  {display
                    ? "R" +
                      (
                        Number(display.cash) +
                        Number(display.tillOpeningAmount) -
                        Number(cashCounted)
                      ).toFixed(2)
                    : null}
                </div>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                top: 125,
                left: 20,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "90%",
              }}
            >
              <div style={{ display: "flex", width: "100%", marginBottom: 2 }}>
                <div style={{ fontWeight: "normal", fontSize: 8, flex: 1 }}>
                  Card
                </div>
                <div
                  style={{
                    fontWeight: "normal",
                    fontSize: 8,
                    width: 60,
                    textAlign: "right",
                  }}
                >
                  {display ? "R" + Number(display.card).toFixed(2) : null}
                </div>
                <div
                  style={{
                    fontWeight: "normal",
                    fontSize: 8,
                    width: 60,
                    textAlign: "right",
                  }}
                >
                  {display ? "R" + Number(cardCounted).toFixed(2) : null}
                </div>
                <div
                  style={{
                    fontWeight: "normal",
                    fontSize: 8,
                    width: 60,
                    textAlign: "right",
                  }}
                >
                  R0.00
                </div>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                top: 145,
                left: 20,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "90%",
              }}
            >
              <div style={{ display: "flex", width: "100%", marginBottom: 2 }}>
                <div style={{ fontWeight: "normal", fontSize: 8, flex: 1 }}>
                  Credit
                </div>
                <div
                  style={{
                    fontWeight: "normal",
                    fontSize: 8,
                    width: 60,
                    textAlign: "right",
                  }}
                >
                  {display
                    ? "R" + Number(display.storeCredit).toFixed(2)
                    : null}
                </div>
                <div
                  style={{
                    fontWeight: "normal",
                    fontSize: 8,
                    width: 60,
                    textAlign: "right",
                  }}
                >
                  {display
                    ? "R" + Number(display.storeCredit).toFixed(2)
                    : null}
                </div>
                <div
                  style={{
                    fontWeight: "normal",
                    fontSize: 8,
                    width: 60,
                    textAlign: "right",
                  }}
                >
                  R0.00
                </div>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                top: 165,
                left: 20,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "90%",
              }}
            >
              <div
                style={{
                  borderStyle: "solid",
                  width: "100%",
                  borderWidth: 0.8,
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                top: 175,
                left: 20,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "90%",
              }}
            >
              <div style={{ display: "flex", width: "100%", marginBottom: 2 }}>
                <div
                  style={{ fontWeight: "normal", fontSize: 8, flex: 1 }}
                ></div>

                <div
                  style={{
                    fontWeight: "normal",
                    fontSize: 8,
                    width: 120,
                    textAlign: "right",
                    display: "flex",
                    flexDirection: "row",
                    position: "relative",
                  }}
                >
                  Payments:
                  <div style={{ flex: 1 }} />
                  {display ? "R" + Number(display.TotalInc).toFixed(2) : null}
                  <div
                    style={{
                      position: "absolute",
                      borderStyle: "solid",
                      borderWidth: 0.5,
                      width: 120,
                      bottom: -5,
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                top: 195,
                left: 20,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "90%",
              }}
            >
              <div style={{ display: "flex", width: "100%", marginBottom: 2 }}>
                <div
                  style={{ fontWeight: "normal", fontSize: 8, flex: 1 }}
                ></div>

                <div
                  style={{
                    fontWeight: "normal",
                    fontSize: 8,
                    width: 120,
                    textAlign: "right",
                    display: "flex",
                    flexDirection: "row",
                    position: "relative",
                  }}
                >
                  Refunds:
                  <div style={{ flex: 1 }} />
                  {display ? "R" + Number(display.refunds).toFixed(2) : null}
                  <div
                    style={{
                      position: "absolute",
                      borderStyle: "solid",
                      borderWidth: 0.5,
                      width: 120,
                      bottom: -5,
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                top: 215,
                left: 20,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "90%",
              }}
            >
              <div style={{ display: "flex", width: "100%", marginBottom: 2 }}>
                <div
                  style={{ fontWeight: "normal", fontSize: 8, flex: 1 }}
                ></div>

                <div
                  style={{
                    fontWeight: "normal",
                    fontSize: 8,
                    width: 120,
                    textAlign: "right",
                    display: "flex",
                    flexDirection: "row",
                    position: "relative",
                  }}
                >
                  Net receipts:
                  <div style={{ flex: 1 }} />
                  {display
                    ? "R" +
                      (
                        Number(display.TotalInc) - Number(display.refunds)
                      ).toFixed(2)
                    : null}
                </div>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                top: 230,
                left: 20,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "90%",
              }}
            >
              <div
                style={{
                  borderStyle: "solid",
                  width: "100%",
                  borderWidth: 0.8,
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                top: 240,
                left: 20,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "90%",
              }}
            >
              <div style={{ display: "flex", width: "100%", marginBottom: 2 }}>
                <div
                  style={{ fontWeight: "normal", fontSize: 8, flex: 1 }}
                ></div>

                <div
                  style={{
                    fontWeight: "normal",
                    fontSize: 8,
                    minWidth: 120,
                    textAlign: "right",
                    display: "flex",
                    flexDirection: "row",
                    position: "relative",
                  }}
                >
                  Total (Incl) Sales:
                  <div style={{ flex: 1 }} />
                  {display ? "R" + Number(display.TotalInc).toFixed(2) : null}
                  {/* <div
                  style={{
                    position: "absolute",
                    borderStyle: "solid",
                    borderWidth: 0.5,
                    width: 120,
                    bottom: -5,
                  }}
                /> */}
                </div>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                top: 260,
                left: 20,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "90%",
              }}
            >
              <div style={{ display: "flex", width: "100%", marginBottom: 2 }}>
                <div
                  style={{ fontWeight: "normal", fontSize: 8, flex: 1 }}
                ></div>

                <div
                  style={{
                    fontWeight: "normal",
                    fontSize: 8,
                    minWidth: 120,
                    textAlign: "right",
                    display: "flex",
                    flexDirection: "row",
                    position: "relative",
                    alignItems: "center",
                  }}
                >
                  Total (Ex) Sales: <div style={{ flex: 1 }} />
                  {display ? "R" + ExVatCheck : null}
                  {/* <div
                  style={{
                    position: "absolute",
                    borderStyle: "solid",
                    borderWidth: 0.5,
                    width: 120,
                    bottom: -5,
                  }}
                /> */}
                </div>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                top: 280,
                left: 20,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "90%",
              }}
            >
              <div style={{ display: "flex", width: "100%", marginBottom: 2 }}>
                <div
                  style={{ fontWeight: "normal", fontSize: 8, flex: 1 }}
                ></div>

                <div
                  style={{
                    fontWeight: "normal",
                    fontSize: 8,
                    minWidth: 120,
                    textAlign: "right",
                    display: "flex",
                    flexDirection: "row",
                    position: "relative",
                    alignItems: "center",
                  }}
                >
                  Total Vat:{" "}
                  <div style={{ fontSize: 7, marginLeft: 3 }}>
                    {display
                      ? "(R" + Number(display.TotalInc).toFixed(2) + ")"
                      : null}
                  </div>
                  <div style={{ flex: 1 }} />
                  {display ? "R" + Number(display.vat).toFixed(2) : null}
                  {/* <div
                  style={{
                    position: "absolute",
                    borderStyle: "solid",
                    borderWidth: 0.5,
                    width: 120,
                    bottom: -5,
                  }}
                /> */}
                </div>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                top: 300,
                left: 20,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "90%",
              }}
            >
              <div style={{ display: "flex", width: "100%", marginBottom: 2 }}>
                <div
                  style={{ fontWeight: "normal", fontSize: 8, flex: 1 }}
                ></div>

                <div
                  style={{
                    fontWeight: "normal",
                    fontSize: 8,
                    minWidth: 120,
                    textAlign: "right",
                    display: "flex",
                    flexDirection: "row",
                    position: "relative",
                    alignItems: "center",
                  }}
                >
                  Discounts:
                  <div style={{ flex: 1 }} />
                  {display ? "R" + Number(display.discounts).toFixed(2) : null}
                  {/* <div
                  style={{
                    position: "absolute",
                    borderStyle: "solid",
                    borderWidth: 0.5,
                    width: 120,
                    bottom: -5,
                  }}
                /> */}
                </div>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                top: 320,
                left: 20,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "90%",
              }}
            >
              <div style={{ display: "flex", width: "100%", marginBottom: 2 }}>
                <div
                  style={{ fontWeight: "normal", fontSize: 8, flex: 1 }}
                ></div>

                <div
                  style={{
                    fontWeight: "normal",
                    fontSize: 8,
                    minWidth: 120,
                    textAlign: "right",
                    display: "flex",
                    flexDirection: "row",
                    position: "relative",
                    alignItems: "center",
                  }}
                >
                  Till Opening Amt.:
                  <div style={{ flex: 1 }} />
                  {display
                    ? "R" + Number(display.tillOpeningAmount).toFixed(2)
                    : null}
                  {/* <div
                  style={{
                    position: "absolute",
                    borderStyle: "solid",
                    borderWidth: 0.5,
                    width: 120,
                    bottom: -5,
                  }}
                /> */}
                </div>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                top: 340,
                left: 20,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "90%",
              }}
            >
              <div
                style={{
                  borderStyle: "solid",
                  width: "100%",
                  borderWidth: 0.8,
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                top: 360,
                left: 20,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "90%",
              }}
            >
              <div style={{ display: "flex", width: "100%", marginBottom: 2 }}>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: 8,
                    flex: 1,
                    display: "flex",
                  }}
                >
                  <div>Transaction</div>
                  <div style={{ marginLeft: 40, fontWeight: "bold" }}>User</div>
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: 8,

                    display: "flex",
                  }}
                >
                  Action
                </div>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                top: 380,
                left: 20,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "90%",
              }}
            >
              <div
                style={{
                  borderStyle: "solid",
                  width: "100%",
                  borderWidth: 0.8,
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                top: 400,
                left: 20,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "90%",
              }}
            >
              <div style={{ display: "flex", width: "100%", marginBottom: 2 }}>
                <div
                  style={{
                    fontWeight: "normal",
                    fontSize: 7,
                    flex: 1,
                    display: "flex",
                  }}
                >
                  <div>Opening float</div>
                  <div style={{ marginLeft: 40 }}>
                    {display ? display.openedBy : null}
                  </div>
                </div>
                <div
                  style={{
                    fontWeight: "normal",
                    fontSize: 8,

                    display: "flex",
                  }}
                >
                  +{" "}
                  {display
                    ? "R" + Number(display.tillOpeningAmount).toFixed(2)
                    : null}
                </div>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                top: 420,
                left: 20,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "90%",
              }}
            >
              <div
                style={{
                  borderStyle: "solid",
                  width: "100%",
                  borderWidth: 0.8,
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                top: 440,
                left: 20,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "90%",
              }}
            >
              <div style={{ display: "flex", width: "100%", marginBottom: 2 }}>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: 8,
                    flex: 1,
                    display: "flex",
                  }}
                >
                  <div>Num. of transctions</div>
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: 8,

                    display: "flex",
                  }}
                >
                  Average Sale
                </div>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                top: 460,
                left: 20,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "90%",
              }}
            >
              <div
                style={{
                  borderStyle: "solid",
                  width: "100%",
                  borderWidth: 0.8,
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                top: 480,
                left: 20,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "90%",
              }}
            >
              <div style={{ display: "flex", width: "100%", marginBottom: 2 }}>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: 8,
                    flex: 1,
                    display: "flex",
                  }}
                >
                  <div>{display ? display.receiptsArray.length : null}</div>
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: 8,

                    display: "flex",
                  }}
                >
                  {display
                    ? "R" + Number(display.averageSale).toFixed(2)
                    : null}
                </div>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                top: 500,
                left: 20,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "90%",
              }}
            >
              <div
                style={{
                  borderStyle: "solid",
                  width: "100%",
                  borderWidth: 0.8,
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                top: 520,
                left: 20,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "90%",
              }}
            >
              <div style={{ display: "flex", width: "100%", marginBottom: 2 }}>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: 8,
                    flex: 1,
                  }}
                >
                  <div style={{ marginBottom: 10 }}>
                    {display ? "Opened by:" + display.openedBy : null}
                  </div>
                  <div>{display ? "Closed by:" + display.closedBy : null}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            width: 350,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: 600,
            textAlign: "left",
            marginLeft: 40,
          }}
        >
          <div style={{ width: "90%", fontSize: 18, fontWeight: "bold" }}>
            CASH AMOUNT EXPECTED
          </div>
          <div style={{ width: "90%", marginBottom: 10, marginTop: 5 }}>
            (Float:{" "}
            {display
              ? "R" + Number(display.tillOpeningAmount).toFixed(2)
              : "loading"}
            )
          </div>
          <div style={{ width: "90%", fontSize: 35, fontWeight: "bold" }}>
            {display
              ? "R" +
                (
                  Number(display.cash) + Number(display.tillOpeningAmount)
                ).toFixed(2)
              : "loading"}
          </div>
          <TextInputField
            labelTitle={"CASH COUNT"}
            invert={true}
            mini={true}
            size={280}
            value={cashCounted}
            onChange={(e) => setCashCounted(e.target.value)}
            height={80}
            fontsize={cashCounted !== "" ? 45 : 20}
            placeholder={"ADD AMOUNT HERE"}
            borderRadius={8}
          />
          <div style={{ width: "90%", marginTop: 20, marginBottom: 10 }}>
            CARD AMOUNT EXPECTED
          </div>
          <div style={{ width: "90%", fontSize: 35, fontWeight: "bold" }}>
            {display ? "R" + Number(display.card).toFixed(2) : "loading"}
          </div>
          <TextInputField
            labelTitle={"CARD SLIPS COUNT"}
            invert={true}
            mini={true}
            value={cardCounted}
            size={280}
            onChange={(e) => setCardCounted(e.target.value)}
            height={80}
            fontsize={cardCounted !== "" ? 45 : 20}
            placeholder={"ADD AMOUNT HERE"}
            borderRadius={8}
          />

          <BaseButtons
            label={"CONFIRM TOTALS & GENERATE REPORT"}
            size={310}
            mini={true}
            onClick={() => updateCashRegisterReportCount()}
            disable={cardCounted !== "" && cashCounted !== "" ? false : true}
          />
        </div>
      </div>
    </div>
  );
};

export default CashRegisterCount;
