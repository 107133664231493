import React, { useState, useEffect } from "react";
import { LoadingPopUp, ToastNotification } from "../../../../components";
import {
  CreateTransferPopUp,
  HeadersInventory,
  InventoryDashboard,
  InventoryFilter,
  InventoryManageCard,
  MultiSelectReceivingPopUp,
  StoresInventoryDisplayCard,
  Transfers,
  UploadItem,
  UploadLinkedFacilitiesPopUp,
} from "./components";
import {
  BaseButtons,
  TextInputFieldSearch,
} from "../../../../components/atoms";
import { BlackAndWhite } from "../../../../themes/Colors";

const dummyData = ["test1", "test2", "test3"];

const Inventory = ({ subSelection, setSubSelection, auth }) => {
  const [loading, setLoading] = useState(false);
  const [toastPop, setToastPop] = useState(false);
  const [message, setMessage] = useState(false);
  const [search, setSearch] = useState("");
  const [screen, setScreen] = useState("products");
  const [selectedProduct, setSelectedProd] = useState(null);

  let multiRecieveArray = [];
  const [recievingList, setRecievingList] = useState([]);
  const [multiRecieveArrayItem, setMultiRecieveArrayItem] = useState([]);

  const [uploadItemPopUp, setUploadItemPopUp] = useState(false);
  const [multiRecieve, setMultiRecieve] = useState(false);
  const [transferCreate, setTransferCreate] = useState(false);
  const [uploadLinkedFaclitiesPopUp, setUploadLinkedFaclitiesPopUp] =
    useState(false);

  const [warehouseInventoryList, setWarehouseInventoryList] = useState(null);
  const [warehouseInventoryStockList, setWarehouseInventoryStockList] =
    useState(null);

  //facilities varibales
  const [facilitiesArray, setFacilitiesArray] = useState(null);

  // these are the varibales to allow the pop ups to open
  const [uploadStockPop, setUploadStockPop] = useState(false);

  const getProducts = async () => {
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/getProductsAdded",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const respnseData = await response.json();
      if (respnseData.product) {
        // const wait = await updateListFetch();
        setWarehouseInventoryList(respnseData.product);
      } else if (respnseData.message) {
        setWarehouseInventoryList([]);
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  const getWarehouseStock = async () => {
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/inventory/warehouseStock",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: auth.userId,
          }),
        }
      );

      const respnseData = await response.json();

      if (respnseData.success) {
        setWarehouseInventoryStockList(respnseData.warehouseList);
      } else if (respnseData.message) {
        setWarehouseInventoryList([]);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const ReturnStockAmount = ({ productId }) => {
    let stock = 0;
    if (warehouseInventoryStockList) {
      warehouseInventoryStockList.map((item) =>
        item.productId === productId ? (stock = item) : null
      );
    }

    return stock;
  };

  const getStores = async () => {
    await fetch("https://merry-jane-api.onrender.com/facilities/getFacilities")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setFacilitiesArray(resData);
      });
  };
  const NameAssemble = ({ product }) => {
    if (product) {
      let name =
        product.productName +
        " | " +
        product.productSubType +
        " | " +
        product.thcType +
        " | " +
        product.thcContent;
      return name;
    }
  };

  useEffect(() => {
    getStores();
    getProducts();
    getWarehouseStock();
  }, []);

  useEffect(() => {
    if (toastPop) {
      setTimeout(() => {
        setToastPop(false);
      }, 5000);
    }
  }, [toastPop]);
  return (
    <div
      style={{
        height: "100%",
        width: "100%",

        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {/* //header tool bar */}
      {subSelection === "myInventory" ? (
        <>
          <div
            style={{
              height: 60,
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* <div style={{ marginLeft: 20, marginRight: 20 }}>MY INVENTORY</div> */}
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                position: "relative",
              }}
            >
              {/* ser=arch field */}
              <div style={{ marginLeft: 10, marginRight: 10 }}>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                    // width: 300,

                    borderWidth: 0.5,
                  }}
                >
                  <TextInputFieldSearch
                    placeholder={"Type here to search"}
                    height={25}
                    borderRadius={4}
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                  />
                  <div
                    style={{
                      height: 30,
                      width: 70,
                      backgroundColor: BlackAndWhite.primary,
                      borderRadius: 4,
                      display: "flex",
                      color: BlackAndWhite.secondary,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      onClick={() => setSearch("")}
                      style={{ fontSize: 12, fontWeight: "bold" }}
                    >
                      CLEAR
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ flex: 1 }}></div>
              <div style={{}}>
                <InventoryFilter filterName={"FILTER"} setScreen={setScreen} />
              </div>
            </div>
            <div style={{ marginRight: 10, display: "flex" }}>
              <BaseButtons
                label={"TRANSFER"}
                mini={true}
                marginTopFalse={true}
                borderRadius={8}
                height={35}
                size={130}
                onClick={() => setTransferCreate(true)}
              />
              <div style={{ minWidth: 10 }} />
              <BaseButtons
                label={"RECIEVE"}
                mini={true}
                marginTopFalse={true}
                borderRadius={8}
                height={35}
                size={130}
                onClick={() => setUploadItemPopUp(true)}
              />
            </div>
          </div>
          {screen === "products" ? <HeadersInventory /> : null}
        </>
      ) : null}

      {subSelection === "myInventory" ? (
        <div
          style={{
            width: "100%",
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          {screen === "products" ? (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "flex-start",
                overflowY: "auto",
                overflowX: "hidden",
                flexDirection: "column",
                scrollbarWidth: "none",
              }}
            >
              {warehouseInventoryList
                ? warehouseInventoryList.map((product, index) => {
                    return NameAssemble({ product })
                      .toLocaleLowerCase()
                      .match(search) ? (
                      <div key={index} style={{ width: "100%" }}>
                        <InventoryManageCard
                          ReturnStockAmount={ReturnStockAmount}
                          product={product}
                          productName={NameAssemble({ product })}
                          sku={product.sku}
                          supplier={product.supplier}
                          image={product.imageUrl}
                          info={ReturnStockAmount({ productId: product._id })}
                        />
                      </div>
                    ) : null;
                  })
                : null}
            </div>
          ) : null}
          {screen === "stores" ? (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "flex-start",
                overflowY: "auto",
                overflowX: "hidden",
                flexDirection: "column",
                scrollbarWidth: "none",
              }}
            >
              {facilitiesArray
                ? facilitiesArray.store.map((store, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          width: "100%",
                          marginBottom:
                            index === facilitiesArray.store.length - 1 ? 60 : 0,
                        }}
                      >
                        <StoresInventoryDisplayCard
                          search={search}
                          storeName={store.storeName}
                          store={store}
                          type={store.type}
                          auth={auth}
                          warehouseInventoryList={warehouseInventoryList}
                        />
                      </div>
                    );
                  })
                : null}
            </div>
          ) : null}
        </div>
      ) : null}
      {subSelection === "dashboard" ? <InventoryDashboard /> : null}
      {subSelection === "transfers" ? (
        <Transfers
          auth={auth}
          setLoading={setLoading}
          setMessage={setMessage}
          setToastPop={setToastPop}
        />
      ) : null}
      {subSelection === "countStockTake" ? (
        <div style={{ width: "100%", flex: 1, borderStyle: "solid" }}>
          Count Stock Take
        </div>
      ) : null}
      <UploadItem
        setRecievingList={setRecievingList}
        multiRecieveArray={multiRecieveArray}
        setMultiRecieve={setMultiRecieve}
        selectedProduct={selectedProduct}
        setSelectedProd={setSelectedProd}
        setLoading={setLoading}
        display={
          uploadItemPopUp && subSelection === "myInventory" ? true : false
        }
        onCloseClick={() => setUploadItemPopUp(false)}
        setUploadItemPopUp={setUploadItemPopUp}
        setUploadLinkedFaclitiesPopUp={setUploadLinkedFaclitiesPopUp}
        setMultiRecieveArrayItem={setMultiRecieveArrayItem}
        multiRecieveArrayItem={multiRecieveArrayItem}
      />
      <MultiSelectReceivingPopUp
        recievingList={recievingList}
        multiRecieveArray={multiRecieveArray}
        display={multiRecieve}
        setMultiRecieve={setMultiRecieve}
        multiRecieveArrayItem={multiRecieveArrayItem}
        setLoading={setLoading}
        setMessage={setMessage}
        setToastPop={setToastPop}
        toastPop={toastPop}
        auth={auth}
        setMultiRecieveArrayItem={setMultiRecieveArrayItem}
        setUploadItemPopUp={setUploadItemPopUp}
      />
      <UploadLinkedFacilitiesPopUp
        selectedProduct={selectedProduct}
        setSelectedProd={setSelectedProd}
        setLoading={setLoading}
        display={
          uploadLinkedFaclitiesPopUp && subSelection === "myInventory"
            ? true
            : false
        }
        onCloseClick={() => setUploadLinkedFaclitiesPopUp(false)}
        setUploadItemPopUp={setUploadLinkedFaclitiesPopUp}
      />
      <CreateTransferPopUp
        display={transferCreate}
        setTransferCreate={setTransferCreate}
        auth={auth}
        setMessage={setMessage}
        setToastPop={setToastPop}
      />
      <ToastNotification
        display={toastPop}
        message={
          message
            ? message.toLocaleUpperCase()
            : "Contact support an error has occured"
        }
      />
      <LoadingPopUp display={loading} newWidth={"100%"} />
    </div>
  );
};

export default Inventory;
