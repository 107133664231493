import React, { useEffect, useState } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import { BaseButtons } from "../../../../../../components/atoms";

const ChangeCreditTip = ({
  excessAmount,
  total,
  paidSoFar,
  setExcessPayment,
  setUserVerified,
  setPin1,
  setPaidSoFar,
  setAmount,
  setSelectedBudTender,
  setCompleteSale,
  setRecieptList,
  setSelectedUser,
  setRefresh,
  refresh,
  setSelectedSubCategory,
  selectedSubCategory,
  selectedCategory,
  setSelectedCategory,
  setSearch,
  setCustomers,
  setFilterOption,
  filterOption,
  onCompleteClick,
  method,
  setMethod,
  CompletSaleCreateReciept,
  clicked,
  setClicked,
}) => {
  useEffect(() => {
    setClicked(false);
  }, []);
  return (
    <div
      style={{
        position: "absolute",
        paddingTop: 10,
        paddingBottom: 20,
        width: 600,
        backgroundColor: BlackAndWhite.secondary,
        borderRadius: 10,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          marginTop: 20,
          marginBottom: 50,
          fontWeight: "bold",
          fontSize: 30,
          textAlign: "center",
        }}
      >
        CHANGE / CREDIT
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-evenly",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: 250,
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: 30, fontWeight: "bold" }}>
            R {total ? total.toFixed(2) : (0).toFixed(2)}
          </div>
          <div style={{ fontSize: 14, fontWeight: "bold", opacity: 0.6 }}>
            AMOUNT DUE
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: 250,
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: 30, fontWeight: "bold" }}>
            R {paidSoFar ? paidSoFar.toFixed(2) : (0).toFixed(2)}
          </div>
          <div style={{ fontSize: 14, fontWeight: "bold", opacity: 0.6 }}>
            TOTAL PAID
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: 250,
            alignItems: "center",
          }}
        >
          <div style={{ fontSize: 30, fontWeight: "bold" }}>
            R{" "}
            {paidSoFar
              ? (paidSoFar.toFixed(2) - total.toFixed(2)).toFixed(2)
              : (0).toFixed(2)}
          </div>
          <div style={{ fontSize: 14, fontWeight: "bold", opacity: 0.6 }}>
            CHANGE
          </div>
        </div>
      </div>
      {total === paidSoFar ? null : (
        <div
          style={{
            width: "88%",
            textAlign: "center",
            marginBottom: 20,
            fontWeight: "bold",
            fontSize: 16,
            marginTop: 40,
          }}
        >
          What would you like to do with the Change?
        </div>
      )}
      {total === paidSoFar ? null : (
        <div style={{ display: "flex" }}>
          <BaseButtons
            mini={true}
            label={"STORE CREDIT"}
            onClick={() => setMethod("credit")}
            invert={method === "credit" ? false : true}
            marginTopFalse={true}
          />
          <BaseButtons
            mini={true}
            label={"TIP"}
            onClick={() => setMethod("tip")}
            invert={method === "tip" ? false : true}
            marginTopFalse={true}
          />
          <BaseButtons
            mini={true}
            label={"CHANGE"}
            onClick={() => setMethod("change")}
            invert={method === "change" ? false : true}
            marginTopFalse={true}
          />
        </div>
      )}
      <BaseButtons
        mini={true}
        label={"COMPLETE PURCHASE"}
        size={"90%"}
        borderRadius={8}
        onClick={
          clicked
            ? null
            : () => {
                setClicked(true);
                CompletSaleCreateReciept();
              }
        }
        disable={clicked}
      />
    </div>
  );
};

export default ChangeCreditTip;
