import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import {
  BaseButtons,
  TextInputField,
} from "../../../../../../components/atoms";

const CashRegisterViewPopUp = ({
  display,
  setCashRegisterOpen,
  setPinCheckPopUp,
  user,
  setLoading,
  setMessage,
  setToastPop,
  setTillAmount,
  tillamount,
  onOpenClick,
  onCloseClick,
}) => {
  const [pin1, setPin1] = useState("0");

  const [selectedBudtender, setselectedBudtender] = useState(null);
  const [staffArray, setStaffArray] = useState(null);

  const [userVerfiied, setUserVerified] = useState(false);

  const GetStoreUsers = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/processSale/getAllStoreUsers",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storeName: user.adminUserData.storeName,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setStaffArray(responseData.storeUsers);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };
  const ConfirmAccess = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/processSale/checkUserAccessPin",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storeName: user.adminUserData.storeName,
            userId: user.userId,
            pin: pin1,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setUserVerified(true);
        setLoading(false);
      } else {
        setLoading(false);
        setMessage(responseData.message);
        setToastPop(true);
      }
    } catch (err) {
      setLoading(false);
      setMessage("No network try again later");
      setToastPop(true);
      console.log("error", err);
    }
  };
  useEffect(() => {
    if (pin1.length === 4) {
      ConfirmAccess();
    }
  }, [pin1]);
  useEffect(() => {
    GetStoreUsers();
    //get all store users
  }, []);
  return (
    <div
      style={{
        position: "fixed",
        height: display ? "100%" : 0,
        width: "100%",
        backgroundColor: "#00000050",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        top: 0,
        left: 0,
        zIndex: 2000000000,
      }}
    >
      <div
        style={{
          minHeight: 600,
          width: 500,
          backgroundColor: BlackAndWhite.secondary,
          borderRadius: 8,
          display: "flex",
          flexDirection: "column",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <>
          <div
            style={{
              display: "flex",
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                fontSize: 22,
                width: "100%",
                marginTop: 20,
                marginBottom: 20,
                textAlign: "center",
              }}
            >
              OPENING FLOAT
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontSize: 50,
                  fontWeight: "bold",
                  marginTop: 30,
                  marginRight: 30,
                }}
              >
                R
              </div>
              <div style={{ marginRight: 60 }}>
                <TextInputField
                  mini={true}
                  width={250}
                  size={"100%"}
                  labelTitle={"ADD AMOUNT TO OPEN REGISTER"}
                  invert={true}
                  fontsize={tillamount !== "" ? 50 : 23}
                  // type={"Number"}
                  placeholder={"ADD AMOUNT HERE"}
                  value={tillamount}
                  height={80}
                  borderRadius={8}
                />
              </div>
            </div>
            <div style={{ minHeight: 10 }} />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                flex: 0.25,

                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 7,
              }}
            >
              <BaseButtons
                label={"1"}
                fontSize={25}
                mini={true}
                marginTopFalse={true}
                size={90}
                height={70}
                borderRadius={8}
                onClick={() => setTillAmount(tillamount + "1")}
                marginRight={10}
              />
              <BaseButtons
                label={"2"}
                fontSize={25}
                mini={true}
                marginTopFalse={true}
                size={90}
                height={70}
                borderRadius={8}
                onClick={() => setTillAmount(tillamount + "2")}
                marginRight={10}
              />
              <BaseButtons
                label={"3"}
                fontSize={25}
                mini={true}
                marginTopFalse={true}
                size={90}
                height={70}
                borderRadius={8}
                onClick={() => setTillAmount(tillamount + "3")}
              />
            </div>
            <div
              style={{
                flex: 0.25,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 7,
              }}
            >
              <BaseButtons
                label={"4"}
                fontSize={25}
                mini={true}
                marginTopFalse={true}
                size={90}
                height={70}
                borderRadius={8}
                onClick={() => setTillAmount(tillamount + "4")}
                marginRight={10}
              />
              <BaseButtons
                label={"5"}
                fontSize={25}
                mini={true}
                marginTopFalse={true}
                size={90}
                height={70}
                borderRadius={8}
                onClick={() => setTillAmount(tillamount + "5")}
                marginRight={10}
              />
              <BaseButtons
                label={"6"}
                fontSize={25}
                mini={true}
                marginTopFalse={true}
                size={90}
                height={70}
                borderRadius={8}
                onClick={() => setTillAmount(tillamount + "6")}
              />
            </div>
            <div
              style={{
                flex: 0.25,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 7,
              }}
            >
              <BaseButtons
                label={"7"}
                fontSize={25}
                mini={true}
                marginTopFalse={true}
                size={90}
                height={70}
                borderRadius={8}
                onClick={() => setTillAmount(tillamount + "7")}
                marginRight={10}
              />
              <BaseButtons
                label={"8"}
                fontSize={25}
                mini={true}
                marginTopFalse={true}
                size={90}
                height={70}
                borderRadius={8}
                onClick={() => setTillAmount(tillamount + "8")}
                marginRight={10}
              />
              <BaseButtons
                label={"9"}
                fontSize={25}
                mini={true}
                marginTopFalse={true}
                size={90}
                height={70}
                borderRadius={8}
                onClick={() => setTillAmount(tillamount + "9")}
              />
            </div>
            <div
              style={{
                flex: 0.25,

                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BaseButtons
                label={"."}
                fontSize={25}
                mini={true}
                marginTopFalse={true}
                size={90}
                height={70}
                borderRadius={8}
                onClick={() => setTillAmount(tillamount + ".")}
                marginRight={10}
              />
              <BaseButtons
                label={"0"}
                fontSize={25}
                mini={true}
                marginTopFalse={true}
                size={90}
                height={70}
                borderRadius={8}
                onClick={() => setTillAmount(tillamount + "0")}
                marginRight={10}
              />
              <BaseButtons
                label={"BACK"}
                fontSize={20}
                mini={true}
                marginTopFalse={true}
                size={90}
                height={70}
                borderRadius={8}
                onClick={() =>
                  setTillAmount(tillamount.substring(0, tillamount.length - 1))
                }
              />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
              marginBottom: 20,
              marginTop: 20,
            }}
          >
            <BaseButtons
              mini={true}
              marginTopFalse={true}
              borderRadius={8}
              label={"CANCEL"}
              size={"45%"}
              onClick={() => setCashRegisterOpen(false)}
            />
            <BaseButtons
              mini={true}
              marginTopFalse={true}
              borderRadius={8}
              label={"OPEN REGISTER"}
              size={"45%"}
              onClick={onOpenClick}
            />
          </div>
        </>
      </div>
    </div>
  );
};

export default CashRegisterViewPopUp;
