import React, { useState, useEffect } from "react";
import useWindowDimensions from "../../get-diemensions/UseWindowDiemensions";
import { BlackAndWhite } from "../../../themes/Colors";
import CloseButton from "../close-button/CloseButton";
import TextInputField from "../text-input-field/TextInputField";
import BaseButtons from "../base-buttons/BaseButtons";
import CheckInput from "../check-input/CheckInput";
import HelpPopUp from "./components/HelpPopUp";
import LoadingPopUp from "../../loading-pop-up/LoadingPopUp";
import ToastNotification from "../../toast-notification/ToastNotifictaion";
import SelectMonth from "./components/SelectMonth";
import SelectDay from "./components/SelectDay";
import DropDownWithButton from "../../drop-down-with-button/DropDownWithButton";
import { TermsDisplay } from "../../../modules/customer-portal/components/mobile-layout/components/profile/components";
import ProvinceSelector from "../../../modules/customer-portal/components/validate-profile/components/province-selector/ProvinceSelector";
import StoreSelector from "../../../modules/customer-portal/components/validate-profile/components/store-selector/StoreSelector";

const RegistrationTab = ({
  display,
  onCloseClick,
  onQuestionClick,
  helpPopUp,
  onGotItClick,
  onNotClick,
  setRegistration,
  setLogin,
  setDisplayButtons,
  setError,
}) => {
  const [userType, setUserType] = useState("CLECK HERE TO SELECT MEMBER");
  const [signedAt, setSignedAt] = useState("");

  const [displayTc, setDisplayTc] = useState(false);

  const { width, height } = useWindowDimensions();
  const [tandC, SetTandC] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [idNum, setIdNum] = useState("");
  const [gender, setGender] = useState("");
  const [cellNumber, setCellNumber] = useState("");
  const [email, setEmail] = useState("");
  const [suburb, setSuburb] = useState("");
  const [town, setTown] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConformPassword] = useState(null);
  const [street, setStreet] = useState("");
  const [streetNumber, setStreetNumber] = useState("");

  const [province, setProvince] = useState(null);
  const [provinceOpen, setProvinceOpen] = useState(false);
  const [store, setStore] = useState(null);
  const [storeOpen, setStoreOpen] = useState(false);

  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [reason, setReason] = useState("none");

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("LOADING ...");
  const [toastPop, setToastPop] = useState(false);
  const [message, setMessage] = useState("");
  const [passport, setPassport] = useState("");
  const [isSouthAfrican, setIsSouthAfrican] = useState(true);

  const checkIdnumber = (year.substring(2, 4) + month + day).toString();

  const idOrPassportValidationCheck = () => {
    if (isSouthAfrican && idNum.length === 13) {
      return idNum.includes(checkIdnumber);
    } else {
      return idNum > 6;
    }
  };
  const registerationHandler = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/user/reg",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `bearer ${token}`,
          },
          body: JSON.stringify({
            firstName: firstName.replace(/\\|\//g, ""),
            lastName: lastname.replace(/\\|\//g, ""),
            cellNumber: cellNumber.replace(/\\|\//g, ""),
            email: email.toLowerCase().replace(/\\|\//g, ""),
            idDoc:
              idNum.replace(/\\|\//g, "") || passport.replace(/\\|\//g, ""),
            gender: gender,
            street: streetNumber + " " + street,
            suburb: suburb,
            town: town,
            dateOfBirth: day + "/" + month + "/" + year,
            password: password,
            acceptedTandC: tandC,
            reason: reason,
            signedAt: signedAt,
            province: province,
            store: store,
          }),
        }
      );

      const respnseData = await response.json();
      if (respnseData.userId) {
        setLoading(false);
        setRegistration(false);
        setLogin(true);
        setError("Your registration was succesful");
        // setToastPop(true);
      } else {
        setLoading(false);
        setErrorMessage(
          "Unable to register because, " + " " + respnseData.message
        );
        setToastPop(true);
      }
    } catch (err) {
      setLoading(false);
      setErrorMessage(
        "Unable to resgister account, some of the information provided is already in our system" +
          " " +
          err
      );
      setToastPop(true);
    }
  };
  // const validateRegistration = () => {
  //   if()
  // }
  useEffect(() => {
    if (toastPop) {
      setTimeout(() => {
        setToastPop(false);
      }, 8000);
    }
  }, [toastPop]);
  return (
    <div
      style={{
        height: display ? height : 0,
        width: width,
        backgroundColor: "#00000033",
        overflow: "hidden",
        transition: "all 0.5s",
        position: "fixed",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        top: 0,
      }}
    >
      {/* <div style={{ color: BlackAndWhite.secondary, flex: 0.05 }} /> */}
      <div
        style={{
          color: BlackAndWhite.secondary,
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          //   borderStyle: "solid",
          backgroundColor: "#000000cc",
          width: width > 899 ? "35%" : "100%",
          position: "relative",
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
          overflow: "hidden",
        }}
      >
        <div style={{ position: "absolute", top: 20, left: 20 }}>
          <CloseButton
            onClick={onCloseClick}
            label={"X"}
            width={50}
            invert={true}
          />
        </div>
        <div style={{ position: "absolute", top: 20, right: 20 }}>
          <CloseButton
            onClick={onQuestionClick}
            label={"?"}
            width={50}
            invert={true}
          />
        </div>
        <h2 style={{ marginTop: 35, fontFamily: "Montserrat" }}>
          REGISTRATION
        </h2>

        <div
          style={{ width: "100%", overflowY: "scroll", scrollbarWidth: "none" }}
        >
          {/* <div style={{ marginLeft: 40, marginBottom: 10 }}>
            Select Account Type
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DropDownWithButton
              title={userType}
              width={"100%"}
              data={["SOCIAL CLUB", "GROWER"]}
              setSelected={setUserType}
              dropdownHeight={100}
            />
          </div> */}
          <TextInputField
            labelTitle={"First name"}
            size={"80%"}
            onChange={(event) => setFirstName(event.target.value)}
            rule={firstName.length >= 2 ? true : false}
            reqiredCheck={true}
          />
          <TextInputField
            labelTitle={"Last name"}
            size={"80%"}
            onChange={(event) => setLastName(event.target.value)}
            rule={lastname.length >= 2 ? true : false}
            reqiredCheck={true}
          />
          <div
            style={{
              width: "90%",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              height: 120,
              flexDirection: "column",

              marginTop: 30,
              marginLeft: 20,
              borderRadius: 10,
              marginBottom: 10,
            }}
          >
            <div
              style={{
                fontSize: 16,
                color: BlackAndWhite.secondary,
                width: "100%",
                textAlign: "left",
                marginLeft: 40,
                // marginBottom: 10,
                position: "relative",
              }}
            >
              Date of birth:
              <div
                style={{
                  position: "absolute",
                  right: 40,
                  top: 0,
                  color:
                    day >= 1 &&
                    day <= 31 &&
                    month >= 1 &&
                    month <= 12 &&
                    Number("2025") - Number(year) >= 18 &&
                    Number("2025") - Number(year) <= 120 &&
                    Number(year) !== 0
                      ? "#5EEB5B"
                      : "#FFFFFF",
                }}
              >
                {day >= 1 &&
                day <= 31 &&
                month >= 1 &&
                month <= 12 &&
                Number("2025") - Number(year) >= 18 &&
                Number("2025") - Number(year) <= 120 &&
                Number(year) !== 0
                  ? "complete"
                  : "required"}
              </div>
            </div>
            <div
              style={{
                // backgroundColor: "#FFFFFF66",
                padding: 5,
                borderRadius: 4,
                display: "flex",
              }}
            >
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <div style={{ marginBottom: 10 }}>day</div>
                <div
                  onClick={() => setDay("open")}
                  style={{
                    fontSize: day ? 16 : 12,
                    height: 48,
                    width: 80,
                    backgroundColor: BlackAndWhite.secondary,
                    color: BlackAndWhite.primary,
                    borderRadius: 50,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  {day ? day : "Click here"}
                </div>
                <SelectDay
                  display={day === "open" ? true : null}
                  setDay={setDay}
                  day={day}
                />
              </div>
              <div style={{ width: width > 899 ? 30 : 10 }} />
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <div style={{ marginBottom: 10 }}>month</div>
                <div
                  onClick={() => setMonth("open")}
                  style={{
                    fontSize: month ? 16 : 12,
                    height: 48,
                    width: 80,
                    backgroundColor: BlackAndWhite.secondary,
                    color: BlackAndWhite.primary,
                    borderRadius: 50,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  {month ? month : "Click here"}
                </div>
                <SelectMonth
                  display={month === "open" ? true : null}
                  setMonth={setMonth}
                />
              </div>

              {/* <TextInputField
                labelTitle={"month"}
                size={width > 899 ? 60 : height > 550 ? 50 : 40}
                onChange={(event) => setMonth(event.target.value)}
              /> */}
              <div style={{ width: width > 899 ? 30 : 10 }} />
              <TextInputField
                labelTitle={"year"}
                size={width > 899 ? 70 : 60}
                onChange={(event) => setYear(event.target.value)}
              />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                width: "80%",
                marginTop: 20,
                fontSize: 16,
                color: BlackAndWhite.secondary,
              }}
            >
              Are you a SA citizen?
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              height: 85,
              overflow: "hidden",
            }}
          >
            <div style={{ position: "absolute", left: width > 899 ? 20 : -20 }}>
              <CheckInput
                width={width}
                question={"Yes"}
                check={isSouthAfrican ? true : false}
                onClick={() => setIsSouthAfrican(true)}
                size={220}
              />
            </div>
            <div style={{ position: "absolute", right: -10 }}>
              <CheckInput
                width={width}
                question={"No"}
                check={isSouthAfrican ? false : true}
                onClick={() => setIsSouthAfrican(false)}
                size={220}
              />
            </div>
          </div>
          {/* <TextInputField
              labelTitle={"date of birth"}
              size={"80%"}
              onChange={(event) => setDateOfBirth(event.target.value)}
            /> */}

          {isSouthAfrican ? (
            <TextInputField
              labelTitle={"ID-Number"}
              size={"80%"}
              onChange={(event) => setIdNum(event.target.value)}
              rule={
                idNum.length === 13 && idNum.includes(checkIdnumber)
                  ? true
                  : false
              }
              reqiredCheck={true}
            />
          ) : (
            <TextInputField
              labelTitle={"Passport"}
              size={"80%"}
              onChange={(event) => setPassport(event.target.value)}
              rule={passport.length > 6 ? true : false}
              reqiredCheck={true}
            />
          )}

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                width: "80%",
                marginTop: 30,
                fontSize: 16,
                color: BlackAndWhite.secondary,
              }}
            >
              Medical gender
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              height: 100,
              overflow: "hidden",
            }}
          >
            <div style={{ position: "absolute", left: width > 899 ? 20 : -20 }}>
              <CheckInput
                width={width}
                question={"MALE"}
                check={gender === "male" ? true : false}
                onClick={() => setGender("male")}
                size={220}
              />
            </div>
            <div style={{ position: "absolute", right: -10 }}>
              <CheckInput
                width={width}
                question={"FEMALE"}
                check={gender === "female" ? true : false}
                onClick={() => setGender("female")}
                size={220}
              />
            </div>
          </div>

          <TextInputField
            labelTitle={"SA cell number"}
            size={"80%"}
            onChange={(event) => setCellNumber(event.target.value)}
            rule={cellNumber.length === 10 ? true : false}
            reqiredCheck={true}
          />
          <TextInputField
            labelTitle={"Email Address"}
            size={"80%"}
            rule={
              email.length >= 7 && email.includes("@") && email.includes(".")
                ? true
                : false
            }
            reqiredCheck={true}
            onChange={(event) => setEmail(event.target.value)}
          />
          <TextInputField
            labelTitle={"Street Number"}
            size={"80%"}
            rule={streetNumber.length >= 1 ? true : false}
            reqiredCheck={true}
            onChange={(event) => setStreetNumber(event.target.value)}
          />
          <TextInputField
            labelTitle={"Street"}
            size={"80%"}
            rule={street.length >= 2 ? true : false}
            reqiredCheck={true}
            onChange={(event) => setStreet(event.target.value)}
          />
          <TextInputField
            labelTitle={"Suburb"}
            size={"80%"}
            rule={suburb.length >= 2 ? true : false}
            reqiredCheck={true}
            onChange={(event) => setSuburb(event.target.value)}
          />
          <TextInputField
            labelTitle={"Town"}
            size={"80%"}
            rule={town.length >= 2 ? true : false}
            reqiredCheck={true}
            onChange={(event) => setTown(event.target.value)}
          />
          <ProvinceSelector
            open={provinceOpen}
            setProvince={setProvince}
            province={province}
            onProvinceClick={() => setProvinceOpen(true)}
            setProvinceOpen={setProvinceOpen}
          />
          <StoreSelector
            open={storeOpen}
            setStore={setStore}
            store={store}
            setStoreOPen={setStoreOpen}
            onStoreClick={() => setStoreOpen(true)}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ width: "80%", textAlign: "left", marginTop: 20 }}>
              Password must have a minimum of 8 characters
            </div>
          </div>
          <TextInputField
            labelTitle={"Password"}
            size={"80%"}
            rule={password.length >= 8 ? true : false}
            reqiredCheck={true}
            onChange={(event) => setPassword(event.target.value)}
          />
          <TextInputField
            labelTitle={"Confirm Password"}
            size={"80%"}
            rule={password === confirmPassword ? true : false}
            reqiredCheck={true}
            onChange={(event) => setConformPassword(event.target.value)}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <BaseButtons
              mini={true}
              borderRadius={8}
              width={"100%"}
              size={"80%"}
              label={"VIEW MEMBERS AGREEMENT"}
              invert={true}
              onClick={() => setDisplayTc(true)}
            />
          </div>
          {/* <div>
            <CheckInput check={tandC} question={"ACCEPT MEMERS AGGREMENT"} />
          </div> */}
          {/* <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ width: "80%", marginTop: 20, marginBottom: 20 }}>
              We understand you're seeking help and relief. Weed can assist with
              various health issues in small amounts. Let us know which concerns
              are most immediate, so we can address them while understanding
              more about your needs.
            </div>

            <div style={{ width: "80%" }}>
              Please select one of the following options:
            </div>
            <CheckInput
              check={reason === "Pain & Inflamation" ? true : false}
              question={"Pain & Inflamation"}
              size={width > 899 ? "86%" : "96%"}
              onClick={() => setReason("Pain & Inflamation")}
            />
            <CheckInput
              check={reason === "Anxiety, PTSD & Stress relief" ? true : false}
              question={"Anxiety, PTSD & Stress relief"}
              size={width > 899 ? "86%" : "96%"}
              onClick={() => setReason("Anxiety, PTSD & Stress relief")}
            />
            <CheckInput
              check={reason === "Sleep / Insomnia treatment" ? true : false}
              question={"Sleep / Insomnia treatment"}
              size={width > 899 ? "86%" : "96%"}
              onClick={() => setReason("Sleep / Insomnia treatment")}
            />
            <CheckInput
              check={reason === "Fatigue" ? true : false}
              question={"Fatigue"}
              size={width > 899 ? "86%" : "96%"}
              onClick={() => setReason("Fatigue")}
            />
            <CheckInput
              check={reason === "Tension or muscle spasms" ? true : false}
              question={"Tension or muscle spasms"}
              size={width > 899 ? "86%" : "96%"}
              onClick={() => setReason("Tension or muscle spasms")}
            />
            <CheckInput
              check={reason === "Appetite / Weight loss" ? true : false}
              question={"Appetite / Weight loss"}
              size={width > 899 ? "86%" : "96%"}
              onClick={() => setReason("Appetite / Weight loss")}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginTop: 20,
                marginBottom: 20,
                // width: "80%",
                // textAlign: "left",
              }}
            >
              Click below to accept T&C's
            </div>
            <CheckInput
              width={width}
              question={"Accept terms & conditions?"}
              check={tandC}
              onClick={() => SetTandC(tandC ? false : true)}
              size={width > 899 ? "86%" : "86%"}
            />
          </div> */}

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            <BaseButtons
              // disable={!loading}
              invert={true}
              label={"SUBMIT"}
              mini={true}
              borderRadius={8}
              disable={
                isSouthAfrican
                  ? confirmPassword === password &&
                    tandC &&
                    firstName.length >= 2 &&
                    lastname.length >= 2 &&
                    cellNumber.length === 10 &&
                    suburb.length >= 2 &&
                    town.length >= 2 &&
                    gender !== "" &&
                    day >= 1 &&
                    day <= 31 &&
                    month >= 1 &&
                    month <= 12 &&
                    idNum.length === 13 &&
                    store &&
                    province &&
                    email.includes("@") &&
                    email.includes(".") &&
                    Number("2025") - Number(year) >= 18 &&
                    Number("2025") - Number(year) <= 120 &&
                    Number(year) !== 0
                    ? false
                    : true
                  : confirmPassword === password &&
                    tandC &&
                    firstName.length >= 2 &&
                    lastname.length >= 2 &&
                    cellNumber.length === 10 &&
                    suburb.length >= 2 &&
                    town.length >= 2 &&
                    gender !== "" &&
                    day >= 1 &&
                    day <= 31 &&
                    month >= 1 &&
                    month <= 12 &&
                    passport.length >= 6 &&
                    store &&
                    province &&
                    email.includes("@") &&
                    email.includes(".") &&
                    Number("2025") - Number(year) >= 18 &&
                    Number("2025") - Number(year) <= 120 &&
                    Number(year) !== 0
                  ? false
                  : true
              }
              onClick={() => registerationHandler()}
              width={"100%"}
              size={"80%"}
            />
          </div>
        </div>
      </div>
      <TermsDisplay
        display={displayTc}
        terms={"social"}
        onCloseClick={() => setDisplayTc(false)}
        userName={firstName + " " + lastname}
        signedAt={signedAt}
        setSignedAt={setSignedAt}
        onAcceptClick={() => {
          SetTandC(true);
          setDisplayTc(false);
        }}
      />
      <HelpPopUp
        width={width}
        onGotItClick={onGotItClick}
        onNotClick={onNotClick}
        display={helpPopUp}
      />
      {loading ? <LoadingPopUp display={loading} /> : null}
      <ToastNotification display={toastPop} message={errorMessage} />
    </div>
  );
};

export default RegistrationTab;
