import React, { useState } from "react";
import { BlackAndWhite } from "../../../../themes/Colors";
import { CloseButton } from "../../../../components/atoms";
import { Bar, Pie, Line, Stacked, Doughnut } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { BaseStatBlock } from "./components";

const StatPopUp = ({ display, onCloseClick, statType, dataPoint }) => {
  const [graphType, setGraphType] = useState(false);
  const [selectedGraph, setSelectedGraph] = useState("week");
  let toUpper = statType.toUpperCase();
  let dateCheck = new Date();
  let minus2days = new Date();
  let minus3days = new Date();
  let minus4days = new Date();
  let minus5days = new Date();
  let minus6days = new Date();
  let minus7days = new Date();
  let minus8days = new Date();
  let minus9days = new Date();
  let minus10days = new Date();
  let minus11days = new Date();
  let minus12days = new Date();
  let minus13days = new Date();
  let minus14days = new Date();
  let minus15days = new Date();
  let minus16days = new Date();
  let minus17days = new Date();
  let minus18days = new Date();
  let minus19days = new Date();
  let minus20days = new Date();
  let minus21days = new Date();
  let minus22days = new Date();
  let minus23days = new Date();
  let minus24days = new Date();
  let minus25days = new Date();
  let minus26days = new Date();
  let minus27days = new Date();
  let minus28days = new Date();
  let minus29days = new Date();

  const day7Stats = {
    femaleRatio: dataPoint
      ? ((dataPoint.femalePatients / dataPoint.allPatinetsCount) * 100).toFixed(
          2
        )
      : "loading",
  };

  dateCheck.setDate(dateCheck.getDate() - 1);
  minus2days.setDate(minus2days.getDate() - 2);
  minus3days.setDate(minus3days.getDate() - 3);
  minus4days.setDate(minus4days.getDate() - 4);
  minus5days.setDate(minus5days.getDate() - 5);
  minus6days.setDate(minus6days.getDate() - 6);
  minus7days.setDate(minus7days.getDate() - 7);
  minus8days.setDate(minus8days.getDate() - 8);
  minus9days.setDate(minus9days.getDate() - 9);
  minus10days.setDate(minus10days.getDate() - 10);
  minus11days.setDate(minus11days.getDate() - 11);
  minus12days.setDate(minus12days.getDate() - 12);
  minus13days.setDate(minus13days.getDate() - 13);
  minus14days.setDate(minus14days.getDate() - 14);
  minus15days.setDate(minus15days.getDate() - 15);
  minus16days.setDate(minus16days.getDate() - 16);
  minus17days.setDate(minus17days.getDate() - 17);
  minus18days.setDate(minus18days.getDate() - 18);
  minus19days.setDate(minus19days.getDate() - 19);
  minus20days.setDate(minus20days.getDate() - 20);
  minus21days.setDate(minus21days.getDate() - 21);
  minus22days.setDate(minus22days.getDate() - 22);
  minus23days.setDate(minus23days.getDate() - 23);
  minus24days.setDate(minus24days.getDate() - 24);
  minus25days.setDate(minus25days.getDate() - 25);
  minus26days.setDate(minus26days.getDate() - 26);
  minus27days.setDate(minus27days.getDate() - 27);
  minus28days.setDate(minus28days.getDate() - 28);
  minus29days.setDate(minus29days.getDate() - 29);

  const labels = [
    minus6days.toDateString().substring(0, 3) +
      " " +
      minus6days.toDateString().substring(8, 11),
    minus5days.toDateString().substring(0, 3) +
      " " +
      minus5days.toDateString().substring(8, 11),
    minus4days.toDateString().substring(0, 3) +
      " " +
      minus4days.toDateString().substring(8, 11),
    minus3days.toDateString().substring(0, 3) +
      " " +
      minus3days.toDateString().substring(8, 11),
    minus2days.toDateString().substring(0, 3) +
      " " +
      minus2days.toDateString().substring(8, 11),
    dateCheck.toDateString().substring(0, 3) +
      " " +
      dateCheck.toDateString().substring(8, 11),
    new Date().toDateString().substring(0, 3) +
      " " +
      new Date().toDateString().substring(8, 11),
  ];
  const labels14days = [
    minus13days.toDateString().substring(0, 3) +
      " " +
      minus13days.toDateString().substring(8, 11),
    minus12days.toDateString().substring(0, 3) +
      " " +
      minus12days.toDateString().substring(8, 11),
    minus11days.toDateString().substring(0, 3) +
      " " +
      minus11days.toDateString().substring(8, 11),
    minus10days.toDateString().substring(0, 3) +
      " " +
      minus10days.toDateString().substring(8, 11),
    minus9days.toDateString().substring(0, 3) +
      " " +
      minus9days.toDateString().substring(8, 11),
    minus8days.toDateString().substring(0, 3) +
      " " +
      minus8days.toDateString().substring(8, 11),
    minus7days.toDateString().substring(0, 3) +
      " " +
      minus7days.toDateString().substring(8, 11),
    minus6days.toDateString().substring(0, 3) +
      " " +
      minus6days.toDateString().substring(8, 11),
    minus5days.toDateString().substring(0, 3) +
      " " +
      minus5days.toDateString().substring(8, 11),
    minus4days.toDateString().substring(0, 3) +
      " " +
      minus4days.toDateString().substring(8, 11),
    minus3days.toDateString().substring(0, 3) +
      " " +
      minus3days.toDateString().substring(8, 11),
    minus2days.toDateString().substring(0, 3) +
      " " +
      minus2days.toDateString().substring(8, 11),
    dateCheck.toDateString().substring(0, 3) +
      " " +
      dateCheck.toDateString().substring(8, 11),
    new Date().toDateString().substring(0, 3) +
      " " +
      new Date().toDateString().substring(8, 11),
  ];
  const labels30days = [
    minus29days.toDateString().substring(0, 3) +
      " " +
      minus29days.toDateString().substring(4, 11),
    minus28days.toDateString().substring(0, 3) +
      " " +
      minus28days.toDateString().substring(8, 11),
    minus27days.toDateString().substring(0, 3) +
      " " +
      minus27days.toDateString().substring(8, 11),
    minus26days.toDateString().substring(0, 3) +
      " " +
      minus26days.toDateString().substring(8, 11),
    minus25days.toDateString().substring(0, 3) +
      " " +
      minus25days.toDateString().substring(8, 11),
    minus24days.toDateString().substring(0, 3) +
      " " +
      minus24days.toDateString().substring(8, 11),
    minus23days.toDateString().substring(0, 3) +
      " " +
      minus23days.toDateString().substring(8, 11),
    minus22days.toDateString().substring(0, 3) +
      " " +
      minus22days.toDateString().substring(8, 11),
    minus21days.toDateString().substring(0, 3) +
      " " +
      minus21days.toDateString().substring(8, 11),
    minus20days.toDateString().substring(0, 3) +
      " " +
      minus20days.toDateString().substring(8, 11),
    minus19days.toDateString().substring(0, 3) +
      " " +
      minus19days.toDateString().substring(8, 11),
    minus18days.toDateString().substring(0, 3) +
      " " +
      minus18days.toDateString().substring(8, 11),
    minus17days.toDateString().substring(0, 3) +
      " " +
      minus17days.toDateString().substring(8, 11),
    minus16days.toDateString().substring(0, 3) +
      " " +
      minus16days.toDateString().substring(8, 11),
    minus15days.toDateString().substring(0, 3) +
      " " +
      minus15days.toDateString().substring(8, 11),
    minus14days.toDateString().substring(0, 3) +
      " " +
      minus14days.toDateString().substring(8, 11),
    minus13days.toDateString().substring(0, 3) +
      " " +
      minus13days.toDateString().substring(8, 11),
    minus12days.toDateString().substring(0, 3) +
      " " +
      minus12days.toDateString().substring(8, 11),
    minus11days.toDateString().substring(0, 3) +
      " " +
      minus11days.toDateString().substring(8, 11),
    minus10days.toDateString().substring(0, 3) +
      " " +
      minus10days.toDateString().substring(8, 11),
    minus9days.toDateString().substring(0, 3) +
      " " +
      minus9days.toDateString().substring(8, 11),
    minus8days.toDateString().substring(0, 3) +
      " " +
      minus8days.toDateString().substring(8, 11),
    minus7days.toDateString().substring(0, 3) +
      " " +
      minus7days.toDateString().substring(8, 11),
    minus6days.toDateString().substring(0, 3) +
      " " +
      minus6days.toDateString().substring(8, 11),
    minus5days.toDateString().substring(0, 3) +
      " " +
      minus5days.toDateString().substring(8, 11),
    minus4days.toDateString().substring(0, 3) +
      " " +
      minus4days.toDateString().substring(8, 11),
    minus3days.toDateString().substring(0, 3) +
      " " +
      minus3days.toDateString().substring(8, 11),
    minus2days.toDateString().substring(0, 3) +
      " " +
      minus2days.toDateString().substring(8, 11),
    dateCheck.toDateString().substring(0, 3) +
      " " +
      dateCheck.toDateString().substring(8, 11),
    new Date().toDateString().substring(0, 3) +
      " " +
      new Date().toDateString().substring(4, 11),
  ];
  const days90compareLabels = [
    minus29days.toDateString().substring(0, 3) +
      " " +
      minus29days.toDateString().substring(4, 11),
    minus28days.toDateString().substring(0, 3) +
      " " +
      minus28days.toDateString().substring(8, 11),
    minus27days.toDateString().substring(0, 3) +
      " " +
      minus27days.toDateString().substring(8, 11),
    minus26days.toDateString().substring(0, 3) +
      " " +
      minus26days.toDateString().substring(8, 11),
    minus25days.toDateString().substring(0, 3) +
      " " +
      minus25days.toDateString().substring(8, 11),
    minus24days.toDateString().substring(0, 3) +
      " " +
      minus24days.toDateString().substring(8, 11),
    minus23days.toDateString().substring(0, 3) +
      " " +
      minus23days.toDateString().substring(8, 11),
    minus22days.toDateString().substring(0, 3) +
      " " +
      minus22days.toDateString().substring(8, 11),
    minus21days.toDateString().substring(0, 3) +
      " " +
      minus21days.toDateString().substring(8, 11),
    minus20days.toDateString().substring(0, 3) +
      " " +
      minus20days.toDateString().substring(8, 11),
    minus19days.toDateString().substring(0, 3) +
      " " +
      minus19days.toDateString().substring(8, 11),
    minus18days.toDateString().substring(0, 3) +
      " " +
      minus18days.toDateString().substring(8, 11),
    minus17days.toDateString().substring(0, 3) +
      " " +
      minus17days.toDateString().substring(8, 11),
    minus16days.toDateString().substring(0, 3) +
      " " +
      minus16days.toDateString().substring(8, 11),
    minus15days.toDateString().substring(0, 3) +
      " " +
      minus15days.toDateString().substring(8, 11),
    minus14days.toDateString().substring(0, 3) +
      " " +
      minus14days.toDateString().substring(8, 11),
    minus13days.toDateString().substring(0, 3) +
      " " +
      minus13days.toDateString().substring(8, 11),
    minus12days.toDateString().substring(0, 3) +
      " " +
      minus12days.toDateString().substring(8, 11),
    minus11days.toDateString().substring(0, 3) +
      " " +
      minus11days.toDateString().substring(8, 11),
    minus10days.toDateString().substring(0, 3) +
      " " +
      minus10days.toDateString().substring(8, 11),
    minus9days.toDateString().substring(0, 3) +
      " " +
      minus9days.toDateString().substring(8, 11),
    minus8days.toDateString().substring(0, 3) +
      " " +
      minus8days.toDateString().substring(8, 11),
    minus7days.toDateString().substring(0, 3) +
      " " +
      minus7days.toDateString().substring(8, 11),
    minus6days.toDateString().substring(0, 3) +
      " " +
      minus6days.toDateString().substring(8, 11),
    minus5days.toDateString().substring(0, 3) +
      " " +
      minus5days.toDateString().substring(8, 11),
    minus4days.toDateString().substring(0, 3) +
      " " +
      minus4days.toDateString().substring(8, 11),
    minus3days.toDateString().substring(0, 3) +
      " " +
      minus3days.toDateString().substring(8, 11),
    minus2days.toDateString().substring(0, 3) +
      " " +
      minus2days.toDateString().substring(8, 11),
    dateCheck.toDateString().substring(0, 3) +
      " " +
      dateCheck.toDateString().substring(8, 11),
    new Date().toDateString().substring(0, 3) +
      " " +
      new Date().toDateString().substring(8, 11),
  ];
  const labelsWeeklyCompare = [
    minus6days.toDateString().substring(0, 3),
    minus5days.toDateString().substring(0, 3),
    minus4days.toDateString().substring(0, 3),
    minus3days.toDateString().substring(0, 3),
    minus2days.toDateString().substring(0, 3),
    dateCheck.toDateString().substring(0, 3),
    new Date().toDateString().substring(0, 3),
  ];
  // nice pink
  //   rgb(255, 99, 132)
  const data = {
    labels: labels,
    datasets: [
      {
        label: "Patients registered over 7 days", // Setting up the label for the dataset
        backgroundColor: "#FAA919", // Setting up the background color for the dataset
        borderColor: "#FAA919", // Setting up the border color for the dataset
        data: [
          dataPoint ? dataPoint.back6days : "loading",
          dataPoint ? dataPoint.back5days : "loading",
          dataPoint ? dataPoint.back4days : "loading",
          dataPoint ? dataPoint.back3days : "loading",
          dataPoint ? dataPoint.back2days : "loading",
          dataPoint ? dataPoint.yesterday : "loading",
          dataPoint ? dataPoint.today : "loading",
        ],
        // Setting up the data for the dataset
      },
    ],
  };
  const completedProfiledata = {
    labels: ["60%", "70%", "80%", "90%", "100%"],
    datasets: [
      {
        label: "patients profile completion status", // Setting up the label for the dataset
        backgroundColor: [
          "#FAA919",
          "#48A14D",
          "rgb(255, 99, 132)",
          "#B33F40",
          "#008080",
        ], // Setting up the background color for the dataset
        // borderColor: [BlackAndWhite.primary], // Setting up the border color for the dataset
        data: [
          dataPoint ? dataPoint.completionRatio.justRegistered : "loading",
          dataPoint ? dataPoint.completionRatio.percentSeventy : "loading",
          dataPoint ? dataPoint.completionRatio.percentEighty : "loading",
          dataPoint ? dataPoint.completionRatio.percentNinety : "loading",
          dataPoint ? dataPoint.completionRatio.percentOneHundred : "loading",
        ],
        // Setting up the data for the dataset
      },
    ],
  };
  const dataWeeklyCompare = {
    labels: labelsWeeklyCompare,
    datasets: [
      {
        label: "this week", // Setting up the label for the dataset
        backgroundColor: "#FAA919", // Setting up the background color for the dataset
        borderColor: "#FAA919", // Setting up the border color for the dataset
        data: [
          dataPoint ? dataPoint.back6days : "loading",
          dataPoint ? dataPoint.back5days : "loading",
          dataPoint ? dataPoint.back4days : "loading",
          dataPoint ? dataPoint.back3days : "loading",
          dataPoint ? dataPoint.back2days : "loading",
          dataPoint ? dataPoint.yesterday : "loading",
          dataPoint ? dataPoint.today : "loading",
        ],

        // Setting up the data for the dataset
      },
      {
        label: "last week", // Setting up the label for the dataset
        backgroundColor: "#48A14D", // Setting up the background color for the dataset
        borderColor: "#48A14D", // Setting up the border color for the dataset
        data: [
          dataPoint ? dataPoint.back13days : "loading",
          dataPoint ? dataPoint.back12days : "loading",
          dataPoint ? dataPoint.back11days : "loading",
          dataPoint ? dataPoint.back10days : "loading",
          dataPoint ? dataPoint.back9days : "loading",
          dataPoint ? dataPoint.back8days : "loading",
          dataPoint ? dataPoint.back7days : "loading",
        ],
      },
      {
        label: "The week before last", // Setting up the label for the dataset
        backgroundColor: "rgb(255, 99, 132)", // Setting up the background color for the dataset
        borderColor: "rgb(255, 99, 132)", // Setting up the border color for the dataset
        data: [
          dataPoint ? dataPoint.back20days : "loading",
          dataPoint ? dataPoint.back19days : "loading",
          dataPoint ? dataPoint.back18days : "loading",
          dataPoint ? dataPoint.back17days : "loading",
          dataPoint ? dataPoint.back16days : "loading",
          dataPoint ? dataPoint.back15days : "loading",
          dataPoint ? dataPoint.back14days : "loading",
        ],
      },
    ],
  };
  const data14 = {
    labels: labels14days,
    datasets: [
      {
        label: "Patients registered over 14 days", // Setting up the label for the dataset
        backgroundColor: "#48A14D", // Setting up the background color for the dataset
        borderColor: "#48A14D", // Setting up the border color for the dataset
        data: [
          dataPoint ? dataPoint.back13days : "loading",
          dataPoint ? dataPoint.back12days : "loading",
          dataPoint ? dataPoint.back11days : "loading",
          dataPoint ? dataPoint.back10days : "loading",
          dataPoint ? dataPoint.back9days : "loading",
          dataPoint ? dataPoint.back8days : "loading",
          dataPoint ? dataPoint.back7days : "loading",
          dataPoint ? dataPoint.back6days : "loading",
          dataPoint ? dataPoint.back5days : "loading",
          dataPoint ? dataPoint.back4days : "loading",
          dataPoint ? dataPoint.back3days : "loading",
          dataPoint ? dataPoint.back2days : "loading",
          dataPoint ? dataPoint.yesterday : "loading",
          dataPoint ? dataPoint.today : "loading",
        ],
        // Setting up the data for the dataset
      },
    ],
  };
  const dataMonth = {
    labels: labels30days,
    datasets: [
      {
        label: "Patients registered over 30 days", // Setting up the label for the dataset
        backgroundColor: "rgb(255, 99, 132)", // Setting up the background color for the dataset
        borderColor: "rgb(255, 99, 132)", // Setting up the border color for the dataset
        data: [
          dataPoint ? dataPoint.back29days : "loading",
          dataPoint ? dataPoint.back28days : "loading",
          dataPoint ? dataPoint.back27days : "loading",
          dataPoint ? dataPoint.back26days : "loading",
          dataPoint ? dataPoint.back25days : "loading",
          dataPoint ? dataPoint.back24days : "loading",
          dataPoint ? dataPoint.back23days : "loading",
          dataPoint ? dataPoint.back22days : "loading",
          dataPoint ? dataPoint.back21days : "loading",
          dataPoint ? dataPoint.back20days : "loading",
          dataPoint ? dataPoint.back19days : "loading",
          dataPoint ? dataPoint.back18days : "loading",
          dataPoint ? dataPoint.back17days : "loading",
          dataPoint ? dataPoint.back16days : "loading",
          dataPoint ? dataPoint.back15days : "loading",
          dataPoint ? dataPoint.back14days : "loading",
          dataPoint ? dataPoint.back13days : "loading",
          dataPoint ? dataPoint.back12days : "loading",
          dataPoint ? dataPoint.back11days : "loading",
          dataPoint ? dataPoint.back10days : "loading",
          dataPoint ? dataPoint.back9days : "loading",
          dataPoint ? dataPoint.back8days : "loading",
          dataPoint ? dataPoint.back7days : "loading",
          dataPoint ? dataPoint.back6days : "loading",
          dataPoint ? dataPoint.back5days : "loading",
          dataPoint ? dataPoint.back4days : "loading",
          dataPoint ? dataPoint.back3days : "loading",
          dataPoint ? dataPoint.back2days : "loading",
          dataPoint ? dataPoint.yesterday : "loading",
          dataPoint ? dataPoint.today : "loading",
        ],
        // Setting up the data for the dataset
      },
    ],
  };

  const dataCompareMonth = {
    labels: days90compareLabels,
    datasets: [
      {
        label: "Current days", // Setting up the label for the dataset
        backgroundColor: "rgb(255, 99, 132)", // Setting up the background color for the dataset
        borderColor: "rgb(255, 99, 132)", // Setting up the border color for the dataset
        data: [
          dataPoint ? dataPoint.back29days : "loading",
          dataPoint ? dataPoint.back28days : "loading",
          dataPoint ? dataPoint.back27days : "loading",
          dataPoint ? dataPoint.back26days : "loading",
          dataPoint ? dataPoint.back25days : "loading",
          dataPoint ? dataPoint.back24days : "loading",
          dataPoint ? dataPoint.back23days : "loading",
          dataPoint ? dataPoint.back22days : "loading",
          dataPoint ? dataPoint.back21days : "loading",
          dataPoint ? dataPoint.back20days : "loading",
          dataPoint ? dataPoint.back19days : "loading",
          dataPoint ? dataPoint.back18days : "loading",
          dataPoint ? dataPoint.back17days : "loading",
          dataPoint ? dataPoint.back16days : "loading",
          dataPoint ? dataPoint.back15days : "loading",
          dataPoint ? dataPoint.back14days : "loading",
          dataPoint ? dataPoint.back13days : "loading",
          dataPoint ? dataPoint.back12days : "loading",
          dataPoint ? dataPoint.back11days : "loading",
          dataPoint ? dataPoint.back10days : "loading",
          dataPoint ? dataPoint.back9days : "loading",
          dataPoint ? dataPoint.back8days : "loading",
          dataPoint ? dataPoint.back7days : "loading",
          dataPoint ? dataPoint.back6days : "loading",
          dataPoint ? dataPoint.back5days : "loading",
          dataPoint ? dataPoint.back4days : "loading",
          dataPoint ? dataPoint.back3days : "loading",
          dataPoint ? dataPoint.back2days : "loading",
          dataPoint ? dataPoint.yesterday : "loading",
          dataPoint ? dataPoint.today : "loading",
        ],
        // Setting up the data for the dataset
      },
      {
        label: "Previous Month", // Setting up the label for the dataset
        backgroundColor: "#48A14D", // Setting up the background color for the dataset
        borderColor: "#48A14D", // Setting up the border color for the dataset
        data: [
          dataPoint ? dataPoint.days59 : "loading",
          dataPoint ? dataPoint.days58 : "loading",
          dataPoint ? dataPoint.days57 : "loading",
          dataPoint ? dataPoint.days56 : "loading",
          dataPoint ? dataPoint.days55 : "loading",
          dataPoint ? dataPoint.days54 : "loading",
          dataPoint ? dataPoint.days53 : "loading",
          dataPoint ? dataPoint.days52 : "loading",
          dataPoint ? dataPoint.days51 : "loading",
          dataPoint ? dataPoint.days50 : "loading",
          dataPoint ? dataPoint.days49 : "loading",
          dataPoint ? dataPoint.days48 : "loading",
          dataPoint ? dataPoint.days47 : "loading",
          dataPoint ? dataPoint.days46 : "loading",
          dataPoint ? dataPoint.days45 : "loading",
          dataPoint ? dataPoint.days44 : "loading",
          dataPoint ? dataPoint.days43 : "loading",
          dataPoint ? dataPoint.days42 : "loading",
          dataPoint ? dataPoint.days41 : "loading",
          dataPoint ? dataPoint.days40 : "loading",
          dataPoint ? dataPoint.days39 : "loading",
          dataPoint ? dataPoint.days38 : "loading",
          dataPoint ? dataPoint.days37 : "loading",
          dataPoint ? dataPoint.days36 : "loading",
          dataPoint ? dataPoint.days35 : "loading",
          dataPoint ? dataPoint.days34 : "loading",
          dataPoint ? dataPoint.days33 : "loading",
          dataPoint ? dataPoint.days32 : "loading",
          dataPoint ? dataPoint.days31 : "loading",
          dataPoint ? dataPoint.days30 : "loading",
        ],
        // Setting up the data for the dataset
      },
      {
        label: "the Month Before Last", // Setting up the label for the dataset
        backgroundColor: "#FAA919", // Setting up the background color for the dataset
        borderColor: "#FAA919", // Setting up the border color for the dataset
        data: [
          dataPoint ? dataPoint.days89 : "loading",
          dataPoint ? dataPoint.days88 : "loading",
          dataPoint ? dataPoint.days87 : "loading",
          dataPoint ? dataPoint.days86 : "loading",
          dataPoint ? dataPoint.days85 : "loading",
          dataPoint ? dataPoint.days84 : "loading",
          dataPoint ? dataPoint.days83 : "loading",
          dataPoint ? dataPoint.days82 : "loading",
          dataPoint ? dataPoint.days81 : "loading",
          dataPoint ? dataPoint.days80 : "loading",
          dataPoint ? dataPoint.days79 : "loading",
          dataPoint ? dataPoint.days78 : "loading",
          dataPoint ? dataPoint.days77 : "loading",
          dataPoint ? dataPoint.days76 : "loading",
          dataPoint ? dataPoint.days75 : "loading",
          dataPoint ? dataPoint.days74 : "loading",
          dataPoint ? dataPoint.days73 : "loading",
          dataPoint ? dataPoint.days72 : "loading",
          dataPoint ? dataPoint.days71 : "loading",
          dataPoint ? dataPoint.days70 : "loading",
          dataPoint ? dataPoint.days69 : "loading",
          dataPoint ? dataPoint.days68 : "loading",
          dataPoint ? dataPoint.days67 : "loading",
          dataPoint ? dataPoint.days66 : "loading",
          dataPoint ? dataPoint.days65 : "loading",
          dataPoint ? dataPoint.days64 : "loading",
          dataPoint ? dataPoint.days63 : "loading",
          dataPoint ? dataPoint.days62 : "loading",
          dataPoint ? dataPoint.days61 : "loading",
          dataPoint ? dataPoint.days60 : "loading",
        ],
        // Setting up the data for the dataset
      },
    ],
  };

  return (
    <div
      style={{
        height: display ? "100%" : 0,
        width: "100%",
        backgroundColor: BlackAndWhite.secondary,
        display: "flex",
        position: "absolute",
        overflow: "hidden",
        top: 0,
        transition: "all 0.4s",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          height: 80,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "80%", fontSize: 16, fontWeight: "bold" }}>
          {statType ? toUpper + " " + "STATS" : null}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* <div style={{ width: "99.7%", height: 50, borderStyle: "solid" }}></div> */}
        <div
          style={{
            flex: 1,
            display: "flex",
          }}
        >
          <div
            style={{
              flex: 0.65,
              display: "flex",
              height: "95%",
              padding: 20,
              position: "relative",
            }}
          >
            {selectedGraph === "weekCompare" ? (
              <div style={{ width: "100%", height: 400, position: "relative" }}>
                {graphType ? (
                  <Bar
                    //   options={}
                    data={dataWeeklyCompare}
                    // style={{ height: 300, width: "20%" }}
                  />
                ) : (
                  <Line
                    //   options={}
                    data={dataWeeklyCompare}
                    // style={{ height: 300, width: "20%" }}
                  />
                )}
                <div
                  onClick={() => setGraphType(true)}
                  style={{
                    height: 20,
                    width: 60,
                    borderStyle: "solid",
                    borderWidth: 0.5,
                    position: "absolute",
                    top: -10,
                    right: 10,
                    borderRadius: 4,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: graphType
                      ? BlackAndWhite.primary
                      : BlackAndWhite.secondary,
                    color: graphType
                      ? BlackAndWhite.secondary
                      : BlackAndWhite.primary,
                  }}
                >
                  Bar
                </div>
                <div
                  onClick={() => setGraphType(false)}
                  style={{
                    height: 20,
                    width: 60,
                    borderStyle: "solid",
                    borderWidth: 0.5,
                    position: "absolute",
                    top: -10,
                    right: 80,
                    borderRadius: 4,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: !graphType
                      ? BlackAndWhite.primary
                      : BlackAndWhite.secondary,
                    color: !graphType
                      ? BlackAndWhite.secondary
                      : BlackAndWhite.primary,
                  }}
                >
                  Line
                </div>
              </div>
            ) : null}
            {selectedGraph === "week" ? (
              <div style={{ width: "100%", height: 400, position: "relative" }}>
                {!graphType ? (
                  <Line
                    //   options={}
                    data={data}
                  />
                ) : (
                  <Bar
                    //   options={}
                    data={data}
                  />
                )}
                <div
                  onClick={() => setGraphType(true)}
                  style={{
                    height: 20,
                    width: 60,
                    borderStyle: "solid",
                    borderWidth: 0.5,
                    position: "absolute",
                    top: -10,
                    right: 10,
                    borderRadius: 4,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: graphType
                      ? BlackAndWhite.primary
                      : BlackAndWhite.secondary,
                    color: graphType
                      ? BlackAndWhite.secondary
                      : BlackAndWhite.primary,
                  }}
                >
                  Bar
                </div>
                <div
                  onClick={() => setGraphType(false)}
                  style={{
                    height: 20,
                    width: 60,
                    borderStyle: "solid",
                    borderWidth: 0.5,
                    position: "absolute",
                    top: -10,
                    right: 80,
                    borderRadius: 4,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: !graphType
                      ? BlackAndWhite.primary
                      : BlackAndWhite.secondary,
                    color: !graphType
                      ? BlackAndWhite.secondary
                      : BlackAndWhite.primary,
                  }}
                >
                  Line
                </div>
              </div>
            ) : null}
            {selectedGraph === "month" ? (
              <div style={{ width: "100%", height: 400, position: "relative" }}>
                {!graphType ? (
                  <Line
                    //   options={}
                    data={dataMonth}
                  />
                ) : (
                  <Bar
                    //   options={}
                    data={dataMonth}
                  />
                )}
                <div
                  onClick={() => setGraphType(true)}
                  style={{
                    height: 20,
                    width: 60,
                    borderStyle: "solid",
                    borderWidth: 0.5,
                    position: "absolute",
                    top: -10,
                    right: 10,
                    borderRadius: 4,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: graphType
                      ? BlackAndWhite.primary
                      : BlackAndWhite.secondary,
                    color: graphType
                      ? BlackAndWhite.secondary
                      : BlackAndWhite.primary,
                  }}
                >
                  Bar
                </div>
                <div
                  onClick={() => setGraphType(false)}
                  style={{
                    height: 20,
                    width: 60,
                    borderStyle: "solid",
                    borderWidth: 0.5,
                    position: "absolute",
                    top: -10,
                    right: 80,
                    borderRadius: 4,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: !graphType
                      ? BlackAndWhite.primary
                      : BlackAndWhite.secondary,
                    color: !graphType
                      ? BlackAndWhite.secondary
                      : BlackAndWhite.primary,
                  }}
                >
                  Line
                </div>
              </div>
            ) : null}
            {selectedGraph === "monthCompare" ? (
              <div style={{ width: "100%", height: 400, position: "relative" }}>
                {!graphType ? (
                  <Line
                    //   options={}
                    data={dataCompareMonth}
                  />
                ) : (
                  <Bar
                    //   options={}
                    data={dataCompareMonth}
                  />
                )}
                <div
                  onClick={() => setGraphType(true)}
                  style={{
                    height: 20,
                    width: 60,
                    borderStyle: "solid",
                    borderWidth: 0.5,
                    position: "absolute",
                    top: -10,
                    right: 10,
                    borderRadius: 4,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: graphType
                      ? BlackAndWhite.primary
                      : BlackAndWhite.secondary,
                    color: graphType
                      ? BlackAndWhite.secondary
                      : BlackAndWhite.primary,
                  }}
                >
                  Bar
                </div>
                <div
                  onClick={() => setGraphType(false)}
                  style={{
                    height: 20,
                    width: 60,
                    borderStyle: "solid",
                    borderWidth: 0.5,
                    position: "absolute",
                    top: -10,
                    right: 80,
                    borderRadius: 4,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: !graphType
                      ? BlackAndWhite.primary
                      : BlackAndWhite.secondary,
                    color: !graphType
                      ? BlackAndWhite.secondary
                      : BlackAndWhite.primary,
                  }}
                >
                  Line
                </div>
              </div>
            ) : null}
            {selectedGraph === "14" ? (
              <div style={{ width: "100%", height: 400, position: "relative" }}>
                {!graphType ? (
                  <Line
                    //   options={}
                    data={data14}
                  />
                ) : (
                  <Bar
                    //   options={}
                    data={data14}
                  />
                )}{" "}
                <div
                  onClick={() => setGraphType(true)}
                  style={{
                    height: 20,
                    width: 60,
                    borderStyle: "solid",
                    borderWidth: 0.5,
                    position: "absolute",
                    top: -10,
                    right: 10,
                    borderRadius: 4,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: graphType
                      ? BlackAndWhite.primary
                      : BlackAndWhite.secondary,
                    color: graphType
                      ? BlackAndWhite.secondary
                      : BlackAndWhite.primary,
                  }}
                >
                  Bar
                </div>
                <div
                  onClick={() => setGraphType(false)}
                  style={{
                    height: 20,
                    width: 60,
                    borderStyle: "solid",
                    borderWidth: 0.5,
                    position: "absolute",
                    top: -10,
                    right: 80,
                    borderRadius: 4,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: !graphType
                      ? BlackAndWhite.primary
                      : BlackAndWhite.secondary,
                    color: !graphType
                      ? BlackAndWhite.secondary
                      : BlackAndWhite.primary,
                  }}
                >
                  Line
                </div>
              </div>
            ) : null}
            <div
              style={{
                height: 130,
                position: "absolute",
                bottom: 55,
                width: "97%",
                borderRadius: 10,
                backgroundColor: BlackAndWhite.secondary,
                WebkitBoxShadow: "0px 0px 10px 1px #00000033",
                right: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 12,
              }}
            >
              {selectedGraph === "monthCompare" ? (
                <>
                  <div
                    style={{
                      position: "absolute",
                      top: -30,
                      left: 10,
                      fontSize: 16,
                    }}
                  >
                    Quick stats - monthly comparisons
                  </div>
                  <BaseStatBlock
                    label={"Average daily registration"}
                    value={
                      dataPoint
                        ? (
                            (dataPoint.today +
                              dataPoint.yesterday +
                              dataPoint.back2days +
                              dataPoint.back3days +
                              dataPoint.back4days +
                              dataPoint.back5days +
                              dataPoint.back6days +
                              dataPoint.back7days +
                              dataPoint.back8days +
                              dataPoint.back9days +
                              dataPoint.back10days +
                              dataPoint.back11days +
                              dataPoint.back12days +
                              dataPoint.back13days +
                              dataPoint.back14days +
                              dataPoint.back15days +
                              dataPoint.back16days +
                              dataPoint.back17days +
                              dataPoint.back18days +
                              dataPoint.back19days +
                              dataPoint.back20days +
                              dataPoint.back21days +
                              dataPoint.back22days +
                              dataPoint.back23days +
                              dataPoint.back24days +
                              dataPoint.back25days +
                              dataPoint.back26days +
                              dataPoint.back27days +
                              dataPoint.back28days +
                              dataPoint.back29days) /
                            30
                          ).toFixed(0)
                        : null
                    }
                  />
                  <BaseStatBlock
                    label={"Average weekly registration"}
                    value={
                      dataPoint
                        ? (
                            (dataPoint.today +
                              dataPoint.yesterday +
                              dataPoint.back2days +
                              dataPoint.back3days +
                              dataPoint.back4days +
                              dataPoint.back5days +
                              dataPoint.back6days +
                              dataPoint.back7days +
                              dataPoint.back8days +
                              dataPoint.back9days +
                              dataPoint.back10days +
                              dataPoint.back11days +
                              dataPoint.back12days +
                              dataPoint.back13days +
                              dataPoint.back14days +
                              dataPoint.back15days +
                              dataPoint.back16days +
                              dataPoint.back17days +
                              dataPoint.back18days +
                              dataPoint.back19days +
                              dataPoint.back20days +
                              dataPoint.back21days +
                              dataPoint.back22days +
                              dataPoint.back23days +
                              dataPoint.back24days +
                              dataPoint.back25days +
                              dataPoint.back26days +
                              dataPoint.back27days +
                              dataPoint.back28days +
                              dataPoint.back29days) /
                            4
                          ).toFixed(0)
                        : null
                    }
                  />
                  <BaseStatBlock
                    label={"Average monthly registration"}
                    value={
                      dataPoint
                        ? (
                            (dataPoint.today +
                              dataPoint.yesterday +
                              dataPoint.back2days +
                              dataPoint.back3days +
                              dataPoint.back4days +
                              dataPoint.back5days +
                              dataPoint.back6days +
                              dataPoint.back7days +
                              dataPoint.back8days +
                              dataPoint.back9days +
                              dataPoint.back10days +
                              dataPoint.back11days +
                              dataPoint.back12days +
                              dataPoint.back13days +
                              dataPoint.back14days +
                              dataPoint.back15days +
                              dataPoint.back16days +
                              dataPoint.back17days +
                              dataPoint.back18days +
                              dataPoint.back19days +
                              dataPoint.back20days +
                              dataPoint.back21days +
                              dataPoint.back22days +
                              dataPoint.back23days +
                              dataPoint.back24days +
                              dataPoint.back25days +
                              dataPoint.back26days +
                              dataPoint.back27days +
                              dataPoint.back28days +
                              dataPoint.back29days +
                              dataPoint.days30 +
                              dataPoint.days31 +
                              dataPoint.days32 +
                              dataPoint.days33 +
                              dataPoint.days34 +
                              dataPoint.days35 +
                              dataPoint.days36 +
                              dataPoint.days37 +
                              dataPoint.days38 +
                              dataPoint.days39 +
                              dataPoint.days40 +
                              dataPoint.days41 +
                              dataPoint.days42 +
                              dataPoint.days43 +
                              dataPoint.days44 +
                              dataPoint.days45 +
                              dataPoint.days46 +
                              dataPoint.days47 +
                              dataPoint.days48 +
                              dataPoint.days49 +
                              dataPoint.days50 +
                              dataPoint.days51 +
                              dataPoint.days52 +
                              dataPoint.days53 +
                              dataPoint.days54 +
                              dataPoint.days55 +
                              dataPoint.days56 +
                              dataPoint.days57 +
                              dataPoint.days58 +
                              dataPoint.days59 +
                              dataPoint.days60 +
                              dataPoint.days61 +
                              dataPoint.days62 +
                              dataPoint.days63 +
                              dataPoint.days64 +
                              dataPoint.days65 +
                              dataPoint.days66 +
                              dataPoint.days67 +
                              dataPoint.days68 +
                              dataPoint.days69 +
                              dataPoint.days70 +
                              dataPoint.days71 +
                              dataPoint.days72 +
                              dataPoint.days73 +
                              dataPoint.days74 +
                              dataPoint.days75 +
                              dataPoint.days76 +
                              dataPoint.days77 +
                              dataPoint.days78 +
                              dataPoint.days79 +
                              dataPoint.days80 +
                              dataPoint.days81 +
                              dataPoint.days82 +
                              dataPoint.days83 +
                              dataPoint.days84 +
                              dataPoint.days85 +
                              dataPoint.days86 +
                              dataPoint.days87 +
                              dataPoint.days88 +
                              dataPoint.days89) /
                            3
                          ).toFixed(0)
                        : null
                    }
                  />
                  <BaseStatBlock
                    label={"Valdated Accounts"}
                    value={dataPoint ? dataPoint.validatedUsers : null}
                  />

                  <BaseStatBlock
                    label={"Validated ratio"}
                    value={
                      dataPoint
                        ? (
                            (dataPoint.validatedUsers /
                              dataPoint.allPatinetsCount) *
                            100
                          ).toFixed(2)
                        : null
                    }
                  />
                </>
              ) : null}
              {selectedGraph === "weekCompare" ? (
                <>
                  <div
                    style={{
                      position: "absolute",
                      top: -30,
                      left: 10,
                      fontSize: 16,
                    }}
                  >
                    Quick stats - weekly comparisons
                  </div>
                  <BaseStatBlock
                    label={"Average daily registration"}
                    value={
                      dataPoint
                        ? (
                            (dataPoint.today +
                              dataPoint.yesterday +
                              dataPoint.back2days +
                              dataPoint.back3days +
                              dataPoint.back4days +
                              dataPoint.back5days +
                              dataPoint.back6days +
                              dataPoint.back7days +
                              dataPoint.back8days +
                              dataPoint.back9days +
                              dataPoint.back10days +
                              dataPoint.back11days +
                              dataPoint.back12days +
                              dataPoint.back13days +
                              dataPoint.back14days +
                              dataPoint.back15days +
                              dataPoint.back16days +
                              dataPoint.back17days +
                              dataPoint.back18days +
                              dataPoint.back19days +
                              dataPoint.back20days) /
                            21
                          ).toFixed(0)
                        : null
                    }
                  />
                  <BaseStatBlock
                    label={"Average weekly registration"}
                    value={
                      dataPoint
                        ? (
                            (dataPoint.today +
                              dataPoint.yesterday +
                              dataPoint.back2days +
                              dataPoint.back3days +
                              dataPoint.back4days +
                              dataPoint.back5days +
                              dataPoint.back6days +
                              dataPoint.back7days +
                              dataPoint.back8days +
                              dataPoint.back9days +
                              dataPoint.back10days +
                              dataPoint.back11days +
                              dataPoint.back12days +
                              dataPoint.back13days +
                              dataPoint.back14days +
                              dataPoint.back15days +
                              dataPoint.back16days +
                              dataPoint.back17days +
                              dataPoint.back18days +
                              dataPoint.back19days +
                              dataPoint.back20days) /
                            3
                          ).toFixed(0)
                        : null
                    }
                  />

                  <BaseStatBlock
                    label={"Valdated Accounts"}
                    value={dataPoint ? dataPoint.validatedUsers : null}
                  />
                  {/* <BaseStatBlock label={"female ratio"} value={700} /> */}
                  <BaseStatBlock
                    label={"Validated ratio"}
                    value={
                      dataPoint
                        ? (
                            (dataPoint.validatedUsers /
                              dataPoint.allPatinetsCount) *
                            100
                          ).toFixed(2)
                        : null
                    }
                  />
                </>
              ) : null}

              {selectedGraph === "week" ? (
                <>
                  <div
                    style={{
                      position: "absolute",
                      top: -30,
                      left: 10,
                      fontSize: 16,
                    }}
                  >
                    Quick stats
                  </div>
                  <BaseStatBlock
                    label={"Average daily registration"}
                    value={
                      dataPoint
                        ? (
                            (dataPoint.today +
                              dataPoint.yesterday +
                              dataPoint.back2days +
                              dataPoint.back3days +
                              dataPoint.back4days +
                              dataPoint.back5days +
                              dataPoint.back6days +
                              dataPoint.back7days +
                              dataPoint.back8days +
                              dataPoint.back9days +
                              dataPoint.back10days +
                              dataPoint.back11days +
                              dataPoint.back12days +
                              dataPoint.back13days +
                              dataPoint.back14days +
                              dataPoint.back15days +
                              dataPoint.back16days +
                              dataPoint.back17days +
                              dataPoint.back18days +
                              dataPoint.back19days +
                              dataPoint.back20days +
                              dataPoint.back21days +
                              dataPoint.back22days +
                              dataPoint.back23days +
                              dataPoint.back24days +
                              dataPoint.back25days +
                              dataPoint.back26days +
                              dataPoint.back27days +
                              dataPoint.back28days +
                              dataPoint.back29days) /
                            30
                          ).toFixed(0)
                        : null
                    }
                  />
                  <BaseStatBlock
                    label={"Average weekly registration"}
                    value={
                      dataPoint
                        ? (
                            (dataPoint.today +
                              dataPoint.yesterday +
                              dataPoint.back2days +
                              dataPoint.back3days +
                              dataPoint.back4days +
                              dataPoint.back5days +
                              dataPoint.back6days +
                              dataPoint.back7days +
                              dataPoint.back8days +
                              dataPoint.back9days +
                              dataPoint.back10days +
                              dataPoint.back11days +
                              dataPoint.back12days +
                              dataPoint.back13days +
                              dataPoint.back14days +
                              dataPoint.back15days +
                              dataPoint.back16days +
                              dataPoint.back17days +
                              dataPoint.back18days +
                              dataPoint.back19days +
                              dataPoint.back20days +
                              dataPoint.back21days +
                              dataPoint.back22days +
                              dataPoint.back23days +
                              dataPoint.back24days +
                              dataPoint.back25days +
                              dataPoint.back26days +
                              dataPoint.back27days +
                              dataPoint.back28days +
                              dataPoint.back29days) /
                            4
                          ).toFixed(0)
                        : null
                    }
                  />
                  <BaseStatBlock
                    label={"Average monthly registration"}
                    value={
                      dataPoint
                        ? dataPoint.today +
                          dataPoint.yesterday +
                          dataPoint.back2days +
                          dataPoint.back3days +
                          dataPoint.back4days +
                          dataPoint.back5days +
                          dataPoint.back6days +
                          dataPoint.back7days +
                          dataPoint.back8days +
                          dataPoint.back9days +
                          dataPoint.back10days +
                          dataPoint.back11days +
                          dataPoint.back12days +
                          dataPoint.back13days +
                          dataPoint.back14days +
                          dataPoint.back15days +
                          dataPoint.back16days +
                          dataPoint.back17days +
                          dataPoint.back18days +
                          dataPoint.back19days +
                          dataPoint.back20days +
                          dataPoint.back21days +
                          dataPoint.back22days +
                          dataPoint.back23days +
                          dataPoint.back24days +
                          dataPoint.back25days +
                          dataPoint.back26days +
                          dataPoint.back27days +
                          dataPoint.back28days +
                          dataPoint.back29days
                        : null
                    }
                  />

                  <BaseStatBlock
                    label={"female ratio"}
                    value={day7Stats.femaleRatio + "%"}
                  />
                  <BaseStatBlock
                    label={"Valdated Accounts"}
                    value={dataPoint ? dataPoint.validatedUsers : null}
                  />
                  {/* <BaseStatBlock label={"female ratio"} value={700} /> */}
                  <BaseStatBlock
                    label={"Validated ratio"}
                    value={
                      dataPoint
                        ? (
                            (dataPoint.validatedUsers /
                              dataPoint.allPatinetsCount) *
                            100
                          ).toFixed(2) + "%"
                        : null
                    }
                  />
                </>
              ) : null}
              {selectedGraph === "month" ? (
                <>
                  <div
                    style={{
                      position: "absolute",
                      top: -30,
                      left: 10,
                      fontSize: 16,
                    }}
                  >
                    Quick stats
                  </div>
                  <BaseStatBlock
                    label={"Average daily registration"}
                    value={
                      dataPoint
                        ? (
                            (dataPoint.today +
                              dataPoint.yesterday +
                              dataPoint.back2days +
                              dataPoint.back3days +
                              dataPoint.back4days +
                              dataPoint.back5days +
                              dataPoint.back6days +
                              dataPoint.back7days +
                              dataPoint.back8days +
                              dataPoint.back9days +
                              dataPoint.back10days +
                              dataPoint.back11days +
                              dataPoint.back12days +
                              dataPoint.back13days +
                              dataPoint.back14days +
                              dataPoint.back15days +
                              dataPoint.back16days +
                              dataPoint.back17days +
                              dataPoint.back18days +
                              dataPoint.back19days +
                              dataPoint.back20days +
                              dataPoint.back21days +
                              dataPoint.back22days +
                              dataPoint.back23days +
                              dataPoint.back24days +
                              dataPoint.back25days +
                              dataPoint.back26days +
                              dataPoint.back27days +
                              dataPoint.back28days +
                              dataPoint.back29days) /
                            30
                          ).toFixed(0)
                        : null
                    }
                  />
                  <BaseStatBlock
                    label={"Average weekly registration"}
                    value={
                      dataPoint
                        ? (
                            (dataPoint.today +
                              dataPoint.yesterday +
                              dataPoint.back2days +
                              dataPoint.back3days +
                              dataPoint.back4days +
                              dataPoint.back5days +
                              dataPoint.back6days +
                              dataPoint.back7days +
                              dataPoint.back8days +
                              dataPoint.back9days +
                              dataPoint.back10days +
                              dataPoint.back11days +
                              dataPoint.back12days +
                              dataPoint.back13days +
                              dataPoint.back14days +
                              dataPoint.back15days +
                              dataPoint.back16days +
                              dataPoint.back17days +
                              dataPoint.back18days +
                              dataPoint.back19days +
                              dataPoint.back20days +
                              dataPoint.back21days +
                              dataPoint.back22days +
                              dataPoint.back23days +
                              dataPoint.back24days +
                              dataPoint.back25days +
                              dataPoint.back26days +
                              dataPoint.back27days +
                              dataPoint.back28days +
                              dataPoint.back29days) /
                            4
                          ).toFixed(0)
                        : null
                    }
                  />
                  <BaseStatBlock
                    label={"Average monthly registration"}
                    value={
                      dataPoint
                        ? dataPoint.today +
                          dataPoint.yesterday +
                          dataPoint.back2days +
                          dataPoint.back3days +
                          dataPoint.back4days +
                          dataPoint.back5days +
                          dataPoint.back6days +
                          dataPoint.back7days +
                          dataPoint.back8days +
                          dataPoint.back9days +
                          dataPoint.back10days +
                          dataPoint.back11days +
                          dataPoint.back12days +
                          dataPoint.back13days +
                          dataPoint.back14days +
                          dataPoint.back15days +
                          dataPoint.back16days +
                          dataPoint.back17days +
                          dataPoint.back18days +
                          dataPoint.back19days +
                          dataPoint.back20days +
                          dataPoint.back21days +
                          dataPoint.back22days +
                          dataPoint.back23days +
                          dataPoint.back24days +
                          dataPoint.back25days +
                          dataPoint.back26days +
                          dataPoint.back27days +
                          dataPoint.back28days +
                          dataPoint.back29days
                        : null
                    }
                  />

                  <BaseStatBlock
                    label={"female ratio"}
                    value={day7Stats.femaleRatio + "%"}
                  />
                  <BaseStatBlock
                    label={"Valdated Accounts"}
                    value={dataPoint ? dataPoint.validatedUsers : null}
                  />
                  {/* <BaseStatBlock label={"female ratio"} value={700} /> */}
                  <BaseStatBlock
                    label={"Validated ratio"}
                    value={
                      dataPoint
                        ? (
                            (dataPoint.validatedUsers /
                              dataPoint.allPatinetsCount) *
                            100
                          ).toFixed(2) + "%"
                        : null
                    }
                  />
                </>
              ) : null}
              {selectedGraph === "14" ? (
                <>
                  <div
                    style={{
                      position: "absolute",
                      top: -30,
                      left: 10,
                      fontSize: 16,
                    }}
                  >
                    Quick stats
                  </div>
                  <BaseStatBlock
                    label={"Average daily registration"}
                    value={
                      dataPoint
                        ? (
                            (dataPoint.today +
                              dataPoint.yesterday +
                              dataPoint.back2days +
                              dataPoint.back3days +
                              dataPoint.back4days +
                              dataPoint.back5days +
                              dataPoint.back6days +
                              dataPoint.back7days +
                              dataPoint.back8days +
                              dataPoint.back9days +
                              dataPoint.back10days +
                              dataPoint.back11days +
                              dataPoint.back12days +
                              dataPoint.back13days +
                              dataPoint.back14days +
                              dataPoint.back15days +
                              dataPoint.back16days +
                              dataPoint.back17days +
                              dataPoint.back18days +
                              dataPoint.back19days +
                              dataPoint.back20days +
                              dataPoint.back21days +
                              dataPoint.back22days +
                              dataPoint.back23days +
                              dataPoint.back24days +
                              dataPoint.back25days +
                              dataPoint.back26days +
                              dataPoint.back27days +
                              dataPoint.back28days +
                              dataPoint.back29days) /
                            30
                          ).toFixed(0)
                        : null
                    }
                  />
                  <BaseStatBlock
                    label={"Average weekly registration"}
                    value={
                      dataPoint
                        ? (
                            (dataPoint.today +
                              dataPoint.yesterday +
                              dataPoint.back2days +
                              dataPoint.back3days +
                              dataPoint.back4days +
                              dataPoint.back5days +
                              dataPoint.back6days +
                              dataPoint.back7days +
                              dataPoint.back8days +
                              dataPoint.back9days +
                              dataPoint.back10days +
                              dataPoint.back11days +
                              dataPoint.back12days +
                              dataPoint.back13days +
                              dataPoint.back14days +
                              dataPoint.back15days +
                              dataPoint.back16days +
                              dataPoint.back17days +
                              dataPoint.back18days +
                              dataPoint.back19days +
                              dataPoint.back20days +
                              dataPoint.back21days +
                              dataPoint.back22days +
                              dataPoint.back23days +
                              dataPoint.back24days +
                              dataPoint.back25days +
                              dataPoint.back26days +
                              dataPoint.back27days +
                              dataPoint.back28days +
                              dataPoint.back29days) /
                            4
                          ).toFixed(0)
                        : null
                    }
                  />
                  <BaseStatBlock
                    label={"Average monthly registration"}
                    value={
                      dataPoint
                        ? dataPoint.today +
                          dataPoint.yesterday +
                          dataPoint.back2days +
                          dataPoint.back3days +
                          dataPoint.back4days +
                          dataPoint.back5days +
                          dataPoint.back6days +
                          dataPoint.back7days +
                          dataPoint.back8days +
                          dataPoint.back9days +
                          dataPoint.back10days +
                          dataPoint.back11days +
                          dataPoint.back12days +
                          dataPoint.back13days +
                          dataPoint.back14days +
                          dataPoint.back15days +
                          dataPoint.back16days +
                          dataPoint.back17days +
                          dataPoint.back18days +
                          dataPoint.back19days +
                          dataPoint.back20days +
                          dataPoint.back21days +
                          dataPoint.back22days +
                          dataPoint.back23days +
                          dataPoint.back24days +
                          dataPoint.back25days +
                          dataPoint.back26days +
                          dataPoint.back27days +
                          dataPoint.back28days +
                          dataPoint.back29days
                        : null
                    }
                  />

                  <BaseStatBlock
                    label={"female ratio"}
                    value={day7Stats.femaleRatio + "%"}
                  />
                  <BaseStatBlock
                    label={"Valdated Accounts"}
                    value={dataPoint ? dataPoint.validatedUsers : null}
                  />
                  {/* <BaseStatBlock label={"female ratio"} value={700} /> */}
                  <BaseStatBlock
                    label={"Validated ratio"}
                    value={
                      dataPoint
                        ? (
                            (dataPoint.validatedUsers /
                              dataPoint.allPatinetsCount) *
                            100
                          ).toFixed(2) + "%"
                        : null
                    }
                  />
                </>
              ) : null}
              {/* // buttons for quick stats changes */}
            </div>
          </div>
          <div
            style={{
              flex: 0.35,
              display: "flex",
              height: "95%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                height: "95%",
                width: "90%",
                borderRadius: 10,
                backgroundColor: BlackAndWhite.secondary,
                WebkitBoxShadow: "0px 0px 10px 1px #00000033",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  width: "90%",
                  textAlign: "left",
                  marginTop: 10,
                }}
              >
                General stats
              </div>

              <div
                style={{
                  width: "90%",
                  // marginTop: 10,
                  height: 50,
                  display: "flex",
                  alignItems: "center",
                  fontSize: 16,
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  width: "100%",
                  marginTop: 10,
                }}
              >
                <div
                  style={{
                    height: 40,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",

                    marginTop: 10,
                    padding: 8,
                    borderRadius: 8,

                    width: 90,
                  }}
                >
                  <div style={{ fontSize: 22, fontWeight: "bold" }}>
                    {dataPoint ? dataPoint.today : null}
                  </div>
                  <div
                    style={{
                      // marginLeft: 20,
                      fontSize: 10,
                      // marginTop: 10,
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    REGISTERED TODAY
                  </div>
                </div>
                <div
                  style={{
                    height: 40,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    // borderStyle: "solid",
                    marginTop: 10,
                    padding: 8,
                    borderRadius: 8,
                    // borderWidth: 0.5,
                    width: 90,
                  }}
                >
                  <div style={{ fontSize: 22, fontWeight: "bold" }}>
                    {dataPoint
                      ? dataPoint.today +
                        dataPoint.yesterday +
                        dataPoint.back2days +
                        dataPoint.back3days +
                        dataPoint.back4days +
                        dataPoint.back5days +
                        dataPoint.back6days
                      : null}
                  </div>
                  <div
                    style={{
                      // marginLeft: 20,
                      fontSize: 10,
                      // marginTop: 10,
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    THIS WEEK
                  </div>
                </div>
                <div
                  style={{
                    height: 40,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    // borderStyle: "solid",
                    marginTop: 10,
                    padding: 8,
                    borderRadius: 8,
                    // borderWidth: 0.5,
                    width: 80,
                  }}
                >
                  <div
                    style={{
                      fontSize: 22,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {dataPoint
                      ? dataPoint.today +
                        dataPoint.yesterday +
                        dataPoint.back2days +
                        dataPoint.back3days +
                        dataPoint.back4days +
                        dataPoint.back5days +
                        dataPoint.back6days +
                        dataPoint.back7days +
                        dataPoint.back8days +
                        dataPoint.back9days +
                        dataPoint.back10days +
                        dataPoint.back11days +
                        dataPoint.back12days +
                        dataPoint.back13days +
                        dataPoint.back14days +
                        dataPoint.back15days +
                        dataPoint.back16days +
                        dataPoint.back17days +
                        dataPoint.back18days +
                        dataPoint.back19days +
                        dataPoint.back20days +
                        dataPoint.back21days +
                        dataPoint.back22days +
                        dataPoint.back23days +
                        dataPoint.back24days +
                        dataPoint.back25days +
                        dataPoint.back26days +
                        dataPoint.back27days +
                        dataPoint.back28days +
                        dataPoint.back29days
                      : null}
                  </div>
                  <div
                    style={{
                      // marginLeft: 20,
                      fontSize: 10,
                      // marginTop: 10,
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    {" "}
                    THIS MONTH
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-evenly",
                  marginTop: 10,
                }}
              >
                <div
                  style={{
                    height: 40,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    // borderStyle: "solid",
                    marginTop: 10,
                    padding: 8,
                    borderRadius: 8,
                    borderWidth: 0.5,
                  }}
                >
                  <div
                    style={{
                      width: "100%",

                      alignItems: "center",
                      textAlign: "center",
                      fontSize: 26,
                      fontWeight: "bold",
                    }}
                  >
                    {dataPoint ? dataPoint.allPatinetsCount : null}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      // marginTop: 10,
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                      fontSize: 10,
                    }}
                  >
                    TOTAL PATIENTS
                  </div>
                </div>
                <div
                  style={{
                    height: 40,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    // borderStyle: "solid",
                    marginTop: 10,
                    padding: 8,
                    borderRadius: 8,
                    borderWidth: 0.5,
                  }}
                >
                  <div
                    style={{
                      width: "100%",

                      alignItems: "center",
                      textAlign: "center",
                      fontSize: 26,
                      fontWeight: "bold",
                    }}
                  >
                    {dataPoint ? dataPoint.malePatiensts : null}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      // marginTop: 10,
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                      fontSize: 10,
                    }}
                  >
                    MALE PATIENTS
                  </div>
                </div>
                <div
                  style={{
                    height: 40,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    // borderStyle: "solid",
                    marginTop: 10,
                    padding: 8,
                    borderRadius: 8,
                    borderWidth: 0.5,
                  }}
                >
                  <div
                    style={{
                      width: "100%",

                      alignItems: "center",
                      textAlign: "center",
                      fontSize: 26,
                      fontWeight: "bold",
                    }}
                  >
                    {dataPoint ? dataPoint.femalePatients : null}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      // marginTop: 10,
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                      fontSize: 10,
                    }}
                  >
                    FEMALE PATIENTS
                  </div>
                </div>
              </div>

              <div
                style={{
                  fontSize: 22,
                  fontWeight: "bold",
                  width: "90%",
                  textAlign: "left",
                  marginTop: 10,
                }}
              >
                Profile completed stats:
              </div>
              <div style={{ width: 300 }}>
                <Pie data={completedProfiledata} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ position: "absolute", top: 20, left: 20 }}>
        <CloseButton label={"X"} onClick={onCloseClick} />
      </div>
      {/* tab buttons */}
      <div
        onClick={() => setSelectedGraph("weekCompare")}
        style={{
          height: 40,
          position: "absolute",
          top: 20,
          width: 160,
          borderRadius: 10,
          color:
            selectedGraph === "weekCompare"
              ? BlackAndWhite.secondary
              : BlackAndWhite.primary,
          backgroundColor:
            selectedGraph === "weekCompare"
              ? BlackAndWhite.primary
              : BlackAndWhite.secondary,
          WebkitBoxShadow: "0px 0px 10px 1px #00000033",
          right: 350,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          transition: "all 0.3s",
        }}
      >
        Compare 21 days
      </div>
      <div
        onClick={() => setSelectedGraph("monthCompare")}
        style={{
          height: 40,
          position: "absolute",
          top: 20,
          width: 160,
          borderRadius: 10,
          color:
            selectedGraph === "monthCompare"
              ? BlackAndWhite.secondary
              : BlackAndWhite.primary,
          backgroundColor:
            selectedGraph === "monthCompare"
              ? BlackAndWhite.primary
              : BlackAndWhite.secondary,
          WebkitBoxShadow: "0px 0px 10px 1px #00000033",
          right: 520,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          transition: "all 0.3s",
        }}
      >
        Compare 90 days
      </div>
      <div
        onClick={() => setSelectedGraph("week")}
        style={{
          height: 40,
          position: "absolute",
          top: 20,
          width: 100,
          borderRadius: 10,
          color:
            selectedGraph === "week"
              ? BlackAndWhite.secondary
              : BlackAndWhite.primary,
          backgroundColor:
            selectedGraph === "week"
              ? BlackAndWhite.primary
              : BlackAndWhite.secondary,
          WebkitBoxShadow: "0px 0px 10px 1px #00000033",
          right: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          transition: "all 0.3s",
        }}
      >
        7 days
      </div>
      <div
        onClick={() => setSelectedGraph("14")}
        style={{
          height: 40,
          position: "absolute",
          top: 20,
          width: 100,
          borderRadius: 10,
          color:
            selectedGraph === "14"
              ? BlackAndWhite.secondary
              : BlackAndWhite.primary,
          backgroundColor:
            selectedGraph === "14"
              ? BlackAndWhite.primary
              : BlackAndWhite.secondary,
          WebkitBoxShadow: "0px 0px 10px 1px #00000033",
          right: 130,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          transition: "all 0.3s",
        }}
      >
        14 Days
      </div>
      <div
        onClick={() => setSelectedGraph("month")}
        style={{
          height: 40,
          position: "absolute",
          top: 20,
          width: 100,
          borderRadius: 10,
          color:
            selectedGraph === "month"
              ? BlackAndWhite.secondary
              : BlackAndWhite.primary,
          backgroundColor:
            selectedGraph === "month"
              ? BlackAndWhite.primary
              : BlackAndWhite.secondary,
          WebkitBoxShadow: "0px 0px 10px 1px #00000033",
          right: 240,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          transition: "all 0.3s",
        }}
      >
        30 Days
      </div>
    </div>
  );
};

export default StatPopUp;
