import React, { useState, useContext } from "react";
import {
  BaseButtons,
  CheckInput,
  TextAreaInput,
  TextInputField,
} from "../../../../../../components/atoms";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import { ImageCheckMark, LoadingPopUp } from "../../../../../../components";
import ReadOnlyDisplay from "../read-only-display/ReadOnlyDisplay";
import StrainSelector from "../strain-selector/StrainSelector";
import MeasurementSelector from "../measurment-selector/MeasurementSelector";
import CompletedStepNotice from "../completed-step-notice/CompletedStepNotice";
import { AdminContext } from "../../../../../../context/admin-context";

const AddProduct = ({
  setMessage,
  setToastPop,
  setSelectedAdd,
  categorySelected,
  selectedSubCategory,
}) => {
  const [loading, setLoading] = useState(false);
  const admin = useContext(AdminContext);
  const [steps, setSteps] = useState(1);
  const [productName, setProductName] = useState(null);
  const [productType, setProductType] = useState(null);
  const [typeCheck, setTypeCheck] = useState(null);

  const [strain, setStrain] = useState(null);
  const [strainOpen, setStrainOpen] = useState(true);

  const [growType, setGrowType] = useState(null);
  const [thcContent, setThcContent] = useState(null);
  const [thcType, setThcType] = useState(null);

  const [measurement, setMeasurment] = useState("g");
  const [measurementOpen, setMeasuremntOpen] = useState(false);

  const [productSizeOrAmount, setProductSizeOrAmount] = useState(null);

  const [gender, setGender] = useState("nothing");
  const [flavour, setFlavour] = useState("nothing");

  const [inventory, setInventory] = useState(null);
  const [description, setDescription] = useState(null);
  const [menuDescription, setMenuDescription] = useState(null);
  const [useDescription, setUseDescription] = useState(null);

  const [tag1, setTag1] = useState(null);
  const [tag2, setTag2] = useState(null);
  const [tag3, setTag3] = useState(null);

  const [productNo, setProductNo] = useState(null);
  const [productSubCat, setProductSubCat] = useState(null);

  const [grouping, setGrouping] = useState(null);

  const [image, setImage] = useState("coming soon");

  const [moreInfo, setMoreInfo] = useState(null);

  const [retailPrice, setRetailPrice] = useState(null);
  const [retailPriceInc, setRetailPriceInc] = useState(null);
  const [costPrice, setCostPrice] = useState(null);
  const [merryJaneCostPrice, setMerryJaneCostPrice] = useState(null);

  const [includesVat, setIncludesVat] = useState(true);
  const [sku, setSku] = useState(null);
  const [trackStock, setTrackStock] = useState(null);

  const [onlineStore, setOnlineStore] = useState(false);

  const [malemesbury, setMalmesbury] = useState(false);
  const [woodstock, setWoodstock] = useState(false);
  const [langebaan, setLangebaan] = useState(false);

  const [baseAdjusterOpen, setBaseAdjusterOpen] = useState("none");
  const [mjPercent, setMjPercent] = useState(0);
  const [costPercent, setCostPercent] = useState(0);
  const vatValue = 15;

  const valueCheckMerryJaneCost = (merryJaneCostPrice / 100) * mjPercent;
  const valueCheckCost = (costPrice / 100) * costPercent;
  const workOutVat = (retailPrice / 100) * vatValue;
  const workOutVaInclusive = (retailPriceInc / 100) * vatValue;
  const mjBasePriceDiff = costPrice - merryJaneCostPrice;
  const markUpBasePercent = (mjBasePriceDiff / merryJaneCostPrice) * 100;
  const mjBaseRetailDif = retailPrice - costPrice;
  const markRetailEarings = (mjBaseRetailDif / costPrice) * 100;

  const retailPercentMarkUpCheck = retailPriceInc - costPrice - workOutVat;
  const checkRetailInc = retailPercentMarkUpCheck;

  const UpDateInfoHandler = (e) => {
    // if (costPrice !== merryJaneCostPrice) {
    //   setCostPrice(merryJaneCostPrice);
    // }

    const vatValueCheck = Number(e.target.value / 100);
    const checkForVatOnCost = Number(vatValueCheck * 15);
    const updateVatValue = Number(e.target.value - checkForVatOnCost);
    setMerryJaneCostPrice(e.target.value);
    setCostPrice(e.target.value);
    setRetailPrice(e.target.value);
    const checkRetailInclusive = (
      Number(e.target.value) +
      workOutVat * 10
    ).toFixed(2);
    setRetailPriceInc(checkRetailInclusive);
    // let checkValue = Number(costPrice);
  };

  const UpdateRetailPricePlusVat = (e) => {
    setRetailPrice(e.target.value);
    const checkRetailInclusive = (
      Number(e.target.value) +
      workOutVat * 10
    ).toFixed(2);
    setRetailPriceInc(checkRetailInclusive);
  };

  const RetailExVatHandler = (e) => {
    setRetailPriceInc(e.target.value);
    const vatRemove = workOutVat;
    const checkRetailEx = (Number(e.target.value) / 1.15).toFixed(2);

    setRetailPrice(checkRetailEx);
  };

  const WholeSaleHandler = (e) => {
    setCostPrice(e.target.value);
    setRetailPrice(e.target.value);
    const checkRetailInclusive = (
      Number(e.target.value) +
      workOutVat * 10
    ).toFixed(2);
    setRetailPriceInc(checkRetailInclusive);
  };

  // const UpdateRetailPricePlusVat = (e) => {
  //   setRetailPrice(e.target.value);
  //   setRetailPriceInc(e.target.value);
  //   // if (retailPrice === retailPriceInc) {
  //   //   setRetailPriceInc(Number(retailPrice) + Number(workOutVat));
  //   // }
  // };

  let whichStores = [
    { storeName: "malemesbury", value: malemesbury },
    { storeName: "langebaan", value: langebaan },
    { storeName: "woodstock", value: woodstock },
  ];
  const uploadProduct = async () => {
    setLoading(true);
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg
    if (productName) {
      try {
        const response = await fetch(
          "https://merry-jane-api.onrender.com/productUpload/createProduct",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              // step1
              productName: productName,
              productType: categorySelected.categoryName,
              type: typeCheck ? typeCheck : "Na",
              size: productSizeOrAmount,
              strain: strain ? strain : "Na",
              growType: growType ? growType : "Na",
              thcContent: thcContent ? thcContent : "Na",
              thcType: thcType ? thcType : "Na",
              productNo: productNo,
              quantityDenomiation: measurement,
              gender: gender ? gender : "Na",
              flavour: flavour ? flavour : "Na",
              // step 2
              description: description,
              menuDescription: menuDescription,
              useDescription: useDescription,
              moreInfo: "none",
              medicalUses: [{ use: "none" }],
              productSubType: "none",
              grouping: "none",
              tag1: tag1,
              tag2: tag2 ? tag2 : "nothing",
              tag3: tag3 ? tag3 : "nothing",
              // step 3
              categoryId: categorySelected.categoryId,
              subCategoryId: selectedSubCategory.subCategoryId,
              baseCostPrice: merryJaneCostPrice,
              retailPrice: retailPrice,
              retailPriceInc: retailPriceInc,
              vat: includesVat,
              costPrice: costPrice,
              image: "N/A",
              addedBy:
                admin.adminUserData.firstName +
                " " +
                admin.adminUserData.lastName,
              inventory: inventory,
              // step4
              onlineStore: onlineStore,
              availableAt: whichStores,
              trackStock: "coming soon",
              sku: "none",
            }),
          }
        );

        const respnseData = await response.json();
        if (respnseData.newProduct) {
          // const wait = await updateListFetch();
          setToastPop(true);
          setMessage("Product Uploaded");
          setLoading(false);
          setSelectedAdd(null);
        } else if (respnseData.message) {
          setToastPop(true);
          setMessage(respnseData.message);
          setLoading(false);
        }
      } catch (err) {
        setToastPop(true);
        setMessage("Error occured please try again in a few minutes");
        setLoading(false);
      }
    } else {
      setToastPop(true);
      setMessage(
        "You are missing data fields please review inforation and try again"
      );
      setLoading(false);
    }
  };

  return (
    <div style={{ flex: 1, display: "flex", position: "relative" }}>
      {categorySelected ? (
        categorySelected.categoryName === "Cannabis Flower" ? (
          <div style={{ flex: 0.5, overflow: "scroll" }}>
            {steps === 1 ? (
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: 600,
                }}
              >
                <div style={{ height: 40 }} />
                <TextInputField
                  labelTitle={" Product name:"}
                  invert
                  size={"70%"}
                  onChange={(e) => setProductName(e.target.value)}
                  value={productName}
                />
                <div style={{ position: "absolute", right: 50, top: 0 }}>
                  <ReadOnlyDisplay categorySelected={categorySelected} />
                </div>
                <TextInputField
                  labelTitle={" Thc content:"}
                  invert
                  size={"70%"}
                  onChange={(e) => setThcContent(e.target.value)}
                  value={thcContent}
                />
                <TextInputField
                  labelTitle={"Product Size/Amount:"}
                  invert
                  size={"70%"}
                  onChange={(e) => setProductSizeOrAmount(e.target.value)}
                  value={productSizeOrAmount}
                />

                <MeasurementSelector
                  open={measurementOpen}
                  setType={setMeasurment}
                  type={measurement}
                  setTypeOpen={setMeasuremntOpen}
                  onTypeClick={() => setMeasuremntOpen(true)}
                />
                <StrainSelector
                  open={strainOpen}
                  setStrain={setStrain}
                  strain={strain}
                  setStrainOpen={setStrainOpen}
                  onStrainClick={() => setStrainOpen(true)}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 20,
                    //   textAlign: "left",
                    //   width: "100%",
                    //   borderStyle: "solid",
                    fontSize: 20,
                  }}
                >
                  Grow Condition:
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 20,
                  }}
                >
                  <CheckInput
                    check={growType === "Indoor" ? true : false}
                    question={"INDOOR"}
                    size={230}
                    invert={true}
                    onClick={() => setGrowType("Indoor")}
                  />
                  <CheckInput
                    check={growType === "Greenhouse" ? true : false}
                    question={"GREENHOUSE"}
                    size={230}
                    invert={true}
                    onClick={() => setGrowType("Greenhouse")}
                  />
                </div>
                <div style={{ height: 40 }} />
              </div>
            ) : null}
            {steps === 2 ? (
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: 600,
                }}
              >
                <div style={{ height: 40 }} />

                <TextAreaInput
                  labelTitle={"Description :"}
                  invert
                  size={"70%"}
                  color={BlackAndWhite.primary}
                  onChange={(e) => setDescription(e.target.value)}
                  height={100}
                  value={description}
                />
                <TextAreaInput
                  labelTitle={"Menu Description :"}
                  invert
                  size={"70%"}
                  color={BlackAndWhite.primary}
                  onChange={(e) => setMenuDescription(e.target.value)}
                  height={100}
                  value={menuDescription}
                />
                <TextAreaInput
                  labelTitle={"Use Description :"}
                  invert
                  size={"70%"}
                  color={BlackAndWhite.primary}
                  onChange={(e) => setUseDescription(e.target.value)}
                  height={100}
                  value={useDescription}
                />
                <TextInputField
                  labelTitle={" Tag 1:"}
                  invert
                  size={"70%"}
                  onChange={(e) => setTag1(e.target.value)}
                  value={tag1}
                />
                <TextInputField
                  labelTitle={" Tag 2:"}
                  invert
                  size={"70%"}
                  onChange={(e) => setTag2(e.target.value)}
                  value={tag2}
                />
                <TextInputField
                  labelTitle={" Tag 3:"}
                  invert
                  size={"70%"}
                  onChange={(e) => setTag3(e.target.value)}
                  value={tag3}
                />

                <div style={{ height: 100 }} />
              </div>
            ) : null}
            {steps === 3 ? (
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: 600,
                }}
              >
                <div style={{ height: 40 }} />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "70%", textAlign: "left" }}>
                    Cost price includes VAT:
                  </div>{" "}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CheckInput
                    question={"YES"}
                    invert={true}
                    check={includesVat}
                    onClick={() => setIncludesVat(true)}
                    size={200}
                  />
                  <CheckInput
                    question={"NO"}
                    invert={true}
                    check={!includesVat}
                    onClick={() => setIncludesVat(false)}
                    size={200}
                  />
                </div>
                <TextInputField
                  labelTitle={"Cost:"}
                  invert
                  size={"70%"}
                  onChange={(e) => UpDateInfoHandler(e)}
                  value={merryJaneCostPrice}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <div
                    style={{
                      width: 160,
                      marginLeft: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 40,
                      flexDirection: "column",
                    }}
                  >
                    <div>Mark up</div>
                    <div>{mjBasePriceDiff}</div>
                    <div>{markUpBasePercent + "%"}</div>
                  </div> */}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextInputField
                    labelTitle={"Wholesale:"}
                    autoFocus={true}
                    invert
                    size={260}
                    onChange={(e) => {
                      WholeSaleHandler(e);
                    }}
                    value={costPrice}
                    amountValue={mjPercent}
                    amountMarkedUp={
                      "R" +
                      " " +
                      (
                        Number(valueCheckMerryJaneCost) + Number(costPrice)
                      ).toFixed(2)
                    }
                  />
                  <div
                    style={{
                      width: 160,
                      marginLeft: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 40,
                      flexDirection: "column",
                    }}
                  >
                    <div>Mark up</div>
                    <div>{"R" + " " + mjBasePriceDiff.toFixed(2)}</div>
                    <div>{markUpBasePercent.toFixed(2) + "%"}</div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextInputField
                    labelTitle={"Retail ex Vat:"}
                    invert
                    size={260}
                    onChange={(e) => UpdateRetailPricePlusVat(e)}
                    value={retailPrice}
                  />
                  <div
                    style={{
                      width: 160,
                      marginLeft: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 40,
                      flexDirection: "column",
                    }}
                  >
                    <div>Mark up</div>
                    <div>{"R" + " " + Number(retailPrice - costPrice)}</div>
                    <div>{markRetailEarings.toFixed(2) + "%"}</div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextInputField
                    labelTitle={"Retail Inc Vat:"}
                    invert
                    size={260}
                    onChange={(e) => RetailExVatHandler(e)}
                    value={retailPriceInc}
                  />
                  <div
                    style={{
                      width: 160,
                      marginLeft: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 40,
                      flexDirection: "column",
                    }}
                  >
                    Vat
                    <div>R {workOutVaInclusive.toFixed(2)}</div>
                    <div>Mark up</div>
                    <div>R {checkRetailInc.toFixed(2)}</div>
                  </div>
                </div>
                <TextInputField
                  labelTitle={"Inventory in:" + " " + measurement}
                  invert
                  size={"70%"}
                  onChange={(e) => setInventory(e.target.value)}
                  value={inventory}
                />

                <div style={{ height: 100 }} />
              </div>
            ) : null}
            {steps === 4 ? (
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: 600,
                }}
              >
                <div style={{ height: 40 }} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 20,
                    fontSize: 20,
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  <div style={{ width: "80%", textAlign: "left" }}>
                    Available at:
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CheckInput
                    check={onlineStore === "yes" ? true : false}
                    question={"Available Online"}
                    invert={true}
                    onClick={() =>
                      setOnlineStore(onlineStore === "yes" ? null : "yes")
                    }
                  />
                  <CheckInput
                    question={"In-store Only"}
                    invert={true}
                    check={onlineStore === "in-store" ? true : false}
                    onClick={() =>
                      setOnlineStore(
                        onlineStore === "in-store" ? null : "in-store"
                      )
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 20,
                    fontSize: 20,
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  <div style={{ width: "80%", textAlign: "left" }}>
                    Select the stores:
                  </div>
                </div>
                <CheckInput
                  check={malemesbury}
                  question={"Malmesbury"}
                  invert={true}
                  onClick={() => setMalmesbury(malemesbury ? false : true)}
                />
                <CheckInput
                  check={langebaan}
                  question={"Langebaan"}
                  invert={true}
                  onClick={() => setLangebaan(langebaan ? false : true)}
                />
                <CheckInput
                  check={woodstock}
                  question={"Woodstock"}
                  invert={true}
                  onClick={() => setWoodstock(woodstock ? false : true)}
                />

                <div style={{ height: 100 }} />
              </div>
            ) : null}
          </div>
        ) : null
      ) : null}
      {categorySelected ? (
        categorySelected.categoryName === "Edibles" ? (
          <div style={{ flex: 0.5, overflow: "scroll" }}>
            {steps === 1 ? (
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: 600,
                }}
              >
                <div style={{ height: 40 }} />
                <TextInputField
                  labelTitle={" Product name:"}
                  invert
                  size={"70%"}
                  onChange={(e) => setProductName(e.target.value)}
                  value={productName}
                />
                <div style={{ position: "absolute", right: 50, top: 0 }}>
                  <ReadOnlyDisplay categorySelected={categorySelected} />
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextInputField
                    labelTitle={" Thc content:"}
                    invert
                    size={"80%"}
                    onChange={(e) => setThcContent(e.target.value)}
                    value={thcContent}
                  />
                  <TextInputField
                    labelTitle={"Size / Amount:"}
                    invert
                    size={"70%"}
                    onChange={(e) => setProductSizeOrAmount(e.target.value)}
                    value={productSizeOrAmount}
                  />
                </div>

                <MeasurementSelector
                  open={measurementOpen}
                  setType={setMeasurment}
                  type={measurement}
                  setTypeOpen={setMeasuremntOpen}
                  onTypeClick={() => setMeasuremntOpen(true)}
                />
                <StrainSelector
                  open={strainOpen}
                  edibles={[
                    "Nano",
                    "Indica Hybrid",
                    "Pure Indica",
                    "Sativa Hybrid",
                    "Pure Sativa",
                    "Balanced",
                  ]}
                  setStrain={setThcType}
                  strain={thcType}
                  setStrainOpen={setStrainOpen}
                  onStrainClick={() => setStrainOpen(true)}
                />

                <div style={{ height: 40 }} />
              </div>
            ) : null}
            {steps === 2 ? (
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: 600,
                }}
              >
                <div style={{ height: 40 }} />

                <TextAreaInput
                  labelTitle={"Description :"}
                  invert
                  size={"70%"}
                  color={BlackAndWhite.primary}
                  onChange={(e) => setDescription(e.target.value)}
                  height={100}
                  value={description}
                />
                <TextAreaInput
                  labelTitle={"Menu Description :"}
                  invert
                  size={"70%"}
                  color={BlackAndWhite.primary}
                  onChange={(e) => setMenuDescription(e.target.value)}
                  height={100}
                  value={menuDescription}
                />
                <TextAreaInput
                  labelTitle={"Use Description :"}
                  invert
                  size={"70%"}
                  color={BlackAndWhite.primary}
                  onChange={(e) => setUseDescription(e.target.value)}
                  height={100}
                  value={useDescription}
                />
                <TextInputField
                  labelTitle={" Tag 1:"}
                  invert
                  size={"70%"}
                  onChange={(e) => setTag1(e.target.value)}
                  value={tag1}
                />
                <TextInputField
                  labelTitle={" Tag 2:"}
                  invert
                  size={"70%"}
                  onChange={(e) => setTag2(e.target.value)}
                  value={tag2}
                />
                <TextInputField
                  labelTitle={" Tag 3:"}
                  invert
                  size={"70%"}
                  onChange={(e) => setTag3(e.target.value)}
                  value={tag3}
                />

                <div style={{ height: 100 }} />
              </div>
            ) : null}
            {steps === 3 ? (
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: 600,
                }}
              >
                <div style={{ height: 40 }} />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "70%", textAlign: "left" }}>
                    Cost price includes VAT:
                  </div>{" "}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CheckInput
                    question={"YES"}
                    invert={true}
                    check={includesVat}
                    onClick={() => setIncludesVat(true)}
                    size={200}
                  />
                  <CheckInput
                    question={"NO"}
                    invert={true}
                    check={!includesVat}
                    onClick={() => setIncludesVat(false)}
                    size={200}
                  />
                </div>
                <TextInputField
                  labelTitle={"Cost:"}
                  invert
                  size={"70%"}
                  onChange={(e) => UpDateInfoHandler(e)}
                  value={merryJaneCostPrice}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <div
                    style={{
                      width: 160,
                      marginLeft: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 40,
                      flexDirection: "column",
                    }}
                  >
                    <div>Mark up</div>
                    <div>{mjBasePriceDiff}</div>
                    <div>{markUpBasePercent + "%"}</div>
                  </div> */}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextInputField
                    labelTitle={"Wholesale:"}
                    autoFocus={true}
                    invert
                    size={260}
                    onChange={(e) => {
                      WholeSaleHandler(e);
                    }}
                    value={costPrice}
                    amountValue={mjPercent}
                    amountMarkedUp={
                      "R" +
                      " " +
                      (
                        Number(valueCheckMerryJaneCost) + Number(costPrice)
                      ).toFixed(2)
                    }
                  />
                  <div
                    style={{
                      width: 160,
                      marginLeft: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 40,
                      flexDirection: "column",
                    }}
                  >
                    <div>Mark up</div>
                    <div>{"R" + " " + mjBasePriceDiff.toFixed(2)}</div>
                    <div>{markUpBasePercent.toFixed(2) + "%"}</div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextInputField
                    labelTitle={"Retail ex Vat:"}
                    invert
                    size={260}
                    onChange={(e) => UpdateRetailPricePlusVat(e)}
                    value={retailPrice}
                  />
                  <div
                    style={{
                      width: 160,
                      marginLeft: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 40,
                      flexDirection: "column",
                    }}
                  >
                    <div>Mark up</div>
                    <div>{"R" + " " + Number(retailPrice - costPrice)}</div>
                    <div>{markRetailEarings.toFixed(2) + "%"}</div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextInputField
                    labelTitle={"Retail Inc Vat:"}
                    invert
                    size={260}
                    onChange={(e) => RetailExVatHandler(e)}
                    value={retailPriceInc}
                  />
                  <div
                    style={{
                      width: 160,
                      marginLeft: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 40,
                      flexDirection: "column",
                    }}
                  >
                    Vat
                    <div>R {workOutVaInclusive.toFixed(2)}</div>
                    <div>Mark up</div>
                    <div>R {checkRetailInc.toFixed(2)}</div>
                  </div>
                </div>
                <TextInputField
                  labelTitle={"Inventory in:" + " " + measurement}
                  invert
                  size={"70%"}
                  onChange={(e) => setInventory(e.target.value)}
                  value={inventory}
                />

                <div style={{ height: 100 }} />
              </div>
            ) : null}
            {steps === 4 ? (
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: 600,
                }}
              >
                <div style={{ height: 40 }} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 20,
                    fontSize: 20,
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  <div style={{ width: "80%", textAlign: "left" }}>
                    Available at:
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CheckInput
                    check={onlineStore === "yes" ? true : false}
                    question={"Available Online"}
                    invert={true}
                    onClick={() =>
                      setOnlineStore(onlineStore === "yes" ? null : "yes")
                    }
                  />
                  <CheckInput
                    question={"In-store Only"}
                    invert={true}
                    check={onlineStore === "in-store" ? true : false}
                    onClick={() =>
                      setOnlineStore(
                        onlineStore === "in-store" ? null : "in-store"
                      )
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 20,
                    fontSize: 20,
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  <div style={{ width: "80%", textAlign: "left" }}>
                    Select the stores:
                  </div>
                </div>
                <CheckInput
                  check={malemesbury}
                  question={"Malmesbury"}
                  invert={true}
                  onClick={() => setMalmesbury(malemesbury ? false : true)}
                />
                <CheckInput
                  check={langebaan}
                  question={"Langebaan"}
                  invert={true}
                  onClick={() => setLangebaan(langebaan ? false : true)}
                />
                <CheckInput
                  check={woodstock}
                  question={"Woodstock"}
                  invert={true}
                  onClick={() => setWoodstock(woodstock ? false : true)}
                />

                <div style={{ height: 100 }} />
              </div>
            ) : null}
          </div>
        ) : null
      ) : null}
      {categorySelected ? (
        categorySelected.categoryName === "Beverages" ? (
          <div style={{ flex: 0.5, overflow: "scroll" }}>
            {steps === 1 ? (
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: 600,
                }}
              >
                <div style={{ height: 40 }} />
                <TextInputField
                  labelTitle={" Product name:"}
                  invert
                  size={"70%"}
                  onChange={(e) => setProductName(e.target.value)}
                  value={productName}
                />
                <div style={{ position: "absolute", right: 50, top: 0 }}>
                  <ReadOnlyDisplay categorySelected={categorySelected} />
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextInputField
                    labelTitle={" Thc content:"}
                    invert
                    size={"80%"}
                    onChange={(e) => setThcContent(e.target.value)}
                    value={thcContent}
                  />
                  <TextInputField
                    labelTitle={"Size / Amount:"}
                    invert
                    size={"70%"}
                    onChange={(e) => setProductSizeOrAmount(e.target.value)}
                    value={productSizeOrAmount}
                  />
                </div>

                <MeasurementSelector
                  open={measurementOpen}
                  setType={setMeasurment}
                  type={measurement}
                  setTypeOpen={setMeasuremntOpen}
                  onTypeClick={() => setMeasuremntOpen(true)}
                />
                <StrainSelector
                  open={strainOpen}
                  edibles={[
                    "Nano",
                    "Indica Hybrid",
                    "Pure Indica",
                    "Sativa Hybrid",
                    "Pure Sativa",
                    "Balanced",
                  ]}
                  setStrain={setThcType}
                  strain={thcType}
                  setStrainOpen={setStrainOpen}
                  onStrainClick={() => setStrainOpen(true)}
                />

                <div style={{ height: 40 }} />
              </div>
            ) : null}
            {steps === 2 ? (
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: 600,
                }}
              >
                <div style={{ height: 40 }} />

                <TextAreaInput
                  labelTitle={"Description :"}
                  invert
                  size={"70%"}
                  color={BlackAndWhite.primary}
                  onChange={(e) => setDescription(e.target.value)}
                  height={100}
                  value={description}
                />
                <TextAreaInput
                  labelTitle={"Menu Description :"}
                  invert
                  size={"70%"}
                  color={BlackAndWhite.primary}
                  onChange={(e) => setMenuDescription(e.target.value)}
                  height={100}
                  value={menuDescription}
                />
                <TextAreaInput
                  labelTitle={"Use Description :"}
                  invert
                  size={"70%"}
                  color={BlackAndWhite.primary}
                  onChange={(e) => setUseDescription(e.target.value)}
                  height={100}
                  value={useDescription}
                />
                <TextInputField
                  labelTitle={" Tag 1:"}
                  invert
                  size={"70%"}
                  onChange={(e) => setTag1(e.target.value)}
                  value={tag1}
                />
                <TextInputField
                  labelTitle={" Tag 2:"}
                  invert
                  size={"70%"}
                  onChange={(e) => setTag2(e.target.value)}
                  value={tag2}
                />
                <TextInputField
                  labelTitle={" Tag 3:"}
                  invert
                  size={"70%"}
                  onChange={(e) => setTag3(e.target.value)}
                  value={tag3}
                />

                <div style={{ height: 100 }} />
              </div>
            ) : null}
            {steps === 3 ? (
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: 600,
                }}
              >
                <div style={{ height: 40 }} />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "70%", textAlign: "left" }}>
                    Cost price includes VAT:
                  </div>{" "}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CheckInput
                    question={"YES"}
                    invert={true}
                    check={includesVat}
                    onClick={() => setIncludesVat(true)}
                    size={200}
                  />
                  <CheckInput
                    question={"NO"}
                    invert={true}
                    check={!includesVat}
                    onClick={() => setIncludesVat(false)}
                    size={200}
                  />
                </div>
                <TextInputField
                  labelTitle={"Cost:"}
                  invert
                  size={"70%"}
                  onChange={(e) => UpDateInfoHandler(e)}
                  value={merryJaneCostPrice}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <div
                    style={{
                      width: 160,
                      marginLeft: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 40,
                      flexDirection: "column",
                    }}
                  >
                    <div>Mark up</div>
                    <div>{mjBasePriceDiff}</div>
                    <div>{markUpBasePercent + "%"}</div>
                  </div> */}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextInputField
                    labelTitle={"Wholesale:"}
                    autoFocus={true}
                    invert
                    size={260}
                    onChange={(e) => {
                      WholeSaleHandler(e);
                    }}
                    value={costPrice}
                    amountValue={mjPercent}
                    amountMarkedUp={
                      "R" +
                      " " +
                      (
                        Number(valueCheckMerryJaneCost) + Number(costPrice)
                      ).toFixed(2)
                    }
                  />
                  <div
                    style={{
                      width: 160,
                      marginLeft: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 40,
                      flexDirection: "column",
                    }}
                  >
                    <div>Mark up</div>
                    <div>{"R" + " " + mjBasePriceDiff.toFixed(2)}</div>
                    <div>{markUpBasePercent.toFixed(2) + "%"}</div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextInputField
                    labelTitle={"Retail ex Vat:"}
                    invert
                    size={260}
                    onChange={(e) => UpdateRetailPricePlusVat(e)}
                    value={retailPrice}
                  />
                  <div
                    style={{
                      width: 160,
                      marginLeft: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 40,
                      flexDirection: "column",
                    }}
                  >
                    <div>Mark up</div>
                    <div>{"R" + " " + Number(retailPrice - costPrice)}</div>
                    <div>{markRetailEarings.toFixed(2) + "%"}</div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextInputField
                    labelTitle={"Retail Inc Vat:"}
                    invert
                    size={260}
                    onChange={(e) => RetailExVatHandler(e)}
                    value={retailPriceInc}
                  />
                  <div
                    style={{
                      width: 160,
                      marginLeft: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 40,
                      flexDirection: "column",
                    }}
                  >
                    Vat
                    <div>R {workOutVaInclusive.toFixed(2)}</div>
                    <div>Mark up</div>
                    <div>R {checkRetailInc.toFixed(2)}</div>
                  </div>
                </div>
                <TextInputField
                  labelTitle={"Inventory in:" + " " + measurement}
                  invert
                  size={"70%"}
                  onChange={(e) => setInventory(e.target.value)}
                  value={inventory}
                />

                <div style={{ height: 100 }} />
              </div>
            ) : null}
            {steps === 4 ? (
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: 600,
                }}
              >
                <div style={{ height: 40 }} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 20,
                    fontSize: 20,
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  <div style={{ width: "80%", textAlign: "left" }}>
                    Available at:
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CheckInput
                    check={onlineStore === "yes" ? true : false}
                    question={"Available Online"}
                    invert={true}
                    onClick={() =>
                      setOnlineStore(onlineStore === "yes" ? null : "yes")
                    }
                  />
                  <CheckInput
                    question={"In-store Only"}
                    invert={true}
                    check={onlineStore === "in-store" ? true : false}
                    onClick={() =>
                      setOnlineStore(
                        onlineStore === "in-store" ? null : "in-store"
                      )
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 20,
                    fontSize: 20,
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  <div style={{ width: "80%", textAlign: "left" }}>
                    Select the stores:
                  </div>
                </div>
                <CheckInput
                  check={malemesbury}
                  question={"Malmesbury"}
                  invert={true}
                  onClick={() => setMalmesbury(malemesbury ? false : true)}
                />
                <CheckInput
                  check={langebaan}
                  question={"Langebaan"}
                  invert={true}
                  onClick={() => setLangebaan(langebaan ? false : true)}
                />
                <CheckInput
                  check={woodstock}
                  question={"Woodstock"}
                  invert={true}
                  onClick={() => setWoodstock(woodstock ? false : true)}
                />

                <div style={{ height: 100 }} />
              </div>
            ) : null}
          </div>
        ) : null
      ) : null}
      {categorySelected ? (
        categorySelected.categoryName === "Art" ? (
          <div style={{ flex: 0.5, overflow: "scroll" }}>
            {steps === 1 ? (
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: 600,
                }}
              >
                <div style={{ height: 40 }} />
                <TextInputField
                  labelTitle={" Product name:"}
                  invert
                  size={"70%"}
                  onChange={(e) => setProductName(e.target.value)}
                  value={productName}
                />
                <div style={{ position: "absolute", right: 50, top: 0 }}>
                  <ReadOnlyDisplay categorySelected={categorySelected} />
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextInputField
                    labelTitle={"Size / Amount:"}
                    invert
                    size={"70%"}
                    onChange={(e) => setProductSizeOrAmount(e.target.value)}
                    value={productSizeOrAmount}
                  />
                </div>

                <MeasurementSelector
                  open={measurementOpen}
                  setType={setMeasurment}
                  type={measurement}
                  setTypeOpen={setMeasuremntOpen}
                  onTypeClick={() => setMeasuremntOpen(true)}
                />

                <div style={{ height: 40 }} />
              </div>
            ) : null}
            {steps === 2 ? (
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: 600,
                }}
              >
                <div style={{ height: 40 }} />

                <TextAreaInput
                  labelTitle={"Description :"}
                  invert
                  size={"70%"}
                  color={BlackAndWhite.primary}
                  onChange={(e) => setDescription(e.target.value)}
                  height={100}
                  value={description}
                />
                <TextAreaInput
                  labelTitle={"Menu Description :"}
                  invert
                  size={"70%"}
                  color={BlackAndWhite.primary}
                  onChange={(e) => setMenuDescription(e.target.value)}
                  height={100}
                  value={menuDescription}
                />
                <TextAreaInput
                  labelTitle={"Use Description :"}
                  invert
                  size={"70%"}
                  color={BlackAndWhite.primary}
                  onChange={(e) => setUseDescription(e.target.value)}
                  height={100}
                  value={useDescription}
                />
                <TextInputField
                  labelTitle={" Tag 1:"}
                  invert
                  size={"70%"}
                  onChange={(e) => setTag1(e.target.value)}
                  value={tag1}
                />
                <TextInputField
                  labelTitle={" Tag 2:"}
                  invert
                  size={"70%"}
                  onChange={(e) => setTag2(e.target.value)}
                  value={tag2}
                />
                <TextInputField
                  labelTitle={" Tag 3:"}
                  invert
                  size={"70%"}
                  onChange={(e) => setTag3(e.target.value)}
                  value={tag3}
                />

                <div style={{ height: 100 }} />
              </div>
            ) : null}
            {steps === 3 ? (
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: 600,
                }}
              >
                <div style={{ height: 40 }} />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "70%", textAlign: "left" }}>
                    Cost price includes VAT:
                  </div>{" "}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CheckInput
                    question={"YES"}
                    invert={true}
                    check={includesVat}
                    onClick={() => setIncludesVat(true)}
                    size={200}
                  />
                  <CheckInput
                    question={"NO"}
                    invert={true}
                    check={!includesVat}
                    onClick={() => setIncludesVat(false)}
                    size={200}
                  />
                </div>
                <TextInputField
                  labelTitle={"Cost:"}
                  invert
                  size={"70%"}
                  onChange={(e) => UpDateInfoHandler(e)}
                  value={merryJaneCostPrice}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <div
                    style={{
                      width: 160,
                      marginLeft: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 40,
                      flexDirection: "column",
                    }}
                  >
                    <div>Mark up</div>
                    <div>{mjBasePriceDiff}</div>
                    <div>{markUpBasePercent + "%"}</div>
                  </div> */}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextInputField
                    labelTitle={"Wholesale:"}
                    autoFocus={true}
                    invert
                    size={260}
                    onChange={(e) => {
                      WholeSaleHandler(e);
                    }}
                    value={costPrice}
                    amountValue={mjPercent}
                    amountMarkedUp={
                      "R" +
                      " " +
                      (
                        Number(valueCheckMerryJaneCost) + Number(costPrice)
                      ).toFixed(2)
                    }
                  />
                  <div
                    style={{
                      width: 160,
                      marginLeft: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 40,
                      flexDirection: "column",
                    }}
                  >
                    <div>Mark up</div>
                    <div>{"R" + " " + mjBasePriceDiff.toFixed(2)}</div>
                    <div>{markUpBasePercent.toFixed(2) + "%"}</div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextInputField
                    labelTitle={"Retail ex Vat:"}
                    invert
                    size={260}
                    onChange={(e) => UpdateRetailPricePlusVat(e)}
                    value={retailPrice}
                  />
                  <div
                    style={{
                      width: 160,
                      marginLeft: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 40,
                      flexDirection: "column",
                    }}
                  >
                    <div>Mark up</div>
                    <div>{"R" + " " + Number(retailPrice - costPrice)}</div>
                    <div>{markRetailEarings.toFixed(2) + "%"}</div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextInputField
                    labelTitle={"Retail Inc Vat:"}
                    invert
                    size={260}
                    onChange={(e) => RetailExVatHandler(e)}
                    value={retailPriceInc}
                  />
                  <div
                    style={{
                      width: 160,
                      marginLeft: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 40,
                      flexDirection: "column",
                    }}
                  >
                    Vat
                    <div>R {workOutVaInclusive.toFixed(2)}</div>
                    <div>Mark up</div>
                    <div>R {checkRetailInc.toFixed(2)}</div>
                  </div>
                </div>
                <TextInputField
                  labelTitle={"Inventory in:" + " " + measurement}
                  invert
                  size={"70%"}
                  onChange={(e) => setInventory(e.target.value)}
                  value={inventory}
                />

                <div style={{ height: 100 }} />
              </div>
            ) : null}
            {steps === 4 ? (
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: 600,
                }}
              >
                <div style={{ height: 40 }} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 20,
                    fontSize: 20,
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  <div style={{ width: "80%", textAlign: "left" }}>
                    Available at:
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CheckInput
                    check={onlineStore === "yes" ? true : false}
                    question={"Available Online"}
                    invert={true}
                    onClick={() =>
                      setOnlineStore(onlineStore === "yes" ? null : "yes")
                    }
                  />
                  <CheckInput
                    question={"In-store Only"}
                    invert={true}
                    check={onlineStore === "in-store" ? true : false}
                    onClick={() =>
                      setOnlineStore(
                        onlineStore === "in-store" ? null : "in-store"
                      )
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 20,
                    fontSize: 20,
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  <div style={{ width: "80%", textAlign: "left" }}>
                    Select the stores:
                  </div>
                </div>
                <CheckInput
                  check={malemesbury}
                  question={"Malmesbury"}
                  invert={true}
                  onClick={() => setMalmesbury(malemesbury ? false : true)}
                />
                <CheckInput
                  check={langebaan}
                  question={"Langebaan"}
                  invert={true}
                  onClick={() => setLangebaan(langebaan ? false : true)}
                />
                <CheckInput
                  check={woodstock}
                  question={"Woodstock"}
                  invert={true}
                  onClick={() => setWoodstock(woodstock ? false : true)}
                />

                <div style={{ height: 100 }} />
              </div>
            ) : null}
          </div>
        ) : null
      ) : null}
      {categorySelected ? (
        categorySelected.categoryName === "Apparel" ? (
          <div style={{ flex: 0.5, overflow: "scroll" }}>
            {steps === 1 ? (
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: 600,
                }}
              >
                <div style={{ height: 40 }} />
                <TextInputField
                  labelTitle={" Product name:"}
                  invert
                  size={"70%"}
                  onChange={(e) => setProductName(e.target.value)}
                  value={productName}
                />
                <div style={{ position: "absolute", right: 50, top: 0 }}>
                  <ReadOnlyDisplay categorySelected={categorySelected} />
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextInputField
                    labelTitle={"Size / Amount:"}
                    invert
                    size={"70%"}
                    onChange={(e) => setProductSizeOrAmount(e.target.value)}
                    value={productSizeOrAmount}
                  />
                </div>

                <MeasurementSelector
                  open={measurementOpen}
                  setType={setMeasurment}
                  type={measurement}
                  setTypeOpen={setMeasuremntOpen}
                  onTypeClick={() => setMeasuremntOpen(true)}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 20,
                    //   textAlign: "left",
                    //   width: "100%",
                    //   borderStyle: "solid",
                    fontSize: 20,
                  }}
                >
                  Gender:
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 20,
                    marginLeft: 50,
                  }}
                >
                  <CheckInput
                    check={gender === "male" ? true : false}
                    question={"MALE"}
                    size={180}
                    invert={true}
                    onClick={() => setGender("male")}
                  />
                  <CheckInput
                    check={gender === "female" ? true : false}
                    question={"FEMALE"}
                    size={180}
                    invert={true}
                    onClick={() => setGender("female")}
                  />
                  <CheckInput
                    check={gender === "unisex" ? true : false}
                    question={"UNISEX"}
                    size={180}
                    invert={true}
                    onClick={() => setGender("unisex")}
                  />
                </div>

                <div style={{ height: 40 }} />
              </div>
            ) : null}
            {steps === 2 ? (
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: 600,
                }}
              >
                <div style={{ height: 40 }} />

                <TextAreaInput
                  labelTitle={"Description :"}
                  invert
                  size={"70%"}
                  color={BlackAndWhite.primary}
                  onChange={(e) => setDescription(e.target.value)}
                  height={100}
                  value={description}
                />
                <TextAreaInput
                  labelTitle={"Menu Description :"}
                  invert
                  size={"70%"}
                  color={BlackAndWhite.primary}
                  onChange={(e) => setMenuDescription(e.target.value)}
                  height={100}
                  value={menuDescription}
                />
                <TextAreaInput
                  labelTitle={"Use Description :"}
                  invert
                  size={"70%"}
                  color={BlackAndWhite.primary}
                  onChange={(e) => setUseDescription(e.target.value)}
                  height={100}
                  value={useDescription}
                />
                <TextInputField
                  labelTitle={" Tag 1:"}
                  invert
                  size={"70%"}
                  onChange={(e) => setTag1(e.target.value)}
                  value={tag1}
                />
                <TextInputField
                  labelTitle={" Tag 2:"}
                  invert
                  size={"70%"}
                  onChange={(e) => setTag2(e.target.value)}
                  value={tag2}
                />
                <TextInputField
                  labelTitle={" Tag 3:"}
                  invert
                  size={"70%"}
                  onChange={(e) => setTag3(e.target.value)}
                  value={tag3}
                />

                <div style={{ height: 100 }} />
              </div>
            ) : null}
            {steps === 3 ? (
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: 600,
                }}
              >
                <div style={{ height: 40 }} />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "70%", textAlign: "left" }}>
                    Cost price includes VAT:
                  </div>{" "}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CheckInput
                    question={"YES"}
                    invert={true}
                    check={includesVat}
                    onClick={() => setIncludesVat(true)}
                    size={200}
                  />
                  <CheckInput
                    question={"NO"}
                    invert={true}
                    check={!includesVat}
                    onClick={() => setIncludesVat(false)}
                    size={200}
                  />
                </div>
                <TextInputField
                  labelTitle={"Cost:"}
                  invert
                  size={"70%"}
                  onChange={(e) => UpDateInfoHandler(e)}
                  value={merryJaneCostPrice}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <div
                    style={{
                      width: 160,
                      marginLeft: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 40,
                      flexDirection: "column",
                    }}
                  >
                    <div>Mark up</div>
                    <div>{mjBasePriceDiff}</div>
                    <div>{markUpBasePercent + "%"}</div>
                  </div> */}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextInputField
                    labelTitle={"Wholesale:"}
                    autoFocus={true}
                    invert
                    size={260}
                    onChange={(e) => {
                      WholeSaleHandler(e);
                    }}
                    value={costPrice}
                    amountValue={mjPercent}
                    amountMarkedUp={
                      "R" +
                      " " +
                      (
                        Number(valueCheckMerryJaneCost) + Number(costPrice)
                      ).toFixed(2)
                    }
                  />
                  <div
                    style={{
                      width: 160,
                      marginLeft: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 40,
                      flexDirection: "column",
                    }}
                  >
                    <div>Mark up</div>
                    <div>{"R" + " " + mjBasePriceDiff.toFixed(2)}</div>
                    <div>{markUpBasePercent.toFixed(2) + "%"}</div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextInputField
                    labelTitle={"Retail ex Vat:"}
                    invert
                    size={260}
                    onChange={(e) => UpdateRetailPricePlusVat(e)}
                    value={retailPrice}
                  />
                  <div
                    style={{
                      width: 160,
                      marginLeft: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 40,
                      flexDirection: "column",
                    }}
                  >
                    <div>Mark up</div>
                    <div>{"R" + " " + Number(retailPrice - costPrice)}</div>
                    <div>{markRetailEarings.toFixed(2) + "%"}</div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextInputField
                    labelTitle={"Retail Inc Vat:"}
                    invert
                    size={260}
                    onChange={(e) => RetailExVatHandler(e)}
                    value={retailPriceInc}
                  />
                  <div
                    style={{
                      width: 160,
                      marginLeft: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 40,
                      flexDirection: "column",
                    }}
                  >
                    Vat
                    <div>R {workOutVaInclusive.toFixed(2)}</div>
                    <div>Mark up</div>
                    <div>R {checkRetailInc.toFixed(2)}</div>
                  </div>
                </div>
                <TextInputField
                  labelTitle={"Inventory in:" + " " + measurement}
                  invert
                  size={"70%"}
                  onChange={(e) => setInventory(e.target.value)}
                  value={inventory}
                />

                <div style={{ height: 100 }} />
              </div>
            ) : null}
            {steps === 4 ? (
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: 600,
                }}
              >
                <div style={{ height: 40 }} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 20,
                    fontSize: 20,
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  <div style={{ width: "80%", textAlign: "left" }}>
                    Available at:
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CheckInput
                    check={onlineStore === "yes" ? true : false}
                    question={"Available Online"}
                    invert={true}
                    onClick={() =>
                      setOnlineStore(onlineStore === "yes" ? null : "yes")
                    }
                  />
                  <CheckInput
                    question={"In-store Only"}
                    invert={true}
                    check={onlineStore === "in-store" ? true : false}
                    onClick={() =>
                      setOnlineStore(
                        onlineStore === "in-store" ? null : "in-store"
                      )
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 20,
                    fontSize: 20,
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  <div style={{ width: "80%", textAlign: "left" }}>
                    Select the stores:
                  </div>
                </div>
                <CheckInput
                  check={malemesbury}
                  question={"Malmesbury"}
                  invert={true}
                  onClick={() => setMalmesbury(malemesbury ? false : true)}
                />
                <CheckInput
                  check={langebaan}
                  question={"Langebaan"}
                  invert={true}
                  onClick={() => setLangebaan(langebaan ? false : true)}
                />
                <CheckInput
                  check={woodstock}
                  question={"Woodstock"}
                  invert={true}
                  onClick={() => setWoodstock(woodstock ? false : true)}
                />

                <div style={{ height: 100 }} />
              </div>
            ) : null}
          </div>
        ) : null
      ) : null}
      {categorySelected ? (
        categorySelected.categoryName === "Consumables" ? (
          <div style={{ flex: 0.5, overflow: "scroll" }}>
            {steps === 1 ? (
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: 600,
                }}
              >
                <div style={{ height: 40 }} />
                <TextInputField
                  labelTitle={" Product name:"}
                  invert
                  size={"70%"}
                  onChange={(e) => setProductName(e.target.value)}
                  value={productName}
                />
                <div style={{ position: "absolute", right: 50, top: 0 }}>
                  <ReadOnlyDisplay categorySelected={categorySelected} />
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextInputField
                    labelTitle={"Size / Amount:"}
                    invert
                    size={"70%"}
                    onChange={(e) => setProductSizeOrAmount(e.target.value)}
                    value={productSizeOrAmount}
                  />
                </div>

                <MeasurementSelector
                  open={measurementOpen}
                  setType={setMeasurment}
                  type={measurement}
                  setTypeOpen={setMeasuremntOpen}
                  onTypeClick={() => setMeasuremntOpen(true)}
                />

                <div style={{ height: 40 }} />
              </div>
            ) : null}
            {steps === 2 ? (
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: 600,
                }}
              >
                <div style={{ height: 40 }} />

                <TextAreaInput
                  labelTitle={"Description :"}
                  invert
                  size={"70%"}
                  color={BlackAndWhite.primary}
                  onChange={(e) => setDescription(e.target.value)}
                  height={100}
                  value={description}
                />
                <TextAreaInput
                  labelTitle={"Menu Description :"}
                  invert
                  size={"70%"}
                  color={BlackAndWhite.primary}
                  onChange={(e) => setMenuDescription(e.target.value)}
                  height={100}
                  value={menuDescription}
                />
                <TextAreaInput
                  labelTitle={"Use Description :"}
                  invert
                  size={"70%"}
                  color={BlackAndWhite.primary}
                  onChange={(e) => setUseDescription(e.target.value)}
                  height={100}
                  value={useDescription}
                />
                <TextInputField
                  labelTitle={" Tag 1:"}
                  invert
                  size={"70%"}
                  onChange={(e) => setTag1(e.target.value)}
                  value={tag1}
                />
                <TextInputField
                  labelTitle={" Tag 2:"}
                  invert
                  size={"70%"}
                  onChange={(e) => setTag2(e.target.value)}
                  value={tag2}
                />
                <TextInputField
                  labelTitle={" Tag 3:"}
                  invert
                  size={"70%"}
                  onChange={(e) => setTag3(e.target.value)}
                  value={tag3}
                />

                <div style={{ height: 100 }} />
              </div>
            ) : null}
            {steps === 3 ? (
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: 600,
                }}
              >
                <div style={{ height: 40 }} />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "70%", textAlign: "left" }}>
                    Cost price includes VAT:
                  </div>{" "}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CheckInput
                    question={"YES"}
                    invert={true}
                    check={includesVat}
                    onClick={() => setIncludesVat(true)}
                    size={200}
                  />
                  <CheckInput
                    question={"NO"}
                    invert={true}
                    check={!includesVat}
                    onClick={() => setIncludesVat(false)}
                    size={200}
                  />
                </div>
                <TextInputField
                  labelTitle={"Cost:"}
                  invert
                  size={"70%"}
                  onChange={(e) => UpDateInfoHandler(e)}
                  value={merryJaneCostPrice}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <div
                    style={{
                      width: 160,
                      marginLeft: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 40,
                      flexDirection: "column",
                    }}
                  >
                    <div>Mark up</div>
                    <div>{mjBasePriceDiff}</div>
                    <div>{markUpBasePercent + "%"}</div>
                  </div> */}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextInputField
                    labelTitle={"Wholesale:"}
                    autoFocus={true}
                    invert
                    size={260}
                    onChange={(e) => {
                      WholeSaleHandler(e);
                    }}
                    value={costPrice}
                    amountValue={mjPercent}
                    amountMarkedUp={
                      "R" +
                      " " +
                      (
                        Number(valueCheckMerryJaneCost) + Number(costPrice)
                      ).toFixed(2)
                    }
                  />
                  <div
                    style={{
                      width: 160,
                      marginLeft: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 40,
                      flexDirection: "column",
                    }}
                  >
                    <div>Mark up</div>
                    <div>{"R" + " " + mjBasePriceDiff.toFixed(2)}</div>
                    <div>{markUpBasePercent.toFixed(2) + "%"}</div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextInputField
                    labelTitle={"Retail ex Vat:"}
                    invert
                    size={260}
                    onChange={(e) => UpdateRetailPricePlusVat(e)}
                    value={retailPrice}
                  />
                  <div
                    style={{
                      width: 160,
                      marginLeft: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 40,
                      flexDirection: "column",
                    }}
                  >
                    <div>Mark up</div>
                    <div>{"R" + " " + Number(retailPrice - costPrice)}</div>
                    <div>{markRetailEarings.toFixed(2) + "%"}</div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextInputField
                    labelTitle={"Retail Inc Vat:"}
                    invert
                    size={260}
                    onChange={(e) => RetailExVatHandler(e)}
                    value={retailPriceInc}
                  />
                  <div
                    style={{
                      width: 160,
                      marginLeft: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 40,
                      flexDirection: "column",
                    }}
                  >
                    Vat
                    <div>R {workOutVaInclusive.toFixed(2)}</div>
                    <div>Mark up</div>
                    <div>R {checkRetailInc.toFixed(2)}</div>
                  </div>
                </div>
                <TextInputField
                  labelTitle={"Inventory in:" + " " + measurement}
                  invert
                  size={"70%"}
                  onChange={(e) => setInventory(e.target.value)}
                  value={inventory}
                />

                <div style={{ height: 100 }} />
              </div>
            ) : null}
            {steps === 4 ? (
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  height: 600,
                }}
              >
                <div style={{ height: 40 }} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 20,
                    fontSize: 20,
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  <div style={{ width: "80%", textAlign: "left" }}>
                    Available at:
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CheckInput
                    check={onlineStore === "yes" ? true : false}
                    question={"Available Online"}
                    invert={true}
                    onClick={() =>
                      setOnlineStore(onlineStore === "yes" ? null : "yes")
                    }
                  />
                  <CheckInput
                    question={"In-store Only"}
                    invert={true}
                    check={onlineStore === "in-store" ? true : false}
                    onClick={() =>
                      setOnlineStore(
                        onlineStore === "in-store" ? null : "in-store"
                      )
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 20,
                    fontSize: 20,
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  <div style={{ width: "80%", textAlign: "left" }}>
                    Select the stores:
                  </div>
                </div>
                <CheckInput
                  check={malemesbury}
                  question={"Malmesbury"}
                  invert={true}
                  onClick={() => setMalmesbury(malemesbury ? false : true)}
                />
                <CheckInput
                  check={langebaan}
                  question={"Langebaan"}
                  invert={true}
                  onClick={() => setLangebaan(langebaan ? false : true)}
                />
                <CheckInput
                  check={woodstock}
                  question={"Woodstock"}
                  invert={true}
                  onClick={() => setWoodstock(woodstock ? false : true)}
                />

                <div style={{ height: 100 }} />
              </div>
            ) : null}
          </div>
        ) : null
      ) : null}

      <div
        style={{
          flex: 0.5,
          display: "flex",
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {/* // marker for steps begin */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            height: 60,
            width: 500,
            backgroundColor: BlackAndWhite.secondary,
            position: "absolute",
            // right: 10,
            top: -25,
            justifyContent: "flex-start",
            alignItems: "center",
            borderRadius: 30,
            WebkitBoxShadow: "0px 0px 10px 1px #00000033",
          }}
        >
          <div
            style={{
              height: "70%",
              borderStyle: "solid",
              width: 150,
              borderRadius: 30,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 10,
              borderWidth: 0.5,
              backgroundColor: BlackAndWhite.primary,
              color: BlackAndWhite.secondary,
              fontWeight: "bold",
            }}
          >
            Steps
          </div>
          <div
            style={{
              height: "80%",
              backgroundColor: "#FAA91990",
              width: 322,
              borderRadius: 30,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 10,
            }}
          >
            <div
              style={{
                flex: 0.25,
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: steps === 1 ? "80%" : "70%",
                  width: steps === 1 ? 60 : 30,

                  borderRadius: 20,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  transition: "all 0.3s",
                  fontSize: 18,
                  color:
                    steps === 1
                      ? BlackAndWhite.secondary
                      : BlackAndWhite.primary,
                  backgroundColor:
                    steps === 1
                      ? BlackAndWhite.primary
                      : BlackAndWhite.secondary,
                }}
              >
                1
              </div>
            </div>
            <div
              style={{
                flex: 0.25,
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: steps === 2 ? "80%" : "70%",
                  width: steps === 2 ? 60 : 30,
                  color:
                    steps === 2
                      ? BlackAndWhite.secondary
                      : BlackAndWhite.primary,
                  backgroundColor:
                    steps === 2
                      ? BlackAndWhite.primary
                      : BlackAndWhite.secondary,
                  borderRadius: 20,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  transition: "all 0.3s",
                  fontSize: 18,
                }}
              >
                2
              </div>
            </div>
            <div
              style={{
                flex: 0.25,
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: steps === 3 ? "80%" : "70%",
                  width: steps === 3 ? 60 : 30,

                  color:
                    steps === 3
                      ? BlackAndWhite.secondary
                      : BlackAndWhite.primary,
                  backgroundColor:
                    steps === 3
                      ? BlackAndWhite.primary
                      : BlackAndWhite.secondary,
                  borderRadius: 20,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  transition: "all 0.3s",
                  fontSize: 18,
                }}
              >
                3
              </div>
            </div>
            <div
              style={{
                flex: 0.25,
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: steps === 4 ? "80%" : "70%",
                  width: steps === 4 ? 60 : 30,

                  color:
                    steps === 4
                      ? BlackAndWhite.secondary
                      : BlackAndWhite.primary,
                  backgroundColor:
                    steps === 4
                      ? BlackAndWhite.primary
                      : BlackAndWhite.secondary,
                  borderRadius: 20,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  transition: "all 0.3s",
                  fontSize: 18,
                }}
              >
                4
              </div>
            </div>
          </div>
        </div>
        {/* // marker for steps end */}
        {steps === 1 ? (
          <div
            style={{
              height: "90%",
              width: "90%",
              backgroundColor: BlackAndWhite.primary,
              borderRadius: 20,
              marginBottom: 20,
              WebkitBoxShadow: "0px 0px 10px 1px #00000070",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "80%",
                  color: BlackAndWhite.secondary,
                  textAlign: "left",
                  marginTop: 40,
                }}
              >
                Step 1:{" "}
              </div>
              <div
                style={{
                  width: "80%",
                  color: BlackAndWhite.secondary,
                  textAlign: "left",
                  marginTop: 10,
                  fontSize: 14,
                }}
              >
                Here we need you to pu the product name and some other
                importnant pieces we broke this up into teps to make the process
                easier and with less errors
              </div>

              <div
                style={{
                  marginTop: 20,
                  width: "80%",
                  textAlign: "left",
                  color: BlackAndWhite.secondary,
                  fontSize: 18,
                  fontWeight: "bold",
                }}
              >
                Complete the following:
              </div>
              {categorySelected.categoryName === "Cannabis Flower" ? (
                <>
                  <CompletedStepNotice
                    width={"84%"}
                    task={"Product name"}
                    complete={productName}
                  />
                  <CompletedStepNotice
                    width={"84%"}
                    task={"THC content"}
                    complete={thcContent}
                  />
                  <CompletedStepNotice
                    width={"84%"}
                    task={"Size / Amount"}
                    complete={productSizeOrAmount}
                  />
                  <CompletedStepNotice
                    width={"84%"}
                    task={"Measurement type"}
                    complete={measurement}
                  />
                  <CompletedStepNotice
                    width={"84%"}
                    task={"Strain"}
                    complete={strain}
                  />
                  <CompletedStepNotice
                    width={"84%"}
                    task={"Grow condition"}
                    complete={growType}
                  />
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      marginTop: 20,
                    }}
                  >
                    <BaseButtons label={"Need help"} invert={true} />
                    <BaseButtons
                      label={"Next"}
                      onClick={() => setSteps(2)}
                      disable={
                        productName &&
                        thcContent &&
                        productSizeOrAmount &&
                        measurement &&
                        strain &&
                        growType
                          ? false
                          : true
                      }
                      invert={true}
                    />
                  </div>
                </>
              ) : null}
              {categorySelected.categoryName === "Edibles" ? (
                <>
                  <CompletedStepNotice
                    width={"84%"}
                    task={"Product name"}
                    complete={productName}
                  />
                  <CompletedStepNotice
                    width={"84%"}
                    task={"THC content"}
                    complete={thcContent}
                  />
                  <CompletedStepNotice
                    width={"84%"}
                    task={"Size / Amount"}
                    complete={productSizeOrAmount}
                  />
                  <CompletedStepNotice
                    width={"84%"}
                    task={"Measurement type"}
                    complete={measurement}
                  />
                  <CompletedStepNotice
                    width={"84%"}
                    task={"Thc type"}
                    complete={thcType}
                  />
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      marginTop: 20,
                    }}
                  >
                    <BaseButtons label={"Need help"} invert={true} />
                    <BaseButtons
                      label={"Next"}
                      onClick={() => setSteps(2)}
                      disable={
                        productName &&
                        thcContent &&
                        productSizeOrAmount &&
                        measurement &&
                        thcType
                          ? false
                          : true
                      }
                      invert={true}
                    />
                  </div>
                </>
              ) : null}
              {categorySelected.categoryName === "Beverages" ? (
                <>
                  <CompletedStepNotice
                    width={"84%"}
                    task={"Product name"}
                    complete={productName}
                  />
                  <CompletedStepNotice
                    width={"84%"}
                    task={"THC content"}
                    complete={thcContent}
                  />
                  <CompletedStepNotice
                    width={"84%"}
                    task={"Size / Amount"}
                    complete={productSizeOrAmount}
                  />
                  <CompletedStepNotice
                    width={"84%"}
                    task={"Measurement type"}
                    complete={measurement}
                  />
                  <CompletedStepNotice
                    width={"84%"}
                    task={"Thc type"}
                    complete={thcType}
                  />
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      marginTop: 20,
                    }}
                  >
                    <BaseButtons label={"Need help"} invert={true} />
                    <BaseButtons
                      label={"Next"}
                      onClick={() => setSteps(2)}
                      disable={
                        productName &&
                        thcContent &&
                        productSizeOrAmount &&
                        measurement &&
                        thcType
                          ? false
                          : true
                      }
                      invert={true}
                    />
                  </div>
                </>
              ) : null}
              {categorySelected.categoryName === "Art" ? (
                <>
                  <CompletedStepNotice
                    width={"84%"}
                    task={"Product name"}
                    complete={productName}
                  />
                  <CompletedStepNotice
                    width={"84%"}
                    task={"Size / Amount"}
                    complete={productSizeOrAmount}
                  />
                  <CompletedStepNotice
                    width={"84%"}
                    task={"Measurement type"}
                    complete={measurement}
                  />
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      marginTop: 20,
                    }}
                  >
                    <BaseButtons label={"Need help"} invert={true} />
                    <BaseButtons
                      label={"Next"}
                      onClick={() => setSteps(2)}
                      disable={
                        productName && productSizeOrAmount && measurement
                          ? false
                          : true
                      }
                      invert={true}
                    />
                  </div>
                </>
              ) : null}
              {categorySelected.categoryName === "Apparel" ? (
                <>
                  <CompletedStepNotice
                    width={"84%"}
                    task={"Product name"}
                    complete={productName}
                  />
                  <CompletedStepNotice
                    width={"84%"}
                    task={"Size / Amount"}
                    complete={productSizeOrAmount}
                  />
                  <CompletedStepNotice
                    width={"84%"}
                    task={"Measurement type"}
                    complete={measurement}
                  />
                  <CompletedStepNotice
                    width={"84%"}
                    task={"Gender"}
                    complete={gender !== "nothing" ? true : false}
                  />
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      marginTop: 20,
                    }}
                  >
                    <BaseButtons label={"Need help"} invert={true} />
                    <BaseButtons
                      label={"Next"}
                      onClick={() => setSteps(2)}
                      disable={
                        productName &&
                        productSizeOrAmount &&
                        measurement &&
                        gender !== "nothing"
                          ? false
                          : true
                      }
                      invert={true}
                    />
                  </div>
                </>
              ) : null}
              {categorySelected.categoryName === "Consumables" ? (
                <>
                  <CompletedStepNotice
                    width={"84%"}
                    task={"Product name"}
                    complete={productName}
                  />

                  <CompletedStepNotice
                    width={"84%"}
                    task={"Size / Amount"}
                    complete={productSizeOrAmount}
                  />
                  <CompletedStepNotice
                    width={"84%"}
                    task={"Measurement type"}
                    complete={measurement}
                  />

                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      marginTop: 20,
                    }}
                  >
                    <BaseButtons label={"Need help"} invert={true} />
                    <BaseButtons
                      label={"Next"}
                      onClick={() => setSteps(2)}
                      disable={
                        productName && productSizeOrAmount && measurement
                          ? false
                          : true
                      }
                      invert={true}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
        ) : null}
        {steps === 2 ? (
          <div
            style={{
              height: "90%",
              width: "90%",
              backgroundColor: BlackAndWhite.primary,
              borderRadius: 20,
              marginBottom: 20,
              WebkitBoxShadow: "0px 0px 10px 1px #00000070",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "80%",
                  color: BlackAndWhite.secondary,
                  textAlign: "left",
                  marginTop: 40,
                }}
              >
                Step 2:{" "}
              </div>
              <div
                style={{
                  width: "80%",
                  color: BlackAndWhite.secondary,
                  textAlign: "left",
                  marginTop: 10,
                  fontSize: 14,
                }}
              >
                All the descriptions you could possibly need... and the
                descritions and tags you need below...
              </div>
              <div
                style={{
                  marginTop: 20,
                  width: "80%",
                  textAlign: "left",
                  color: BlackAndWhite.secondary,
                  fontSize: 18,
                  fontWeight: "bold",
                }}
              >
                Complete the following:
              </div>

              <>
                <CompletedStepNotice
                  width={"84%"}
                  task={"Basic description"}
                  complete={description}
                />
                <CompletedStepNotice
                  width={"84%"}
                  task={"Menu description"}
                  complete={menuDescription}
                />
                <CompletedStepNotice
                  width={"84%"}
                  task={"how to use description"}
                  complete={useDescription}
                />
                <CompletedStepNotice
                  width={"84%"}
                  task={"Tags"}
                  complete={tag1 || tag2 || tag3}
                />
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <BaseButtons
                    label={"Back"}
                    onClick={() => setSteps(1)}
                    invert={true}
                  />
                  <BaseButtons
                    label={"Next"}
                    onClick={() => setSteps(3)}
                    invert={true}
                    disable={
                      !description &&
                      !menuDescription &&
                      !useDescription &&
                      !tag1
                    }
                  />
                </div>
              </>
            </div>
          </div>
        ) : null}
        {steps === 3 ? (
          <div
            style={{
              height: "90%",
              width: "90%",
              backgroundColor: BlackAndWhite.primary,
              borderRadius: 20,
              marginBottom: 20,
              WebkitBoxShadow: "0px 0px 10px 1px #00000070",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "80%",
                  color: BlackAndWhite.secondary,
                  textAlign: "left",
                  marginTop: 40,
                }}
              >
                Step 3:{" "}
              </div>
              <div
                style={{
                  width: "80%",
                  color: BlackAndWhite.secondary,
                  textAlign: "left",
                  marginTop: 10,
                  fontSize: 14,
                }}
              >
                We need you to add all the costs for the product... and dont
                forget to add your inventory count.
              </div>
              <div
                style={{
                  marginTop: 20,
                  width: "80%",
                  textAlign: "left",
                  color: BlackAndWhite.secondary,
                  fontSize: 18,
                  fontWeight: "bold",
                }}
              >
                Complete the following:
              </div>

              <>
                <CompletedStepNotice
                  width={"84%"}
                  task={"Merry-Jane cost:"}
                  complete={merryJaneCostPrice}
                />
                <CompletedStepNotice
                  width={"84%"}
                  task={"Store cost:"}
                  complete={costPrice}
                />
                <CompletedStepNotice
                  width={"84%"}
                  task={"Retail price"}
                  complete={retailPrice}
                />
                <CompletedStepNotice
                  width={"84%"}
                  task={"Inventory"}
                  complete={inventory}
                />
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <BaseButtons
                    label={"Back"}
                    onClick={() => setSteps(2)}
                    invert={true}
                  />
                  <BaseButtons
                    label={"Next"}
                    onClick={() => setSteps(4)}
                    invert={true}
                    disable={
                      merryJaneCostPrice &&
                      costPrice &&
                      retailPrice &&
                      inventory
                        ? false
                        : true
                    }
                  />
                </div>
              </>
            </div>
          </div>
        ) : null}
        {steps === 4 ? (
          <div
            style={{
              height: "90%",
              width: "90%",
              backgroundColor: BlackAndWhite.primary,
              borderRadius: 20,
              marginBottom: 20,
              WebkitBoxShadow: "0px 0px 10px 1px #00000070",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "80%",
                  color: BlackAndWhite.secondary,
                  textAlign: "left",
                  marginTop: 40,
                }}
              >
                Step 4:
              </div>
              <div
                style={{
                  width: "80%",
                  color: BlackAndWhite.secondary,
                  textAlign: "left",
                  marginTop: 10,
                  fontSize: 14,
                }}
              >
                Here we need you to pu the product name and some other
                importnant pieces we broke this up into teps to make the process
                easier and with less errors
              </div>
              <div
                style={{
                  marginTop: 20,
                  width: "80%",
                  textAlign: "left",
                  color: BlackAndWhite.secondary,
                  fontSize: 18,
                  fontWeight: "bold",
                }}
              >
                Complete the following:
              </div>
              {categorySelected.categoryName === "Cannabis Flower" ? (
                <>
                  <CompletedStepNotice
                    width={"84%"}
                    task={"Online Store:"}
                    complete={onlineStore}
                  />
                  <CompletedStepNotice
                    width={"84%"}
                    task={"Stores:"}
                    complete={
                      whichStores[0].value === true ||
                      whichStores[1].value === true ||
                      whichStores[2].value === true
                        ? true
                        : false
                    }
                  />

                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}
                  >
                    <BaseButtons
                      label={"Back"}
                      onClick={() => setSteps(3)}
                      invert={true}
                    />
                    <BaseButtons
                      label={"Submit"}
                      invert={true}
                      onClick={() => uploadProduct()}
                      disable={
                        onlineStore &&
                        (whichStores[0].value === true ||
                          whichStores[1].value === true ||
                          whichStores[2].value === true)
                          ? false
                          : true
                      }
                    />
                  </div>
                </>
              ) : null}
              {categorySelected.categoryName === "Edibles" ? (
                <>
                  <CompletedStepNotice
                    width={"84%"}
                    task={"Online Store:"}
                    complete={onlineStore}
                  />
                  <CompletedStepNotice
                    width={"84%"}
                    task={"Stores:"}
                    complete={
                      whichStores[0].value === true ||
                      whichStores[1].value === true ||
                      whichStores[2].value === true
                        ? true
                        : false
                    }
                  />

                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}
                  >
                    <BaseButtons
                      label={"Back"}
                      onClick={() => setSteps(3)}
                      invert={true}
                    />
                    <BaseButtons
                      label={"Submit"}
                      invert={true}
                      onClick={() => uploadProduct()}
                      disable={
                        onlineStore &&
                        (whichStores[0].value === true ||
                          whichStores[1].value === true ||
                          whichStores[2].value === true)
                          ? false
                          : true
                      }
                    />
                  </div>
                </>
              ) : null}
              {categorySelected.categoryName === "Beverages" ? (
                <>
                  <CompletedStepNotice
                    width={"84%"}
                    task={"Online Store:"}
                    complete={onlineStore}
                  />
                  <CompletedStepNotice
                    width={"84%"}
                    task={"Stores:"}
                    complete={
                      whichStores[0].value === true ||
                      whichStores[1].value === true ||
                      whichStores[2].value === true
                        ? true
                        : false
                    }
                  />

                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}
                  >
                    <BaseButtons
                      label={"Back"}
                      onClick={() => setSteps(3)}
                      invert={true}
                    />
                    <BaseButtons
                      label={"Submit"}
                      invert={true}
                      onClick={() => uploadProduct()}
                      disable={
                        onlineStore &&
                        (whichStores[0].value === true ||
                          whichStores[1].value === true ||
                          whichStores[2].value === true)
                          ? false
                          : true
                      }
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
      <LoadingPopUp display={loading} />
    </div>
  );
};

export default AddProduct;
