import React, { useState } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import { BaseButtons } from "../../../../../../components/atoms";

const GeneratedReportCard = ({
  date,
  time,
  slipNo,
  cashier,
  customerName,
  total,
}) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{
        width: "95%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        overflow: "hidden",

        minHeight: 80,
        fontSize: 14,
        backgroundColor: hover ? "#00000020" : BlackAndWhite.secondary,
        borderRadius: 8,
        WebkitBoxShadow: "0px 0px 7px 2px #00000050",
        marginTop: 20,
        // color: hover ? BlackAndWhite.secondary : BlackAndWhite.primary,
      }}
    >
      <div
        style={{
          marginLeft: 10,
          flex: 0.36,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            fontSize: 10,
            fontWeight: "bold",
            width: "100%",
            opacity: 0.6,
            marginBottom: 3,
          }}
        >
          DATE:
        </div>
        <div> {slipNo}</div>
      </div>
      <div style={{ flex: 0.28, display: "flex", flexDirection: "column" }}>
        <div
          style={{
            fontSize: 10,
            fontWeight: "bold",
            width: "100%",
            opacity: 0.6,
            marginBottom: 3,
          }}
        >
          OPENED BY:
        </div>
        <div> {date}</div>
      </div>
      <div style={{ flex: 0.28, display: "flex", flexDirection: "column" }}>
        <div
          style={{
            fontSize: 10,
            fontWeight: "bold",
            width: "100%",
            opacity: 0.6,
            marginBottom: 3,
          }}
        >
          CLOSED BY:
        </div>
        <div> {time}</div>
      </div>
      {/* <div style={{ flex: 0.1, display: "flex", flexDirection: "column" }}>
        <div
          style={{
            fontSize: 10,
            fontWeight: "bold",
            width: "100%",
            opacity: 0.6,
            marginBottom: 3,
            marginLeft: 5,
          }}
        >
          EXCHANGES:
        </div>
        <div style={{ marginLeft: 5 }}>{cashier}</div>
      </div> */}
      {/* <div style={{ flex: 0.1, display: "flex", flexDirection: "column" }}>
        <div
          style={{
            fontSize: 10,
            fontWeight: "bold",
            width: "100%",
            opacity: 0.6,
            marginBottom: 3,
          }}
        >
          REFUNDS:
        </div>
        <div>{customerName}</div>
      </div> */}
      <div style={{ flex: 0.1, display: "flex", flexDirection: "column" }}>
        <div
          style={{
            fontSize: 10,
            fontWeight: "bold",
            width: "100%",
            opacity: 0.6,
            marginBottom: 3,
          }}
        >
          TOTAL:
        </div>
        <div>{total}</div>
      </div>
      <div style={{ flex: 0.1 }}>
        <BaseButtons
          label={"VIEW"}
          mini={true}
          marginTop={-1}
          borderRadius={8}
          height={35}
          marginRight={20}
        />
      </div>
    </div>
  );
};

export default GeneratedReportCard;
