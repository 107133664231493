import React, { useState, useEffect } from "react";
import PosPatientCard from "./PosPatientCard";
import { LoadingPopUp, NewSearchBar } from "../../../../components";
import PatientProfilePopUp from "./PatientProfilePopUp";
import SearchBar from "./SearchBar";
import FilterPopUp from "./FilterPopUp";

const PatientDisplay = ({
  setPatientProfilePop,
  setSelectedPatient,
  setLoading,
  setSearch,
  customers,
  isSelector,
}) => {
  const [arrayDisplay, setArrayDisplay] = useState(false);

  const [filterOpen, setFliterOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("NONE");

  // const [displayMore, setDisplayMore] = useState(false);

  const [patientSearch, setPatientSearch] = useState("");

  return (
    <div
      style={{
        flex: 1,
        overflowY: "scroll",
      }}
    >
      {selectedFilter === "NONE" ? (
        customers ? (
          customers.map((patient, index) => {
            return (
              <div key={index}>
                <PosPatientCard
                  isSelector={isSelector}
                  firstName={patient ? patient.firstName : "loading"}
                  lastName={patient ? patient.lastName : "loading"}
                  idNum={patient ? patient.idDoc : "loading"}
                  email={patient ? patient.email : "loading"}
                  status={"in progress"}
                  cell={patient ? patient.cellNumber : "loading"}
                  onClick={
                    isSelector
                      ? () => {
                          setSelectedPatient(patient);
                          setSearch("");
                        }
                      : () => {
                          setPatientProfilePop(true);
                          setSelectedPatient(patient);
                          setLoading(true);
                          setSearch("");
                        }
                  }
                />
              </div>
            );
          })
        ) : (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: 40,
              display: "flex",
              flexDirection: "column",
              opacity: 0.7,
            }}
          >
            SEARCH CUSTOMER BY ENTERING PARTIAL OR FULL DETAILS THAT MAY
            INCLUDE:
            <div style={{ marginTop: 20 }}>1. NAME OR SURNAME</div>
            <div style={{ marginTop: 20 }}> 2. ID OR CELLULAR NUMBER</div>
            <div style={{ marginTop: 20 }}>3. EMAIL ADRESS</div>
          </div>
        )
      ) : null}

      <div
        style={{
          position: "fixed",
          top: 75,
          right: 15,
          zIndex: 400000,
        }}
      ></div>
    </div>
  );
};

export default PatientDisplay;
