import React, { useState, useEffect } from "react";
import {
  BaseButtons,
  TextInputField,
} from "../../../../../../components/atoms";
import { BlackAndWhite } from "../../../../../../themes/Colors";

const InventoryManageCard = ({
  productName,
  NameAssemble,
  selectedProduct,
  product,
  sku,
  supplier,
  image,
  isStore,
  processSale,
  onAddClick,
  priceDisplayed,
  price,
  index,
  productsArray,
  setVerifyStock,
  onProductClick,
  storeId,
  stockValue,
  setStockValue,
  stockTakeDate,
}) => {
  const [productHover, setProductHover] = useState(false);
  const [hover, setHover] = useState(false);
  const [info, setInfo] = useState(null);
  // const [stockValue, setStockValue] = useState(0);

  const [backgroundColorSelections, setBackgroundColorSelections] = useState(
    BlackAndWhite.primary
  );

  let dateCheck = new Date();
  let minus1days = new Date();
  let minus2days = new Date();
  let minus3days = new Date();
  let minus4days = new Date();
  let minus5days = new Date();
  let minus6days = new Date();
  let minus7days = new Date();
  let minus8days = new Date();

  const buttonColorCheck = () => {
    minus1days.setDate(dateCheck.getDate() - 1);
    minus2days.setDate(dateCheck.getDate() - 2);
    minus3days.setDate(dateCheck.getDate() - 3);
    minus4days.setDate(dateCheck.getDate() - 4);
    minus5days.setDate(dateCheck.getDate() - 5);
    minus6days.setDate(dateCheck.getDate() - 6);
    minus7days.setDate(dateCheck.getDate() - 7);
    minus8days.setDate(dateCheck.getDate() - 8);

    if (
      stockTakeDate
        .substring(0, 10)
        .toLocaleLowerCase()
        .match(dateCheck.toISOString().substring(0, 10)) ||
      stockTakeDate
        .substring(0, 10)
        .toLocaleLowerCase()
        .match(minus1days.toISOString().substring(0, 10)) ||
      stockTakeDate
        .substring(0, 10)
        .toLocaleLowerCase()
        .match(minus2days.toISOString().substring(0, 10)) ||
      stockTakeDate
        .substring(0, 10)
        .toLocaleLowerCase()
        .match(minus3days.toISOString().substring(0, 10)) ||
      stockTakeDate
        .substring(0, 10)
        .toLocaleLowerCase()
        .match(minus4days.toISOString().substring(0, 10))
    ) {
      setBackgroundColorSelections("#48A14D");
    } else if (
      stockTakeDate
        .substring(0, 10)
        .toLocaleLowerCase()
        .match(minus5days.toISOString().substring(0, 10)) ||
      stockTakeDate
        .substring(0, 10)
        .toLocaleLowerCase()
        .match(minus6days.toISOString().substring(0, 10))
    ) {
      setBackgroundColorSelections("#FFBF46");
    } else if (
      stockTakeDate
        .substring(0, 10)
        .toLocaleLowerCase()
        .match(minus7days.toISOString().substring(0, 10)) ||
      stockTakeDate
        .substring(0, 10)
        .toLocaleLowerCase()
        .match(minus8days.toISOString().substring(0, 10))
    ) {
      setBackgroundColorSelections("#DB5461");
    } else {
      setBackgroundColorSelections("#DB5461");
    }
  };

  const GetWareHouseStockInfo = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/inventory/getLineItemWarehouseStockLevel",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            productId: product._id,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setInfo(responseData.stockAmount);
        setStockValue(responseData.stockAmount.currentStock);
        // setMessage(responseData.success);
        // setToastPop(true);
      }
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    if (isStore) {
      return;
    } else {
      GetWareHouseStockInfo();
    }
  }, [productsArray]);
  useEffect(() => {
    if (stockTakeDate) {
      buttonColorCheck();
    }
  }, [stockTakeDate]);

  return (
    <div
      onMouseOver={() => setProductHover(true)}
      onMouseOut={() => setProductHover(false)}
      onClick={hover ? null : onProductClick}
      style={{
        cursor: "pointer",
        height: 70,
        width: "100%",
        // borderBottomStyle: "solid",
        // borderBottomWidth: 1,
        // borderBottomColor: "#00000030",
        display: "flex",
        backgroundColor: productHover ? "#00000030" : BlackAndWhite.secondary,
      }}
    >
      <div
        style={{
          flex: 0.1,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
          overflow: "hidden",
        }}
      >
        <img alt="product" src={image} style={{ height: 50 }} />
      </div>
      <div
        style={{
          flex: 0.25,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 15, width: "100%", textAlign: "left" }}>
          {productName}
        </div>
      </div>

      <div
        style={{
          flex: 0.15,

          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        <div style={{ marginLeft: 35, width: "100%", textAlign: "left" }}>
          {sku}
        </div>
      </div>
      {processSale ? null : (
        <div
          style={{
            flex: 0.15,

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 11,
          }}
        >
          <div style={{ marginLeft: 25, width: "100%", textAlign: "left" }}>
            {supplier}
          </div>
        </div>
      )}
      {stockTakeDate ? (
        <div
          style={{
            flex: 0.15,

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 11,
            position: "relative",
          }}
        >
          <div style={{ position: "relative" }}>
            {stockTakeDate ? stockTakeDate.substring(0, 10) : null}
            {/* <div
            style={{
              position: "absolute",
              top: -15,
              fontSize: 10,
              fontWeight: "bold",
              opacity: 0.6,
              width: 100,
            }}
          >
            LAST STOCK TAKE
          </div> */}
          </div>
        </div>
      ) : null}
      <div
        style={{
          flex: priceDisplayed ? 0.15 : 0.2,

          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
          fontSize: 11,
        }}
      >
        {/* <div
            style={{
              fontSize: 14,
              marginTop: 15,
              marginRight: 5,
            }}
          >
            UNIT
          </div> */}
        <div>
          {priceDisplayed ? (
            product ? (
              product.type === "composite" ? null : (
                <div
                  style={{
                    display: "flex",

                    flexDirection: "column",

                    padding: 5,
                    width: 50,
                    borderRadius: 4,
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: 10,
                      opacity: 0.6,
                      width: "90%",
                      textAlign: "center",
                    }}
                  >
                    QTY
                  </div>
                  <div
                    style={{
                      marginTop: 8,
                      fontSize: 14,
                      fontWeight: "bold",
                      width: "90%",
                      // borderStyle: "solid",
                      // padding: 3,
                      borderRadius: 4,
                      textAlign: "center",
                      marginBottom: 3,
                    }}
                  >
                    {" "}
                    {stockValue}
                  </div>
                </div>
              )
            ) : (
              <div
                style={{
                  display: "flex",

                  flexDirection: "column",

                  padding: 5,
                  width: 50,
                  borderRadius: 4,
                  alignItems: "flex-start",
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: 10,
                    opacity: 0.6,
                    width: "90%",
                    textAlign: "center",
                  }}
                >
                  QTY
                </div>
                <div
                  style={{
                    marginTop: 8,
                    fontSize: 14,
                    fontWeight: "bold",
                    width: "90%",
                    // borderStyle: "solid",
                    // padding: 3,
                    borderRadius: 4,
                    textAlign: "center",
                    marginBottom: 3,
                  }}
                >
                  {" "}
                  {stockValue}
                </div>
              </div>
            )
          ) : (
            <TextInputField
              invert={true}
              mini={true}
              height={10}
              marginTop={-5}
              size={processSale ? 40 : 80}
              labelTitle={"QTY"}
              type={"Number"}
              readOnly={
                processSale || isStore ? true : stockValue ? false : true
              }
              value={stockValue}
              onChange={
                processSale || isStore
                  ? (e) => setStockValue(0)
                  : (e) => setStockValue(e.target.value)
              }
            />
          )}
        </div>
      </div>
      {priceDisplayed ? (
        <div
          style={{
            flex: 0.2,

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 14,
          }}
        >
          <div
            style={{
              fontSize: 10,
              fontWeight: "bold",
              opacity: 0.6,
              marginBottom: 8,
            }}
          >
            PRICE
          </div>
          <div
            style={{
              fontSize: 14,
              fontWeight: "bold",
              // opacity: 0.6,
              marginBottom: 5,
            }}
          >
            {price ? "R " + price : null}
          </div>
        </div>
      ) : null}
      {processSale ? (
        <div
          style={{
            flex: 0.1,

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 11,
          }}
        >
          <BaseButtons
            label={"ADD"}
            mini={true}
            marginTopFalse={true}
            borderRadius={8}
            height={35}
            size={80}
            onClick={onAddClick}
            marginRight={10}
            disable={
              product.type === "composite"
                ? false
                : stockValue >= 0.099
                ? false
                : true
            }
          />
        </div>
      ) : (
        <div
          style={{
            flex: 0.15,

            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 11,
          }}
        >
          {isStore ? (
            <div
              onMouseOver={() => setHover(true)}
              onMouseOut={() => setHover(false)}
            >
              <BaseButtons
                fontWeight={"bold"}
                label={"VERIFY"}
                mini={true}
                marginTopFalse={true}
                borderRadius={8}
                height={35}
                size={130}
                borderWidth={0}
                backgroundColor={backgroundColorSelections}
                onClick={() =>
                  setVerifyStock({
                    product: {
                      stockIno: info,
                      product: product,
                    },
                  })
                }
              />
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default InventoryManageCard;
