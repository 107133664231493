import React from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";

const ModalLayout = ({ display, page, mobile }) => {
  return (
    <div
      style={{
        position: "fixed",
        width: "100%",
        height: display ? "100%" : 0,
        backgroundColor: "#00000070",
        top: 0,
        left: 0,
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: "all 0.2s",
        zIndex: mobile ? 10000000000 : null,
      }}
    >
      {page}
    </div>
  );
};

export default ModalLayout;
