import React, { useState } from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";

const DashboardDisplayButton = ({
  title,
  value,
  point1,
  point2,
  point3,
  point4,
  value1,
  value2,
  value3,
  value4,
  onClick,
}) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      onClick={onClick}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyItems: "center",
        minHeight: 160,
        maxWidth: 270,
        minWidth: 270,

        WebkitBoxShadow: "0px 0px 7px 2px #00000050",
        overflow: "hidden",
        borderRadius: 8,
        backgroundColor: hover
          ? BlackAndWhite.primary
          : BlackAndWhite.secondary,
        color: hover ? BlackAndWhite.secondary : BlackAndWhite.primary,
      }}
    >
      <div
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <div style={{ marginTop: 10, width: "85%" }}>{title}</div>
        <div
          style={{
            flex: 1,
            justifyContent: "center",
            // alignItems: "center",
            display: "flex",
            flexDirection: "column",
            fontSize: 34,
            fontWeight: "bold",
            width: "85%",
            textAlign: "left",
          }}
        >
          {value}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 10,
          justifyContent: "space-evenly",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: 10,
              fontWeight: "bold",
              opacity: 0.7,
              marginBottom: 2,
            }}
          >
            {point1}
          </div>
          <div style={{ fontSize: 14 }}>{value1}</div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: 10,
              fontWeight: "bold",
              opacity: 0.7,
              marginBottom: 2,
            }}
          >
            {point2}
          </div>
          <div style={{ fontSize: 14 }}>{value2}</div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: 10,
              fontWeight: "bold",
              opacity: 0.7,
              marginBottom: 2,
            }}
          >
            {point3}
          </div>
          <div style={{ fontSize: 14 }}>{value3}</div>
        </div>
        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: 10,
              fontWeight: "bold",
              opacity: 0.7,
              marginBottom: 2,
            }}
          >
            {point4}
          </div>
          <div style={{ fontSize: 12 }}>{value4}</div>
        </div> */}
      </div>
    </div>
  );
};

export default DashboardDisplayButton;
