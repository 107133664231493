import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../../themes/Colors";
import {
  AdminForm,
  CashierForm,
  CategoryButton,
  DoctorForm,
  InvestorForm,
  LoadingPopUp,
  ManagerForm,
  SupportForm,
  ToastNotification,
} from "../../../components";
import HeadersTitle from "./table-blocks/HeadersTitle";
import UserItem from "./table-blocks/UserItem";
import UserPopUp from "./user-pop-up/UserPopUp";

const Users = ({
  subSelection,
  userDisplay,
  setUserdisplay,
  setSubSelection,
  readOnly,
}) => {
  const [selectedType, setSelectedType] = useState("none");
  const [userArray, setUserArray] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("error");

  const [userSelected, setUserSelected] = useState(null);

  // register fields

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [cellNumber, setCellNumber] = useState("none");
  const [email, setEmail] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [province, setProvince] = useState("none");
  const [accountNumber, setAccountNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [storeName, setStoreName] = useState("none");
  const [selectedStore, setSelectedStore] = useState(null);

  const [toastPop, setToastPop] = useState(false);
  const [message, setMessage] = useState("");

  const registerSubmit = async () => {
    setLoading(true);
    // setError("Loading");
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/cannaPos/reg",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            firstName: firstName,
            lastName: lastName,
            cellNumber: cellNumber,
            email: email,
            idDoc: idNumber,
            province: province || selectedStore.province,
            storeName:
              selectedType === "owner"
                ? "Owner"
                : selectedType === "Admin"
                ? "N/A"
                : selectedStore.storeName,
            accountNumber: accountNumber,
            userName: email,
            dateOfBirth: idNumber.substring(0, 6),
            password: password,
            acceptedTandC: true,
            type: selectedType,
            profileImage: "coming soon",
            storeId: selectedStore ? selectedStore.storeId : "NA",
          }),
        }
      );

      const respnseData = await response.json();

      if (respnseData.type) {
        setLoading(false);
        setToastPop(true);
        setMessage(
          "An email invite has been sent to" +
            " " +
            email +
            " ," +
            " " +
            firstName +
            " " +
            lastName +
            "," +
            selectedType +
            " user " +
            "has been registered succefully"
        );
        setSelectedType("none");
        setRefresh(refresh ? false : true);
      } else {
        setToastPop(true);
        setMessage("Unable to create user" + " ," + respnseData.message);
        setError("invalid credentials");
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setError("invalid credentials");
    }
  };
  useEffect(() => {
    fetch("https://merry-jane-api.onrender.com/cannaPos/fetchAllUsers")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setUserArray(resData);
      });
  }, [refresh]);
  useEffect(() => {
    if (toastPop) {
      setTimeout(() => {
        setToastPop(false);
      }, 5000);
    }
  }, [toastPop]);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: BlackAndWhite.secondary,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {subSelection === "ADD USERS" ? (
        <>
          {selectedType === "none" ? (
            <>
              <div
                style={{
                  marginBottom: 20,
                  fontSize: 24,
                  fontWeight: "bold",
                  textAlign: "left",
                  width: "80%",
                }}
              >
                ADD USERS
              </div>
              <div
                style={{
                  marginBottom: 40,
                  fontSize: 20,
                  textAlign: "left",
                  width: "80%",
                }}
              >
                SELECT OPTION FROM BELOW TO CREATE A USER
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <CategoryButton
                  src={require("../../../images/categories/cashier.png")}
                  top={-50}
                  altTop={-35}
                  altLeft={-60}
                  left={-40}
                  sizeImage={250}
                  title={"Create Cashier"}
                  description={"POS and Product knowledge"}
                  onClick={readOnly ? null : () => setSelectedType("Cashier")}
                />
                <div style={{ width: 20 }} />
                <CategoryButton
                  src={require("../../../images/categories/Manager.png")}
                  top={-20}
                  altTop={0}
                  altLeft={-30}
                  left={-20}
                  sizeImage={225}
                  title={"Create Manager"}
                  description={"POS ,Product knowledge and store dashboard"}
                  onClick={readOnly ? null : () => setSelectedType("Manager")}
                />
                <div style={{ width: 20 }} />
                <CategoryButton
                  small={true}
                  src={require("../../../images/categories/admin.png")}
                  top={-20}
                  altTop={0}
                  altLeft={-30}
                  left={-20}
                  sizeImage={225}
                  title={"Create Admin"}
                  onClick={readOnly ? null : () => setSelectedType("Admin")}
                />
              </div>
              <div
                style={{ display: "flex", flexDirection: "row", marginTop: 20 }}
              >
                <CategoryButton
                  comingSoon={true}
                  src={require("../../../images/categories/support.png")}
                  top={-50}
                  altTop={-35}
                  altLeft={-60}
                  left={-40}
                  sizeImage={250}
                  title={"Create Support"}
                  description={"Product knowledge, Support and Inquiries"}
                  onClick={readOnly ? null : () => setSelectedType("Support")}
                />
                <div style={{ width: 20 }} />
                <CategoryButton
                  comingSoon={true}
                  src={require("../../../images/categories/doctor.png")}
                  top={-20}
                  altTop={0}
                  altLeft={-30}
                  left={-20}
                  sizeImage={225}
                  title={"Create Doctor"}
                  description={
                    "Profile, Patients, Prescriptions and Product knowledge"
                  }
                  onClick={readOnly ? null : () => setSelectedType("Doctor")}
                />
                <div style={{ width: 20 }} />
                <CategoryButton
                  small={true}
                  src={require("../../../images/categories/investor.png")}
                  top={-20}
                  altTop={0}
                  altLeft={-30}
                  left={-20}
                  sizeImage={225}
                  title={"Create Owner"}
                  onClick={readOnly ? null : () => setSelectedType("owner")}
                />
              </div>
            </>
          ) : null}
          {selectedType === "Cashier" ? (
            <CashierForm
              selectedStore={selectedStore}
              setSelectedStore={setSelectedStore}
              onCloseClick={() => {
                setSelectedType("none");
                setFirstName("");
                setLastName("");
                setProvince("none");
                setCellNumber("");
                setIdNumber("");
                setEmail("");
                setPassword("");
              }}
              firstName={firstName}
              lastName={lastName}
              idNumber={idNumber}
              email={email}
              password={password}
              province={province}
              setProvince={setProvince}
              onFirstNameChange={(e) => setFirstName(e.target.value)}
              onLastNameChange={(e) => setLastName(e.target.value)}
              onIdNumChange={(e) => setIdNumber(e.target.value)}
              onEmailChange={(e) => setEmail(e.target.value)}
              isBussinesClick={() => setIdNumber("Is Business")}
              onPasswordChange={(e) => setPassword(e.target.value)}
              onAccountNameChange={(e) => setAccountNumber(e.target.value)}
              onSubmitClick={() => registerSubmit()}
              onCellChange={(e) => setCellNumber(e.target.value)}
            />
          ) : null}
          {selectedType === "Manager" ? (
            <ManagerForm
              selectedStore={selectedStore}
              setSelectedStore={setSelectedStore}
              onCloseClick={() => {
                setSelectedType("none");
                setFirstName("");
                setLastName("");
                setProvince("none");
                setCellNumber("");
                setIdNumber("");
                setEmail("");
                setPassword("");
              }}
              firstName={firstName}
              lastName={lastName}
              idNumber={idNumber}
              email={email}
              password={password}
              province={province}
              setProvince={setProvince}
              onFirstNameChange={(e) => setFirstName(e.target.value)}
              onLastNameChange={(e) => setLastName(e.target.value)}
              onIdNumChange={(e) => setIdNumber(e.target.value)}
              onEmailChange={(e) => setEmail(e.target.value)}
              isBussinesClick={() => setIdNumber("Is Business")}
              onPasswordChange={(e) => setPassword(e.target.value)}
              onAccountNameChange={(e) => setAccountNumber(e.target.value)}
              onSubmitClick={() => registerSubmit()}
              onCellChange={(e) => setCellNumber(e.target.value)}
            />
          ) : null}
          {selectedType === "Support" ? (
            <SupportForm onCloseClick={() => setSelectedType("none")} />
          ) : null}
          {selectedType === "Admin" ? (
            <AdminForm
              onCloseClick={() => {
                setSelectedType("none");
                setFirstName("");
                setLastName("");
                setProvince("none");
                setCellNumber("");
                setIdNumber("");
                setEmail("");
                setPassword("");
              }}
              firstName={firstName}
              lastName={lastName}
              idNumber={idNumber}
              email={email}
              password={password}
              province={province}
              setProvince={setProvince}
              onFirstNameChange={(e) => setFirstName(e.target.value)}
              onLastNameChange={(e) => setLastName(e.target.value)}
              onIdNumChange={(e) => setIdNumber(e.target.value)}
              onEmailChange={(e) => setEmail(e.target.value)}
              // isBussinesClick={() => setIdNumber("Is Business")}
              onPasswordChange={(e) => setPassword(e.target.value)}
              onAccountNameChange={(e) => setAccountNumber(e.target.value)}
              onSubmitClick={() => registerSubmit()}
              onCellChange={(e) => setCellNumber(e.target.value)}
            />
          ) : null}
          {selectedType === "Doctor" ? (
            <DoctorForm onCloseClick={() => setSelectedType("none")} />
          ) : null}
          {selectedType === "owner" ? (
            <InvestorForm
              onCloseClick={() => {
                setSelectedType("none");
                setFirstName("");
                setLastName("");
                setProvince("none");
                setCellNumber("");
                setIdNumber("");
                setEmail("");
                setPassword("");
              }}
              firstName={firstName}
              lastName={lastName}
              idNumber={idNumber}
              email={email}
              password={password}
              province={province}
              setProvince={setProvince}
              onFirstNameChange={(e) => setFirstName(e.target.value)}
              onLastNameChange={(e) => setLastName(e.target.value)}
              onIdNumChange={(e) => setIdNumber(e.target.value)}
              onEmailChange={(e) => setEmail(e.target.value)}
              isBussinesClick={() => setIdNumber("Is Business")}
              onPasswordChange={(e) => setPassword(e.target.value)}
              onAccountNameChange={(e) => setAccountNumber(e.target.value)}
              onSubmitClick={() => registerSubmit()}
              onCellChange={(e) => setCellNumber(e.target.value)}
            />
          ) : null}
        </>
      ) : null}
      {subSelection === "ALL USERS" || subSelection === "none" ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            borderStyle: "solid",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <HeadersTitle onClick={() => setSubSelection("ADD USERS")} />
          {userArray.data
            ? userArray.data.map((user, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <UserItem
                      onManageClick={() => setUserSelected(user)}
                      firstName={user.firstName}
                      surname={user.lastName}
                      role={user.type}
                      store={user.storeName}
                      email={user.email}
                      cell={user.cellNumber}
                    />
                  </div>
                );
              })
            : null}
        </div>
      ) : null}
      {subSelection === "STATS" ? <div>all stats</div> : null}
      <LoadingPopUp display={loading} />
      <ToastNotification display={toastPop} message={message.toUpperCase()} />
      <UserPopUp
        display={userSelected ? true : false}
        onCloseClick={() => setUserSelected(null)}
        userSelected={userSelected}
      />
    </div>
  );
};

export default Users;
