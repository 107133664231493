import React, { useState, useEffect, useRef } from "react";
import { BlackAndWhite } from "../../../themes/Colors";
import {
  AdminForm,
  CashierForm,
  CategoryButton,
  DoctorForm,
  InvestorForm,
  LoadingPopUp,
  ManagerForm,
  SubmitPopUp,
  SupportForm,
  ToastNotification,
} from "../../../components";
import HeadersTitle from "./table-blocks/HeadersTitle";
import UserItem from "./table-blocks/UserItem";
import HeadersTitleFacilities from "./table-blocks/HeadersTitleFacilities";
import FacilityItem from "./table-blocks/FacilityItem";
import FacilityPopUp from "./facility-pop-up/FacilityPopUp";
import CreateFacility from "./CreateFacility";
import SelectOwnerPopUp from "./SelectOwnerPopUp";
import { BaseButtons } from "../../../components/atoms";

const Facilities = ({
  subSelection,
  userDisplay,
  setUserdisplay,
  setSubSelection,
  readOnly,
}) => {
  const [imageUploadHover, setImageUploadHover] = useState(false);

  const [selectedType, setSelectedType] = useState("none");
  const [facilitiesArray, setFacilitiesArray] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState("");
  const [completedPopUp, setCompletePopUp] = useState(false);
  const [ownerArray, setOwnerArrray] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("error");

  const [completed, setCompleted] = useState(0);

  //image info and state
  const [file, setFile] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);

  const filepickerRef = useRef();

  // register facilties fields

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [landLine, setLandLine] = useState("");
  const [type, setType] = useState("");
  const [cellNumber, setCellNumber] = useState("");
  const [email, setEmail] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [province, setProvince] = useState("none");
  const [accountNumber, setAccountNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [storeName, setStoreName] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [suburb, setSuburb] = useState("");
  const [city, setCity] = useState("");
  const [postCode, setPostCode] = useState("");

  const [para1, setPara1] = useState("none");
  const [para2, setPara2] = useState("nothing");

  const [dayTo, setDayTo] = useState("");
  const [dayFrom, setDayFrom] = useState("");
  const [endTo, setEndTo] = useState("");
  const [endFrom, setEndFrom] = useState("");
  const [publicTo, setPublicTo] = useState("");
  const [publicFrom, setPublicFrom] = useState("");

  const [toastPop, setToastPop] = useState(false);
  const [message, setMessage] = useState("");

  // selected item display
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [openFacilityDisplay, setOpenFacilityDisplay] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const ResetFields = () => {
    setStoreName("");
    setStreetAddress("");
    setSuburb("");
    setCity("");
    setPostCode("");
    setProvince("");
    setLandLine("");
    setCellNumber("");
    setEmail("");
    setType("");
    setPara1("");
    setPara2("");
  };

  const registerNewFacility = async () => {
    setLoading(true);
    setError("Loading");
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg
    try {
      const formData = new FormData();
      formData.append("storeName", storeName);
      formData.append("streetAddress", streetAddress);
      formData.append("suburb", suburb);
      formData.append("city", city);
      formData.append("postalCode", postCode);
      formData.append("province", province);
      formData.append("landLine", landLine);
      formData.append("cellNumber", cellNumber);
      formData.append("email", email);
      formData.append("type", type);
      formData.append("weekdays", dayFrom + " - " + dayTo);
      formData.append("weekends", endFrom + " - " + endTo);
      formData.append("publicHoliday", publicFrom + " - " + publicTo);
      formData.append("createdDate", new Date().toDateString());
      formData.append("descriptionP1", para1);
      formData.append("descriptionp2", para2);
      formData.append("image", file);
      formData.append("ownerId", selectedOwner.id);

      // const response = await fetch(
      //   "https://merry-jane-api.onrender.com/facilities/createStore",
      //   {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({
      //       storeName: storeName,
      //       streetAddress: streetAddress,
      //       suburb: suburb,
      //       city: city,
      //       postalCode: postCode,
      //       province: province,
      //       landLine: landLine,
      //       cellNumber: cellNumber,
      //       email: email,
      //       type: type,
      //       weekdays: dayFrom + " - " + dayTo,
      //       weekends: endFrom + " - " + endTo,
      //       publicHoliday: publicFrom + " - " + publicTo,
      //       createdDate: new Date().toDateString(),
      //       descriptionP1: para1,
      //       descriptionp2: para2 || "none",
      //       image: "coming soon",
      //       ownerId: selectedOwner.id,
      //     }),
      //   }
      // );
      const response = await fetch(
        "https://merry-jane-api.onrender.com/facilities/createStore",
        {
          method: "POST",
          body: formData,
        }
      );

      const respnseData = await response.json();
      if (respnseData.store) {
        const wait = await updateListFetch();
        setSelectedOwner("");
        setCompletePopUp(false);
        setLoading(false);
        setToastPop(true);
        setSubSelection("All FACILITES");
        setMessage(
          "A new Facility has been added to the database, the owner will get a email"
        );
        ResetFields();
      } else {
        setToastPop(true);
        setMessage("Unable to create facilty" + " ," + respnseData.message);
        setError("invalid credentials");
        setLoading(false);
      }
    } catch (err) {
      setError("invalid credentials");
    }
  };

  const pickedImage = (e) => {
    if (e.target.files || e.target.files.length === 1) {
      const pickedImageFile = e.target.files[0];
      setFile(pickedImageFile);
      setIsValid(true);
    } else {
      setIsValid(false);
      console.log("error");
    }
  };

  const pickedImageHandler = () => {
    filepickerRef.current.click();
  };

  const updateListFetch = async () => {
    await fetch("https://merry-jane-api.onrender.com/facilities/getFacilities")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setFacilitiesArray(resData);
      });
  };

  useEffect(() => {
    fetch("https://merry-jane-api.onrender.com/facilities/getFacilities")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setFacilitiesArray(resData);
      });
    fetch("https://merry-jane-api.onrender.com/cannaPos/fetchOwners")
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setOwnerArrray(resData);
      });
  }, []);
  useEffect(() => {
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  }, [file]);
  useEffect(() => {}, []);
  useEffect(() => {
    if (toastPop) {
      setTimeout(() => {
        setToastPop(false);
      }, 5000);
    }
  }, [toastPop]);
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: BlackAndWhite.secondary,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {subSelection === "SETUP" ? (
        <>
          {selectedType === "none" ? (
            <>
              <div
                style={{
                  marginBottom: 20,
                  fontSize: 24,
                  fontWeight: "bold",
                  textAlign: "left",
                  width: "90%",
                  marginTop: 20,
                }}
              >
                SETUP
              </div>
              <div
                style={{
                  marginBottom: 20,
                  fontSize: 15,
                  textAlign: "left",
                  width: "90%",
                }}
              >
                FILL OUT THE FACILITY INFORMATION BELOW TO ADD IT TO THE
                DATABASE
              </div>
              <div
                style={{
                  flex: 1,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <CreateFacility
                  onStoreChange={(e) => setStoreName(e.target.value)}
                  onEmailChange={(e) => setEmail(e.target.value)}
                  onCelNumberChange={(e) => setCellNumber(e.target.value)}
                  onLandlineChange={(e) => setLandLine(e.target.value)}
                  onTypeChange={(e) => setType(e.target.value)}
                  onStreetChange={(e) => setStreetAddress(e.target.value)}
                  onSuburbChange={(e) => setSuburb(e.target.value)}
                  onCityChange={(e) => setCity(e.target.value)}
                  onProvinceChange={(e) => setProvince(e.target.value)}
                  onPostalChange={(e) => setPostCode(e.target.value)}
                  onChangeParagraph1={(e) => setPara1(e.target.value)}
                  onChangeParagraph2={(e) => setPara2(e.target.value)}
                  onDayfromChange={(e) => setDayFrom(e.target.value)}
                  onDayToChange={(e) => setDayTo(e.target.value)}
                  onEndfromChange={(e) => setEndFrom(e.target.value)}
                  onEndToChange={(e) => setEndTo(e.target.value)}
                  onPublicfromChange={(e) => setPublicFrom(e.target.value)}
                  onPublicToChange={(e) => setPublicTo(e.target.value)}
                />
                <div style={{ position: "absolute", top: -90, right: 10 }}>
                  <SubmitPopUp
                    display={
                      storeName.length >= 3 &&
                      email.length >= 7 &&
                      cellNumber.length === 10 &&
                      landLine.length === 10 &&
                      type.length > 1 &&
                      streetAddress.length >= 5 &&
                      suburb.length >= 2 &&
                      city.length >= 2 &&
                      province !== "none" &&
                      province.length >= 5 &&
                      postCode.length > 3 &&
                      isValid
                        ? // para1.length > 10
                          true
                        : false
                    }
                    incomplete={completed}
                    onClick={() => setCompletePopUp(true)}
                  />
                  <div
                    onMouseOver={() => setImageUploadHover(true)}
                    onMouseOut={() => setImageUploadHover(false)}
                    style={{
                      position: "absolute",
                      top: 20,
                      right: 20,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        color: BlackAndWhite.primary,
                        width: 300,
                        borderStyle: "solid",
                        height: 40,
                        borderRadius: 8,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      IMAGE UPLOAD
                    </div>
                    <form
                      style={{
                        color: BlackAndWhite.primary,
                        width: 300,
                        borderStyle: "solid",
                        height: imageUploadHover ? 300 : 0,
                        borderRadius: 8,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: BlackAndWhite.secondary,
                        overflow: "hidden",
                        transition: "all 0.5s",
                        borderWidth: imageUploadHover ? 1.5 : 0,
                        marginTop: 10,
                      }}
                    >
                      <label
                        onClick={() => pickedImageHandler()}
                        style={{
                          borderStyle: "solid",

                          borderRadius: 8,

                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          marginBottom: 10,
                          width: "80%",
                          height: "60%",
                          display: "flex",
                          overflow: "hidden",
                        }}
                      >
                        {previewUrl ? (
                          <img
                            alt="Preview"
                            src={previewUrl ? previewUrl : null}
                            style={{ height: "100%", width: "100%" }}
                          />
                        ) : (
                          <div style={{ width: "80%" }}>
                            Click here to Select image to upload
                          </div>
                        )}
                      </label>
                      <input
                        style={{
                          display: "none",
                          borderStyle: "solid",

                          borderRadius: 8,

                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        type="file"
                        accept=".jpg,.png,.jpeg"
                        ref={filepickerRef}
                        onChange={pickedImage}
                      ></input>
                      <BaseButtons label={"UPLOAD"} onClick={null} />
                    </form>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </>
      ) : null}
      {subSelection === "All FACILITES" || subSelection === "none" ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            borderStyle: "solid",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              marginBottom: 20,
              fontSize: 24,
              fontWeight: "bold",
              textAlign: "left",
              width: "90%",
              marginTop: 20,
            }}
          >
            ALL FACILITES
          </div>
          <HeadersTitleFacilities
            onClick={readOnly ? null : () => setSubSelection("SETUP")}
          />
          {facilitiesArray.store
            ? facilitiesArray.store.map((store, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FacilityItem
                      onManageClick={() => {
                        setSelectedFacility(store);
                        setOpenFacilityDisplay(true);
                      }}
                      onDeleteClick={
                        readOnly
                          ? null
                          : () => {
                              setSelectedFacility(store);
                              setOpenFacilityDisplay(true);
                              setIsDeleting(true);
                            }
                      }
                      storeName={store.storeName}
                      province={store.province}
                      city={store.city}
                      type={store.type}
                      email={store.email}
                      cell={store.cellNumber}
                    />
                  </div>
                );
              })
            : null}
        </div>
      ) : null}
      {subSelection === "STATS" ? <div>all stats</div> : null}
      <SelectOwnerPopUp
        ownerArray={ownerArray}
        onCloseClick={() => setCompletePopUp(false)}
        display={completedPopUp}
        selected={selectedOwner}
        setSelectedOwner={setSelectedOwner}
        onCreateClick={() => registerNewFacility()}
        onCancelClick={() => setCompletePopUp(false)}
      />
      <FacilityPopUp
        updateListFetch={updateListFetch}
        setToastPop={setToastPop}
        setLoading={setLoading}
        setMessage={setMessage}
        setOpenFacilityDisplay={setOpenFacilityDisplay}
        setIsDeleting={setIsDeleting}
        selectedFacility={selectedFacility}
        deleteOption={isDeleting}
        display={
          openFacilityDisplay === true && subSelection === "All FACILITES"
            ? true
            : false
        }
        width={"100%"}
        height={"100%"}
        onCloseClick={() => {
          setOpenFacilityDisplay(false);
          setIsDeleting(false);
        }}
      />
      <LoadingPopUp display={loading} />
      <ToastNotification display={toastPop} message={message.toUpperCase()} />
    </div>
  );
};

export default Facilities;
