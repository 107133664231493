import React, { useState, useEffect } from "react";
import {
  BaseButtons,
  TextInputFieldSearch,
} from "../../../../components/atoms";
import { BlackAndWhite } from "../../../../themes/Colors";

import {
  HeadersInventoryPOs,
  HeadersTransfers,
  InventoryManageCard,
  VerifyStockPopUp,
} from "./components";
import {
  TransferItem,
  TransferManageView,
} from "../../../admin-portal/components/inventory/components/transfers/components";
import { findAllByDisplayValue } from "@testing-library/react";
import { FilterDropDown } from "../process-sale/components";

const InventoryPos = ({
  user,
  refresh,
  setLoading,

  setToastPop,
  setMessage,
  setRefresh,
  storeId,
}) => {
  const [view, setView] = useState("stores");
  const [search, setSearch] = useState("");
  const [productsArray, setProductsArray] = useState(null);
  const [transferArrayComplete, setTransfersArrayComplete] = useState([]);
  const [transferArrayInTRansit, setTransfersArrayInTRansit] = useState([]);
  const [dipslayTransferOptions, setDisplayTransferOptions] =
    useState("in-transit");
  const [selectTranfersForView, setSelectTranfersForView] = useState(null);

  const [verifyStock, setVerifyStock] = useState(null);

  const [info, setInfo] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  const [filterOption, setFilterOption] = useState("CATEGORY");

  const NameAssemble = ({ product }) => {
    if (product.flavour !== "None" && product) {
      let name =
        product.productName +
        " | " +
        product.productSubType +
        " | " +
        product.thcType +
        " | " +
        product.thcContent +
        " | " +
        product.flavour;

      return name;
    } else {
      let name =
        product.productName +
        " | " +
        product.productSubType +
        " | " +
        product.thcType +
        " | " +
        product.thcContent;

      return name;
    }
  };

  const TransferNameAssemble = ({ doc }) => {
    if (doc) {
      let name = doc.transferId + " | " + doc.createdBy;

      return name;
    }
  };

  const getProducts = async () => {
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/getProductsAdded",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const respnseData = await response.json();
      if (respnseData.product) {
        // const wait = await updateListFetch();
        setProductsArray(respnseData.product);
        setLoading(false);
      } else if (respnseData.message) {
        setProductsArray([]);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("err", err);
    }
  };
  const GetTransferDocs = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/inventory/getStoreTRansfers",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: user.adminUserData.userId,
            storeName: user.adminUserData.storeName,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setTransfersArrayComplete(responseData.tansferDocsComplete);
        setTransfersArrayInTRansit(responseData.tansferDocsIntransit);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };

  const GetStoreStockInfo = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/inventory/getStoreInventoryProcessSalesStock",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            // productId: product._id,
            storeId: storeId,
          }),
        }
      );

      const responseData = await response.json();

      if (responseData.success) {
        setInfo(responseData.completeProducts);
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  const ReturnStockAmount = ({ productId }) => {
    let stock = 0;
    if (info) {
      info.map((item) =>
        item.productId === productId ? (stock = item.currentStock) : null
      );
    }

    return Number(stock);
  };

  const ReturnStockDateLastCheckAmount = ({ productId }) => {
    let stockTakeDate;
    info.map((item) =>
      item.productId === productId ? (stockTakeDate = item.stockTakeDate) : null
    );

    return stockTakeDate;
  };
  useEffect(() => {
    if (user) {
      GetTransferDocs();
    }
  }, [refresh]);
  useEffect(() => {
    getProducts();
  }, [refresh]);
  useEffect(() => {
    if (!isLoaded) {
      GetStoreStockInfo();
      setIsLoaded(true);
    }
  }, []);
  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        marginBottom: 25,
      }}
    >
      <div
        style={{
          width: "100%",
          height: 55,

          display: "flex",
          alignItems: "center",
          marginBottom: 5,
        }}
      >
        <div style={{ marginLeft: 10, marginRight: 10, marginBottom: 0 }}>
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",

              // width: 300,

              borderWidth: 0.5,
            }}
          >
            <TextInputFieldSearch
              placeholder={"Type here to search"}
              height={30}
              borderRadius={4}
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              // fontSize={22}
            />
            <div
              style={{
                height: 35,
                width: 70,
                backgroundColor: BlackAndWhite.primary,
                borderRadius: 4,
                display: "flex",
                color: BlackAndWhite.secondary,
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                marginLeft: 5,
              }}
            >
              <div
                onClick={() => setSearch("")}
                style={{ fontSize: 12, fontWeight: "bold" }}
              >
                CLEAR
              </div>
            </div>
          </div>
        </div>

        <div style={{ flex: 1 }}></div>
        {view === "stores" ? (
          <div style={{ marginRight: 10, marginBottom: 0 }}>
            <FilterDropDown
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              selectedSubCategory={selectedSubCategory}
              setSelectedSubCategory={setSelectedSubCategory}
              filterOption={filterOption}
              setFilterOption={setFilterOption}
            />
          </div>
        ) : null}

        {view === "transfers" ? (
          <div
            style={{
              padding: 10,
              backgroundColor: BlackAndWhite.primary,
              display: "flex",
              alignItems: "center",
              borderRadius: 8,
            }}
          >
            <BaseButtons
              label={"IN-TRANSIT"}
              size={100}
              mini={true}
              marginTopFalse={true}
              height={30}
              marginRight={10}
              borderRadius={8}
              onClick={() => setDisplayTransferOptions("in-transit")}
              invert={dipslayTransferOptions === "in-transit" ? false : true}
            />
            <BaseButtons
              label={"COMPLETE"}
              size={120}
              mini={true}
              marginTopFalse={true}
              height={30}
              borderRadius={8}
              onClick={() => setDisplayTransferOptions("complete")}
              invert={dipslayTransferOptions === "complete" ? false : true}
            />
          </div>
        ) : null}
        <BaseButtons
          label={"STORE INVENTORY"}
          size={180}
          mini={true}
          marginTopFalse={true}
          // marginTop={5}
          height={35}
          marginRight={20}
          borderRadius={8}
          invert={view === "stores" ? false : true}
          onClick={() => setView("stores")}
        />
        <BaseButtons
          label={"TRANSFERS"}
          size={160}
          mini={true}
          marginTopFalse={true}
          // marginTop={5}
          height={35}
          marginRight={20}
          borderRadius={8}
          invert={view === "transfers" ? false : true}
          onClick={() => setView("transfers")}
        />
      </div>
      {view === "stores" ? <HeadersInventoryPOs /> : null}
      {view === "transfers" ? <HeadersTransfers /> : null}
      <div
        style={{
          flex: 1,
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          maxHeight: 800,
        }}
      >
        {view === "stores"
          ? productsArray
            ? productsArray.map((product, index) => {
                return selectedCategory ? (
                  selectedCategory.categoryName === product.productType ? (
                    selectedSubCategory ? (
                      selectedSubCategory.subCategoryName ===
                      product.productSubType ? (
                        NameAssemble({ product })
                          .toLocaleLowerCase()
                          .match(search.toLocaleLowerCase()) ? (
                          <div key={index} style={{ width: "100%" }}>
                            <InventoryManageCard
                              product={product}
                              productName={NameAssemble({ product })}
                              sku={product.sku}
                              supplier={product.supplier}
                              image={product.imageUrl}
                              isStore={true}
                              setVerifyStock={setVerifyStock}
                              productsArray={productsArray}
                              storeId={storeId}
                              stockValue={ReturnStockAmount({
                                productId: product._id,
                              })}
                              stockTakeDate={ReturnStockDateLastCheckAmount({
                                productId: product._id,
                              })}
                            />
                          </div>
                        ) : null
                      ) : null
                    ) : NameAssemble({ product })
                        .toLocaleLowerCase()
                        .match(search.toLocaleLowerCase()) ? (
                      <div key={index} style={{ width: "100%" }}>
                        <InventoryManageCard
                          product={product}
                          productName={NameAssemble({ product })}
                          sku={product.sku}
                          supplier={product.supplier}
                          image={product.imageUrl}
                          isStore={true}
                          setVerifyStock={setVerifyStock}
                          productsArray={productsArray}
                          storeId={storeId}
                          stockValue={ReturnStockAmount({
                            productId: product._id,
                          })}
                          stockTakeDate={ReturnStockDateLastCheckAmount({
                            productId: product._id,
                          })}
                        />
                      </div>
                    ) : null
                  ) : null
                ) : NameAssemble({ product })
                    .toLocaleLowerCase()
                    .match(search.toLocaleLowerCase()) ? (
                  <div key={index} style={{ width: "100%" }}>
                    <InventoryManageCard
                      product={product}
                      productName={NameAssemble({ product })}
                      sku={product.sku}
                      supplier={product.supplier}
                      image={product.imageUrl}
                      isStore={true}
                      setVerifyStock={setVerifyStock}
                      productsArray={productsArray}
                      storeId={storeId}
                      stockValue={ReturnStockAmount({ productId: product._id })}
                      stockTakeDate={ReturnStockDateLastCheckAmount({
                        productId: product._id,
                      })}
                    />
                  </div>
                ) : null;

                // NameAssemble({ product })
                //   .toLocaleLowerCase()
                //   .match(search) ? (
                //   <div key={index} style={{ width: "100%" }}>
                //
                // </div>
              })
            : null
          : null}
        {view === "transfers"
          ? dipslayTransferOptions === "in-transit"
            ? transferArrayInTRansit.length >= 1
              ? transferArrayInTRansit.map((doc, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        width: "100%",
                        minHeight: 60,
                        maxHeight: 60,

                        display: "flex",
                        alignItems: "center",
                        marginBottom: transferArrayInTRansit
                          ? index === transferArrayInTRansit.length - 1
                            ? 350
                            : 5
                          : 5,
                        backgroundColor: BlackAndWhite.secondary,
                      }}
                    >
                      <TransferItem
                        doc={doc}
                        SelectTranfersForView={setSelectTranfersForView}
                      />
                    </div>
                  );
                })
              : "NO DOCUMENTS"
            : null
          : null}
        {view === "transfers"
          ? dipslayTransferOptions === "complete"
            ? transferArrayComplete.length >= 1
              ? transferArrayComplete.map((doc, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        width: "100%",
                        minHeight: 60,
                        maxHeight: 60,
                        // borderBottomStyle: "solid",
                        display: "flex",
                        alignItems: "center",
                        marginBottom: transferArrayComplete
                          ? index === transferArrayComplete.length - 1
                            ? 350
                            : 5
                          : 5,
                        backgroundColor: BlackAndWhite.secondary,
                      }}
                    >
                      <TransferItem
                        doc={doc}
                        SelectTranfersForView={setSelectTranfersForView}
                      />
                    </div>
                  );
                })
              : "NO DOCUMENTS"
            : null
          : null}
      </div>
      <TransferManageView
        notPosCheck={true}
        isPos={true}
        display={selectTranfersForView}
        setSelectedTransfer={setSelectTranfersForView}
        setRefresh={setRefresh}
        refresh={refresh}
        auth={user.adminUserData}
        setLoading={setLoading}
        setMessage={setMessage}
        setToastPop={setToastPop}
      />
      <VerifyStockPopUp
        display={verifyStock}
        setClose={setVerifyStock}
        selectedProduct={verifyStock}
        setMessage={setMessage}
        setLoading={setLoading}
        setToastPop={setToastPop}
        user={user}
        refresh={refresh}
        setRefresh={setRefresh}
        info={info}
      />
    </div>
  );
};

export default InventoryPos;
