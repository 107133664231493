import React, { useState, useEffect } from "react";
import useWindowDimensions from "../../../../components/get-diemensions/UseWindowDiemensions";
import { BlackAndWhite } from "../../../../themes/Colors";
import {
  BaseButtons,
  CloseButton,
  TextAreaInput,
  TextInputField,
} from "../../../../components/atoms";

const FacilityPopUp = ({
  display,
  width,
  height,
  onCloseClick,
  deleteOption,
  selectedFacility,
  setLoading,
  setMessage,
  setToastPop,
  setOpenFacilityDisplay,
  setIsDeleting,
  updateListFetch,
}) => {
  const [ownerName, setOwnerName] = useState("");

  const deleteFacility = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/facilities/deleteStore",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            storeId: selectedFacility.storeId,
          }),
        }
      );
      const respnseData = await response.json();
      if (respnseData.message === "facility was deleted") {
        const updatelist = await updateListFetch();
        setLoading(false);
        setToastPop(true);
        setMessage(
          "Facility" + " " + selectedFacility.storeName + " was deleted."
        );
        setOpenFacilityDisplay(false);
        setIsDeleting(false);
      } else {
        setLoading(false);
        setToastPop(true);
        setOpenFacilityDisplay(false);
        setIsDeleting(false);
        setMessage("A error has occured, try again later");
      }
    } catch (err) {
      setOpenFacilityDisplay(false);
      setIsDeleting(false);
      setLoading(false);
      setToastPop(true);
      setMessage("there was a error deleteing your facilty trya again later");
    }
  };

  const checkOwner = async () => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/facilities/getStoresByOwner",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ownerId: selectedFacility.ownerId,
          }),
        }
      );

      const respnseData = await response.json();
      if (respnseData.store.firstName) {
        const value =
          respnseData.store.firstName + " " + respnseData.store.lastName;
        setOwnerName(value || "loading");
      }
    } catch (err) {
      setOwnerName("no owner");
    }
  };
  useEffect(() => {
    checkOwner();
  }, [display]);
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        width: width,
        height: display ? height : 0,
        backgroundColor: BlackAndWhite.secondary,
        overflow: "hidden",
        transition: "all 0.4s",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
          overflow: "scroll",
        }}
      >
        {deleteOption ? (
          <div
            style={{
              color: BlackAndWhite.primary,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div>would you like to delete this Facility?</div>
            <div style={{ display: "flex" }}>
              <BaseButtons label={"NO"} onClick={onCloseClick} />
              <BaseButtons
                label={"YES, IM SURE"}
                onClick={() => deleteFacility()}
              />
            </div>
          </div>
        ) : (
          <div
            style={{
              color: BlackAndWhite.primary,
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <div
              style={{
                flex: 0.5,
                display: "flex",
                flexDirection: "column",
                height: "100%",
                // borderStyle: "solid",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  height: 210,
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 25,
                }}
              >
                <div
                  style={{
                    width: 200,
                    height: 200,
                    borderRadius: 8,
                    backgroundColor: "#00000033",
                  }}
                >
                  <img
                    style={{ height: "100%" }}
                    alt="location"
                    src={
                      selectedFacility
                        ? `https://merry-jane-api.onrender.com/${selectedFacility.image}`
                        : null
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: 300,
                    height: 200,
                    justifyContent: "flex-start",
                    alignItems: "center",
                    // borderStyle: "solid",
                  }}
                >
                  <div
                    style={{
                      width: "80%",
                      textAlign: "left",
                      fontWeight: "normal",
                      fontSize: 14,
                      marginBottom: 5,
                    }}
                  >
                    STORE NAME:
                  </div>
                  <div
                    style={{
                      width: "80%",
                      textAlign: "left",
                      fontWeight: "bold",
                      fontSize: 18,
                    }}
                  >
                    {selectedFacility
                      ? "Merry-Jane" + " " + selectedFacility.storeName
                      : "loading"}
                  </div>
                  <div
                    style={{
                      width: "80%",
                      textAlign: "left",
                      fontWeight: "normal",
                      fontSize: 14,
                      marginBottom: 5,
                      marginTop: 10,
                    }}
                  >
                    ADDRESS:
                  </div>
                  <div style={{ width: "80%", textAlign: "left" }}>
                    {selectedFacility
                      ? selectedFacility.streetAddress
                      : "loading"}
                  </div>
                  <div style={{ width: "80%", textAlign: "left" }}>
                    {selectedFacility ? selectedFacility.suburb : "loading"}
                  </div>

                  <div style={{ width: "80%", textAlign: "left" }}>
                    {selectedFacility ? selectedFacility.city : "loading"}
                  </div>

                  <div style={{ width: "80%", textAlign: "left" }}>
                    {selectedFacility ? selectedFacility.postalCode : "loading"}
                  </div>
                  <div
                    style={{
                      width: "80%",
                      textAlign: "left",

                      fontSize: 16,
                    }}
                  >
                    {selectedFacility ? selectedFacility.province : "loading"}
                  </div>
                </div>
              </div>

              <TextInputField
                size={400}
                invert={true}
                labelTitle={"STORE CELL-NUMBER"}
                value={
                  selectedFacility ? selectedFacility.cellNumber : "loading"
                }
              />
              <TextInputField
                size={400}
                invert={true}
                labelTitle={"STORE LANDLINE"}
                value={selectedFacility ? selectedFacility.landLine : "loading"}
              />
              <TextInputField
                size={400}
                invert={true}
                labelTitle={"EMAIL"}
                value={selectedFacility ? selectedFacility.email : "loading"}
              />
            </div>
            <div
              style={{
                flex: 0.5,
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              {/* //operating block */}
              <div>
                <div
                  style={{
                    width: "100%",
                    height: 250,
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "column",
                    marginTop: 30,
                  }}
                >
                  <div
                    style={{
                      fontSize: 14,
                      width: "75%",
                      textAlign: "left",
                      marginBottom: 3,
                    }}
                  >
                    OWNER:
                  </div>
                  <div
                    style={{ width: "75%", textAlign: "left", marginBottom: 7 }}
                  >
                    {selectedFacility ? ownerName : "loading"}
                  </div>
                  <div
                    style={{
                      fontSize: 14,
                      width: "75%",
                      textAlign: "left",
                      marginBottom: 3,
                    }}
                  >
                    CREATED ON:
                  </div>
                  <div
                    style={{ width: "75%", textAlign: "left", marginBottom: 7 }}
                  >
                    {selectedFacility
                      ? selectedFacility.createdDate
                      : "loading"}
                  </div>
                  <div
                    style={{
                      fontSize: 14,
                      width: "75%",
                      textAlign: "left",
                      marginBottom: 3,
                    }}
                  >
                    TYPE:
                  </div>
                  <div style={{ width: "75%", textAlign: "left" }}>
                    {selectedFacility ? selectedFacility.type : "loading"}
                  </div>
                  <div
                    style={{ width: "75%", marginTop: 25, marginBottom: 10 }}
                  >
                    OPERATING HOURS:
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "75%",

                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        flex: 0.33,
                        height: 60,
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div>weekdays</div>
                      <div>
                        {selectedFacility
                          ? selectedFacility.weekdays
                          : "loading"}
                      </div>
                    </div>
                    <div
                      style={{
                        flex: 0.34,
                        height: 60,
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div>weekends</div>
                      <div>
                        {selectedFacility
                          ? selectedFacility.weekends
                          : "loading"}
                      </div>
                    </div>
                    <div
                      style={{
                        flex: 0.33,
                        height: 60,
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div>public holidays</div>
                      <div>
                        {selectedFacility
                          ? selectedFacility.publicHoliday
                          : "loading"}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    width: "100%",
                    height: 300,
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ width: "75%", marginTop: 5, marginBottom: 10 }}>
                    Description/ About store:
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: 270 }}>
                      <TextAreaInput
                        labelTitle={"Paragraph 1"}
                        value={
                          selectedFacility
                            ? selectedFacility.descriptionP1
                            : "loading"
                        }
                        color={BlackAndWhite.primary}
                      />
                    </div>
                    <div style={{ width: 270 }}>
                      <TextAreaInput
                        labelTitle={"Paragraph 1"}
                        value={
                          selectedFacility
                            ? selectedFacility.descriptionp2
                            : "loading"
                        }
                        color={BlackAndWhite.primary}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ height: 40, color: BlackAndWhite.secondary }}>
                space
              </div>
            </div>
          </div>
        )}

        <div style={{ position: "absolute", top: 10, left: 10 }}>
          <CloseButton onClick={onCloseClick} label={"X"} invert={false} />
        </div>
      </div>
    </div>
  );
};

export default FacilityPopUp;
