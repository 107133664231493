import React from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";
import TabMobileButton from "../tab-mobile-button/TabMobileButton";

const BottomTabs = ({
  onMedicalClick,
  selected,
  onProfileClick,
  onPrescriptionClick,
  onStoreClick,
  onMessageClick,
  onMenuClick,
}) => {
  return (
    <div
      style={{
        height: 80,
        width: "100%",
        backgroundColor: BlackAndWhite.primary,
        display: "flex",
        borderTopStyle: "solid",
        borderTopColor: "#FFFFFF66",
        justifyContent: "space-evenly",
        alignItems: "center",
        zIndex: 0,
      }}
    >
      <div
        style={{
          flex: 0.2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TabMobileButton
          // title={"Profile"}
          onClick={onProfileClick}
          icon={require("../../../../../../images/material-icons/profileWhite.png")}
          icon2={require("../../../../../../images/material-icons/profileBlack.png")}
          selected={selected === "profile" ? true : false}
        />
      </div>
      <div
        style={{
          flex: 0.2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TabMobileButton
          // title={"Medical History"}
          onClick={onMedicalClick}
          icon={require("../../../../../../images/material-icons/medicalHistpryWhite.png")}
          icon2={require("../../../../../../images/material-icons/medicalHistoryBlack.png")}
          selected={selected === "medical" ? true : false}
        />
      </div>

      <div
        style={{
          flex: 0.2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TabMobileButton
          onClick={onPrescriptionClick}
          // title={"Prescription"}
          icon={require("../../../../../../images/material-icons/prescriptionWhite.png")}
          icon2={require("../../../../../../images/material-icons/prescriptionBlack.png")}
          selected={selected === "prescription" ? true : false}
        />
      </div>
      {/* <div
        style={{
          flex: 0.2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TabMobileButton
          onClick={onStoreClick}
          icon={require("../../../../../../images/material-icons/shopWhite.png")}
          icon2={require("../../../../../../images/material-icons/shopBlack.png")}
          selected={selected === "shop" ? true : false}
        />
      </div> */}
      <div
        style={{
          flex: 0.2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TabMobileButton
          onClick={onMessageClick}
          icon={require("../../../../../../images/material-icons/messageWhite.png")}
          icon2={require("../../../../../../images/material-icons/messageBlack.png")}
          selected={selected === "message" ? true : false}
        />
      </div>
      <div
        style={{
          flex: 0.2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TabMobileButton
          // title={"LogOut"}
          onClick={onMenuClick}
          icon={require("../../../../../../images/material-icons/menuWhite.png")}
          icon2={require("../../../../../../images/material-icons/menuBlack.png")}
          selected={selected === "menu" ? true : false}
        />
      </div>
    </div>
  );
};

export default BottomTabs;
