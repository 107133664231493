import React, { useEffect, useContext, useState } from "react";
import PrescriptionCard from "./components/PrescriptionCard";
import { AuthContext } from "../../../../context/auth-context";
import JsPDF from "jspdf";
import useWindowDimensions from "../../../../components/get-diemensions/UseWindowDiemensions";
import { BlackAndWhite } from "../../../../themes/Colors";
import { BaseButtons, CloseButton } from "../../../../components/atoms";
import PrescriptionRequestPopUp from "./components/prescription-request-pop-up/PrescriptionRequestPopUp";
import AWS from "aws-sdk";

const Prescription = ({
  isDoc,
  isCustomer,
  medicalCheck,
  setToastPop,
  setMessage,
  setScreenDisplayed,
  onHelpClick,
}) => {
  const { width, height } = useWindowDimensions();
  const [data, setData] = useState(null);
  const auth = useContext(AuthContext);
  const [pdfDisplay, setPdfDisplay] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [newRequest, setNewRequest] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pendingCheck, setPendingCheck] = useState(true);

  const [logo, setLogo] = useState(null);
  const [signiture, setSigniture] = useState(null);

  const [logoImage, setLogoImage] = useState(null);
  const [signitureImage, setSignitureImage] = useState(null);

  const [doctorData, setDoctorData] = useState(null);

  const dateFind = new Date().toDateString().substring(0, 12);

  const [selectedExeption, setSelectedException] = useState(null);

  const [tabState, setTabState] = useState("expired");

  const generatePDF = async () => {
    // prescriptionPdf();
    setDownloading(true);
    const report = await new JsPDF("portrait", "px", "a4");
    report.html(document.querySelector("#report")).then(() => {
      // report.getFontList();
      // report.setFont("Lato-Regular", "normal");
      report.save("report.pdf");
    });
    setDownloading(false);
  };

  const checkPrescriptionLayout = async ({}) => {
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/requestPrescriptionUser/getPrescriptionLayout",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fullTitle: selectedExeption.doctor,
          }),
        }
      );
      const respnseData = await response.json();

      if (respnseData.success) {
        setLogo(respnseData.logo);
        setSigniture(respnseData.signiture);
        setDoctorData(respnseData.practiceData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getUplaodImage = async () => {
    const S3_BUCKET = process.env.REACT_APP_BUCKET;
    const apiKey = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
    const secrectapiKey = process.env.REACT_APP_AWS_SECRET_KEY_ID;
    AWS.config.update({
      accessKeyId: apiKey,
      secretAccessKey: secrectapiKey,
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: process.env.REACT_APP_REGION,
      signatureVersion: "v4",
    });

    const params = {
      Bucket: S3_BUCKET,
      Key: logo ? logo.imageId : "",
    };

    var promise = s3.getSignedUrlPromise("getObject", params);
    // return promise;
    promise.then(
      function (url) {
        if (url) {
          setLogoImage(url);
        }
        return url;
      },
      function (err) {
        console.log(err);
      }
    );
  };

  const getSigImage = async () => {
    const S3_BUCKET = process.env.REACT_APP_BUCKET;
    const apiKey = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
    const secrectapiKey = process.env.REACT_APP_AWS_SECRET_KEY_ID;
    AWS.config.update({
      accessKeyId: apiKey,
      secretAccessKey: secrectapiKey,
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: process.env.REACT_APP_REGION,
      signatureVersion: "v4",
    });

    const params = {
      Bucket: S3_BUCKET,
      Key: signiture ? signiture.imageId : "",
    };

    var promise = s3.getSignedUrlPromise("getObject", params);
    // return promise;
    promise.then(
      function (url) {
        if (url) {
          setSignitureImage(url);
        }
        return url;
      },
      function (err) {
        console.log(err);
      }
    );
  };
  useEffect(() => {
    if (selectedExeption) {
      checkPrescriptionLayout(selectedExeption.doctor);
      getUplaodImage();
      getSigImage();
    }
  }, [selectedExeption]);

  useEffect(() => {
    if (signiture) {
      getSigImage();
    }
  }, [signiture]);
  useEffect(() => {
    if (logo) {
      getUplaodImage();
    }
  }, [logo]);
  const PrescriptionPdf = ({
    onCloseClick,
    idKey,
    patientName,
    dob,
    idNumber,
    diagnosis,
    address,
    prescriptionText,
    statusCheck,
    date,
    height,
  }) => {
    return (
      <div
        key={idKey}
        style={{
          width: "100%",
          height: height,
          overflow: "scroll",
          position: "absolute",
          zIndex: 200,
        }}
      >
        <div
          id="report"
          style={{
            backgroundColor: BlackAndWhite.secondary,
            width: width,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingLeft: downloading ? 25 : null,
            // fontFamily: "Montserrat",
            letterSpacing: 1.6,
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "80%",
              // marginRight: 40,
            }}
          >
            <img
              alt="doc"
              src={require("../../../../images/newLogos/1. MJ Logo Black with White Surround.png")}
              style={{ height: 50, marginTop: 10 }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 20,
            }}
          >
            <div>
              {doctorData ? doctorData.practiceName : "Dr. B MC LAUGHLIN"}
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ fontSize: downloading ? 6 : 8 }}>
              Practice No: {doctorData ? doctorData.practiceNo : "loading"}
            </div>
            <div style={{ fontSize: downloading ? 6 : 8 }}>
              Reg No: {doctorData ? doctorData.hspcaRegNo : "loading"}
            </div>
          </div>
          {/* //titles */}
          <div
            style={{
              width: "80%",
              display: "flex",
              marginTop: 10,
              fontWeight: "bold",
            }}
          >
            <div style={{ fontSize: downloading ? 7 : 11 }}>
              POSTAL/PHYSICAL ADDRESS
            </div>
            <div style={{ flex: 1 }} />
            <div style={{ fontSize: downloading ? 7 : 11 }}>
              CONTACT DETAILS
            </div>
          </div>
          {/* //values */}
          <div
            style={{
              width: "80%",
              display: "flex",
              marginTop: 10,
            }}
          >
            <div
              style={{
                fontSize: downloading ? 4 : 10,
                // marginLeft: 10,
                width: 150,
              }}
            >
              {doctorData ? doctorData.address : "loading"}
            </div>
            <div style={{ flex: 1 }} />
            <div
              style={{
                fontSize: downloading ? 4 : 10,
                // marginRight: 60,
                width: 145,
                textAlign: "right",
              }}
            >
              contact no: {doctorData ? doctorData.contactNo : "loading"} ,
              {doctorData ? doctorData.email.substring(0, 10) : "loading"}
              <br></br>
              {doctorData ? doctorData.email.substring(10, 30) : "loading"}
            </div>
          </div>

          <div
            style={{
              marginTop: 8,
              marginBottom: 4,
              fontSize: downloading ? 10 : 16,
            }}
          >
            PRESCRIPTION
          </div>
          {/* //first line of test */}
          <div style={{ width: "80%", display: "flex", flexDirection: "row" }}>
            <div
              style={{
                flex: 0.6,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
                fontSize: downloading ? 5 : 10,
              }}
            >
              <div style={{ fontWeight: "bold" }}>PATIENT NAME & SURNAME:</div>
              <div style={{ marginTop: 2 }}>{patientName}</div>
            </div>
            <div
              style={{
                flex: 0.4,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
                fontSize: downloading ? 5 : 10,
              }}
            >
              <div style={{ fontWeight: "bold" }}>D.O.B:</div>
              <div style={{ marginTop: 2 }}>{dob}</div>
            </div>
          </div>
          {/* //second line of test */}
          <div
            style={{
              width: "80%",
              display: "flex",
              flexDirection: "row",
              marginTop: 2,
            }}
          >
            <div
              style={{
                flex: 0.6,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
                fontSize: downloading ? 5 : 10,
              }}
            >
              <div style={{ fontWeight: "bold" }}>
                I.D. NUMBER / PASSPORT NUMBER:
              </div>
              <div style={{ marginTop: 2 }}>{idNumber}</div>
            </div>
            <div
              style={{
                flex: 0.4,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
                fontSize: downloading ? 5 : 10,
              }}
            >
              <div style={{ fontWeight: "bold" }}>DIAGNOSIS:</div>
              <div style={{ marginTop: 2 }}>{diagnosis}</div>
            </div>
          </div>
          {/* //third line of test */}
          <div
            style={{
              width: "80%",
              display: "flex",
              flexDirection: "row",
              marginTop: 2,
            }}
          >
            <div
              style={{
                flex: 0.6,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
                fontSize: downloading ? 5 : 10,
              }}
            ></div>
            <div
              style={{
                flex: 0.4,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
                fontSize: downloading ? 5 : 10,
              }}
            >
              <div style={{ fontWeight: "bold" }}>PHYSICAL ADDRESS:</div>
              <div style={{ marginTop: 2 }}>{address}</div>
            </div>
          </div>
          <div
            style={{
              borderStyle: "solid",
              width: "80%",
              display: "flex",
              marginTop: 5,
              marginBottom: 5,
              position: "relative",
            }}
          >
            <img
              style={{
                height: downloading ? 40 : 40,
                position: "absolute",
                top: downloading ? 10 : 20,
              }}
              alt="marker"
              src={require("./components/pdf images/marker.png")}
            />
          </div>
          <div
            style={{
              width: "80%",
              display: "flex",
              flexDirection: "row",
              marginTop: 10,
            }}
          >
            <div
              style={{
                flex: 0.7,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
                fontSize: downloading ? 8 : 10,
              }}
            >
              <div style={{ marginLeft: downloading ? 40 : 80 }}>
                MEDICINE DESCRIPTION
              </div>
            </div>
            <div
              style={{
                flex: 0.3,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                textAlign: "right",
                fontSize: downloading ? 6 : 10,
              }}
            ></div>
          </div>
          <div
            style={{
              width: "80%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 50,
              fontSize: 10,
            }}
          >
            {prescriptionText}
          </div>
          <div
            style={{
              borderStyle: "solid",
              width: "80%",
              display: "flex",
              marginTop: 3,
              marginBottom: 5,
              position: "relative",
            }}
          />
          <div
            style={{
              fontSize: downloading ? 4 : 7,
              width: "80%",
              lineHeight: 1.2,
            }}
          >
            {doctorData ? doctorData.footerStatement : "loading"}
          </div>
          <div
            style={{
              width: "80%",
              display: "flex",
              flexDirection: "row",
              marginTop: 5,
            }}
          >
            <div
              style={{
                flex: 0.6,
                display: "flex",
                flexDirection: "column",
                fontSize: 5,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  borderStyle: "dashed",
                  width: "70%",
                  borderWidth: 0.5,
                  marginTop: 15,
                  marginBottom: 2,
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {statusCheck === "approved" ? (
                  <div style={{ position: "absolute", top: -22, fontSize: 6 }}>
                    {doctorData ? (
                      doctorData.fullTitle === "Dr. Chuma Makunga" ? (
                        <img
                          alt="sig"
                          src={require("./Dr_Makunga__e-signature.png")}
                          style={{ height: 50 }}
                        />
                      ) : (
                        <img
                          alt="sig"
                          src={require("../../../../images/Dr B Signature.png")}
                          style={{ height: 30 }}
                        />
                      )
                    ) : (
                      <img
                        alt="sig"
                        src={require("../../../../images/Dr B Signature.png")}
                        style={{ height: 30 }}
                      />
                    )}
                  </div>
                ) : (
                  <div style={{ position: "absolute", top: -10, fontSize: 6 }}>
                    {statusCheck === "declined" ? "declined" : "pending"}
                  </div>
                )}
              </div>
              <div>
                {" "}
                {doctorData ? doctorData.fullTitle : "Dr. B MC LAUGHLIN"}
              </div>

              <div>
                Reg No: {doctorData ? doctorData.hspcaRegNo : "loading"}
              </div>
              <div>
                Practice No: {doctorData ? doctorData.practiceNo : "loading"}
              </div>
            </div>
            <div
              style={{
                flex: 0.4,
                display: "flex",
                flexDirection: "column",
                fontSize: 6,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  width: "70%",
                  marginTop: 10,
                  marginBottom: 5,
                }}
              />
              <div>{date}</div>
            </div>
          </div>
          {statusCheck === "approved" ? (
            downloading ? null : (
              <BaseButtons
                label={"DOWNLOAD"}
                size={150}
                onClick={() => generatePDF()}
              />
            )
          ) : null}
          {downloading ? null : (
            <div style={{ position: "fixed", top: 60, left: 20 }}>
              <CloseButton label={"X"} onClick={onCloseClick} />
            </div>
          )}
          {downloading ? null : <div style={{ height: 100 }} />}
        </div>
      </div>
    );
  };

  const checkDataAnswer = (infoArray) => {
    infoArray.map(
      (check) => check.approved === "pending" && setPendingCheck(false)
    );
  };
  useEffect(() => {
    fetch(
      "https://merry-jane-api.onrender.com/prescription/fetchUserPrescriptions",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: auth.customerUserData.idDoc,
        }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setData(resData);
      });
  }, []);

  useEffect(() => {
    if (data) {
      checkDataAnswer(data.data);
    }
  }, [data]);
  return (
    <div
      style={{
        // display: "flex",

        height: "100%",
        width: "100%",
        overflow: "scroll",
        zIndex: 1,
        position: "relative",
        // marginTop: 20,
      }}
    >
      <div
        style={{
          height: 35,
          width: "100%",
          backgroundColor: BlackAndWhite.secondary,
          position: "fixed",
          top: 50,
          display: "flex",
          flexDirection: "row",
          zIndex: 0,
          overflow: "hidden",
        }}
      >
        {/* <div
          onClick={() => setTabState("pending")}
          style={{
            flex: 0.33,
            backgroundColor:
              tabState === "pending" ? BlackAndWhite.primary : "#00000050",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color:
              tabState === "pending"
                ? BlackAndWhite.secondary
                : BlackAndWhite.secondary,
            fontWeight: tabState === "pending" ? "bold" : "normal",
            transition: "all 0.5s",
          }}
        >
          PENDING
        </div> */}
        <div
          onClick={() => setTabState("approved")}
          style={{
            flex: 0.5,
            color:
              tabState === "approved"
                ? BlackAndWhite.secondary
                : BlackAndWhite.secondary,
            backgroundColor:
              tabState === "approved" ? BlackAndWhite.primary : "#00000050",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: tabState === "approved" ? "bold" : "normal",
            transition: "all 0.5s",
          }}
        >
          APPROVED
        </div>
        <div
          onClick={() => setTabState("expired")}
          style={{
            flex: 0.5,
            color:
              tabState === "expired"
                ? BlackAndWhite.secondary
                : BlackAndWhite.secondary,
            backgroundColor:
              tabState === "expired" ? BlackAndWhite.primary : "#00000050",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: tabState === "expired" ? "bold" : "normal",
            transition: "all 0.5s",
          }}
        >
          EXPIRED
        </div>
      </div>
      {pdfDisplay ? (
        <PrescriptionPdf
          onCloseClick={() => setPdfDisplay(false)}
          height={600}
          idNumber={selectedExeption ? selectedExeption.patientId : null}
          patientName={
            auth
              ? auth.customerUserData.firstName +
                " " +
                auth.customerUserData.lastName
              : null
          }
          dob={auth ? auth.customerUserData.dateOfBirth : null}
          diagnosis={selectedExeption ? selectedExeption.diagnosis : null}
          address={
            auth
              ? auth.customerUserData.street +
                " " +
                auth.customerUserData.suburb +
                " " +
                auth.customerUserData.town
              : "no data"
          }
          prescriptionText={
            selectedExeption ? selectedExeption.prescription : null
          }
          statusCheck={selectedExeption ? selectedExeption.approved : null}
          date={dateFind}
        />
      ) : data ? (
        <>
          <div style={{ height: 80 }}></div>
          {tabState === "approved"
            ? data
              ? data.data.map((data, index) => {
                  return (
                    <div key={index}>
                      <PrescriptionCard
                        invert={true}
                        onClick={() => {
                          setSelectedException(data);
                          setPdfDisplay(true);
                        }}
                        isCustomer={isCustomer}
                        isDoc={isDoc}
                        idKey={index}
                        address={
                          auth
                            ? auth.customerUserData.street +
                              " " +
                              auth.customerUserData.suburb +
                              " " +
                              auth.customerUserData.town
                            : "no data"
                        }
                        healerName={data.doctor}
                        prescriptionNumber={data.prescriptionNum}
                        prescriptionText={data.prescription}
                        reasonText={data.diagnosis}
                        patientName={
                          auth
                            ? auth.customerUserData.firstName +
                              " " +
                              auth.customerUserData.lastName
                            : "loading"
                        }
                        dob={
                          auth ? auth.customerUserData.dateOfBirth : "loading"
                        }
                        idNumber={
                          auth ? auth.customerUserData.idDoc : "loading"
                        }
                        diagnosis={data.diagnosis}
                        date={"DATE: " + data.date}
                        idCheckMark={data.id}
                        statusCheck={data.approved}
                      />
                    </div>
                  );
                })
              : null
            : null}
          {tabState === "pending"
            ? data
              ? data.dataPending.map((data, index) => {
                  return (
                    <div key={index}>
                      <PrescriptionCard
                        invert={true}
                        onClick={() => {
                          setSelectedException(data);
                          setPdfDisplay(true);
                        }}
                        isCustomer={isCustomer}
                        isDoc={isDoc}
                        idKey={index}
                        address={
                          auth
                            ? auth.customerUserData.street +
                              " " +
                              auth.customerUserData.suburb +
                              " " +
                              auth.customerUserData.town
                            : "no data"
                        }
                        healerName={data.doctor}
                        prescriptionNumber={data.prescriptionNum}
                        prescriptionText={data.prescription}
                        reasonText={data.diagnosis}
                        patientName={
                          auth
                            ? auth.customerUserData.firstName +
                              " " +
                              auth.customerUserData.lastName
                            : "loading"
                        }
                        dob={
                          auth ? auth.customerUserData.dateOfBirth : "loading"
                        }
                        idNumber={
                          auth ? auth.customerUserData.idDoc : "loading"
                        }
                        diagnosis={data.diagnosis}
                        date={"DATE: " + data.date}
                        idCheckMark={data.id}
                        statusCheck={data.approved}
                      />
                    </div>
                  );
                })
              : null
            : null}
          {tabState === "expired"
            ? data
              ? data.dataExpired.map((data, index) => {
                  return (
                    <div key={index}>
                      <PrescriptionCard
                        invert={true}
                        onClick={() => {
                          setSelectedException(data);
                          setPdfDisplay(true);
                        }}
                        isCustomer={isCustomer}
                        isDoc={isDoc}
                        idKey={index}
                        address={
                          auth
                            ? auth.customerUserData.street +
                              " " +
                              auth.customerUserData.suburb +
                              " " +
                              auth.customerUserData.town
                            : "no data"
                        }
                        healerName={data.doctor}
                        prescriptionNumber={data.prescriptionNum}
                        prescriptionText={data.prescription}
                        reasonText={data.diagnosis}
                        patientName={
                          auth
                            ? auth.customerUserData.firstName +
                              " " +
                              auth.customerUserData.lastName
                            : "loading"
                        }
                        dob={
                          auth ? auth.customerUserData.dateOfBirth : "loading"
                        }
                        idNumber={
                          auth ? auth.customerUserData.idDoc : "loading"
                        }
                        diagnosis={data.diagnosis}
                        date={"DATE: " + data.date}
                        idCheckMark={data.id}
                        statusCheck={data.approved}
                      />
                    </div>
                  );
                })
              : null
            : null}
        </>
      ) : (
        <div
          style={{
            marginTop: 80,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          LOADING . . .
        </div>
      )}

      {medicalCheck && pendingCheck ? (
        medicalCheck.length <= 3 ? null : data ? (
          data.data.length <= 1 ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 40,
                position: "fixed",
                bottom: 100,
              }}
            >
              {/* <BaseButtons
                label={"REQUEST PRECSRIPTION"}
                size={300}
                onClick={() => setNewRequest(true)}
              /> */}
            </div>
          ) : null
        ) : data ? null : null
      ) : null}
      <PrescriptionRequestPopUp
        display={newRequest}
        onCloseClick={() => setNewRequest(false)}
        auth={auth}
        setNewRequest={setNewRequest}
        setToastPop={setToastPop}
        setMessage={setMessage}
        setScreenDisplayed={setScreenDisplayed}
      />
      <div
        style={{
          position: "fixed",
          top: -0,
          zIndex: 20,
          display: "flex",
          backgroundColor: "#000000cc",
          width: "100%",
          height: 48,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ color: "#FFFFFF", fontSize: 20, marginLeft: 20 }}>
          Prescriptions
        </div>
        <div style={{ marginRight: 10 }}>
          <CloseButton label={"?"} invert={true} onClick={onHelpClick} />
        </div>
      </div>
      <div style={{ height: 80 }} />
    </div>
  );
};

export default Prescription;
