import React, { useCallback, useState } from "react";

import {
  AdminEditor,
  AdminPortal,
  CannaPosAccess,
  CannaPosPortal,
  CustomerPortal,
  DirectLink,
  DocAccess,
  DocPortal,
  Facilities,
  Menu2,
  Menus,
  OwnerAdminReader,
  PractionerRegistration,
  StoreOwnerPortal,
  SupportAccess,
  SupportPortal,
  Website,
} from "./modules";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthContext } from "./context/auth-context";
import { AdminContext } from "./context/admin-context";

function App() {
  const [loggedin, setLoggedIn] = useState(false);
  const [loggedInAdmin, setLoggedInAdmin] = useState(false);
  const [adminData, setAdminData] = useState(null);
  const [userData, setUserData] = useState({});
  const login = useCallback(() => {
    setLoggedIn(true);
  }, []);
  const loginAdmin = useCallback((item) => {
    setLoggedInAdmin(true);
  }, []);
  const logout = useCallback(() => {
    setLoggedIn(false);
  }, []);
  const logoutAdmin = useCallback(() => {
    setLoggedInAdmin(false);
  }, []);

  const adminDataSet = useCallback((item) => {
    setAdminData(item);
  }, []);

  const userDataSet = useCallback((user) => {
    setUserData(user);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isloggedIn: loggedin,
        login: login,
        logout: logout,
        customerUserData: userData,
        customerLoadData: userDataSet,
      }}
    >
      <AdminContext.Provider
        value={{
          isloggedIn: loggedInAdmin,
          login: loginAdmin,
          logout: logoutAdmin,
          adminUserInfo: adminDataSet,
          adminUserData: adminData,
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Website />} />
            <Route path="/facilities" element={<Facilities />} />
            <Route path="/storeLocations" element={<DirectLink />} />
            <Route
              path="/customer"
              element={<CustomerPortal userData={userData ? userData : null} />}
            />

            <Route path="/adminPortal" element={<AdminPortal />} />
            <Route path="/adminEditor" element={<AdminEditor />} />
            <Route path="/ownerAdminReader" element={<OwnerAdminReader />} />
            <Route path="/supportPortal" element={<SupportPortal />} />
            <Route path="/supportAccess" element={<SupportAccess />} />
            <Route path="/cannaPos" element={<CannaPosPortal />} />
            <Route path="/cannaPosAccess" element={<CannaPosAccess />} />
            <Route path="/docPortal" element={<DocPortal />} />
            <Route path="/docAccess" element={<DocAccess />} />
            <Route path="/menus" element={<Menus />} />
            <Route path="/menu2" element={<Menu2 />} />
            <Route path="/storeOwner" element={<StoreOwnerPortal />} />
            <Route
              path="/practionerRegistration"
              element={<PractionerRegistration />}
            />
          </Routes>
        </BrowserRouter>
      </AdminContext.Provider>
    </AuthContext.Provider>
  );
}

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(<App />);

export default App;
