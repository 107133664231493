import React, { useEffect, useState } from "react";
import { CategoryListItem, ProductListHeader } from "./components";
import ProductListCard from "./components/product-list-card/ProductListCard";
import { BaseButtons } from "../../../../../../components/atoms";
import { LoadingPopUp } from "../../../../../../components";
import ListViewProductEdit from "../list-view-product-edit/ListViewProductEdit";

const ListView = ({
  width,
  data,
  setToastPop,
  setMessage,
  filterOption,
  setSectionDisplay,
  setSelectedProduct,
  setCategoryEditPop,
  setSubcategoryEditPop,
  setRefresh,
  refresh,
  search,
}) => {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState(null);
  const [selectedProduct, setSelectedProd] = useState(null);
  const [newProductsLoad, setNewProdctsLoad] = useState(false);
  const WorkOutMargin = (product) => {
    const productBaseMargin =
      Number(product.costPrice) - Number(product.baseCostPrice);
    return productBaseMargin.toFixed(2);
  };
  let checkSubCategory = () => {
    if (selectedProduct) {
      return selectedProduct.productSubType;
    } else {
      return "";
    }
  };
  const NameAssemble = (product) => {
    if (product.flavour !== "None" && product) {
      let name =
        product.productName +
        " | " +
        product.productSubType +
        " | " +
        product.thcType +
        " | " +
        product.thcContent +
        " | " +
        product.flavour;

      return name;
    } else {
      let name =
        product.productName +
        " | " +
        product.productSubType +
        " | " +
        product.thcType +
        " | " +
        product.thcContent;

      return name;
    }
  };
  const getProducts = async () => {
    setLoading(true);
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/getProductsAdded",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const respnseData = await response.json();
      if (respnseData.product) {
        // const wait = await updateListFetch();
        setProducts(respnseData.product);
        setLoading(false);
      } else if (respnseData.message) {
        setProducts([]);
        setLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      setLoading(false);
    }
  };

  const SearchParameters = () => {};

  useEffect(() => {
    getProducts();
  }, [newProductsLoad]);

  return (
    <div
      style={{
        width: "100%",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        position: "relative",
      }}
    >
      <div style={{ position: "absolute", left: 120, top: -65, zIndex: 100 }}>
        <BaseButtons
          label={"Refresh"}
          size={100}
          onClick={() => {
            setNewProdctsLoad(newProductsLoad ? false : true);
            setRefresh(refresh ? false : true);
          }}
          height={35}
        />
      </div>
      <ProductListHeader />
      <div
        style={{
          height: "100%",
          width: "100%",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: 600,
            width: "100%",

            scrollbarWidth: "none",
          }}
        >
          {filterOption === "product"
            ? products
              ? products.map((product, index) => {
                  return NameAssemble(product)
                    .toLocaleLowerCase()
                    .match(search) ? (
                    <div key={index} style={{ width: "100%" }}>
                      <ProductListCard
                        productName={NameAssemble(product)}
                        image={product.imageUrl}
                        sku={product.sku}
                        unitMeasure={product.quantityDenomiation}
                        subCat={product.productSubType}
                        size={product.size}
                        thcType={product.thcType}
                        marginPrice={
                          product ? WorkOutMargin(product) : "loading"
                        }
                        category={product.productType}
                        productPref={product.flavour}
                        productFeature={product.strain}
                        retail={product.retailPriceInc}
                        onClick={() => {
                          setSelectedProd(product);
                        }}
                      />
                    </div>
                  ) : null;
                })
              : null
            : null}
          {filterOption === "categories"
            ? data.map((category, index) => {
                return (
                  <div key={index} style={{ width: "100%" }}>
                    <CategoryListItem
                      refresh={refresh}
                      setRefresh={setRefresh}
                      onEditClick={() => setCategoryEditPop(category)}
                      onDeleteClick={() => setCategoryEditPop(category)}
                      category={category}
                      setToastPop={setToastPop}
                      setMessage={setMessage}
                      selectedProduct={selectedProduct}
                      setSelectedProd={setSelectedProd}
                      setSubcategoryEditPop={setSubcategoryEditPop}
                    />
                  </div>
                );
              })
            : null}
          <div style={{ height: 120 }} />
        </div>
      </div>
      <LoadingPopUp display={loading} />
      <ListViewProductEdit
        data={data}
        loading={loading}
        display={selectedProduct}
        setSelectedProd={setSelectedProd}
        selectedProduct={selectedProduct}
        setLoading={setLoading}
        setMessage={setMessage}
        setToastPop={setToastPop}
        setNewLoad={setNewProdctsLoad}
        newLoad={newProductsLoad}
        refresh={newProductsLoad}
        setRefresh={setNewProdctsLoad}
      />
    </div>
  );
};

export default ListView;
