import React from "react";
import { BlackAndWhite } from "../../../../../../themes/Colors";

const TabMobileButton = ({
  title,
  onClick,
  icon,
  selected,
  icon2,
  check,
  invert,
  height,
}) => {
  return (
    <div
      onClick={onClick}
      style={{
        cursor: "pointer",
        height: height ? height : 60,
        width: title ? 110 : height ? height : 60,
        borderStyle: selected && check ? "solid" : null,
        borderRadius: 8,
        color: BlackAndWhite.secondary,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        backgroundColor: selected
          ? invert
            ? "#000000"
            : BlackAndWhite.secondary
          : title
          ? "#FFFFFF"
          : null,
        borderWidth: 2,
      }}
    >
      {title ? (
        <div
          style={{
            color: selected ? BlackAndWhite.secondary : BlackAndWhite.primary,
          }}
        >
          {title}
        </div>
      ) : (
        <img
          alt="icon"
          src={selected ? icon2 : icon}
          style={{ height: 35, width: 35 }}
        />
      )}
    </div>
  );
};

export default TabMobileButton;
