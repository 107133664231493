import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../themes/Colors";
import { BaseButtons } from "../atoms";
import PrescriptionCard from "../../modules/customer-portal/components/prescription/components/PrescriptionCard";

const PatientCard = ({
  firstName,
  lastName,
  gender,
  cellNumber,
  email,
  patientNo,
  idDoc,
  dateOfBirth,
  town,
  suburb,
  type,
  street,
  isDoc,
  isCustomer,
  dataId,
  onClickClient,
  docCheck,
}) => {
  const [displayMore, setDisplayMore] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hover, setHover] = useState(false);

  const updateHandler = async (status, id) => {
    setLoading(true);
    // https://merry-jane-api.onrender.com/prescription/updateprescriptionbyId
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/prescription/updateprescriptionbyId",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: id,
            status: status,
          }),
        }
      );

      const responseData = await response.json();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return;
    }
  };

  const UpdateRefeshlist = () => {
    fetch(
      "https://merry-jane-api.onrender.com/prescription/fetchUserPrescriptions",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: idDoc,
        }),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((resData) => {
        setData(resData.data);
      });
  };

  // useEffect(() => {
  //   fetch(
  //     "https://merry-jane-api.onrender.com/prescription/fetchUserPrescriptions",
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         id: idDoc,
  //       }),
  //     }
  //   )
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((resData) => {
  //       setData(resData.data);
  //     });
  // }, [loading]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: displayMore ? 750 : 240,
      }}
    >
      <div
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        onClick={docCheck ? onClickClient : null}
        style={{
          height: 220,
          width: "80%",
          borderRadius: 10,
          color: BlackAndWhite.primary,
          display: "flex",
          overflow: "hidden",
          backgroundColor: BlackAndWhite.secondary,
          WebkitBoxShadow: "0px 0px 10px 1px #00000033",
          borderStyle: "solid",
          borderWidth: hover ? 4 : 0,
          borderColor: "#FAA919",
          cursor: "pointer",
          transition: "all 0.1s",
        }}
      >
        {/* patinet base info */}
        <div
          style={{
            flex: 0.3,
            display: "flex",
            flexDirection: "column",
            //   width: 200,
            justifyContent: "center",
            alignItems: "center",
            textAlign: "left",
          }}
        >
          <div
            style={{
              width: "100%",
              marginLeft: 40,
              fontSize: 18,
              fontWeight: "bolder",
              marginBottom: 10,
            }}
          >
            Patient Information :
          </div>
          <div
            style={{
              width: "100%",
              marginLeft: 40,
              fontSize: 14,
              fontWeight: "bolder",
            }}
          >
            Patient Name:
          </div>
          <div
            style={{
              width: "100%",
              marginLeft: 80,
              marginBottom: 10,
              marginTop: 10,
            }}
          >
            {firstName + " " + lastName}
          </div>
          <div
            style={{
              width: "100%",
              marginLeft: 40,
              fontSize: 14,
              fontWeight: "bolder",
              marginBottom: 10,
            }}
          >
            Cell Number:
          </div>
          <div style={{ width: "100%", marginLeft: 80, marginBottom: 10 }}>
            {cellNumber}
          </div>
          <div
            style={{
              width: "100%",
              marginLeft: 40,
              fontSize: 14,
              fontWeight: "bolder",
            }}
          >
            Email :
          </div>
          <div
            style={{
              width: "100%",
              marginLeft: 80,
              marginBottom: 10,
              marginTop: 10,
            }}
          >
            {email}
          </div>
        </div>
        {/* second zone with id info */}
        <div
          style={{
            flex: 0.45,
            display: "flex",
            flexDirection: "column",
            //   width: 200,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              marginLeft: 40,
              height: 20,
              fontWeight: "bolder",
              marginBottom: 10,
            }}
          ></div>
          <div
            style={{
              width: "100%",
              marginLeft: 40,
              fontSize: 14,
              fontWeight: "bolder",
            }}
          >
            Patient No:
          </div>
          <div
            style={{
              width: "100%",
              marginLeft: 80,
              marginBottom: 10,
              marginTop: 10,
            }}
          >
            {patientNo}
          </div>
          <div
            style={{
              width: "100%",
              marginLeft: 40,
              fontSize: 14,
              fontWeight: "bolder",
            }}
          >
            Patient ID Num / Passport:
          </div>
          <div
            style={{
              width: "100%",
              marginLeft: 80,
              marginBottom: 10,
              marginTop: 10,
            }}
          >
            {idDoc}
          </div>
          <div
            style={{
              width: "100%",
              marginLeft: 40,
              fontSize: 14,
              fontWeight: "bolder",
            }}
          >
            Date of Birth:
          </div>
          <div
            style={{
              width: "100%",
              marginLeft: 80,
              marginBottom: 10,
              marginTop: 10,
            }}
          >
            {dateOfBirth}
          </div>
        </div>
        {/* last zone with customer type */}
        <div
          style={{
            flex: 0.25,
            display: "flex",
            flexDirection: "column",
            //   width: 200,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              marginLeft: 40,
              height: 20,
              fontWeight: "bolder",
              marginBottom: 10,
            }}
          ></div>
          <div
            style={{
              width: "100%",
              marginLeft: 40,
              fontSize: 14,
              fontWeight: "bolder",
            }}
          >
            Gender:
          </div>
          <div
            style={{
              width: "100%",
              marginLeft: 80,
              marginBottom: 10,
              marginTop: 10,
            }}
          >
            {gender}
          </div>
          <div
            style={{
              width: "100%",
              marginLeft: 40,
              fontSize: 14,
              fontWeight: "bolder",
            }}
          >
            Area:
          </div>
          <div
            style={{
              width: "100%",
              marginLeft: 80,
              marginBottom: 10,
              marginTop: 10,
              fontSize: 14,
            }}
          >
            {town + " " + suburb}
          </div>
          <div
            style={{
              width: "100%",
              marginLeft: 40,
              fontSize: 14,
              fontWeight: "bolder",
            }}
          >
            Account type:
          </div>
          <div
            style={{
              width: "100%",
              marginLeft: 80,
              marginBottom: 10,
              marginTop: 10,
              fontSize: 14,
            }}
          >
            {type}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientCard;

{
  /* <div
style={{
  height: displayMore ? 450 : 80,
  borderStyle: "solid",
  backgroundColor: BlackAndWhite.secondary,
  marginTop: 4,
  transition: "height 0.4s",
  width: "80%",
  borderBottomRightRadius: 10,
  borderBottomLeftRadius: 10,
  //   display: "flex",
  flexDirection: "column",
  //   alignItems: "center",
  //   justifyContent: "center",
  position: "relative",
  overflow: displayMore ? "scroll" : "hidden",
  zIndex: 0,
}}
>
<div style={{ position: "absolute", top: 0, right: 20 }}>
  <BaseButtons
    label={"Prescription"}
    onClick={() => setDisplayMore(displayMore ? false : true)}
  />
</div>
<div style={{ height: 80 }} />
{displayMore ? (
  data || loading ? (
    data.map((data, index) => {
      return (
        <div key={index}>
          <PrescriptionCard
            id={data.id}
            invert={true}
            isCustomer={isCustomer}
            isDoc={isDoc}
            sizeWidth={"90%"}
            idKey={index}
            address={street + " " + suburb + " " + town}
            healerName={data.doctor}
            prescriptionNumber={data.prescriptionNum}
            prescriptionText={data.prescription}
            reasonText={data.diagnosis}
            patientName={firstName + " " + lastName}
            dob={dateOfBirth}
            idNumber={idDoc}
            diagnosis={data.diagnosis}
            date={"DATE: " + data.date}
            statusCheck={data.approved}
            onApproveClick={() => {
              updateHandler("approved", data.id);
              UpdateRefeshlist();
            }}
            onDeclineClick={() => {
              updateHandler("declined", data.id);
              UpdateRefeshlist();
            }}
            UpdateRefeshlist={UpdateRefeshlist}
          />
        </div>
      );
    })
  ) : (
    <div>LOADING . . .</div>
  )
) : null}

</div> */
}
