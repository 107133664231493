import React, { useState, useEffect } from "react";
import {
  DashboardDisplayButton,
  DropDownDisplayArray,
  GeneratedReportCard,
  ModalLayout,
  RecieptItem,
  RecipetItemReportCard,
  StoreCard,
} from "./components";
import {
  CalenderSelector,
  LoadingPopUp,
  ToastNotification,
} from "../../../../components";
import { BlackAndWhite } from "../../../../themes/Colors";
import {
  BaseButtons,
  CloseButton,
  TextInputFieldSearch,
} from "../../../../components/atoms";
import { Bar, Pie, Line, Stacked, Doughnut } from "react-chartjs-2";

const Reporting = ({
  subSelection,
  userDisplay,
  setUserdisplay,
  setSubSelection,
  auth,
}) => {
  const [receiptsArray, setReceiptsArray] = useState(null);
  const [refundArray, setRefundArray] = useState(null);
  const [exchangeArray, setExchangeArray] = useState(null);

  const [generatedReports, setGeneratedReports] = useState(null);

  const [refresh, setRefresh] = useState(false);

  const [search, setSearch] = useState("");

  const [modalSelection, setModalSelection] = useState("");

  const [selectedReportsDisplay, setSelectedReportsDisplay] =
    useState("stores");

  const [selectedReportsList, setSelectedReportList] = useState("reports");

  const [startingDate, setStartingDate] = useState(new Date());

  const [startingDay, setStartingDay] = useState(
    new Date().toISOString().substring(8, 10)
  );
  const [startingMonth, setStartingMonth] = useState(
    new Date().toISOString().substring(5, 7)
  );
  const [startingYear, setStartingYear] = useState(
    new Date().toISOString().substring(0, 4)
  );

  const [dateSelectorPopUp, setDateSelectorPopUp] = useState(false);

  const [dayDropDown, setDayDropDown] = useState(false);
  const [monthDropDown, setMonthDropDown] = useState(false);
  const [yearDropDown, setYearDropDown] = useState(false);

  const [periodSelected, setPeriodSelected] = useState("7");

  const [storeArray, setStoreArray] = useState(null);

  const [selectedStore, setSelectedStore] = useState(null);

  const [productArray, setProductArray] = useState(null);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [refreshProduct, setRefreshProduct] = useState(false);

  const [cashregisterReport, setCashregisterReport] = useState(null);

  const [graphDisplayOption, setGraphDisplayOption] = useState("net sales");

  const [generatedReportView, setGeneratedReportView] = useState(null);

  const [loading, setLoading] = useState(false);
  const [toastPop, setToastPop] = useState(false);
  const [message, setMessage] = useState("");

  const [netSales, setNetSales] = useState(0);
  const [netSalesEx, setNetSalesEx] = useState(0);
  const [refundsReport, setRefundReport] = useState(0);
  const [discountReport, setDiscountsReport] = useState(0);
  const [mjFee, setMjfee] = useState(0);
  const [prescriptionFee, setPresciption] = useState(0);
  const [cogReport, setCogReport] = useState(0);
  const [mjWholeSale, setMjWholeSaleReport] = useState(0);
  const [cashReport, setCashReport] = useState(0);
  const [cardReport, setCardReport] = useState(0);
  const [vatReport, setVatReport] = useState(0);

  const [dateSelection, setDateSelection] = useState(null);

  const [salesToDate, setSalesTodate] = useState("loading...");
  const [salesToday, setSalesToday] = useState("loading...");
  const [sales7Days, setSales7Days] = useState("loading...");
  const [sales30Days, setSales30Days] = useState("loading...");

  const labels = () => {
    let labelsArray = [];
    let totalsArray = [];

    if (cashregisterReport) {
      for (let index = 0; index < cashregisterReport.length; index++) {
        const element = cashregisterReport[index];

        labelsArray.push(element.closedAt);
        totalsArray.push(element.TotalInc);
      }
      return {
        data: {
          labels: labelsArray,
          valuesArray: totalsArray,
        },
      };
    } else {
      return {
        data: {
          labels: labelsArray,
          valuesArray: totalsArray,
        },
      };
    }
  };

  const data = {
    labels: labels().data.labels,
    datasets: [
      {
        label: "Sales report", // Setting up the label for the dataset
        labels: ["test"],
        backgroundColor: [
          "#48A14D",
          "#008080",
          "#B33F40",
          "#049BE5",
          "#9E3AC3",
        ], // Setting up the background color for the dataset
        borderColor: ["#48A14D", "#008080", "#B33F40", "#049BE5", "#9E3AC3"], // Setting up the border color for the dataset
        data: labels().data.valuesArray,

        // Setting up the data for the dataset
      },
      // {
      //   label: "Sales report", // Setting up the label for the dataset
      //   labels: ["test"],
      //   backgroundColor: [
      //     "#48A14D",
      //     "#008080",
      //     "#B33F40",
      //     "#049BE5",
      //     "#9E3AC3",
      //   ], // Setting up the background color for the dataset
      //   borderColor: ["#48A14D", "#008080", "#B33F40", "#049BE5", "#9E3AC3"], // Setting up the border color for the dataset
      //   data: [
      //     11000, 3000, 350, 4090, 2000, 6000, 700, 12000, 800, 3000, 11000,
      //     3000, 350, 4090, 2000, 6000, 700, 12000, 800, 3000, 11000, 3000, 350,
      //     4090, 2000, 6000, 700, 12000, 800, 3000,
      //   ],

      //   // Setting up the data for the dataset
      // },
    ],
  };

  const getStores = async () => {
    // setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/reportingStats/getStores",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: auth.userId,
          }),
        }
      );
      const respnseData = await response.json();

      if (respnseData.success) {
        // setLoading(false);
        setStoreArray(respnseData.stores);
      }
    } catch (err) {
      console.log("test", err);
    }
  };

  const getGeneralStats = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/reportingStats/reportingGeneralStats",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: auth.userId,
          }),
        }
      );
      const respnseData = await response.json();

      if (respnseData.success) {
        setSalesTodate(respnseData.salesTotal);
        setSalesToday(respnseData.salesTodayTotal);
        setSales7Days(respnseData.sales7DaysTotal);
        setSales30Days(respnseData.sales30DaysTotal);
        setLoading(false);
      }
    } catch (err) {
      console.log("test", err);
    }
  };

  const getSalesReports = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/reportingStats/getStoreSalesReports",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: auth.userId,
            storeId: selectedStore.storeId,
            startingDate: {
              day: Number(startingDay),
              month: Number(startingMonth),
              year: Number(startingYear),
            },
            period: periodSelected,
          }),
        }
      );
      const respnseData = await response.json();

      if (respnseData.success) {
        setNetSales(Number(respnseData.netSalesIncl));
        setNetSalesEx(Number(respnseData.netSalesEx));
        setRefundReport(Number(respnseData.refundsTotal));
        setDiscountsReport(Number(respnseData.discountsAmount));
        setMjfee(Number(respnseData.merryJaneServiceFee));
        setPresciption(Number(respnseData.prescriptionFee));
        setCogReport(Number(respnseData.Cog));
        setMjWholeSaleReport(Number(respnseData.merryJaneWholesaleEarnings));
        setCashReport(Number(respnseData.cashSales));
        setCardReport(Number(respnseData.cardSales));
        setVatReport(Number(respnseData.vat));
        setCashregisterReport(respnseData.registerReports);
        setReceiptsArray(respnseData.receiptList);
        setRefundArray(respnseData.refundList);
        setExchangeArray(respnseData.exchangeList);
        setDateSelection(respnseData.dateSelection);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("test", err);
    }
  };

  const getProducts = async () => {
    // authentication or login function heal
    //this code is used for Ui test
    // https://merry-jane-api.onrender.com/cannaPos/reg

    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/productUpload/getProductsAdded",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const respnseData = await response.json();
      if (respnseData.product) {
        // const wait = await updateListFetch();
        setProductArray(respnseData.product);
      } else if (respnseData.message) {
        setProductArray([]);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const getProductsSalesReports = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/reportingStats/getProductReports",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: auth.userId,
            storeId: selectedStore.storeId,
            startingDate: {
              day: Number(startingDay),
              month: Number(startingMonth),
              year: Number(startingYear),
            },
            period: periodSelected,
            productId: selectedProduct._id,
          }),
        }
      );
      const respnseData = await response.json();

      if (respnseData.success) {
        setNetSales(Number(respnseData.netSalesIncl));
        setNetSalesEx(Number(respnseData.netSalesEx));
        setRefundReport(Number(respnseData.refundsTotal));
        setDiscountsReport(Number(respnseData.discountsAmount));
        setMjfee(Number(respnseData.merryJaneServiceFee));
        setPresciption(Number(respnseData.prescriptionFee));
        setCogReport(Number(respnseData.Cog));
        setMjWholeSaleReport(Number(respnseData.merryJaneWholesaleEarnings));
        setCashReport(Number(respnseData.cashSales));
        setCardReport(Number(respnseData.cardSales));
        setVatReport(Number(respnseData.vat));
        setCashregisterReport(respnseData.registerReports);
        setReceiptsArray(respnseData.receiptList);
        setRefundArray(respnseData.refundList);
        setExchangeArray(respnseData.exchangeList);

        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("test", err);
    }
  };
  const NumberSpace = (value) => {
    let checkValue;
    //   if (value) {
    //     return value.substring(0, 1) + " " + value.substring(2, 20);
    //   } else {
    return Number(new Intl.NumberFormat().format(value)).toFixed(2);
    // if (value.match(Number(value).toFixed(2))) {
    //   checkValue = value.replace(
    //     /(?:(^\d{1,3})(?=(?:\d{3})*$)|(\d{3}))(?!$)/gm,
    //     "$1 $2"
    //   );
    // } else if (value.match(Number(value).toFixed(1))) {
    //   checkValue = value.replace(
    //     /(?:(^\d{1,3})(?=(?:\d{3})*$)|(\d{3}))(?!$)/gm,
    //     "$1 $2 " + "0"
    //   );
    // } else {
    //   checkValue =
    //     value.replace(
    //       /(?:(^\d{1,3})(?=(?:\d{3})*$)|(\d{3}))(?!$)/gm,
    //       "$1 $2 "
    //     ) + ".00";
    // }

    // return checkValue;
  };

  const checkPeriod = () => {
    if (periodSelected === "1") {
      return "TODAY'S REPORT";
    } else if (periodSelected === "2") {
      return "YESTERDAY'S REPORT";
    } else if (periodSelected === "7") {
      return "WEEKLY REPORT";
    } else if (periodSelected === "30") {
      return "MONTHLY REPORT";
    } else if (periodSelected === "365") {
      return "YEARLY REPORT";
    }
  };

  const createGeneratedReport = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/reportingStats/createGeneratedReport",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: auth.userId,
            storeName: selectedStore.storeName,
            storeId: selectedStore.storeId,
            date: startingDate,
            period: periodSelected,
            dateFromTo: dateSelection,
            reportType: checkPeriod(),
            requestedBy: auth.firstName + " " + auth.lastName,
            receiptsArray: receiptsArray,
            refundsArray: refundArray,
            exchangesArray: exchangeArray,
            registerReportsArray: cashregisterReport,

            //base report informations

            netSalesIncludingVat: netSales.toFixed(2),
            netSalesExcludingVat: (netSales - netSalesEx).toFixed(2),
            refundsAmount: refundsReport.toFixed(2),
            discounts: discountReport.toFixed(2),
            netSalesInclLessRefunds: (netSales - refundsReport).toFixed(2),
            netSalesExLessRefunds: (
              netSales -
              netSalesEx -
              refundsReport
            ).toFixed(2),

            merryJaneServiceFee: mjFee.toFixed(2),
            prescriptionFee: prescriptionFee.toFixed(2),
            costOfGoodsSold: cogReport.toFixed(2),
            wholesalecostOfGoodsSold: Number(mjWholeSale + cogReport).toFixed(
              2
            ),
            cardTransactions: cardReport.toFixed(2),
            cashReceivedInstore: cashReport.toFixed(2),

            // totals
            totalDeductions: (
              mjFee +
              prescriptionFee +
              mjWholeSale +
              vatReport +
              cogReport
            ).toFixed(2),
            AmountDue: (
              netSales -
              (mjFee + prescriptionFee + mjWholeSale + vatReport + cogReport)
            ).toFixed(2),
            totalServices: (mjFee + prescriptionFee + mjWholeSale).toFixed(2),
          }),
        }
      );
      const respnseData = await response.json();

      if (respnseData.success) {
        setMessage(respnseData.success);
        setToastPop(true);
        setLoading(false);
        // setStoreArray(respnseData.stores);
      } else {
        setMessage(respnseData.message);
        setToastPop(true);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("test", err);
    }
  };

  const getGeneratedReports = async () => {
    // setLoading(true);
    try {
      const response = await fetch(
        "https://merry-jane-api.onrender.com/reportingStats/getStoresGeneratedReports",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: auth.userId,
            storeId: selectedStore.storeId,
          }),
        }
      );
      const respnseData = await response.json();

      if (respnseData.success) {
        // setLoading(false);
        setGeneratedReports(respnseData.generatedReportsList);
      }
    } catch (err) {
      console.log("test", err);
    }
  };

  useEffect(() => {
    if (auth) {
      // here is where the approval fileds will be added for review

      getStores();
    }
  }, []);
  useEffect(() => {
    getGeneralStats();
    getProducts();
  }, []);

  useEffect(() => {
    if (auth && selectedStore) {
      getSalesReports();
      getGeneratedReports();
    }
  }, [selectedStore, refresh, startingDate, periodSelected]);

  useEffect(() => {
    if (auth && selectedStore) {
      getGeneratedReports();
    }
  }, [selectedStore, refresh]);
  useEffect(() => {
    if (auth && selectedProduct && selectedStore) {
      getProductsSalesReports();
    }
  }, [selectedProduct, refreshProduct]);
  useEffect(() => {
    if (startingDate) {
      //this is the day check
      setStartingDay(startingDate.toLocaleString().substring(0, 2));

      //this is the month check
      setStartingMonth(startingDate.toLocaleString().substring(3, 5));

      //this is the year check
      setStartingYear(startingDate.toLocaleString().substring(6, 10));
    }
  }, [startingDate]);
  useEffect(() => {
    if (toastPop) {
      setTimeout(() => {
        setToastPop(false);
      }, 5000);
    }
  }, [toastPop]);
  return (
    <div
      style={{
        flex: 1,

        display: "flex",
        justifyContent: "flex-start",
        overflowY: "hidden",
        overflowX: "hidden",
        flexDirection: "column",
      }}
    >
      {subSelection === "sales" ? (
        <div
          style={{
            marginBottom: 10,
            marginTop: 10,
            display: "flex",
            width: "100%",

            alignItems: "center",
          }}
        >
          {" "}
          <div
            style={{
              marginLeft: 20,
              fontSize: 30,
              fontWeight: "bold",
              marginTop: 15,
            }}
          >
            {subSelection.toUpperCase()}
          </div>
          <div style={{ flex: 1 }} />
          <BaseButtons
            label={"REFRESH"}
            mini={true}
            marginTop={5}
            borderRadius={8}
            marginRight={10}
            minHeight={40}
            height={40}
            size={90}
            onClick={
              modalSelection === "products"
                ? () => setRefreshProduct(refreshProduct ? false : true)
                : () => setRefresh(refresh ? false : true)
            }
          />
          <div
            style={{
              height: 50,
              // borderStyle: "solid",
              // width: 200,
              marginRight: 10,
              paddingLeft: 10,
              paddingRight: 10,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: 20, display: "flex" }}>
              {dateSelectorPopUp ? (
                <CalenderSelector
                  startingDate={startingDate}
                  setStartingDate={setStartingDate}
                  setDateSelectorPopUp={setDateSelectorPopUp}
                />
              ) : (
                <div>
                  <div
                    style={{
                      fontSize: 10,
                      opacity: 0.6,
                      fontWeight: "bold",
                      width: "100%",
                      marginBottom: 3,
                    }}
                  >
                    DATE SELECTOR
                  </div>
                  <BaseButtons
                    label={startingDate.toDateString()}
                    mini={true}
                    minHeight={30}
                    height={30}
                    borderRadius={8}
                    marginTop={5}
                    disable={
                      periodSelected === "1" || periodSelected === "2"
                        ? true
                        : false
                    }
                    onClick={
                      periodSelected === "1" || periodSelected === "2"
                        ? null
                        : () => setDateSelectorPopUp(true)
                    }
                  />
                </div>
              )}

              {/* <div style={{ position: "relative" }}>
                <div
                  style={{
                    fontSize: 10,
                    opacity: 0.6,
                    fontWeight: "bold",
                    width: "100%",
                    marginBottom: 3,
                  }}
                >
                  MONTH
                </div>
                <BaseButtons
                  label={startingMonth}
                  mini={true}
                  marginTop={-1}
                  borderRadius={8}
                  marginRight={10}
                  minHeight={30}
                  height={30}
                  size={60}
                  onClick={() => setMonthDropDown(true)}
                />
                <DropDownDisplayArray
                  setClose={setMonthDropDown}
                  display={monthDropDown}
                  data={[
                    "01",
                    "02",
                    "03",
                    "04",
                    "05",
                    "06",
                    "07",
                    "08",
                    "09",
                    "10",
                    "11",
                    "12",
                  ]}
                  setItem={setStartingMonth}
                />
              </div> */}
              {/* <div style={{ position: "relative" }}>
                <div
                  style={{
                    fontSize: 10,
                    opacity: 0.6,
                    fontWeight: "bold",
                    width: "100%",
                    marginBottom: 3,
                  }}
                >
                  DAY
                </div>
                <BaseButtons
                  label={startingDay}
                  mini={true}
                  marginTop={-1}
                  borderRadius={8}
                  marginRight={10}
                  minHeight={30}
                  height={30}
                  size={60}
                  onClick={() => setDayDropDown(true)}
                />
                <DropDownDisplayArray
                  setClose={setDayDropDown}
                  display={dayDropDown}
                  data={[
                    "01",
                    "02",
                    "03",
                    "04",
                    "05",
                    "06",
                    "07",
                    "08",
                    "09",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                    "19",
                    "20",
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                    "30",
                    "31",
                  ]}
                  setItem={setStartingDay}
                />
              </div> */}

              {/* <div style={{ position: "relative" }}>
                <div
                  style={{
                    fontSize: 10,
                    opacity: 0.6,
                    fontWeight: "bold",
                    width: "100%",
                    marginBottom: 3,
                  }}
                >
                  YEAR
                </div>
                <BaseButtons
                  label={startingYear}
                  mini={true}
                  marginTop={-1}
                  borderRadius={8}
                  marginRight={10}
                  minHeight={30}
                  height={30}
                  size={80}
                  onClick={() => setYearDropDown(true)}
                />
                <DropDownDisplayArray
                  setClose={setYearDropDown}
                  display={yearDropDown}
                  data={["2023", "2024", "2025"]}
                  setItem={setStartingYear}
                />
              </div> */}
            </div>
            <div>
              <div
                style={{
                  fontSize: 10,
                  opacity: 0.6,
                  fontWeight: "bold",
                  width: "100%",
                  marginBottom: 3,
                }}
              >
                PERIOD
              </div>
              <div style={{ display: "flex" }}>
                <BaseButtons
                  label={"TODAY"}
                  mini={true}
                  marginTop={-1}
                  borderRadius={8}
                  marginRight={10}
                  minHeight={30}
                  height={30}
                  size={80}
                  invert={periodSelected === "1" ? false : true}
                  onClick={() => {
                    setPeriodSelected("1");
                    setStartingDate(new Date());
                  }}
                />
                <BaseButtons
                  label={"YESTERDAY"}
                  mini={true}
                  marginTop={-1}
                  borderRadius={8}
                  marginRight={10}
                  minHeight={30}
                  height={30}
                  size={120}
                  invert={periodSelected === "2" ? false : true}
                  onClick={() => {
                    setPeriodSelected("2");
                    setStartingDate(new Date());
                  }}
                />
                <BaseButtons
                  label={"7 DAYS"}
                  mini={true}
                  marginTop={-1}
                  borderRadius={8}
                  marginRight={10}
                  minHeight={30}
                  height={30}
                  size={80}
                  invert={periodSelected === "7" ? false : true}
                  onClick={() => setPeriodSelected("7")}
                />
                <BaseButtons
                  label={"30 DAYS"}
                  mini={true}
                  marginTop={-1}
                  borderRadius={8}
                  marginRight={10}
                  minHeight={30}
                  height={30}
                  size={80}
                  invert={periodSelected === "30" ? false : true}
                  onClick={() => setPeriodSelected("30")}
                />
                {/* <BaseButtons
                  label={"YEAR"}
                  mini={true}
                  marginTop={-1}
                  borderRadius={8}
                  marginRight={10}
                  minHeight={30}
                  height={30}
                  size={80}
                  invert={periodSelected === "365" ? false : true}
                  onClick={() => setPeriodSelected("365")}
                /> */}
              </div>
            </div>
          </div>
          <div
            style={{
              height: 50,
              // borderStyle: "solid",
              // width: 200,
              // marginRight: 20,
              paddingLeft: 10,
              paddingRight: 10,
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>
              <div
                style={{
                  fontSize: 10,
                  opacity: 0.6,
                  fontWeight: "bold",
                  width: "100%",
                  marginBottom: 3,
                }}
              >
                {selectedReportsDisplay === "products"
                  ? "SELECT PRODUCT"
                  : "SELECT STORE"}
              </div>

              {selectedReportsDisplay === "products" ? (
                <BaseButtons
                  label={
                    selectedProduct
                      ? selectedProduct.productName
                      : "SELECT PRODUCT"
                  }
                  mini={true}
                  marginTop={-1}
                  borderRadius={8}
                  // marginRight={10}
                  minHeight={30}
                  height={30}
                  size={180}
                  onClick={() => setModalSelection("products")}
                />
              ) : (
                <BaseButtons
                  label={
                    selectedStore ? selectedStore.storeName : "SELECT STORE"
                  }
                  mini={true}
                  marginTop={-1}
                  borderRadius={8}
                  // marginRight={10}
                  minHeight={30}
                  height={30}
                  size={240}
                  onClick={() => setModalSelection("store")}
                />
              )}
            </div>
            {selectedReportsDisplay === "products" ? (
              <div>
                <div
                  style={{
                    fontSize: 10,
                    opacity: 0.6,
                    fontWeight: "bold",
                    width: "100%",
                    marginBottom: 3,
                    marginLeft: 10,
                  }}
                >
                  SELECT STORE
                </div>

                <BaseButtons
                  label={
                    selectedStore ? selectedStore.storeName : "SELECT STORE"
                  }
                  mini={true}
                  marginTop={-1}
                  borderRadius={8}
                  // marginRight={10}
                  minHeight={30}
                  height={30}
                  size={180}
                  onClick={() => setModalSelection("store")}
                  marginLeft={10}
                />
              </div>
            ) : null}
          </div>
          <div
            style={{
              height: 50,
              // borderStyle: "solid",
              // width: 200,
              marginRight: 10,
              paddingLeft: 10,
              paddingRight: 10,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BaseButtons
              label={"STORES"}
              mini={true}
              marginTop={5}
              borderRadius={8}
              marginRight={10}
              minHeight={35}
              height={40}
              size={100}
              invert={selectedReportsDisplay === "stores" ? false : true}
              onClick={() => setSelectedReportsDisplay("stores")}
            />{" "}
            <BaseButtons
              borderRadius={8}
              label={"PRODUCT"}
              mini={true}
              marginTop={5}
              minHeight={35}
              height={40}
              disable={true}
              size={100}
              // invert={selectedReportsDisplay === "products" ? false : true}
              onClick={() => setSelectedReportsDisplay("products")}
            />
          </div>
        </div>
      ) : null}
      {subSelection === "dashboard" ? (
        <div
          style={{
            marginBottom: 10,
            marginTop: 10,
            display: "flex",
            width: "100%",

            alignItems: "center",
          }}
        >
          {" "}
          <div
            style={{
              marginLeft: 20,
              fontSize: 30,
              fontWeight: "bold",
              marginTop: 15,
            }}
          >
            {subSelection.toUpperCase()}
          </div>
          <div style={{ flex: 1 }} />
        </div>
      ) : null}
      {subSelection === "dashboard" ? (
        <>
          {" "}
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
              alignItems: "center",
              minHeight: 200,
              maxHeight: 200,
            }}
          >
            <DashboardDisplayButton
              title={"SALES"}
              value={
                salesToDate === "loading..."
                  ? "loading"
                  : "R " + Number(salesToDate).toFixed(2)
              }
              point1={"TODAY"}
              point2={"7 days"}
              point3={"30 DAYS"}
              value1={
                salesToday === "loading..."
                  ? "loading"
                  : "R " + Number(salesToday).toFixed(2)
              }
              value2={
                sales7Days === "loading..."
                  ? "loading"
                  : "R " + Number(sales7Days).toFixed(2)
              }
              value3={
                sales30Days === "loading..."
                  ? "loading"
                  : "R " + Number(sales30Days).toFixed(2)
              }
            />
            <DashboardDisplayButton
              title={"INVENTORY"}
              value={100000}
              point1={"TODAY"}
              point2={"YESTERDAY"}
              point3={"7 DAYS"}
              value1={"10000"}
              value2={"10000"}
              value3={"10000"}
            />
            <DashboardDisplayButton
              title={"REGISTER"}
              value={100000}
              point1={"TODAY"}
              point2={"YESTERDAY"}
              point3={"7 DAYS"}
              value1={"10000"}
              value2={"10000"}
              value3={"10000"}
            />
            <DashboardDisplayButton
              title={"PAYMENTS"}
              value={100000}
              point1={"CASH"}
              point2={"CARD"}
              point3={"POINTS"}
              value1={"10000"}
              value2={"10000"}
              value3={"10000"}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
              alignItems: "center",
              minHeight: 200,
              maxHeight: 200,
            }}
          >
            <DashboardDisplayButton
              title={"CUSTOMERS"}
              value={100000}
              point1={"TODAY"}
              point2={"YESTERDAY"}
              point3={"7 DAYS"}
              value1={"10000"}
              value2={"10000"}
              value3={"10000"}
            />
            <DashboardDisplayButton
              title={"FACILITIES"}
              value={100000}
              point1={"TODAY"}
              point2={"YESTERDAY"}
              point3={"7 DAYS"}
              value1={"10000"}
              value2={"10000"}
              value3={"10000"}
            />
            <DashboardDisplayButton
              title={"PAYMENTS"}
              value={100000}
              point1={"CASH"}
              point2={"CARD"}
              point3={"POINTS"}
              value1={"10000"}
              value2={"10000"}
              value3={"10000"}
            />
            <DashboardDisplayButton
              title={"PAYMENTS"}
              value={100000}
              point1={"CASH"}
              point2={"CARD"}
              point3={"POINTS"}
              value1={"10000"}
              value2={"10000"}
              value3={"10000"}
            />
          </div>
        </>
      ) : null}
      {/* {subSelection === "sales" ? (
        <div
          style={{
            width: "100%",
            minHeight: 50,
            display: "flex",
            alignItems: "center",
            backgroundColor: BlackAndWhite.primary,
            borderTopRightRadius: 8,
            borderTopLeftRadius: 8,
            color: BlackAndWhite.secondary,
            fontSize: 12,
          }}
        >
          <div style={{ marginLeft: 10, flex: 0.3 }}>SLIP NO</div>
          <div
            style={{
              flex: 0.1,
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
            }}
          >
            DATE
          </div>
          <div
            style={{
              flex: 0.1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            TIME
          </div>
          <div style={{ flex: 0.2 }}>
            <div style={{ marginLeft: 5 }}>CASHIER</div>
          </div>
          <div style={{ flex: 0.2 }}>CUSTOMER</div>

          <div style={{ flex: 0.1 }}>TOTAL</div>
        </div>
      ) : null} */}
      {subSelection === "sales" ? (
        <div
          style={{
            // minHeight: 500,
            // maxHeight: 500,
            flex: 1,
            display: "flex",
            justifyContent: "flex-start",
            overflowY: "scroll",
            overflowX: "hidden",
            flexDirection: "column",
            scrollbarWidth: "none",
          }}
        >
          <div
            style={{
              width: "100%",

              flex: 0.7,
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              overflow: "hidden",
              minHeight: 710,
            }}
          >
            {/* // report number display */}
            <div
              style={{
                height: "92%",
                minWidth: 350,
                width: 500,
                backgroundColor: BlackAndWhite.secondary,
                WebkitBoxShadow: "0px 0px 7px 2px #00000050",
                borderRadius: 10,
                marginLeft: 25,
                minHeight: 540,
                display: "flex",
                flexDirection: "column",
                overflowY: "scroll",
                overflowX: "hidden",
                scrollbarWidth: "none",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginTop: 10,
                }}
              >
                <div
                  style={{ marginLeft: 10, fontSize: 18, fontWeight: "bold" }}
                >
                  {periodSelected === "1" ? "TODAY'S REPORT" : null}
                  {periodSelected === "2" ? "YESTERDAY'S REPORT" : null}

                  {periodSelected === "7" ? "WEEKLY REPORT" : null}
                  {periodSelected === "30" ? "MONTHLY REPORT" : null}
                  {periodSelected === "365" ? "YEARLY REPORT" : null}
                </div>
                <div style={{ flex: 1 }} />
                <BaseButtons
                  mini={true}
                  borderRadius={4}
                  height={35}
                  marginTop={-1}
                  label={"generate report"}
                  marginRight={10}
                  size={162}
                  onClick={() => createGeneratedReport()}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 10,
                }}
              >
                <div
                  style={{
                    marginLeft: 10,
                    fontSize: 12,
                    fontWeight: "normal",
                    marginTop: 10,
                  }}
                >
                  DATE: {dateSelection ? dateSelection : "Loading"}
                </div>
                <div style={{ flex: 1 }} />
                <div
                  style={{
                    width: 156,
                    borderLeftStyle: "solid",
                    borderRightStyle: "solid",
                    marginRight: 10,
                    textAlign: "center",
                    marginTop: 10,
                    fontWeight: "bold",
                    fontSize: 12,
                  }}
                >
                  SUMMARY
                </div>
              </div>
              {/* net sales */}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                <div style={{ marginLeft: 10, fontSize: 16 }}>
                  {" "}
                  Net sales ( incl vat )
                </div>
                <div style={{ flex: 1 }} />
                <div
                  style={{
                    width: 160,
                    // borderLeftStyle: "solid",
                    // borderRightStyle: "solid",
                    marginRight: 12,
                    textAlign: "center",
                    fontSize: 16,
                    backgroundColor: "#00000030",
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}
                >
                  R {netSales ? netSales.toFixed(2) : " 0.00"}
                </div>
              </div>
              {/* net sales  ex vat*/}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 3,
                }}
              >
                <div style={{ marginLeft: 10, fontSize: 16 }}>
                  Net sales ( Ex vat )
                </div>
                <div style={{ flex: 1 }} />
                <div
                  style={{
                    width: 160,
                    // borderLeftStyle: "solid",
                    // borderRightStyle: "solid",
                    marginRight: 12,
                    textAlign: "center",
                    fontSize: 16,
                    backgroundColor: "#00000030",
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}
                >
                  R{" "}
                  {netSales && netSalesEx
                    ? (netSales - netSalesEx).toFixed(2)
                    : " 0.00"}
                </div>
              </div>
              {/* refunds incl vat*/}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 3,
                }}
              >
                <div style={{ marginLeft: 10, fontSize: 16 }}>
                  Refunds ( Incl vat )
                </div>
                <div style={{ flex: 1 }} />
                <div
                  style={{
                    width: 160,
                    // borderLeftStyle: "solid",
                    // borderRightStyle: "solid",
                    marginRight: 12,
                    textAlign: "center",
                    fontSize: 16,
                    backgroundColor: "#00000030",
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}
                >
                  R {refundsReport ? refundsReport.toFixed(2) : " 0.00"}
                </div>
              </div>
              {/* discount incl vat*/}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 3,
                }}
              >
                <div style={{ marginLeft: 10, fontSize: 16 }}>
                  Discounts ( Incl vat )
                </div>
                <div style={{ flex: 1 }} />
                <div
                  style={{
                    width: 160,
                    // borderLeftStyle: "solid",
                    // borderRightStyle: "solid",
                    marginRight: 12,
                    textAlign: "center",
                    fontSize: 16,
                    backgroundColor: "#00000030",
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}
                >
                  R {discountReport ? discountReport.toFixed(2) : " 0.00"}
                </div>
              </div>

              {/* less refubds total sales incl vat*/}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 3,
                }}
              >
                <div style={{ marginLeft: 10, fontSize: 16 }}>
                  Net sales ( incl ) total less refunds
                </div>
                <div style={{ flex: 1 }} />
                <div
                  style={{
                    width: 160,
                    // borderLeftStyle: "solid",
                    // borderRightStyle: "solid",
                    marginRight: 12,
                    textAlign: "center",
                    fontSize: 16,
                    backgroundColor: "#00000030",
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}
                >
                  R{" "}
                  {netSales && refundsReport
                    ? (netSales - refundsReport).toFixed(2)
                    : " 0.00"}
                </div>
              </div>

              {/* less refubds total sales ex vat*/}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 3,
                }}
              >
                <div style={{ marginLeft: 10, fontSize: 16 }}>
                  Net sales ( Ex ) total less refunds
                </div>
                <div style={{ flex: 1 }} />
                <div
                  style={{
                    width: 160,
                    // borderLeftStyle: "solid",
                    // borderRightStyle: "solid",
                    marginRight: 12,
                    textAlign: "center",
                    fontSize: 16,
                    backgroundColor: "#00000030",
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}
                >
                  R{" "}
                  {netSales && refundsReport
                    ? (netSales - netSalesEx - refundsReport).toFixed(2)
                    : " 0.00"}
                </div>
              </div>

              {/* Merry-Jane service fee*/}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 3,
                }}
              >
                <div style={{ marginLeft: 10, fontSize: 16 }}>
                  Merry-Jane service fee ( 15% )
                </div>
                <div style={{ flex: 1 }} />
                <div
                  style={{
                    width: 160,
                    // borderLeftStyle: "solid",
                    // borderRightStyle: "solid",
                    marginRight: 12,
                    textAlign: "center",
                    fontSize: 16,
                    backgroundColor: "#00000030",
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}
                >
                  R {mjFee ? mjFee.toFixed(2) : " 0.00"}
                </div>
              </div>

              {/* prescription fee service fee*/}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 3,
                }}
              >
                <div style={{ marginLeft: 10, fontSize: 16 }}>
                  Prescription fee ( 1% )
                </div>
                <div style={{ flex: 1 }} />
                <div
                  style={{
                    width: 160,
                    // borderLeftStyle: "solid",
                    // borderRightStyle: "solid",
                    marginRight: 12,
                    textAlign: "center",
                    fontSize: 16,
                    backgroundColor: "#00000030",
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}
                >
                  R {prescriptionFee ? prescriptionFee.toFixed(2) : " 0.00"}
                </div>
              </div>

              {/* Cost of goods sold*/}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 3,
                }}
              >
                <div style={{ marginLeft: 10, fontSize: 16 }}>
                  Cost of goods sold
                </div>
                <div style={{ flex: 1 }} />
                <div
                  style={{
                    width: 160,
                    // borderLeftStyle: "solid",
                    // borderRightStyle: "solid",
                    marginRight: 12,
                    textAlign: "center",
                    fontSize: 16,
                    backgroundColor: "#00000030",
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}
                >
                  R {cogReport ? cogReport.toFixed(2) : " 0.00"}
                </div>
              </div>
              {/* mj wholes sale value*/}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 3,
                }}
              >
                <div style={{ marginLeft: 10, fontSize: 16 }}>
                  Wholesale COG
                </div>
                <div style={{ flex: 1 }} />
                <div
                  style={{
                    width: 160,
                    // borderLeftStyle: "solid",
                    // borderRightStyle: "solid",
                    marginRight: 12,
                    textAlign: "center",
                    fontSize: 16,
                    backgroundColor: "#00000030",
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}
                >
                  R{" "}
                  {mjWholeSale
                    ? Number(mjWholeSale + cogReport).toFixed(2)
                    : " 0.00"}
                </div>
              </div>
              {/* Card transactions merry jane holdings (PTY LTD*/}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 3,
                }}
              >
                <div style={{ marginLeft: 10, fontSize: 16 }}>
                  Card trans Merry jane holdings
                </div>
                <div style={{ flex: 1 }} />
                <div
                  style={{
                    width: 160,
                    // borderLeftStyle: "solid",
                    // borderRightStyle: "solid",
                    marginRight: 12,
                    textAlign: "center",
                    fontSize: 16,
                    backgroundColor: "#00000030",
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}
                >
                  R {cardReport ? cardReport.toFixed(2) : " 0.00"}
                </div>
              </div>

              {/* cash recieved in-store*/}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 3,
                }}
              >
                <div style={{ marginLeft: 10, fontSize: 16 }}>
                  Cash recieved in-store
                </div>
                <div style={{ flex: 1 }} />
                <div
                  style={{
                    width: 160,
                    // borderLeftStyle: "solid",
                    // borderRightStyle: "solid",
                    marginRight: 12,
                    textAlign: "center",
                    fontSize: 16,
                    backgroundColor: "#00000030",
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}
                >
                  R {cashReport ? cashReport.toFixed(2) : " 0.00"}
                </div>
              </div>

              {/* insurnace premiumin-store*/}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 3,
                }}
              >
                <div style={{ marginLeft: 10, fontSize: 16 }}>
                  Insurance premiums
                </div>
                <div style={{ flex: 1 }} />
                <div
                  style={{
                    width: 160,
                    // borderLeftStyle: "solid",
                    // borderRightStyle: "solid",
                    marginRight: 12,
                    textAlign: "center",
                    fontSize: 16,
                    backgroundColor: "#00000030",
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}
                >
                  ?
                </div>
              </div>

              {/*  Total deductions*/}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 30,
                }}
              >
                <div style={{ marginLeft: 10, fontSize: 16 }}>
                  Total deductions
                </div>
                <div style={{ flex: 1 }} />

                <div
                  style={{
                    width: 160,
                    // borderLeftStyle: "solid",
                    // borderRightStyle: "solid",
                    marginRight: 12,
                    textAlign: "center",
                    fontSize: 16,
                    backgroundColor: "#00000030",
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}
                >
                  R{" "}
                  {prescriptionFee && mjFee
                    ? (
                        mjFee +
                        prescriptionFee +
                        mjWholeSale +
                        vatReport +
                        cogReport
                      ).toFixed(2)
                    : " 0.00"}
                </div>
              </div>
              {/*    Amount due*/}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 3,
                }}
              >
                <div style={{ marginLeft: 10, fontSize: 16 }}>Amount due</div>
                <div style={{ flex: 1 }} />

                <div
                  style={{
                    width: 160,
                    // borderLeftStyle: "solid",
                    // borderRightStyle: "solid",
                    marginRight: 12,
                    textAlign: "center",
                    fontSize: 16,
                    backgroundColor: "#00000030",
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}
                >
                  R{" "}
                  {mjFee
                    ? (
                        netSales -
                        (mjFee +
                          prescriptionFee +
                          mjWholeSale +
                          vatReport +
                          cogReport)
                      ).toFixed(2)
                    : "0.00"}
                </div>
              </div>
              {/*    Total services*/}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 3,
                }}
              >
                <div style={{ marginLeft: 10, fontSize: 16 }}>
                  Total services
                </div>
                <div style={{ flex: 1 }} />

                <div
                  style={{
                    width: 160,
                    // borderLeftStyle: "solid",
                    // borderRightStyle: "solid",
                    marginRight: 12,
                    textAlign: "center",
                    fontSize: 16,
                    backgroundColor: "#00000030",
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}
                >
                  R{" "}
                  {prescriptionFee && mjFee
                    ? (mjFee + prescriptionFee + mjWholeSale).toFixed(2)
                    : " 0.00"}
                </div>
              </div>
            </div>
            <div
              style={{
                flex: 1,
                height: "92%",
                backgroundColor: BlackAndWhite.secondary,
                WebkitBoxShadow: "0px 0px 7px 2px #00000050",
                borderRadius: 10,
                marginLeft: 30,
                marginRight: 25,
                minHeight: 400,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* header options */}
              <div
                style={{
                  height: 65,

                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <BaseButtons
                  mini={true}
                  borderRadius={4}
                  height={35}
                  marginTop={-1}
                  label={"COG"}
                  marginRight={10}
                  size={110}
                  marginLeft={10}
                  disable={true}
                />
                <BaseButtons
                  mini={true}
                  borderRadius={4}
                  height={35}
                  marginTop={-1}
                  label={"SALES"}
                  marginRight={10}
                  size={110}
                  marginLeft={20}
                  disable={true}
                />
                <BaseButtons
                  mini={true}
                  borderRadius={4}
                  height={35}
                  marginTop={-1}
                  label={"NET SALES "}
                  marginRight={10}
                  size={110}
                  marginLeft={20}
                />
                <BaseButtons
                  mini={true}
                  borderRadius={4}
                  height={35}
                  marginTop={-1}
                  label={"REFUNDS"}
                  marginRight={10}
                  size={110}
                  marginLeft={20}
                  disable={true}
                />
                <BaseButtons
                  mini={true}
                  borderRadius={4}
                  height={35}
                  marginTop={-1}
                  label={"CASH SALES"}
                  marginRight={10}
                  size={110}
                  marginLeft={20}
                  disable={true}
                />
                <BaseButtons
                  mini={true}
                  borderRadius={4}
                  height={35}
                  marginTop={-1}
                  label={"CARD SALES "}
                  marginRight={10}
                  size={110}
                  marginLeft={20}
                  disable={true}
                />
                <BaseButtons
                  mini={true}
                  borderRadius={4}
                  height={35}
                  marginTop={-1}
                  label={"SERVICE FEE"}
                  marginRight={10}
                  size={110}
                  marginLeft={20}
                  disable={true}
                />
              </div>
              {/* graph options displayed */}
              <div
                style={{
                  width: "95%",
                  marginTop: 10,
                  flex: 1,
                  marginLeft: 20,
                }}
              >
                <Line
                  width={200}
                  height={112}
                  //   options={}
                  data={data}
                  // style={{ height: 300, width: "20%" }}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              // borderStyle: "solid",
              minHeight: 750,
              height: 750,
              marginBottom: 20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* report list */}
            <div
              style={{
                height: "95%",

                width: "97%",
                backgroundColor: BlackAndWhite.secondary,
                WebkitBoxShadow: "0px 0px 7px 2px #00000050",
                borderRadius: 10,
                // marginLeft: 25,
                minHeight: 400,
                marginLeft: 0,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: 50,

                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{ marginLeft: 10, fontSize: 18, fontWeight: "bold" }}
                >
                  GENERATED REPORTS LIST
                </div>
                <div style={{ marginLeft: 10, marginRight: 10 }}>
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",

                      // width: 300,

                      borderWidth: 0.5,
                    }}
                  >
                    <TextInputFieldSearch
                      placeholder={"Type here to search"}
                      height={25}
                      borderRadius={4}
                      onChange={(e) => setSearch(e.target.value)}
                      value={search}
                      fontSize={14}
                    />
                    <div
                      style={{
                        height: 30,
                        width: 70,
                        backgroundColor: BlackAndWhite.primary,
                        borderRadius: 4,
                        display: "flex",
                        color: BlackAndWhite.secondary,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        onClick={() => setSearch("")}
                        style={{ fontSize: 12, fontWeight: "bold" }}
                      >
                        CLEAR
                      </div>
                    </div>
                    <div
                      style={{
                        height: 30,
                        width: 70,
                        backgroundColor: BlackAndWhite.primary,
                        borderRadius: 4,
                        display: "flex",
                        color: BlackAndWhite.secondary,
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: 5,
                      }}
                    >
                      <div
                        onClick={() => setSearch("")}
                        style={{ fontSize: 12, fontWeight: "bold" }}
                      >
                        SEARCH
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ flex: 1 }} />

                <BaseButtons
                  mini={true}
                  borderRadius={4}
                  height={30}
                  marginTop={-1}
                  label={"GENERATED REPORTS"}
                  marginRight={10}
                  size={200}
                  // marginLeft={10}
                  onClick={() => setSelectedReportList("generatedReports")}
                  invert={
                    selectedReportsList === "generatedReports" ? false : true
                  }
                />

                {selectedReportsDisplay === "stores" ? (
                  <BaseButtons
                    mini={true}
                    borderRadius={4}
                    height={30}
                    marginTop={-1}
                    label={"REPORTS"}
                    marginRight={10}
                    size={110}
                    // marginLeft={10}
                    onClick={() => setSelectedReportList("reports")}
                    invert={selectedReportsList === "reports" ? false : true}
                  />
                ) : null}
                <BaseButtons
                  mini={true}
                  borderRadius={4}
                  height={30}
                  marginTop={-1}
                  label={"RECEIPTS"}
                  marginRight={10}
                  size={110}
                  onClick={() => setSelectedReportList("receipts")}
                  invert={selectedReportsList === "receipts" ? false : true}
                  // marginLeft={10}
                />
                <BaseButtons
                  mini={true}
                  borderRadius={4}
                  height={30}
                  marginTop={-1}
                  label={"EXCHANGES"}
                  marginRight={10}
                  size={110}
                  invert={selectedReportsList === "exchanges" ? false : true}
                  onClick={() => setSelectedReportList("exchanges")}
                  // marginLeft={10}
                />
                <BaseButtons
                  mini={true}
                  borderRadius={4}
                  height={30}
                  marginTop={-1}
                  label={"REFUNDS"}
                  marginRight={10}
                  size={110}
                  invert={selectedReportsList === "refunds" ? false : true}
                  onClick={() => setSelectedReportList("refunds")}
                  // marginLeft={10}
                />
              </div>
              <div
                style={{
                  flex: 1,

                  width: "100%",
                  flexDirection: "column",
                  alignItems: "center",
                  display: "flex",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  scrollbarWidth: "none",
                }}
              >
                {selectedReportsList === "refunds"
                  ? refundArray
                    ? refundArray.map((refund, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              width: "100%",
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            <RecipetItemReportCard
                              date={refund.dateTime.substring(0, 10)}
                              time={refund.dateTime.substring(11, 16)}
                              slipNo={refund.slipNoLinkedTo}
                              cashier={refund.name}
                              customerName={refund.customerName}
                              total={
                                "R " + Number(refund.refundedTotal).toFixed(2)
                              }
                            />
                          </div>
                        );
                      })
                    : null
                  : null}
                {selectedReportsList === "exchanges"
                  ? exchangeArray
                    ? exchangeArray.map((exchange, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              width: "100%",
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            <RecipetItemReportCard
                              date={exchange.dateTime.substring(0, 10)}
                              time={exchange.dateTime.substring(11, 16)}
                              slipNo={exchange.slipNoLinkedTo}
                              cashier={exchange.name}
                              customerName={exchange.customerName}
                              total={"R " + Number(exchange.total).toFixed(2)}
                            />
                          </div>
                        );
                      })
                    : null
                  : null}
                {selectedReportsList === "receipts"
                  ? receiptsArray
                    ? receiptsArray.map((receipt, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              width: "100%",
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            <RecipetItemReportCard
                              date={receipt.dateTime.substring(0, 10)}
                              time={receipt.dateTime.substring(11, 16)}
                              slipNo={receipt.slipNo}
                              cashier={receipt.name}
                              customerName={receipt.customerName}
                              total={"R " + Number(receipt.total).toFixed(2)}
                            />
                          </div>
                        );
                      })
                    : null
                  : null}

                {selectedReportsList === "reports" &&
                selectedReportsDisplay === "stores"
                  ? cashregisterReport
                    ? cashregisterReport.map((item, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <GeneratedReportCard
                              date={item.openedBy}
                              time={item.closedBy}
                              slipNo={
                                item.openedAt.substring(0, 10) +
                                " at " +
                                item.openedAt.substring(11, 16) +
                                " TO " +
                                item.closedAt.substring(0, 10) +
                                " at " +
                                item.closedAt.substring(11, 16)
                              }
                              // cashier={"1"}
                              // customerName={"2"}
                              total={"R " + Number(item.TotalInc).toFixed(2)}
                            />
                          </div>
                        );
                      })
                    : null
                  : null}

                {selectedReportsList === "generatedReports" &&
                selectedReportsDisplay === "stores"
                  ? generatedReports
                    ? generatedReports.map((item, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: 20,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "95%",
                                minHeight: 80,
                                WebkitBoxShadow: "0px 0px 7px 2px #00000050",
                                borderRadius: 8,
                                backgroundColor: BlackAndWhite.secondary,
                                alignItems: "center",
                              }}
                            >
                              <div style={{ marginLeft: 20 }}>
                                <div
                                  style={{
                                    marginBottom: 4,
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    opacity: 0.6,
                                  }}
                                >
                                  Count:
                                </div>
                                <div> {index + 1}</div>
                              </div>
                              <div style={{ marginLeft: 20 }}>
                                <div
                                  style={{
                                    marginBottom: 4,
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    opacity: 0.6,
                                  }}
                                >
                                  Type:
                                </div>
                                <div> {item.reportType}</div>
                              </div>
                              <div style={{ marginLeft: 20 }}>
                                <div
                                  style={{
                                    marginBottom: 4,
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    opacity: 0.6,
                                  }}
                                >
                                  Date covered:
                                </div>

                                <div> {item.dateFromTo}</div>
                              </div>
                              <div style={{ marginLeft: 20 }}>
                                <div
                                  style={{
                                    marginBottom: 4,
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    opacity: 0.6,
                                  }}
                                >
                                  Time frame:
                                </div>

                                <div> {item.period} days</div>
                              </div>
                              <div style={{ marginLeft: 20 }}>
                                <div
                                  style={{
                                    marginBottom: 4,
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    opacity: 0.6,
                                  }}
                                >
                                  No of sales:
                                </div>

                                <div> {item.receiptsArray.length}</div>
                              </div>
                              <div style={{ marginLeft: 20 }}>
                                <div
                                  style={{
                                    marginBottom: 4,
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    opacity: 0.6,
                                  }}
                                >
                                  No. of refunds:
                                </div>

                                <div> {item.refundsArray.length}</div>
                              </div>
                              <div style={{ marginLeft: 20 }}>
                                <div
                                  style={{
                                    marginBottom: 4,
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    opacity: 0.6,
                                  }}
                                >
                                  Created by:
                                </div>

                                <div> {item.requestedBy}</div>
                              </div>

                              <div style={{ marginLeft: 20 }}>
                                <div
                                  style={{
                                    marginBottom: 4,
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    opacity: 0.6,
                                  }}
                                >
                                  Total sales:
                                </div>

                                <div>R {item.netSalesIncludingVat}</div>
                              </div>
                              <div style={{ marginLeft: 20 }}>
                                <div
                                  style={{
                                    marginBottom: 4,
                                    fontSize: 10,
                                    fontWeight: "bold",
                                    opacity: 0.6,
                                  }}
                                >
                                  Total services:
                                </div>

                                <div>R {item.totalServices}</div>
                              </div>
                              <div style={{ flex: 1 }} />
                              <BaseButtons
                                mini={true}
                                borderRadius={4}
                                height={40}
                                marginTop={-1}
                                label={"VIEW"}
                                marginRight={20}
                                size={110}
                                fontWeight={"bold"}
                                onClick={() => setGeneratedReportView(item)}
                                // marginLeft={10}
                              />
                            </div>
                          </div>
                        );
                      })
                    : null
                  : null}

                <div style={{ minHeight: 20 }} />
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <LoadingPopUp display={loading} newWidth={"100%"} />
      <ModalLayout
        display={
          modalSelection === "store"
            ? true
            : selectedStore
            ? false
            : subSelection === "sales"
            ? true
            : false
        }
        page={
          <div
            style={{
              height: "80%",
              width: "80%",
              backgroundColor: BlackAndWhite.secondary,
              borderRadius: 20,
              WebkitBoxShadow: "0px 0px 7px 2px #00000050",
              minHeight: 600,
              minWidth: 600,
              maxWidth: 600,
              display: "flex",
              position: "relative",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <div style={{ position: "absolute", top: 20, left: 20 }}>
              <CloseButton label={"X"} onClick={() => setModalSelection("")} />
            </div>
            <div
              style={{
                width: "100%",
                height: 70,

                display: "flex",
                alignItems: "center",
                WebkitBoxShadow: "0px -2px 7px 2px #00000050",
              }}
            >
              <div style={{ marginLeft: 80, fontSize: 18, fontWeight: "bold" }}>
                STORE SELECTION
              </div>
              <div style={{ flex: 1 }} />
              <div style={{ marginLeft: 10, marginRight: 20 }}>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                    // width: 300,

                    borderWidth: 0.5,
                  }}
                >
                  <TextInputFieldSearch
                    placeholder={"Type here to search"}
                    height={25}
                    borderRadius={4}
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                    fontSize={14}
                  />
                  <div
                    style={{
                      height: 30,
                      width: 70,
                      backgroundColor: BlackAndWhite.primary,
                      borderRadius: 4,
                      display: "flex",
                      color: BlackAndWhite.secondary,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      onClick={() => setSearch("")}
                      style={{ fontSize: 12, fontWeight: "bold" }}
                    >
                      CLEAR
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                flex: 1,

                width: "100%",
                overflowY: "scroll",
                overflowX: "hidden",
                scrollbarWidth: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                <StoreCard
                  storeName={"All stores"}
                  onClick={() => {
                    setSelectedStore({
                      storeName: "All",
                    });
                    setModalSelection("");
                  }}
                />
              </div>
              {storeArray
                ? storeArray.map((store, index) => {
                    return store.status === "OPEN" ? (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: 20,
                        }}
                      >
                        <StoreCard
                          storeName={store.storeName}
                          onClick={() => {
                            setSelectedStore(store);
                            setModalSelection("");
                          }}
                        />
                      </div>
                    ) : null;
                  })
                : null}

              <div style={{ minHeight: 20 }} />
            </div>
          </div>
        }
      />
      <ModalLayout
        display={modalSelection === "products" ? true : false}
        page={
          <div
            style={{
              height: "80%",
              width: "80%",
              backgroundColor: BlackAndWhite.secondary,
              borderRadius: 20,
              WebkitBoxShadow: "0px 0px 7px 2px #00000050",
              minHeight: 600,
              minWidth: 650,
              maxWidth: 600,
              display: "flex",
              position: "relative",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <div style={{ position: "absolute", top: 20, left: 20 }}>
              <CloseButton label={"X"} onClick={() => setModalSelection("")} />
            </div>
            <div
              style={{
                width: "100%",
                height: 70,

                display: "flex",
                alignItems: "center",
                WebkitBoxShadow: "0px -2px 7px 2px #00000050",
              }}
            >
              <div style={{ marginLeft: 80, fontSize: 18, fontWeight: "bold" }}>
                PRODUCT SELECTION
              </div>
              <div style={{ flex: 1 }} />
              <div style={{ marginLeft: 10, marginRight: 20 }}>
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                    // width: 300,

                    borderWidth: 0.5,
                  }}
                >
                  <TextInputFieldSearch
                    placeholder={"Type here to search"}
                    height={25}
                    borderRadius={4}
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                    fontSize={14}
                  />
                  <div
                    style={{
                      height: 30,
                      width: 70,
                      backgroundColor: BlackAndWhite.primary,
                      borderRadius: 4,
                      display: "flex",
                      color: BlackAndWhite.secondary,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      onClick={() => setSearch("")}
                      style={{ fontSize: 12, fontWeight: "bold" }}
                    >
                      CLEAR
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                flex: 1,

                width: "100%",
                overflowY: "scroll",
                overflowX: "hidden",
                scrollbarWidth: "none",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {productArray
                ? productArray.map((product, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: 20,
                        }}
                      >
                        <div
                          onClick={() => {
                            setSelectedProduct(product);
                            setModalSelection("");
                          }}
                          style={{
                            width: "90%",
                            minHeight: 100,
                            borderRadius: 8,
                            backgroundColor: BlackAndWhite.secondary,
                            WebkitBoxShadow: "0px 0px 7px 2px #00000050",
                          }}
                        >
                          {product.productName}
                        </div>
                      </div>
                    );
                  })
                : null}

              <div style={{ minHeight: 20 }} />
            </div>
          </div>
        }
      />
      <ModalLayout
        display={generatedReportView}
        page={
          <div
            style={{
              minHeight: 656,
              minWidth: 600,
              backgroundColor: BlackAndWhite.secondary,
              borderRadius: 20,
              position: "relative",
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 20,
                left: 20,
                display: "flex",
                alignItems: "center",
              }}
            >
              <CloseButton
                label={"X"}
                onClick={() => setGeneratedReportView(null)}
              />
              <div style={{ marginLeft: 20, fontSize: 18, fontWeight: "bold" }}>
                {generatedReportView ? generatedReportView.reportType : null}
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                top: 20,
                right: 30,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  marginBottom: 4,
                  fontSize: 10,
                  fontWeight: "bold",
                  opacity: 0.6,
                  width: "100%",
                }}
              >
                DATE REQUESTED:
              </div>
              <div>
                {generatedReportView
                  ? generatedReportView.dateRequested.substring(0, 21)
                  : null}
              </div>
            </div>
            <div style={{ minHeight: 60, width: "100%" }}></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 10,
              }}
            >
              <div
                style={{
                  marginLeft: 25,
                  fontSize: 16,
                  fontWeight: "normal",
                  marginTop: 10,
                }}
              >
                <div
                  style={{
                    marginBottom: 4,
                    fontSize: 10,
                    fontWeight: "bold",
                    opacity: 0.6,
                    width: "100%",
                  }}
                >
                  PERIOD:
                </div>
                <div>
                  {" "}
                  {generatedReportView ? generatedReportView.dateFromTo : null}
                </div>
              </div>
              <div style={{ flex: 1 }} />
              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  // borderTopStyle: "solid",
                  // borderBottomStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  marginTop: 10,
                  fontWeight: "bold",
                  fontSize: 14,
                  paddingTop: 10,
                  paddingBottom: 10,
                  backgroundColor: BlackAndWhite.primary,
                  color: BlackAndWhite.secondary,
                }}
              >
                SUMMARY
              </div>
            </div>

            {/* net sales */}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <div style={{ marginLeft: 25, fontSize: 16 }}>
                {" "}
                Net sales ( incl vat )
              </div>
              <div style={{ flex: 1 }} />
              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  fontSize: 16,
                  backgroundColor: "#00000030",
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                R{" "}
                {generatedReportView
                  ? generatedReportView.netSalesIncludingVat
                  : null}
              </div>
            </div>
            {/* net sales  ex vat*/}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <div style={{ marginLeft: 25, fontSize: 16 }}>
                Net sales ( Ex vat )
              </div>
              <div style={{ flex: 1 }} />
              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  fontSize: 16,
                  backgroundColor: "#00000030",
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                R{" "}
                {generatedReportView
                  ? generatedReportView.netSalesExcludingVat
                  : null}
              </div>
            </div>
            {/* refunds incl vat*/}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <div style={{ marginLeft: 25, fontSize: 16 }}>
                Refunds ( Incl vat )
              </div>
              <div style={{ flex: 1 }} />
              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  fontSize: 16,
                  backgroundColor: "#00000030",
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                R {generatedReportView ? generatedReportView.refunds : null}
              </div>
            </div>
            {/* discount incl vat*/}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <div style={{ marginLeft: 25, fontSize: 16 }}>
                Discounts ( Incl vat )
              </div>
              <div style={{ flex: 1 }} />
              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  fontSize: 16,
                  backgroundColor: "#00000030",
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                R {generatedReportView ? generatedReportView.discounts : null}
              </div>
            </div>

            {/* less refubds total sales incl vat*/}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <div style={{ marginLeft: 25, fontSize: 16 }}>
                Net sales ( incl ) total less refunds
              </div>
              <div style={{ flex: 1 }} />
              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  fontSize: 16,
                  backgroundColor: "#00000030",
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                R{" "}
                {generatedReportView
                  ? generatedReportView.netSalesInclLessRefunds
                  : null}
              </div>
            </div>

            {/* less refubds total sales ex vat*/}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <div style={{ marginLeft: 25, fontSize: 16 }}>
                Net sales ( Ex ) total less refunds
              </div>
              <div style={{ flex: 1 }} />
              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  fontSize: 16,
                  backgroundColor: "#00000030",
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                R{" "}
                {generatedReportView
                  ? generatedReportView.netSalesExLessRefunds
                  : null}
              </div>
            </div>

            {/* Merry-Jane service fee*/}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <div style={{ marginLeft: 25, fontSize: 16 }}>
                Merry-Jane service fee ( 15% )
              </div>
              <div style={{ flex: 1 }} />
              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  fontSize: 16,
                  backgroundColor: "#00000030",
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                R{" "}
                {generatedReportView
                  ? generatedReportView.merryJaneServiceFee
                  : null}
              </div>
            </div>

            {/* prescription fee service fee*/}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <div style={{ marginLeft: 25, fontSize: 16 }}>
                Prescription fee ( 1% )
              </div>
              <div style={{ flex: 1 }} />
              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  fontSize: 16,
                  backgroundColor: "#00000030",
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                R{" "}
                {generatedReportView
                  ? generatedReportView.prescriptionFee
                  : null}
              </div>
            </div>

            {/* Cost of goods sold*/}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <div style={{ marginLeft: 25, fontSize: 16 }}>
                Cost of goods sold
              </div>
              <div style={{ flex: 1 }} />
              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  fontSize: 16,
                  backgroundColor: "#00000030",
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                R{" "}
                {generatedReportView
                  ? generatedReportView.costOfGoodsSold
                  : null}
              </div>
            </div>
            {/* mj wholes sale value*/}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <div style={{ marginLeft: 25, fontSize: 16 }}>Wholesale COG</div>
              <div style={{ flex: 1 }} />
              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  fontSize: 16,
                  backgroundColor: "#00000030",
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                R{" "}
                {generatedReportView
                  ? generatedReportView.wholesalecostOfGoodsSold
                  : null}
              </div>
            </div>
            {/* Card transactions merry jane holdings (PTY LTD*/}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <div style={{ marginLeft: 25, fontSize: 16 }}>
                Card trans Merry jane holdings
              </div>
              <div style={{ flex: 1 }} />
              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  fontSize: 16,
                  backgroundColor: "#00000030",
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                R{" "}
                {generatedReportView
                  ? generatedReportView.cardTransactions
                  : null}
              </div>
            </div>

            {/* cash recieved in-store*/}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <div style={{ marginLeft: 25, fontSize: 16 }}>
                Cash recieved in-store
              </div>
              <div style={{ flex: 1 }} />
              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  fontSize: 16,
                  backgroundColor: "#00000030",
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                R{" "}
                {generatedReportView
                  ? generatedReportView.cashReceivedInstore
                  : null}
              </div>
            </div>

            {/* insurnace premiumin-store*/}
            {/* <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <div style={{ marginLeft: 25, fontSize: 16 }}>
                Insurance premiums
              </div>
              <div style={{ flex: 1 }} />
              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  fontSize: 16,
                  backgroundColor: "#00000030",
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                ?
              </div>
            </div> */}

            {/*  Total deductions*/}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 30,
              }}
            >
              <div style={{ marginLeft: 25, fontSize: 16 }}>
                Total deductions
              </div>
              <div style={{ flex: 1 }} />

              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  fontSize: 16,
                  backgroundColor: "#00000030",
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                R{" "}
                {generatedReportView
                  ? generatedReportView.totalDeductions
                  : null}
              </div>
            </div>
            {/*    Amount due*/}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <div style={{ marginLeft: 25, fontSize: 16 }}>Amount due</div>
              <div style={{ flex: 1 }} />

              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  fontSize: 16,
                  backgroundColor: "#00000030",
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              >
                R {generatedReportView ? generatedReportView.AmountDue : null}
              </div>
            </div>
            {/*    Total services*/}
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 3,
              }}
            >
              <div style={{ marginLeft: 25, fontSize: 16 }}>Total services</div>
              <div style={{ flex: 1 }} />

              <div
                style={{
                  width: 210,
                  // borderLeftStyle: "solid",
                  // borderRightStyle: "solid",
                  marginRight: 10,
                  textAlign: "center",
                  fontSize: 16,
                  backgroundColor: "#00000030",
                  paddingTop: 5,
                  paddingBottom: 5,
                  borderBottomRightRadius: 15,
                }}
              >
                R{" "}
                {generatedReportView ? generatedReportView.totalServices : null}
              </div>
            </div>
          </div>
        }
      />
      <ToastNotification display={toastPop} message={message.toUpperCase()} />
    </div>
  );
};

export default Reporting;
