import React, { useState, useEffect } from "react";
import { BlackAndWhite } from "../../themes/Colors";
import { BaseButtons, CloseButton } from "../atoms";
import AWS from "aws-sdk";

const ClosedMessageCard = ({
  image,
  messageTitle,
  quickMessage,
  date,
  read,
  tags,
  fullDescription,
  signOff,
  href,
  imageId,
  width,
}) => {
  const [open, setOpen] = useState(false);
  const [imageDownLoad, setImageDownLoad] = useState(null);
  const getUplaodImage = async () => {
    const S3_BUCKET = process.env.REACT_APP_BUCKET;
    const apiKey = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
    const secrectapiKey = process.env.REACT_APP_AWS_SECRET_KEY_ID;
    AWS.config.update({
      accessKeyId: apiKey,
      secretAccessKey: secrectapiKey,
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: process.env.REACT_APP_REGION,
      signatureVersion: "v4",
    });

    const params = {
      Bucket: S3_BUCKET,
      Key: imageId,
    };

    var promise = s3.getSignedUrlPromise("getObject", params);
    // return promise;
    promise.then(
      function (url) {
        if (url) {
          setImageDownLoad(url);
        }
        return url;
      },
      function (err) {
        return;
      }
    );
  };
  useEffect(() => {
    if (imageId) {
      getUplaodImage();
    }
  }, [imageId]);
  return (
    <div
      style={{
        width: width ? width : 350,
        height: open ? 600 : 350,

        // borderRadius: 8,
        backgroundColor: BlackAndWhite.secondary,
        WebkitBoxShadow: "1px 2px 10px 3px #00000080",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: open ? "flex-start" : "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          height: open ? null : "100%",
          width: "100%",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          onClick={() => setOpen(true)}
          alt="loading"
          src={imageId ? imageDownLoad : image}
          style={{
            backgroundColor: "#00000040",
            height: open ? 350 : "100%",
            width: "100%",
            // marginTop: open ? 50 : null,
          }}
        />
        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            backgroundColor: "#00000020",
            minHeight: 50,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "95%",
              marginTop: 5,
              fontWeight: "bold",
              color: BlackAndWhite.secondary,
            }}
          >
            {messageTitle}
          </div>
          <div
            style={{
              width: "95%",
              marginTop: 5,
              fontSize: 12,
              color: BlackAndWhite.secondary,
            }}
          >
            {quickMessage}
          </div>

          <div>{read}</div>
          <div
            style={{
              width: "95%",
              marginTop: 5,
              fontSize: 12,
              marginBottom: 5,
              display: "flex",
              color: BlackAndWhite.secondary,
            }}
          >
            {tags}
          </div>
        </div>
      </div>
      {open ? (
        <div
          style={{
            width: "100%",
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "90%",
              textAlign: "left",
              fontSize: 14,
              fontWeight: "bold",
              marginTop: 10,
            }}
          >
            MESSAGE:{" "}
          </div>
          <div
            style={{
              height: href ? 155 : 200,
              width: "100%",
              marginBottom: 10,
              overflowX: "hidden",
              overflowY: "scroll",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "90%",
                marginTop: 5,
                fontSize: 14,
              }}
            >
              <div style={{ width: "100%", marginBottom: 10 }}>
                {fullDescription}
              </div>
              <div style={{ width: "100%" }}>{signOff}</div>
            </div>
          </div>
          {href ? (
            <a
              href={href}
              target="_blank"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textDecoration: "none",
              }}
            >
              <BaseButtons
                label={"VISIT LINK"}
                mini={true}
                marginTopFalse={true}
                width={"100%"}
                size={"90%"}
                height={40}
              />
            </a>
          ) : null}
        </div>
      ) : null}
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,

          // backgroundColor: "#FFFFFF50",
          padding: 10,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          color: BlackAndWhite.secondary,
          fontSize: 14,
        }}
      >
        {date}
      </div>
      {open ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,

            // backgroundColor: "#FFFFFF50",
            padding: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            color: BlackAndWhite.secondary,
            fontSize: 14,
          }}
        >
          <CloseButton
            label={"X"}
            invert={true}
            onClick={() => setOpen(false)}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ClosedMessageCard;
